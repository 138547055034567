import { FORM_TYPES_V2 as FT2 } from '../enums/enumFormTypeV2'

// ND-Root.BT-01-notice
// ND-Root.ND-ProcedureTerms.ND-LocalLegalBasisWithID.0.BT-01(c)-Procedure
// ND-Root.ND-ProcedureTerms.ND-LocalLegalBasisWithID.0.BT-01(d)-Procedure
export const DEFAULT_DATA_NOTICE = {
  [FT2.F4]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F5]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F6]: {
    'BT-01-Notice': '32009L0081',
    'BT-01(c)-Procedure': '32009L0081',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2009/81/ES ze dne 13. července 2009 o koordinaci postupů při zadávání některých zakázek na stavební práce, dodávky a služby zadavateli v oblasti obrany a bezpečnosti a o změně směrnic 2004/17/ES a 2004/18/ES',
  },
  [FT2.F7]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F8]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F9]: {
    'BT-01-Notice': '32009L0081',
    'BT-01(c)-Procedure': '32009L0081',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2009/81/ES ze dne 13. července 2009 o koordinaci postupů při zadávání některých zakázek na stavební práce, dodávky a služby zadavateli v oblasti obrany a bezpečnosti a o změně směrnic 2004/17/ES a 2004/18/ES',
  },
  [FT2.F10]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F11]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F12]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F13]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F14]: {
    'BT-01-Notice': '32014L0023',
    'BT-01(c)-Procedure': '32014L0023',
    'BT-01(d)-Procedure': 'Směrnice Evropského parlamentu a Rady 2014/23/EU ze dne 26. února 2014 o udělování koncesí',
  },
  [FT2.F15]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F16]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F17]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F18]: {
    'BT-01-Notice': '32009L0081',
    'BT-01(c)-Procedure': '32009L0081',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2009/81/ES ze dne 13. července 2009 o koordinaci postupů při zadávání některých zakázek na stavební práce, dodávky a služby zadavateli v oblasti obrany a bezpečnosti a o změně směrnic 2004/17/ES a 2004/18/ES',
  },
  [FT2.F19]: {
    'BT-01-Notice': '32014L0023',
    'BT-01(c)-Procedure': '32014L0023',
    'BT-01(d)-Procedure': 'Směrnice Evropského parlamentu a Rady 2014/23/EU ze dne 26. února 2014 o udělování koncesí',
  },
  [FT2.F20]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F21]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F22]: {
    'BT-01-Notice': '32009L0081',
    'BT-01(c)-Procedure': '32009L0081',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2009/81/ES ze dne 13. července 2009 o koordinaci postupů při zadávání některých zakázek na stavební práce, dodávky a služby zadavateli v oblasti obrany a bezpečnosti a o změně směrnic 2004/17/ES a 2004/18/ES',
  },
  [FT2.F23]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F24]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F25]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F26]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F27]: {
    'BT-01-Notice': '32009L0081',
    'BT-01(c)-Procedure': '32009L0081',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2009/81/ES ze dne 13. července 2009 o koordinaci postupů při zadávání některých zakázek na stavební práce, dodávky a služby zadavateli v oblasti obrany a bezpečnosti a o změně směrnic 2004/17/ES a 2004/18/ES',
  },
  [FT2.F28]: {
    'BT-01-Notice': '32014L0023',
    'BT-01(c)-Procedure': '32014L0023',
    'BT-01(d)-Procedure': 'Směrnice Evropského parlamentu a Rady 2014/23/EU ze dne 26. února 2014 o udělování koncesí',
  },
  [FT2.F29]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F30]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F31]: {
    'BT-01-Notice': '32009L0081',
    'BT-01(c)-Procedure': '32009L0081',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2009/81/ES ze dne 13. července 2009 o koordinaci postupů při zadávání některých zakázek na stavební práce, dodávky a služby zadavateli v oblasti obrany a bezpečnosti a o změně směrnic 2004/17/ES a 2004/18/ES',
  },
  [FT2.F32]: {
    'BT-01-Notice': '32014L0023',
    'BT-01(c)-Procedure': '32014L0023',
    'BT-01(d)-Procedure': 'Směrnice Evropského parlamentu a Rady 2014/23/EU ze dne 26. února 2014 o udělování koncesí',
  },
  [FT2.F33]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F34]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F35]: {
    'BT-01-Notice': '32014L0023',
    'BT-01(c)-Procedure': '32014L0023',
    'BT-01(d)-Procedure': 'Směrnice Evropského parlamentu a Rady 2014/23/EU ze dne 26. února 2014 o udělování koncesí',
  },
  [FT2.F36]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F37]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F38]: {
    'BT-01-Notice': '32014L0024',
    'BT-01(c)-Procedure': '32014L0024',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/24/EU ze dne 26. února 2014 o zadávání veřejných zakázek a o zrušení směrnice 2004/18/ES',
  },
  [FT2.F39]: {
    'BT-01-Notice': '32014L0025',
    'BT-01(c)-Procedure': '32014L0025',
    'BT-01(d)-Procedure':
      'Směrnice Evropského parlamentu a Rady 2014/25/EU ze dne 26. února 2014 o zadávání zakázek subjekty působícími v odvětví vodního hospodářství, energetiky, dopravy a poštovních služeb a o zrušení směrnice 2004/17/ES',
  },
  [FT2.F40]: {
    'BT-01-Notice': '32014L0023',
    'BT-01(c)-Procedure': '32014L0023',
    'BT-01(d)-Procedure': 'Směrnice Evropského parlamentu a Rady 2014/23/EU ze dne 26. února 2014 o udělování koncesí',
  },
}
