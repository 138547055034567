import get from 'lodash/get'
import React from 'react'
import { ArrowDownShort, ArrowUpShort } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import { selectFormTree, selectFormTreeReq } from '../../redux/formTree/formTreeSelectors'
import { getSiblingTreeEl, treeIndexesToPath } from '../../utils/formTreeSibling'
import { useIndexesContext } from '../context/IndexesContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { iFormTree } from '../utils/createFormTree'

export const TITLE_HEIGHT = 45
const MAX_ARROW_LEVEL = 1

const SectionHeaderNav = () => {
  const { isReq } = useFormRenderContext()
  const formTree = useSelector(isReq ? selectFormTreeReq : selectFormTree)
  const indexes = useIndexesContext().indexes

  const treePath = treeIndexesToPath(indexes)
  const formTreeEl: iFormTree | undefined = get(formTree, treePath)
  const formTreeElUp = getSiblingTreeEl(formTree, indexes, -1)
  const formTreeElDown = getSiblingTreeEl(formTree, indexes, 1)

  const showArrow = formTreeEl?.level !== undefined && formTreeEl?.level < MAX_ARROW_LEVEL

  return (
    <div className='section-header-nav'>
      {showArrow && formTreeElUp && formTreeElUp.to && (
        <Link
          title={'přejít na ' + formTreeElUp.text}
          smooth={true}
          isDynamic={true}
          to={formTreeElUp.to}
          offset={-TITLE_HEIGHT * (formTreeElUp.level || 0)}
        >
          <ArrowUpShort size={25} />
        </Link>
      )}
      {showArrow && formTreeElDown && formTreeElDown.to && (
        <Link
          title={'přejít na ' + formTreeElDown.text}
          smooth={true}
          isDynamic={true}
          to={formTreeElDown.to}
          offset={-TITLE_HEIGHT * (formTreeElDown.level || 0)}
        >
          <ArrowDownShort size={25} />
        </Link>
      )}
    </div>
  )
}

export default SectionHeaderNav
