import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import EformsApi, { SLUG_FORM } from '../../api/EformsApi'
import { isFormDraft } from '../../enums/enumWorkflowPlaces'
import { PROFILE_FORM_TYPES } from '../../enums_v1/enumFormType'
import { useModal } from '../../hooks/useModal'
import { iEformsSubmission } from '../../interfaces/iEformsSubmission'
import { FormsCreator } from '../../services/FormsCreator'
import { getErrorMessage } from '../../utils/errorMessage'
import AlertErrorBlock from '../Alert/AlertErrorBlock'
import { LINKS } from '../App/LINKS'
import ButtonGov from '../Btns/ButtonGov'
import ButtonGovLink from '../Btns/ButtonGovLink'
import InputGov from '../Inputs/InputGov'
import RadioInput from '../Inputs/RadioInput'
import Loading from '../Loading/Loading'
import { ONLY_EXIST } from './ModalNewForm'

const COPY_TYPES = {
  NEW: 'new',
  PREV: 'prev',
}
const optionsNewCopyForm = [
  { value: COPY_TYPES.NEW, label: 'KOPIE FORMULÁŘE ZAKLÁDÁ NOVOU ZAKÁZKU' },
  { value: COPY_TYPES.PREV, label: 'KOPIE FORMULÁŘE NAVAZUJE NA PŮVODNÍ ZAKÁZKU A FORMULÁŘ' },
  // TODO pri oprave zmenit text na opravuje
]

const ModalCopyForm = () => {
  const { closeModal, dataCopyForm } = useModal()

  const mainSubId = dataCopyForm

  const [sentLoading, setSentLoading] = useState(false)
  const [newFormId, setNewFormId] = useState('')

  const [loading, setLoading] = useState(true)
  const [resMain, setResMain] = useState<iEformsSubmission<any>>()
  const [resReq, setResReq] = useState<iEformsSubmission<any>>()
  const [hasPrevReq, setHasPrevReq] = useState(false)
  const [isPrevZneplatnen, setIsPrevZneplatnen] = useState(false)

  const formType = resMain?.data.druhFormulare
  const isProfile = PROFILE_FORM_TYPES.includes(formType)

  const isDraft = !!resMain?.workflowPlaceCode && isFormDraft(resMain?.workflowPlaceCode)
  const [isFirstVersion, setIsFirstVersion] = useState(false)

  const loadPrevForm = async (resMain: iEformsSubmission<any>) => {
    if (isFormDraft(resMain.workflowPlaceCode)) {
      return
    }

    const evCislo = resMain.data.evCisloVvzSouvisejicihoFormulare
    const prevResMain = await EformsApi.getSubmissionsSearch(SLUG_FORM.HLAVNI, 1, 1, {
      variableId: evCislo,
    }).then((res) => {
      if (res.data.length) {
        return res.data[0]
      } else {
        return EformsApi.getSubmissionsSearch(SLUG_FORM.PROFIL, 1, 1, { variableId: evCislo }).then(
          (res) => res.data[0],
        )
      }
    })
    if (!prevResMain) {
      throw new Error('Nenalezen formulář s evidenčním číslem ' + evCislo + '.')
    }
    const isPrevFormV1 = prevResMain?.data.verzeXsd
    setIsPrevZneplatnen(!!prevResMain?.data.formularZneplatnen)
    const prevResReq = await EformsApi.getSubmissionsChildrenSearch(
      prevResMain.id,
      isPrevFormV1 ? SLUG_FORM.ZADOST : SLUG_FORM.ZADOST2,
      {
        limit: 1,
      },
    ).then((data) => data[0])

    if (prevResReq) {
      setHasPrevReq(true)
      const kod = isPrevFormV1
        ? prevResReq?.data.kodProSouvisejiciFormulare
        : prevResReq?.data['ND-Root']['BT-002-KodProSouvisejiciFormulare']
      setKodSouvisFormulare(kod)
    }
  }

  const loadVersion = async (res: iEformsSubmission<any>) => {
    const versionId = res.submissionVersion.split('/').slice(-1)[0]
    const dataVer = await EformsApi.getVersionDetail(versionId)
    if (dataVer.version < 2) {
      setIsFirstVersion(true)
    }
  }

  useEffect(() => {
    if (mainSubId) {
      setLoading(true)
      Promise.all([
        EformsApi.getSubmission(mainSubId)
          .then(async (res) => {
            setResMain(res)
            await loadPrevForm(res)
          })
          .catch((err) => {
            toast.error('Nebyl nalezen formulář id ' + mainSubId + '.')
          }),
        EformsApi.getSubmissionsChildrenSearch(
          mainSubId, //
          [SLUG_FORM.ZADOST, SLUG_FORM.ZADOST2],
          { limit: 1 },
        )
          .then(async (res) => {
            await loadVersion(res?.[0])
            setResReq(res?.[0])
          })
          .catch((err) => {}),
      ]).finally(() => {
        setLoading(false)
      })
    }
  }, [mainSubId])

  const actOptions = ONLY_EXIST.includes(formType)
    ? (isPrevZneplatnen ? [] : [optionsNewCopyForm[1]])
    : (isPrevZneplatnen ? [optionsNewCopyForm[0]] : optionsNewCopyForm)

  const [copyType, setCopyType] = useState(actOptions.length === 1 ? actOptions[0].value : '')
  const isCopyPrev = !isDraft && copyType === COPY_TYPES.PREV

  const [kodSouvisFormulare, setKodSouvisFormulare] = useState('')
  const evCisloZakazky = isProfile ? resMain?.data.evCisloProfiluVvz : resMain?.data.evCisloZakazkyVvz
  const evCisloFormulare = resMain?.data.evCisloVvzSouvisejicihoFormulare

  const needExtKodSouvisFourmulare = isCopyPrev && !hasPrevReq
  const needSelectCopyType = !isDraft && evCisloFormulare

  const clickCopy = async () => {
    if (needSelectCopyType && !copyType) {
      toast.error('Vyberte způsob kopie formuláře')
      return
    }
    if (needExtKodSouvisFourmulare && !kodSouvisFormulare) {
      toast.error('Zadejte kód pro související formuláře')
      return
    }

    setSentLoading(true)

    try {
      const formsCreator = new FormsCreator(formType)

      await formsCreator.copyFormData(resMain!, resReq)

      if (isCopyPrev) {
        formsCreator.setPrevNumbers(evCisloFormulare, evCisloZakazky, kodSouvisFormulare)
        formsCreator.copyMainPrev(resMain!)
        formsCreator.initPrevData()
        await formsCreator.validatePrev()
      }

      const formId = await formsCreator.createForms()
      setNewFormId(formId)
    } catch (e) {
      toast.error(getErrorMessage(e))
    } finally {
      setSentLoading(false)
    }
  }

  if (!mainSubId) {
    return null
  }

  if (sentLoading || loading) {
    return <Loading />
  }

  if (newFormId) {
    return (
      <>
        <div className='u-mb--50'>Kopie formuláře byla vytvořena a uložena. Chcete ji zobrazit?</div>
        <ButtonGovLink
          variant='primary'
          className='u-me--20'
          onClick={() => closeModal()}
          to={LINKS.vyhledatFormular + '/' + newFormId}
        >
          Zobrazit kopii
        </ButtonGovLink>
        <ButtonGov variant='primary-outlined' onClick={closeModal}>
          Zavřít
        </ButtonGov>
      </>
    )
  }

  if (isFirstVersion) {
    return (
      <div className='u-mb--50'>
        <AlertErrorBlock>
          {/*Nepovedlo se vytvořit kopii nově vytvořeného formuláře.*/}
          {/*<br />*/}
          Nejprve prosím formulář uložte a poté bude možné vytvořit jeho kopii.
        </AlertErrorBlock>
      </div>
    )
  }

  return (
    <>
      <div className='u-mb--50'>
        <blockquote>
          POZOR! V nově založeném formuláři je nezbytně nutné zkontrolovat platnost všech zkopírovaných údajů a provést
          jejich nezbytnou aktualizaci.
        </blockquote>
      </div>
      {needSelectCopyType && (
        <div className='u-mb--50'>
          {actOptions.length ? (
            <RadioInput
              name={'copyType'}
              value={copyType}
              onChange={(e) => setCopyType(e.target.value)}
              options={actOptions}
            />
          ) : (
            <div>Nelze vytvořit kopii formuláře</div>
          )}
        </div>
      )}
      {needExtKodSouvisFourmulare && (
        <div>
          <p>
            Pro vytvoření kopie formuláře navázaného na zakázku {evCisloZakazky} a formulář {evCisloFormulare} musíte
            zadat jeho kód pro související formuláře.
          </p>
          <InputGov
            name='kodForm'
            label={'Kód pro související formuláře'}
            value={kodSouvisFormulare}
            onChange={(e) => setKodSouvisFormulare(e.target.value)}
            // description={'(Zadejte alfanumerický kód, který jste obdrželi v potvrzujicím emailu)'}
          />
        </div>
      )}

      <ButtonGov variant='primary' className='u-me--20' onClick={clickCopy}>
        Pokračovat
      </ButtonGov>
      <ButtonGov variant='primary-outlined' onClick={closeModal}>
        Zavřít
      </ButtonGov>
    </>
  )
}

export default ModalCopyForm
