import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputCheckbox = () => {
  const { field, errors } = useControlRegister()
  const { id, required, disabled } = useElementContext()

  return (
    <WrapInputGov errors={errors} custom>
      <input
        {...field}
        id={id}
        className='gov-form-control__checkbox'
        type='checkbox'
        checked={!!field.value}
        disabled={disabled}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-labelledby={id}
      />
      <PartGovLabel withIndicator />
    </WrapInputGov>
  )
}

export default InputCheckbox
