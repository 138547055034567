import { useState } from 'react'
import { LocalStorageCache } from '../services/LocalStorageCache'

const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => LocalStorageCache.getItem<T>(key, initialValue))

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      LocalStorageCache.setItem<T>(key, valueToStore)
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
