import { enumNamespaceMapPrefix } from '../../enums_v1/enumNamespaceMap'
import { DELIMETER_NAMESPACE_ALT } from '../specialChars'

export const convertDataNamespaces = (data: any, namaspacesMap?: { [ns: string]: string }): any => {
  if (data === undefined) {
    return undefined
  }

  namaspacesMap = { ...namaspacesMap, ...proccessNamespace(data) }

  if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
    const obj: any = {}
    for (const key in data) {
      let retKey = key

      const itemPrefixes = proccessNamespace(data[key], true)
      //aplikace na stavajici elemet - inline xmls
      if (Object.keys(itemPrefixes).length) {
        for (const oldPrefix in itemPrefixes) {
          const newPrefix = itemPrefixes[oldPrefix]
          if (oldPrefix) {
            retKey = retKey.replaceAll(
              oldPrefix + DELIMETER_NAMESPACE_ALT,
              newPrefix ? newPrefix + DELIMETER_NAMESPACE_ALT : '',
            )
          } else {
            retKey = (newPrefix ? newPrefix + DELIMETER_NAMESPACE_ALT : '') + retKey
          }
        }
        //aplikace vsech replace z nadrazenych elementu
      } else {
        for (const oldPrefix in namaspacesMap) {
          const newPrefix = namaspacesMap[oldPrefix]
          retKey = retKey.replaceAll(
            oldPrefix + DELIMETER_NAMESPACE_ALT,
            newPrefix ? newPrefix + DELIMETER_NAMESPACE_ALT : '',
          )
        }
      }
      //specialni pripad kdy je element zanoren do el_text_nest jen proto ze ma xmnls atribut
      if (data[key].el_text_nest && Object.keys(data[key].el_attrs).length === 1 && data[key].el_attrs?.xmlns) {
        obj[retKey] = convertDataNamespaces(data[key].el_text_nest, namaspacesMap)
      } else {
        obj[retKey] = convertDataNamespaces(data[key], namaspacesMap)
      }
    }

    return obj
  } else if (Array.isArray(data)) {
    return data.map((j) => convertDataNamespaces(j, namaspacesMap))
  } else {
    return data
  }
}

/*
 Vytahne z elementu definice namespace a vrati mapovaci tabulkutabulky
 */
const proccessNamespace = (jsonEl: any, withEmpty?: boolean) => {
  const namespaceAtrs = Array.isArray(jsonEl) ? jsonEl[0].el_attrs : jsonEl.el_attrs

  const namespacesMap: any = {}
  for (const atrName in namespaceAtrs) {
    if (atrName.startsWith('xmlns')) {
      const namespace = atrName.replaceAll(DELIMETER_NAMESPACE_ALT, '').replaceAll('xmlns', '')
      const namespaceUrl = namespaceAtrs[atrName]
      const newNamespace = enumNamespaceMapPrefix[namespaceUrl]
      if (newNamespace === undefined) {
      } else if (namespace || withEmpty) {
        namespacesMap[namespace] = newNamespace
      }
    }
  }
  return namespacesMap
}
