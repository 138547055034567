import React from 'react'
import { useElementErrors } from '../../useElementErrors'
import PartGovError from './PartGovError'

interface iPartGovErrorsBlock {
  index?: number
}

const PartGovErrorsBlock = ({ index }: iPartGovErrorsBlock) => {
  const errors = useElementErrors(index)

  if (!errors?.length) {
    return null
  }

  return (
    <div className='blockGovErrorText'>
      {errors?.map((error, index) => (
        <PartGovError text={error} key={index} />
      ))}
    </div>
  )
}

export default PartGovErrorsBlock
