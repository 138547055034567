import defaultsDeep from 'lodash/defaultsDeep'
import React, { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { isFormDraft } from '../../enums/enumWorkflowPlaces'
import { iEformsSubmission } from '../../interfaces/iEformsSubmission'
import { selectFormUserFormName } from '../../redux/formState/selectors'
import { selectVersionForm, selectVersionReq } from '../../redux/submissions/selectors'
import { SchemaDefaults } from '../../services/SchemaDefaults'
import { ELEMENT_THEME } from '../elements/ELEMENTS'
import { iDataSchema, iDataSchemas } from '../interfaces/iDataSchema'
import { iUIschema } from '../interfaces/iUiSchema'
import { calculateArraysScope } from '../utils/calculateArraysScope'
import { calculateScopes } from '../utils/calculateScopes'

interface iRenderFormContext {
  dataSchemas: iDataSchemas
  uiSchemas: iUIschema[]
  dateSchemaScope: { [scope: string]: iDataSchema }
  dateSchemaArrayScopes: string[]
  dateSchemaDefaults: { [paths: string]: any }
  dateSchemaArrayDefaults: { [paths: string]: any }
  dateSchemaDefaultsCalculate: { [paths: string]: iDataSchema }
  withSchemaDefaults: boolean
  formDisabled: boolean
  formType?: string
  formSchemaId?: string
  submissionId: string
  submission: iEformsSubmission<any>
  submissionValues?: any
  isHistoryDetail: boolean
  loadingAction: boolean
  setLoadingAction: (loading: boolean) => void
  formModifs: iFormModifs
  shouldUnregisterInput: boolean
  setShouldUnregisterInput: (shouldUnregisterInput: boolean) => void
  liveValidation: boolean
  helpSimple: boolean
  isReq: boolean
  theme: string
  validationMode?: string
}

interface iFormModifs {
  isPritVersion?: boolean
  isFullVersion?: boolean
}

const FormRenderContextBase = React.createContext({} as iRenderFormContext)

export const useFormRenderContext = () => useContext(FormRenderContextBase)

export const useFormRenderDataSchemaScope = (scope: string): iDataSchema | undefined =>
  useFormRenderContext().dateSchemaScope[scope]

interface iFormRenderContexProvider {
  formSchemaId?: string
  formType?: string
  formDisabled?: boolean
  dataSchemas?: iDataSchemas
  uiSchemas?: iUIschema[]
  submission?: iEformsSubmission<any>
  liveValidation?: boolean
  helpSimple?: boolean
  shouldUnregisterDefault?: boolean
  children: React.ReactNode
  isReq?: boolean
  theme?: string
  validationMode?: string
}

export const FormRenderContexProvider = ({
  formSchemaId,
  dataSchemas = {},
  uiSchemas = [],
  formType,
  formDisabled,
  submission = {} as iEformsSubmission<any>,
  liveValidation = false,
  helpSimple = false,
  shouldUnregisterDefault = false,
  isReq = false,
  validationMode,
  theme = ELEMENT_THEME.DEFAULT,
  children,
}: iFormRenderContexProvider) => {
  const [loadingAction, setLoadingAction] = useState(false)
  const formName = useSelector(selectFormUserFormName)

  const dateSchemaScope = useMemo(() => calculateScopes(dataSchemas), [dataSchemas])
  const dateSchemaArray = useMemo(() => calculateArraysScope(dataSchemas), [dataSchemas])

  const isHistoryVersion = !!submission?.id && !submission?.submissionVersion
  // const submissionVersionId = isHistoryVersion ? submission.id : submission?.submissionVersion?.split('/').pop()

  const submissionId = submission.id
  // const versionId = ''

  const isDraft = isFormDraft(submission?.workflowPlaceCode)
  const isNotDraft = submission?.workflowPlaceCode && !isDraft
  const formVersionNum = useSelector(isReq ? selectVersionReq : selectVersionForm)

  const [searchParams] = useSearchParams()

  const formModifs = useMemo(
    () => ({
      isPritVersion: searchParams.has('print'),
      isFullVersion: searchParams.has('full'),
    }),
    [searchParams],
  )

  const disabled = isNotDraft || !!formDisabled || formModifs?.isPritVersion || false

  const [shouldUnregisterInput, setShouldUnregisterInput] = useState(shouldUnregisterDefault)

  const { dateSchemaDefs, dateSchemaArrayDefaults, dateSchemaDefaultsCalculate, dateSchemaDefsOnLoad } = useMemo(() => {
    const SchemaDef = new SchemaDefaults(dataSchemas, dateSchemaArray)
    return SchemaDef.calculateDefaults()
  }, [dataSchemas, dateSchemaArray])

  const isCopy = !!submission?.copyFromSubmissionVersion || formName.toLowerCase().includes('kopie') // TODO zrusit po nasazeni copyForm
  const withDefaults = formVersionNum === 1 && !isCopy // jen v prvni verzi a mimo kopii
  const dateSchemaDefaults = disabled ? {} : withDefaults ? dateSchemaDefs : dateSchemaDefsOnLoad
  // TODO nahradit defaultDeepNoArray - jen pokud bude default array v prvni urovni
  const submissionValues = defaultsDeep({}, submission?.data, dateSchemaDefaults)

  // vsechny fce musi byt useCallback - https://react.dev/reference/react/useContext
  const providerValue = React.useMemo(
    () => ({
      dataSchemas: dataSchemas,
      uiSchemas: uiSchemas,
      formSchemaId: formSchemaId,

      formType: formType,
      dateSchemaScope: dateSchemaScope,
      dateSchemaArrayScopes: dateSchemaArray,
      withSchemaDefaults: withDefaults,

      dateSchemaDefaults: dateSchemaDefaults,
      dateSchemaArrayDefaults: dateSchemaArrayDefaults,
      dateSchemaDefaultsCalculate: dateSchemaDefaultsCalculate,
      submissionValues: submissionValues,

      submissionId: submissionId,
      submission: submission,
      isHistoryDetail: isHistoryVersion,
      // submissionVersionId: submissionVersionId,

      formDisabled: disabled,
      loadingAction: loadingAction,
      setLoadingAction: setLoadingAction,
      formModifs: formModifs,
      liveValidation: liveValidation && isDraft,
      helpSimple: helpSimple,
      shouldUnregisterInput: shouldUnregisterInput,
      setShouldUnregisterInput: setShouldUnregisterInput,
      isReq: isReq,
      theme: theme,
      validationMode: validationMode,
    }),
    [
      dataSchemas,
      uiSchemas,
      formDisabled,
      submission,
      loadingAction,
      formModifs,
      liveValidation,
      shouldUnregisterInput,
      withDefaults,
    ],
  )

  return <FormRenderContextBase.Provider value={providerValue}>{children}</FormRenderContextBase.Provider>
}

// export const withFormContextBase = (WrapedComp: any) => (props: any) =>
//   (
//     <FormContexProvider>
//       <WrapedComp {...props} />
//     </FormContexProvider>
//   )
