import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { makeFindFormErrors, makeFindFormLiveErrors } from '../redux/formErrors/selectorsMake'
import { RootState } from '../redux/rootReducer'
import { useElementContext } from './context/ElementContext'
import { useFormRenderContext } from './context/RenderFormContext'

export const useElementErrors = (index?: number) => {
  const { isReq } = useFormRenderContext()
  const { path } = useElementContext()

  const actPath = index === undefined ? path : `${path}.${index}`

  const findStaticError = useMemo(makeFindFormErrors(isReq), [])
  const errorStatic = useSelector((state: RootState) => findStaticError(state, actPath), shallowEqual)

  const findLiveError = useMemo(makeFindFormLiveErrors(isReq), [])
  const errorLive = useSelector((state: RootState) => findLiveError(state, actPath), shallowEqual)

  const errorsMsg = useMemo(() => {
    const errors = [...errorStatic, ...errorLive]
    return errors?.length ? errors.map((err) => err.chyba) : undefined
  }, [errorStatic, errorLive])

  return errorsMsg
}
