import React, { useLayoutEffect, useRef } from 'react'
import { useElementContext } from '../../context/ElementContext'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputTextArea = () => {
  const { field, errors } = useControlRegister()
  const { id, required, disabled, dataSchema } = useElementContext()

  const textareaRef = useRef<any>(null)
  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 5}px`
    }
  }, [field.value])

  const charLen = field.value?.length ?? 0
  const charLimit = dataSchema?.maxLength

  return (
    <WrapInputGov value={field.value} errors={errors}>
      <textarea
        {...field}
        ref={(e) => {
          field.ref(e)
          textareaRef.current = e
        }}
        id={id}
        className='gov-form-control__input'
        disabled={disabled}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-labelledby={id}
        style={{ minHeight: '40px' }}
      />
      {!!charLimit && !disabled && (
        <small className='textarea_char_limit'>
          {charLen}/{charLimit}
        </small>
      )}
      <PartGovLabel />
    </WrapInputGov>
  )
}

export default InputTextArea
