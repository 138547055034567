import React, { useContext } from 'react'

interface disabledArrayContext {
  disabled: boolean
}

const DisabledArrayContextContextBase = React.createContext({ disabled: false } as disabledArrayContext)

export const useDisabledArrayContextContext = () => useContext(DisabledArrayContextContextBase)

interface iDisabledArrayContextContextProvider {
  disabled: boolean
  children: React.ReactNode
}

export const DisabledArrayContextContextProvider = ({ disabled, children }: iDisabledArrayContextContextProvider) => {
  const disabledParent = useDisabledArrayContextContext().disabled

  const providerValue = React.useMemo(
    () => ({
      disabled: disabled || disabledParent,
    }),
    [disabledParent, disabled],
  )

  return (
    <DisabledArrayContextContextBase.Provider value={providerValue}>
      {children}
    </DisabledArrayContextContextBase.Provider>
  )
}
