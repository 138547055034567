import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { useLayoutEffect, useRef } from 'react'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import { UiElTypesInput } from '../uiElTypes'

const GovInputTextarea = (props: ControlProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
  } = props
  const change = (value: string) => {
    if (value === '') {
      handleChange(path, undefined)
    } else {
      if ((uischema.options as any)?.textareaSingleLine) {
        handleChange(path, value.replace(/\n/g, ''))
      } else {
        handleChange(path, value)
      }
    }
  }

  const textareaRef = useRef<any>(null)
  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 5}px`
    }
  }, [data])

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov uischema={uischema} value={data} description={description} error={errors} path={path}>
      <DebugElement data={props} />
      <textarea
        id={elId}
        className='gov-form-control__input'
        disabled={!enabled}
        value={data || ''}
        onChange={(e: any) => change(e.target.value)}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={!enabled ? 'true' : 'false'}
        aria-labelledby={elId}
        ref={textareaRef}
        style={{ height: 'auto', minHeight: '110px' }}
      />

      <InputGovLabel label={label} inputId={elId} required={required} />
    </WrapInputGov>
  )
}

export default withJsonFormsControlPropsCustom(GovInputTextarea)

export const govInputTextareaTester = rankWith(3, uiTypeIs(UiElTypesInput.Textarea))
