import React, { useState } from 'react'
import { ENV } from '../../ENV'
import { hash } from '../../utils/hash'
import ButtonGov from '../Btns/ButtonGov'
import InputGov from '../Inputs/InputGov'

const BaseLoginScreen = ({ children }: { children: React.ReactNode }) => {
  const [login, setLogin] = useState('')
  const [pass, setPass] = useState('')
  const [savedLogin, setSavedLogin] = useState(localStorage.getItem('vvz_base_login'))
  const loged = savedLogin === `qcm:112609658` || savedLogin === `mmr:112609658`
  const click = () => {
    const loginString = login + ':' + hash(pass)
    localStorage.setItem('vvz_base_login', loginString)
    setSavedLogin(loginString)
  }

  if (loged || !ENV.BASE_LOGIN) {
    return <>{children}</>
  }

  return (
    <div style={{ maxWidth: 500, margin: '50px auto 50px auto' }}>
      <InputGov name={'login'} value={login} onChange={(e) => setLogin(e.target.value)} label={'Login'} />
      <InputGov name={'pass'} value={pass} onChange={(e) => setPass(e.target.value)} label={'Heslo'} />
      <ButtonGov variant='primary' onClick={click}>
        Přihlásit
      </ButtonGov>
    </div>
  )
}

export default BaseLoginScreen
