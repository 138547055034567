import { iDataSchema, iDataSchemas } from '../interfaces/iDataSchema'

export const calculateScopes = (items: iDataSchemas, parentScope = '#') => {
  let ret: { [scope: string]: iDataSchema } = {}

  Object.entries(items).forEach(([name, child]) => {
    const scope = parentScope + '/' + name
    ret[scope] = child

    if (child.properties) {
      const childScopes = calculateScopes(child.properties, scope)
      ret = { ...ret, ...childScopes }
    } else if (child.items?.properties) {
      const childScopes = calculateScopes(child.items.properties, scope)
      ret = { ...ret, ...childScopes }
    }
  })
  return ret
}
