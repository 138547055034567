import debounce from 'lodash/debounce'
import { setFormArrayLengthAll } from '../redux/formTree/formTreeSlice'
import store from '../redux/store'

class ArrayLength {
  private LENS: { [path: string]: number } = {}

  private debounceStoreDispatch = debounce(() => {
    store.dispatch(setFormArrayLengthAll({ ...this.LENS }))
  }, 20)

  setLength(path: string, arrayLength: number) {
    this.LENS[path] = arrayLength
    this.debounceStoreDispatch()
  }

  clear(){
    this.LENS = {}
  }
}

const ArrLen = new ArrayLength()

export default ArrLen
