import axios from 'axios'
import { Buffer } from 'buffer'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

const API_URL = process.env.REACT_APP_SENDFORM_API_URL

export interface iSendFormResponse {
  zprava: string // "Formulář byl přijat k uveřejnění",
  evidencniCislo: string // "F2022-008065",
  evidencniCisloFormulare: string // "Z2022-008065",
  kodProSouvisejiciFormular: string // "bfe4e116-8253-41e9-9ae1-02f29a45b92d",
  // cisloObjednavky: string // "ABC/1430/2022",
  datumACasPrijeti: string // "2022-03-20T19:52:46.835"
}

class SendFormApi {
  api = axios.create({
    baseURL: API_URL,
  })

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
  }

  senForm = (
    mainId: string,
    userFormName: string,
    usrname: string,
    formData: any,
    reqData: any,
    hashForm?: string,
    hashReq?: string,
    formularOpravuje = false,
  ) => {
    const formDataBase64 = Buffer.from(JSON.stringify(formData) || '').toString('base64')
    const reqDataBase64 = Buffer.from(JSON.stringify(reqData) || '').toString('base64')
    return this.api
      .post<iSendFormResponse>(`/podat`, {
        uzivatelskyNazevFormulare: userFormName,
        uuidFormulare: mainId,
        hashDatFormulare: hashForm,
        hashZadostiOUverejneni: hashReq,
        prihlasovaciJmenoUzivatele: usrname,
        dataFormulare: formDataBase64,
        zadostOUverejneni: reqDataBase64,
        formularOpravuje: formularOpravuje,
      })
      .then((res) => res.data)
  }
}

export default new SendFormApi()
