import React from 'react'
import Paginator from '../Paginator/Paginator'
import PerPage from '../PerPage/PerPage'

interface iPaginatorPerPage {
  totalCount: number
  maxItems: number
}

const PaginatorPerPage = ({ totalCount, maxItems }: iPaginatorPerPage) => {
  return (
    <>
      <PerPage totalCount={totalCount} />
      <Paginator maxItems={maxItems} />
    </>
  )
}

export default PaginatorPerPage
