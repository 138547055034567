import { RootState } from '../rootReducer'

export const selectFormIsValid = (state: RootState) => state.formState.isValid

export const selectFormActionLoading = (state: RootState) => state.formState.actionLoading
export const selectFormUserFormName = (state: RootState) => state.formState.userFormName
export const selectFormWithRequest = (state: RootState) => state.formState.withRequest
export const selectFormLoadErr = (state: RootState) => state.formState.formLoadErr
export const selectFormResponseSent = (state: RootState) => state.formState.responseSent

export const selectLoadFormData = (state: RootState) => state.formState.loadFormData
export const selectLoadReqData = (state: RootState) => state.formState.loadReqData
