import moment from 'moment'
import { WORKFLOW_PLACES as WP } from '../enums/enumWorkflowPlaces'
import { iEformsSubmission } from '../interfaces/iEformsSubmission'
import { DATE_FORMAT_ISO8601 } from './dateFormat'

export const canAdminChangeWorkflow = (contract: iEformsSubmission<any>) => {
  const place = contract?.workflowPlaceCode

  if (place === WP.ROZHODNE_OPERATOR) {
    return [
      {
        info: 'Rozhodne operátor -> Předáno k uveřejnění',
        toPlace: WP.PREDANO_K_UVEREJNENI,
      },
      {
        info: 'Rozhodne operátor -> Zamítnuto v TED',
        toPlace: WP.ZAMITNUTO_TED,
      },
    ]
  } else if (place === WP.ODESLANO_TED && contract?.data.lhutaUverejneniTed > moment().format(DATE_FORMAT_ISO8601)) {
    return [
      {
        info: 'Odesláno do TED -> Uveřejněno ve VVZ',
        toPlace: WP.UVEREJNENO_VVZ,
      },
    ]
  } else if (place === WP.UVEREJNENO_VVZ) {
    return [
      {
        info: 'Uveřejněno ve VVZ -> Stornovaný',
        toPlace: WP.STORNOVANO,
      },
    ]
  }

  return undefined
}
