import React from 'react'
import { useElementContext } from '../../context/ElementContext'

interface iPartGovLabel {
  withIndicator?: boolean
  noLeftPadding?: boolean
}

const PartGovLabel = ({ withIndicator, noLeftPadding }: iPartGovLabel) => {
  const { id, label, required, uiSchema } = useElementContext()

  if (!label && !withIndicator) {
    return null
  }

  return (
    <>
      <label className={'gov-form-control__label ' + (noLeftPadding ? 'noLeftPadding' : '')} htmlFor={id}>
        {label} {uiSchema.labelId && <small>({uiSchema.labelId})</small>}
        {required && '*'}
      </label>
      {withIndicator && <span className='gov-form-control__indicator'></span>}
    </>
  )
}

export default React.memo(PartGovLabel)
