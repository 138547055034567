import moment from 'moment/moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ENV } from '../../ENV'
import logo from '../Header/mmr_cr_negativni.svg'

const MaintenanceMode = ({ children }: { children: React.ReactNode }) => {
  if (!ENV.MAINTENANCE_MODE) {
    return <>{children}</>
  }

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexFlow: 'column' }}>
      <HeaderSimple />
      <main role='main' id='content' style={{ flexGrow: 1 }}>
        <div style={{ maxWidth: 850, margin: '0px auto', padding: '80px 15px' }}>
          <strong style={{ fontSize: 22 }}>Odstávka VVZ</strong>
          <br />
          <br />
          Aktuálně probíhá plánovaná odstávka Věstníku veřejných zakázek (VVZ), a to{' '}
          <strong>do pondělí 5. 2. 2024 do 8:00</strong>. Během odstávky nejsou přístupné uveřejněné formuláře, není
          možné odesílat ani uveřejňovat žádné formuláře včetně podávání formulářů z elektronických nástrojů.
          <br />
          <strong>Od pondělí 5. 2. 2024 od 8:00</strong> bude k dispozici nová verze VVZ související s přechodem na
          novou podobu uveřejňovacích formulářů (eForms). Novou verzi VVZ si můžete vyzkoušet v referenčním prostředí na
          adrese{' '}
          <a href='https://ref.vvz.nipez.cz/' target='_blank' rel='noreferrer noopener'>
            https://ref.vvz.nipez.cz/
          </a>{' '}
          (přihlašovací jméno: "<strong>lbsrcj</strong>", heslo: "<strong>Vvz123456789</strong>").
          <br />
          Další informace k nové verzi VVZ získáte na on-line školeních. Termíny školení, podrobný program a informace k
          přihlašování jsou k dispozici na Portálu o veřejných zakázkách v sekci{' '}
          <a href='https://portal-vz.cz/info-forum-vzdelavani/skoleni/' target='_blank' rel='noreferrer noopener'>
            Školení
          </a>
          .
        </div>
      </main>
      <FooterSimple />
    </div>
  )
}

const HeaderSimple = () => (
  <header
    className={
      'gov-container gov-container--wide gov-container--no-y-offset gov-header ' + (ENV.REF_ENV ? ' ref-header ' : '')
    }
  >
    <div className='gov-container__content'>
      <div className='gov-header__holder'>
        <div className='gov-header__left' style={{ display: 'flex' }}>
          <a href='https://mmr.cz/cs/uvod' target='_blank' rel='noreferrer noopener' className='gov-footer__image-link'>
            <img alt='MMR logo' src={logo} className='mmr-logo' />
          </a>

          {ENV.REF_ENV ? (
            <a className='gov-logo gov-logo--inversed ref-header-logo' href='/'>
              VĚSTNÍK VEŘEJNÝCH ZAKÁZEK
              <small>TESTOVACÍ PROSTŘEDÍ</small>
            </a>
          ) : (
            <a className='gov-logo gov-logo--inversed' href='/'>
              VĚSTNÍK VEŘEJNÝCH ZAKÁZEK
            </a>
          )}
        </div>
      </div>
    </div>
  </header>
)

const FooterSimple = () => {
  const { t } = useTranslation()

  return (
    <footer className='gov-container gov-container--wide gov-footer u-bg-color--grey-dark'>
      <div className='gov-container__content'>
        <section className='gov-footer__lower'>
          <p className='gov-footnote'>
            {moment().year()} &copy; {t('footer.copy')}
          </p>
          <p className='gov-footnote'>
            {t('footer.verze', 'Verze')} {ENV.VERSION}
          </p>
        </section>
      </div>
    </footer>
  )
}

export default MaintenanceMode
