import React from 'react'
import { useTranslation } from 'react-i18next'
import PageWrap from '../../components/PageWrap/PageWrap'
import HelpsList from './HelpsList'

const NapovedaPage = () => {
  const { t } = useTranslation()

  return (
    <PageWrap title={t('title.napoveda_dokumentace', 'Nápověda a dokumentace')}>
      <HelpsList />
    </PageWrap>
  )
}

export default NapovedaPage
