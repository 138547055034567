import { iValidationLiveResponse } from '../../api/Validation2Api'
import {
  setForbiddenFields,
  setForbiddenFieldsReq,
  setFormHidden,
  setFormLiveErrors,
  setMandatoryFields,
  setMandatoryFieldsReq,
  setReqHidden,
  setReqLiveErrors,
} from '../../redux/formErrors/formErrorsSlice'
import { AppThunk } from '../../redux/store'
import { selectUiSchemaScopeOrder, selectUiSchemaScopeOrderReq } from '../../redux/submissions/selectors'
import { proccessErrors, proccessFields } from './processErrors'

export const processValidateLiveResponse = (
  validResponse: iValidationLiveResponse,
  livePath: string,
  isReq: boolean,
): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()

    const scopeOrder = selectUiSchemaScopeOrder(state)
    const scopeOrderReq = selectUiSchemaScopeOrderReq(state)

    const { seznamChybFormular, seznamChybZadost, povinneFieldy, zakazaneFieldy, skryteFieldy } = validResponse

    const formErrors = proccessErrors(seznamChybFormular ?? [], scopeOrder, livePath)
    const reqErrors = proccessErrors(seznamChybZadost ?? [], scopeOrderReq, livePath)

    const mandatoryFields = proccessFields(povinneFieldy)
    const forbiddenFields = proccessFields(zakazaneFieldy)
    const hiddenFields = proccessFields(skryteFieldy)

    if (isReq) {
      dispatch(setForbiddenFieldsReq(forbiddenFields))
      dispatch(setMandatoryFieldsReq(mandatoryFields))
      dispatch(setReqLiveErrors(reqErrors))
      dispatch(setReqHidden(hiddenFields))
    } else {
      dispatch(setForbiddenFields(forbiddenFields))
      dispatch(setMandatoryFields(mandatoryFields))
      dispatch(setFormLiveErrors(formErrors))
      dispatch(setFormHidden(hiddenFields))
    }
  }
}

