import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadGlobalHidden } from '../../actions/loadGlobalHidden'
import { loadSubmById } from '../../actions/loadSubmissions'
import { ELEMENT_THEME } from '../../builder/elements/ELEMENTS'
import FormBaseRedux from '../../builder/forms/FormBaseRedux'
import FormTextV1 from '../../builder_v1/FormTextV1'
import { useEffectStart } from '../../hooks/useEffectStart'
import { selectFormLoadErr, selectFormWithRequest } from '../../redux/formState/selectors'
import { useAppDispatch } from '../../redux/store'
import { selectLoading, selectSchemaIsV1, selectSubmMainInfo } from '../../redux/submissions/selectors'
import FormFullInit from '../../services/FormFullInit'
import AlertError from '../Alert/AlertError'
import ButtonGov from '../Btns/ButtonGov'
import FormHeaderInfo from '../FormHeader/FormHeaderInfo'
import Loading from '../Loading/Loading'

interface iFormText {
  id: string
}

const FormTxt = ({ id }: iFormText) => {
  const dispatch = useAppDispatch()

  useEffectStart(() => {
    dispatch(loadSubmById(id))
  })

  const loading = useSelector(selectLoading)
  const isV1 = useSelector(selectSchemaIsV1)
  const withReq = useSelector(selectFormWithRequest)
  const formLoadErr = useSelector(selectFormLoadErr)
  const { isDraft, loaded } = useSelector(selectSubmMainInfo)

  useEffect(() => {
    if (id && loaded && !isDraft && !isV1 && !loading) {
      dispatch(loadGlobalHidden(id))
    }
    if (loaded && !loading) {
      FormFullInit.setNoInit()
    }
  }, [id, isDraft, loaded, loading])

  if (formLoadErr) {
    return <AlertError msg={formLoadErr} />
  }

  if (loading || !loaded) {
    return <Loading />
  }

  return (
    <>
      <FormHeaderInfo />
      <ButtonGov size='large' variant='primary' onClick={() => window.print()}>
        TISK
      </ButtonGov>
      {isV1 ? (
        <>
          <FormTextV1 />
          {withReq && <FormTextV1 isReq />}
        </>
      ) : (
        <>
          <FormBaseRedux theme={ELEMENT_THEME.TXT} />
          <div style={{ marginTop: 50 }}>{withReq && <FormBaseRedux isRequest theme={ELEMENT_THEME.TXT} />}</div>
        </>
      )}
    </>
  )
}

export default FormTxt
