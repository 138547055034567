import { toast } from 'react-toastify'
import EformsApi, { SLUG_FORM } from '../api/EformsApi'
import { iSubmissionDataHlavni } from '../interfaces/iEformsSubmission'
import { setFormData, setFormDataRequest } from '../redux/form/formSlice'
import { clearIsValid, setFormLoadErr, setFormWithRequest } from '../redux/formState/formStateSlice'
import { AppThunk } from '../redux/store'
import { setFormSchema, setFormSchemaReq, setLoading, setSubmReq } from '../redux/submissions/submissionsSlice'
import SchemaLoader from '../services/SchemaLoader'
import { ERR_MSG } from './utils/errorMsgs'
import { loadXmlFile } from './utils/loadXmlFile'

//jen pro V1 - xml
export const loadSubmissionsXml = (resMain: iSubmissionDataHlavni): AppThunk => {
  return (dispatch) => {
    const editFormSchemaId = resMain.data.souvisejiciFormSchemaId
    //nacteni schema pro datovy formular
    const promiseData = SchemaLoader.loadSchemaByIdWithTranslate(editFormSchemaId).then((schema) => {
      const dataSchema = schema.schema.properties
      return loadXmlFile(resMain, dataSchema).then((data) => {
        dispatch(setFormSchema(schema))
        dispatch(setFormData(data))
        dispatch(clearIsValid())
      })
    })

    // nactani schema pro formular zadosti
    const promiseReq = EformsApi.getSubmissionsChildrenSearch(resMain.id, SLUG_FORM.ZADOST)
      .then((resChilds) => {
        const resZadost = resChilds[0]
        if (resZadost) {
          const reqFormSchemaId = resZadost.data.souvisejiciFormSchemaId
          return SchemaLoader.loadSchemaByIdWithTranslate(reqFormSchemaId).then((schemaReq) => {
            const dataSchemaRes = schemaReq.schema.properties
            return loadXmlFile(resZadost, dataSchemaRes, true).then((data) => {
              dispatch(setSubmReq(resZadost))
              dispatch(setFormSchemaReq(schemaReq))
              dispatch(setFormDataRequest(Array.isArray(data) ? {} : data))
            })
          })
        } else {
          dispatch(setFormWithRequest(false))
        }
      })
      .catch((e) => {
        toast.error('Nepodařilo se načíst data žádosti formuláře')
      })

    Promise.all([promiseReq, promiseData])
      .then(() => {
        dispatch(setLoading(false))
      })
      .catch((err) => {
        dispatch(setFormLoadErr(ERR_MSG.UNKNOWN))
      })
  }
}

export const loadOnlyFormDataFromXml = (resMain: iSubmissionDataHlavni) => {
  const editFormSchemaId = resMain.data.souvisejiciFormSchemaId
  return SchemaLoader.loadSchemaByIdWithTranslate(editFormSchemaId).then((schema) => {
    const dataSchema = schema.schema.properties
    return loadXmlFile(resMain, dataSchema)
  })
}
