import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SLUG_FORM } from '../../api/EformsApi'
import { useFilterContextInput } from '../../context/FilterContext'
import { optionsDruhVz } from '../../enums/enumContractType'
import { optionsFormTypeProfilV2, optionsFormTypeV2 } from '../../enums/enumFormTypeV2'
import { workwlowPlaceOptions, workwlowPlaceProfilOptions } from '../../enums/enumWorkflowPlaces'
import { optionsFormTypeV1Group } from '../../enums_v1/enumFormType'
import { DATE_FORMAT_ISO8601 } from '../../utils/dateFormat'
import FilterFormWrap from '../components/FilterFormWrap'
import RadioFilterInput from '../components/filterInputs/RadioFilterInput'
import SelectFilterInput from '../components/filterInputs/SelectFilterInput'
import TextFilterInput from '../components/filterInputs/TextFilterInput'

const optionsPeriod = [
  { value: moment().subtract(1, 'hour').format(DATE_FORMAT_ISO8601), label: 'Za poslední hodinu' },
  { value: moment().subtract(1, 'day').format(DATE_FORMAT_ISO8601), label: 'Za posledních 24 hodin' },
  { value: moment().subtract(7, 'days').format(DATE_FORMAT_ISO8601), label: 'Za posledních 7 dní' },
  { value: moment().subtract(1, 'month').format(DATE_FORMAT_ISO8601), label: 'Za poslední měsíc' },
  { value: moment().subtract(3, 'months').format(DATE_FORMAT_ISO8601), label: 'Za poslední 3 měsíce' },
]

const FORM_OPTIONS = [
  { value: SLUG_FORM.HLAVNI, label: 'Formuláře zakázek' },
  { value: SLUG_FORM.PROFIL, label: 'Formuláře profilu' },
]

export const FILTER_MY_FORM = 'filterMyForm'
const FilterMyForms = () => {
  const { t } = useTranslation()
  const { value: mainFormType } = useFilterContextInput(FILTER_MY_FORM)
  const { setValue: setValueDruhFormulare } = useFilterContextInput('data.druhFormulare')

  const optionsType = mainFormType === SLUG_FORM.HLAVNI ? optionsFormTypeV2 : optionsFormTypeProfilV2
  const optionsGroup = mainFormType === SLUG_FORM.HLAVNI ? optionsFormTypeV1Group : undefined

  const optionsWorkflow = mainFormType === SLUG_FORM.HLAVNI ? workwlowPlaceOptions : workwlowPlaceProfilOptions

  useEffect(() => {
    setValueDruhFormulare(undefined)
  }, [mainFormType])

  return (
    <FilterFormWrap className='custom-form u-mb--50'>
      <h2 className='custom-form__title gov-title--delta'>Vyhledávání formulářů</h2>
      <div className='custom-form__wrap'>
        <RadioFilterInput name={FILTER_MY_FORM} options={FORM_OPTIONS} defaultValue={SLUG_FORM.HLAVNI} />
      </div>
      <div className='custom-form__wrap'>
        <SelectFilterInput
          // key={value} // jinak dochazi ke konfliktu a opakovani options
          name='data.druhFormulare'
          label='Druh formuláře'
          description='Vyberte druh formuláře (výchozí výběr = Vše)'
          options={optionsType}
          optgroup={optionsGroup}
        />
      </div>

      <div className='custom-form__wrap'>
        <SelectFilterInput
          name='workflowPlace'
          label='Stav formuláře'
          description='Vyberte stav formuláře (výchozí výběr = Vše)'
          options={optionsWorkflow}
        />

        <SelectFilterInput
          name='updatedAt[gte]'
          label='Naposledy změněno'
          description='Vyberte období formuláře (výchozí výběr = Vše)'
          options={optionsPeriod}
        />
      </div>

      <div className='custom-form__wrap'>
        <TextFilterInput name='variableId' label={t('filterContracts.ev_form', 'Evidenční číslo formuláře')} />
        <TextFilterInput name='data.uzivatelskyNazevFormulare' label='Vlastní pojmenování formuláře' />
      </div>

      <div className='custom-form__wrap'>
        <TextFilterInput name='data.nazevVzACasti' label={t('filterContracts.nazev_vz', 'Název VZ/části')} />
        <SelectFilterInput name='data.druhVz' label={t('filterContracts.druh_vz', 'Druh VZ')} options={optionsDruhVz} />
      </div>
    </FilterFormWrap>
  )
}

export default FilterMyForms
