import { useSelector } from 'react-redux'
import { setMobileMenuOpen } from '../redux/global/globalSlice'
import { selectMobileMenuOpen } from '../redux/global/selectors'
import { useAppDispatch } from '../redux/store'

export const useMobileMenu = () => {
  const mobileMenuOpen = useSelector(selectMobileMenuOpen)
  const dispatch = useAppDispatch()

  const setMenuOpen = (open: boolean) => {
    dispatch(setMobileMenuOpen(open))
  }

  return { mobileMenuOpen, setMenuOpen }
}
