import add from 'date-fns/add'
import isValid from 'date-fns/isValid'
import { useWatch } from 'react-hook-form'
import { dateParseShortTZ, dateParseTimeTZ } from '../../utils/dateFormatFns'
import { useElementContext } from '../context/ElementContext'
import { DATA_TYPE_FORMAT } from '../interfaces/iDataSchema'

export const useElementDateTime = (fieldValue: string) => {
  const { dataSchema, path, uiSchema } = useElementContext()

  const isTime = dataSchema?.format === DATA_TYPE_FORMAT.TIME
  const relatedDateScope = uiSchema.options?.relatedDateScope
  const relatedTimeScope = uiSchema.options?.relatedTimeScope

  const refDatePath = relatedDateScope ? path.split('.').slice(0, -1).join('.') + '.' + relatedDateScope : ''
  const refDateVal = useWatch({ name: refDatePath, disabled: !isTime || !refDatePath })
  const refDateBase = refDateVal?.slice(0, 10) // '2024-03-20'

  const refTimePath = relatedTimeScope ? path.split('.').slice(0, -1).join('.') + '.' + relatedTimeScope : ''
  const refTimeVal = useWatch({ name: refTimePath, disabled: !refTimePath })
  const refTimeBase = refTimeVal?.slice(0, 5) // '16:20'

  const dateStr = fieldValue

  // fix casu - spatne se nastavovala casova zona - musi byt z datumu
  const dateStrTimeFix =
    isTime && refDateVal && dateStr && refDateVal.slice(-6) !== dateStr.slice(-6)
      ? dateStr.slice(0, 8) + refDateVal.slice(-6)
      : dateStr

  let dateValue = isTime ? dateParseTimeTZ(dateStrTimeFix, refDateBase) : dateParseShortTZ(dateStr)
  if (!isTime && refTimeBase && dateValue) {
    dateValue = add(dateValue, {
      hours: parseInt(refTimeBase.slice(0, 2)),
      minutes: parseInt(refTimeBase.slice(3, 5)),
    })
  }
  const selectedValue = isValid(dateValue) ? dateValue : undefined

  return { isTime, selectedValue, refDateVal, refTimeVal }
}
