import { toast } from 'react-toastify'
import Validation2Api from '../api/Validation2Api'
import GlobalFormsStore from '../builder/GlobalFormsStore'
import { ENV } from '../ENV'
import {
  clearFormLiveErrorsPath,
  clearFormLiveErrorsSource,
  clearReqLiveErrorsPath,
  clearReqLiveErrorsSource,
} from '../redux/formErrors/formErrorsSlice'
import { AppThunk } from '../redux/store'
import { jsonPathToLong } from '../utils/jsonPaths'
import { processValidateLiveResponse } from './utils/processValidateLiveResponse'

export const validateLive = (isReq: boolean, fieldPath: string): AppThunk => {
  return (dispatch, getState) => {
    const formData = isReq ? GlobalFormsStore.getReqFormData(true) : GlobalFormsStore.getEditFormData(true)

    if (ENV.DEBUG_MODE) {
      console.log('DEBUG live validate', formData)
    }

    if (isReq) {
      // dispatch(clearReqErrorsPath(fieldPath))
      dispatch(clearReqLiveErrorsPath(fieldPath))
      dispatch(clearReqLiveErrorsSource(fieldPath))
    } else {
      // dispatch(clearFormErrorsPath(fieldPath))
      dispatch(clearFormLiveErrorsPath(fieldPath))
      dispatch(clearFormLiveErrorsSource(fieldPath))
    }

    const fieldPathLong = jsonPathToLong(fieldPath)
    Validation2Api.validateLive(fieldPathLong, formData)
      .then((res) => {
        if (ENV.DEBUG_MODE) {
          console.log('DEBUG live validate response', isReq ? 'reqForm' : 'editForm', formData, res.data)
        }
        dispatch(processValidateLiveResponse(res.data, fieldPath, isReq))
      })
      .catch((err) => {
        const code = err.response?.status
        if (code === 401) {
          toast.error('Nepřihlášený uživatel')
        } else if (code === 403) {
          toast.error('Nedostatečné oprávnění k validaci')
        } else if (code === 422) {
          const validErrors = err.response.data
          dispatch(processValidateLiveResponse(validErrors, fieldPath, isReq))
        } else if (code === 500) {
          toast.error('Nepodařilo se provést validaci')
        } else {
          toast.error('Neznámá chyba validace')
        }
      })
  }
}
