import { useFormContext } from 'react-hook-form'

export const useBtnLoadClear = () => {
  const { setValue, getValues } = useFormContext()

  // smaze obsah jednoho opakovani dle path
  const clearRepeatElement = (repeatElementPath: string) => {
    const parts = repeatElementPath.split('.')
    const lastPart = parts.pop()
    const remainingPath = parts.join('.')

    if (lastPart !== undefined && !isNaN(Number(lastPart))) {
      const vals = getValues(remainingPath)
      if (vals && vals.length) {
        vals[Number(lastPart)] = {}
        setValue(remainingPath, vals)
      }
    }
  }

  return { clearRepeatElement }
}
