import { iUIschema } from '../builder/interfaces/iUiSchema'
import { stringSanitize } from './stringSanitize'

export const ANCHOR_ID_PREFIX = 'na_'

export const anchorLinks = (schema: iUIschema) => {
  // todo pocitat jenom nejaky kratsi hash???
  const link = schema.options?.anchorLink || (schema.label ? stringSanitize(schema.label) : '') + (schema.labelId || '')
  return link ? ANCHOR_ID_PREFIX + link : undefined
}
