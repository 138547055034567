import { RootState } from '../rootReducer'

export const selectModalOpen = (state: RootState) => state.modal.modalOpen
export const selectModalData = (state: RootState) => state.modal.modalData

// export const selectModalDataContract = (state: RootState) => state.modal.modalData.modalDataContract
// export const selectModalDataConfirm = (state: RootState) => state.modal.modalData.modalDataConfirm
// export const selectModalDataNewForm = (state: RootState) => state.modal.modalData.modalDataNewForm
// export const selectModalDataCopyForm = (state: RootState) => state.modal.modalData.modalDataCopyForm
// export const selectModalDataHelp = (state: RootState) => state.modal.modalData.modalDataHelp

