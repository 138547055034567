export interface iEformsSignature {
  signature: string
  certificate: string
}

export interface iEformsDataJsonPatch {
  op: string
  path: string
  value: string
}

export interface iEformsFilterParam {
  publicId?: string | string[]
  variableId?: string | string[]
  workflowPlace?: string | string[]
  parent?: string | string[]
  id?: string | string[]

  [dataKey: string]: string | string[] | undefined | any
}

export interface iEformsOrderParam {
  variableId?: string
  createdAt?: string
  updatedAt?: string
  workflowPlace?: string

  [dataKey: string]: string | undefined
}

export type iEformsFilterOrderParam = iEformsFilterParam & { order?: iEformsOrderParam }

export interface iEformsFileData {
  id: string
  publicId: string
  submission: string
  subtype: string
  type: string
  contentUrl: string
  file: {
    extension: string
    hash: string
    mimeType: string
    mimeTypeExtension: string
    name: string
    safeName: string
    size: number
  }
  createdAt: string
  updatedAt: string
}

export interface iEformsSubmissionPostData {
  form?: string
  organization?: string
  organizations?: string[]
  formSchema?: string
  attachments?: string[]
  children?: string | string[]
  data?: any
  parent?: string
  signature?: iEformsSignature
  workflowPlaceCode?: string
  workflowTransitionCode?: string
  owner?: string
  scope?: string
  related?: string
  copyFrom?: string
}

export const TRANSLATE_DOMAIN_LAYOUT = 'layout'
export const TRANSLATE_DOMAIN_FORM = 'form'

export interface iEformsTranslatesBlock {
  [key: string]: string
}

export interface iEformTranslates {
  [TRANSLATE_DOMAIN_LAYOUT]: iEformsTranslatesBlock
  [TRANSLATE_DOMAIN_FORM]: iEformsTranslatesBlock

  [domain: string]: iEformsTranslatesBlock
}
