import React, { useEffect } from 'react'
import { useFilterContextInput } from '../../../context/FilterContext'

interface iRadioFilterInput {
  name: string
  label?: string
  description?: string
  options: { label: string; value: string }[]
  defaultValue?: string
}

const RadioFilterInput = ({ name, label, options, description, defaultValue }: iRadioFilterInput) => {
  const { value, setValue } = useFilterContextInput(name)

  const selectValue = value

  useEffect(() => {
    if (defaultValue && !selectValue) {
      setValue(defaultValue)
    }
  }, [selectValue])

  return (
    <div className='gov-form-control gov-form-control--custom'>
      {label && <label className='gov-form-control__label u-mb--20'>{label}</label>}
      <ul className='gov-form-group u-ms--95'>
        {options.map(({ label, value }) => (
          <li className='gov-form-group__item' key={value}>
            <input
              id={`radio_${name}_${value}`}
              className='gov-form-control__radio'
              type='radio'
              name={name}
              aria-disabled='false'
              onChange={(e) => setValue(e.target.value)}
              value={value}
              checked={selectValue === value}
            />
            <label className='gov-form-control__label' htmlFor={`radio_${name}_${value}`}>
              {label}
            </label>
            <span className='gov-form-control__indicator'></span>
          </li>
        ))}
      </ul>

      {description && <span className='gov-form-control__message'>{description}</span>}
    </div>
  )
}

export default RadioFilterInput
