import get from 'lodash/get'
import { scopeAddProperties, scopeFullToPath } from '../../utils/scopeModifs'

export const getSchemaItem = (dataSchema: any, scopePath?: string) => {
  if (!scopePath) {
    return {}
  }

  const path = scopeFullToPath(scopeAddProperties(scopePath.replace(/\/\d+\//g, '.items/')))

  return get(dataSchema, path)
}