import moment from 'moment'
import React, { useEffect } from 'react'
import { setDefaultLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'

const LangueSwitch = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  useEffect(() => {
    setDefaultLocale(lang)
    moment.locale(lang)
  }, [lang])

  return (
    <div className='gov-header__item gov-header__lang'>
      <div className='gov-lang-switch'>
        <select
          id='select-lang'
          className='gov-lang-switch__select'
          value={lang}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        >
          <option value='cs'>CZ</option>
          <option value='en'>EN</option>
        </select>
        <label className='u-sr-only' htmlFor='select-lang'>
          {t('others.zmena_jazyka', 'Změna jazyka')}
        </label>
      </div>
    </div>
  )
}

export default LangueSwitch
