import React, { ChangeEventHandler } from 'react'
import InputGovLabel from '../InputsUtils/InputGovLabel'
import WrapInputGov from '../InputsUtils/WrapInputGov'

interface iInputGov {
  name: string
  label?: string
  type?: string
  description?: string
  placeholder?: string

  value?: string
  onChange?: ChangeEventHandler<any>
  error?: string
  tooltip?: string
  disabled?: boolean
  required?: boolean
}

const InputGov = ({
  name,
  label,
  type = 'text',
  description,
  value,
  onChange,
  error,
  tooltip,
  disabled,
  required,
}: iInputGov) => {
  const id = 'input-' + name
  return (
    <WrapInputGov value={value} error={error} description={description} tooltip={tooltip}>
      <input
        id={id}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        className='gov-form-control__input'
        aria-required={required ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        disabled={disabled}
      />
      <InputGovLabel inputId={id} label={label} required={required}/>
    </WrapInputGov>
  )
}

export default InputGov
