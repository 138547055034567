import get from 'lodash/get'
import { FORM_TYPES_V2 as FT2 } from '../enums/enumFormTypeV2'
import { OPT_NOTICE_TYPE, PATH_NOTICE_TYPE } from '../enums/enumFromRepair'
import { FORM_TYPES as FT1 } from '../enums_v1/enumFormType'

const PREV_FORM_TYPE_WITH_TED_NUM = [FT2.F4, FT2.F5, FT2.F6, FT2.F7, FT2.F8, FT2.F9, FT1.F01, FT1.F04, FT1.F16]
const PREV_FORM_TYPE_WITH_TED_NUM_PRI_ONLY = [FT1.F21, FT1.F22, FT1.F23]

export const isPrevWithTedNum = (prevFormType: string, prevEditData: any) => {
  if (PREV_FORM_TYPE_WITH_TED_NUM_PRI_ONLY.includes(prevFormType)) {
    return get(prevEditData, PATH_NOTICE_TYPE) === OPT_NOTICE_TYPE.PRI_ONLY
  }

  return PREV_FORM_TYPE_WITH_TED_NUM.includes(prevFormType)
}
