import axios from 'axios'

const API_URL = process.env.REACT_APP_EXPORT_API_URL

class ExportApi {
  api = axios.create({
    baseURL: API_URL,
  })

  export = (email: string, token: string, filename?: string, compress?: boolean) =>
    this.api
      .post(`/export`, { email, token, filename, compress }) //
      .then((res) => res.data)
}

export default new ExportApi()
