import moment from 'moment'

export const dateFormatFull = (date?: string) => (date ? moment(date).format('DD.MM.yyyy HH:mm') : ' - ')

export const dateFormatShort = (date?: string) => (date ? moment(date).format('DD.MM.yyyy') : ' - ')

export const DATE_FORMAT_ISO8601 = 'YYYY-MM-DDTHH:mm:ssZ'
export const DATE_FORMAT_SHORT = 'YYYY-MM-DD'
export const DATE_FORMAT_TIME = 'HH:mm'

export const DATE_FORMAT_YEAR = 'YYYY'
export const DATE_FORMAT_MONTH = 'MM'
export const DATE_FORMAT_DAY = 'DD'

export const DATE_FORMAT_DATEPICKER_SHORT = 'dd.MM.yyyy'
export const DATE_FORMAT_DATEPICKER_FULL = 'dd.MM.yyyy HH:mm'
export const DATE_FORMAT_DATEPICKER_TIME = 'HH:mm'
