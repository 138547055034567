import React from 'react'

interface iError {
  msg: string
}

const AlertError = ({ msg }: iError) => (
  <div className='gov-alert'>
    <div className='gov-alert__content'>
      <p>{msg}</p>
    </div>
  </div>
)

export default AlertError
