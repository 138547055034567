import { useEffect, useState } from 'react'

const WINDOW_WITH_MOBILE = 832

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowSize.width < WINDOW_WITH_MOBILE

  return { width: windowSize.width, height: windowSize.height, isMobile }
}
