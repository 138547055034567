import EformsApi from '../api/EformsApi'
import { isFormDraft } from '../enums/enumWorkflowPlaces'
import { iSubmissionDataHlavni } from '../interfaces/iEformsSubmission'
import { setFormLoadErr, setFormUserFormName } from '../redux/formState/formStateSlice'
import { AppThunk } from '../redux/store'
import { setLoading, setSubmMain } from '../redux/submissions/submissionsSlice'
import { loadSubmissionJson } from './loadSubmissionJson'
import { loadSubmissionsXml } from './loadSubmissionsXml'
import { ERR_MSG } from './utils/errorMsgs'

export const loadSubmById = (submId: string): AppThunk => {
  return (dispatch) => {
    dispatch(setLoading(true))
    dispatch(setFormLoadErr(''))

    EformsApi.getSubmissionAcl(submId)
      .then((data) => {
        if (!data.allowedSubmissionActions.includes('read')) {
          dispatch(setFormLoadErr(ERR_MSG.NO_PERM))
          return
        }

        EformsApi.getSubmission(submId)
          .then((resMain: iSubmissionDataHlavni) => {
            dispatch(setSubmMain(resMain))
            dispatch(setFormUserFormName(resMain.data.uzivatelskyNazevFormulare))
            if (isFormDraft(resMain.workflowPlaceCode) || resMain.data.verzeSdk) {
              dispatch(loadSubmissionJson(resMain))
            } else {
              dispatch(loadSubmissionsXml(resMain))
            }
          })
          .catch((err) => {
            dispatch(setFormLoadErr(ERR_MSG.UNKNOWN))
          })
      })
      .catch((err) => {
        const status = err.response?.status
        let msg = ''
        if (status === 401 || status === 403) {
          msg = ERR_MSG.NO_PERM
        } else if (status === 404) {
          msg = ERR_MSG.NO_EXIST
        } else {
          msg = ERR_MSG.UNKNOWN
        }
        dispatch(setFormLoadErr(msg))
      })
  }
}
