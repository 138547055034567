export const convertDataKeysVals = (
  data: any,
  transformKey: (key: string) => string,
  transformVal: (key: string) => string,
): any => {
  if (typeof data !== 'object' || data === null) {
    if (typeof data === 'string') {
      return transformVal(data)
    }
    return data
  }

  if (Array.isArray(data)) {
    return data.map((item) => convertDataKeysVals(item, transformKey, transformVal))
  }

  return Object.keys(data).reduce((newdata: any, key) => {
    const transformedKey = transformKey(key)
    newdata[transformedKey] = convertDataKeysVals(data[key], transformKey, transformVal)
    return newdata
  }, {})
}
