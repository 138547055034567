import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import {
  DATE_FORMAT_DATEPICKER_SHORT,
  DATE_FORMAT_DATEPICKER_SHORT_MONTH,
  DATE_FORMAT_DATEPICKER_TIME,
  formatDateShortTZ,
  formatDateTimeTZ,
} from '../../../utils/dateFormatFns'
import { useElementContext } from '../../context/ElementContext'
import { useElementDateTime } from '../../hooks/useElementDateTime'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputDate = () => {
  const { field, errors } = useControlRegister()
  const { uiSchema, id, required, disabled } = useElementContext()

  const [openLabel, setOpenLabel] = useState(false)
  //TODO format date-time

  const { isTime, selectedValue, refDateVal, refTimeVal } = useElementDateTime(field.value)

  // pri zmene datumu upravit casouvou zonu pro cas
  useEffect(() => {
    if (refDateVal && selectedValue && isTime) {
      const dateString = formatDateTimeTZ(selectedValue)
      if (dateString !== field.value) {
        field.onChange(dateString ?? '')
      }
    }
  }, [refDateVal, selectedValue])

  // pri zmene datumu upravit casouvou zonu pro cas
  useEffect(() => {
    if (refTimeVal && selectedValue && !isTime) {
      const dateString = formatDateShortTZ(selectedValue)
      if (dateString !== field.value) {
        field.onChange(dateString ?? '')
      }
    }
  }, [refTimeVal, selectedValue])

  const onlyMonth = uiSchema.options?.dateOnlyMonth
  const disableFuture = uiSchema.options?.dateDisableFuture
  const disablePast = uiSchema.options?.dateDisablePast

  const onChange = (date: Date | null) => {
    const dateString = isTime ? formatDateTimeTZ(date) : formatDateShortTZ(date)
    field.onChange(dateString ?? '') // nemuze byt undefinet
    setOpenLabel(false)
    field.onBlur()
  }

  return (
    <WrapInputGov value={field.value} errors={errors} openLabel={openLabel}>
      {/*{field.value} x {refDateVal} x {refTimeVal}*/}
      <DatePicker
        name={field.name}
        ref={(elem: any) => {
          //kvuli focus a scroll - kdyz je element prvni chyba a scrolluje se na nej
          elem && field.ref(elem.input)
        }}
        showMonthYearPicker={onlyMonth ? true : undefined}
        minDate={disablePast ? new Date() : new Date('1900-1-1')}
        maxDate={disableFuture ? new Date() : undefined}
        timeCaption={'Čas'}
        showTimeSelect={isTime}
        showTimeSelectOnly={isTime}
        timeIntervals={isTime ? 5 : undefined}
        isClearable={!disabled}
        dateFormat={
          isTime
            ? DATE_FORMAT_DATEPICKER_TIME
            : onlyMonth
            ? DATE_FORMAT_DATEPICKER_SHORT_MONTH
            : DATE_FORMAT_DATEPICKER_SHORT
        }
        disabled={disabled}
        onChange={onChange}
        selected={selectedValue}
        id={id}
        className={'gov-form-control__input '}
        wrapperClassName={disabled ? 'datepickerinput-disabled' :''}
        onFocus={() => {
          setOpenLabel(true)
        }}
        onBlur={() => {
          setOpenLabel(false)
          field.onBlur()
        }}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-labelledby={id}
        autoComplete='off'
      />

      <PartGovLabel />
    </WrapInputGov>
  )
}
export default InputDate
