import React, { useEffect } from 'react'
import ArrayCounterHelper from '../../../services/ArrayCounterHelper'
import { useElementContext } from '../../context/ElementContext'
import { useFormLiveValidation } from '../../hooks/useFormLiveValidation'
import PartGovError from '../parts/PartGovError'
import { useControlRegister } from '../useControlRegister'

const InputArrayIdentifier = () => {
  const { field, errors } = useControlRegister()
  const { id, uiSchema, disabled } = useElementContext()
  const { validateFormLive } = useFormLiveValidation()

  const prefix = uiSchema.options?.counterPrefix

  let idValue = ''

  useEffect(() => {
    if (!disabled && !field.value && prefix) {
      const val = ArrayCounterHelper.getNextForPrefix(prefix)
      idValue = val
      field.onChange(val)
      validateFormLive() // kvuli validaci po zruseni disabled arrayindex
    }

    return () => {
      ArrayCounterHelper.deletePrefixId(idValue)
    }
  }, [disabled])

  useEffect(() => {
    if (field.value && prefix) {
      ArrayCounterHelper.addPrefixId(field.value)
    }
  }, [field.value])

  return (
    <div id={id}>
      {errors?.length && (
        <div className='gov-form-control gov-form-control--error'>
          {errors?.map((error, index) => (
            <PartGovError key={index} text={error} />
          ))}
        </div>
      )}
      <input type='hidden' {...field} value={field.value ?? ''} />
    </div>
  )
}

export default InputArrayIdentifier
