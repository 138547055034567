import { toast } from 'react-toastify'
import { clearFormAndReqErrorsState } from '../redux/formErrors/formErrorsSlice'
import { setFormActionLoading } from '../redux/formState/formStateSlice'
import { AppThunk } from '../redux/store'
import { saveSubmissionParts } from './saveSubmissionParts'

export const saveSubmission = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(setFormActionLoading(true))
    saveSubmissionParts(state)
      .then((res) => {
        toast.success('Formulář uložen')
        dispatch(clearFormAndReqErrorsState())
      })
      .catch((e) => {})
      .finally(() => dispatch(setFormActionLoading(false)))
  }
}
