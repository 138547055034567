import set from 'lodash/set'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LINKS } from '../../../components/App/LINKS'
import ButtonGov from '../../../components/Btns/ButtonGov'
import LoadingInline from '../../../components/Loading/LoadingInline'
import { FORM_TYPES_V2_NAMES, formTypeToCodeLabel } from '../../../enums/enumFormTypeV2'
import { FormsCreator } from '../../../services/FormsCreator'
import { getErrorMessage } from '../../../utils/errorMessage'
import { useElementContext } from '../../context/ElementContext'
import { useFormRenderContext } from '../../context/RenderFormContext'

const LIMIT_PATH_FROM = [
  'aktivity.plan.nadlimitniVZ',
  'aktivity.zamer.navazujeNaPredchoziNe.nadlimitniVZ',
  'aktivity.zahajeni.navazujeNaPredchoziNe.nadlimitniVZ',
  'aktivity.vysledek.navazujeNaPredchoziNe.nadlimitniVZ',
]
const LIMIT_PATH_TO = 'ND-Root.ND-StatistickeUdajeFormulare.BT-009-TypZakazkyDlePredpokladaneHodnoty'
const LIMIT_VAL_UP = 'nadlimitni'

const TED_PATH_FROM = [
  'aktivity.plan.nadlimitniVZNe.uverejneniTED',
  'aktivity.zamer.navazujeNaPredchoziNe.nadlimitniVZNe.uverejneniTED',
  'aktivity.zahajeni.navazujeNaPredchoziNe.nadlimitniVZNe.uverejneniTED',
  'aktivity.vysledek.navazujeNaPredchoziNe.uverejneniTED',
]
const TED_PATH_TO = 'ND-Root.ND-StatistickeUdajeFormulare.BT-013-UverejneniTed'

const SPECIAL_PATH_FROM = [
  'aktivity.zahajeni.navazujeNaPredchoziNe.socialniJineZvlastniSluzby',
  'aktivity.vysledek.navazujeNaPredchoziNe.socialniJineZvlastniSluzby',
]

const TYPE_PATH_TO = 'ND-Root.ND-StatistickeUdajeFormulare.BT-010-RezimZakazky'
const TYPE_VAL_UP = 'nadlimitni'
const TYPE_VAL_SIMPL = 'zjednoduseny'

const DUVOD_PATH_FROM = 'aktivity.vysledek.navazujeNaPredchoziNe.duvod'
const DUVOD_PATH_TO = 'ND-Root.BT-041-DuvodNenavazani'

const ODUVOD_PATH_FROM = 'aktivity.vysledek.navazujeNaPredchoziNe.oduvodneni'
const ODUVOD_PATH_TO = 'ND-Root.BT-042-OduvodneniNenavazani'

const BtnCreateForm = () => {
  const { getValues, formState } = useFormContext()
  const { setLoadingAction, loadingAction } = useFormRenderContext()
  const { disabled, uiSchema } = useElementContext()

  const { btnCreateFormType: formType } = uiSchema.options || {}

  const navigate = useNavigate()

  const createForm = async () => {
    if (!formType) {
      return
    }
    setLoadingAction(true)

    const reqFormData: any = {}

    const limitVals = getValues(LIMIT_PATH_FROM)
    const typeSpecialVals = getValues(SPECIAL_PATH_FROM)
    const tedVals = getValues(TED_PATH_FROM)
    const isLimitUp = limitVals.some((val) => val === 'ANO')
    const isTypeScecial = typeSpecialVals.some((val) => val === 'ANO')
    const isTed = tedVals.some((val) => val === 'ANO')

    const duvod = getValues(DUVOD_PATH_FROM)
    const oduvod = getValues(ODUVOD_PATH_FROM)

    if (isLimitUp) {
      set(reqFormData, LIMIT_PATH_TO, LIMIT_VAL_UP)
    }

    if (isTypeScecial) {
      set(reqFormData, TYPE_PATH_TO, TYPE_VAL_SIMPL)
    } else if (isLimitUp) {
      set(reqFormData, TYPE_PATH_TO, TYPE_VAL_UP)
    }

    if (isTed || isLimitUp) {
      set(reqFormData, TED_PATH_TO, true)
    } else {
      set(reqFormData, TED_PATH_TO, false)
    }

    if (duvod) {
      set(reqFormData, DUVOD_PATH_TO, duvod)
    }
    if (oduvod) {
      set(reqFormData, ODUVOD_PATH_TO, oduvod)
    }

    try {
      const formsCreator = new FormsCreator(formType)
      await formsCreator.createEmptyFormData()
      formsCreator.addReqData(reqFormData)

      const formId = await formsCreator.createForms()
      navigate(LINKS.vyhledatFormular + '/' + formId)
    } catch (e) {
      toast.error(getErrorMessage(e))
    } finally {
      setLoadingAction(false)
    }
  }

  if (loadingAction) {
    return <LoadingInline />
  }

  if (!formType) {
    return
  }

  return (
    <div className='u-mb--20'>
      <ButtonGov variant='primary' disabled={disabled || !formState.isValid} onClick={createForm}>
        Založit formulář &nbsp;
        <small>
          "{formTypeToCodeLabel(formType)} - {FORM_TYPES_V2_NAMES[formType]}"
        </small>
      </ButtonGov>
    </div>
  )
}

export default BtnCreateForm
