import { createSelector } from '@reduxjs/toolkit'
import { SLUG_FORM } from '../../api/EformsApi'
import { calculateUiScopesOrder } from '../../builder/utils/calculateUiScopesOrder'
import { findSchemaScopeToLabel } from '../../builder/utils/findSchemaScopeToLabel'
import { PROFILE_FORM_TYPES_V2 } from '../../enums/enumFormTypeV2'
import { isFormDraft } from '../../enums/enumWorkflowPlaces'
import { PROFILE_FORM_TYPES } from '../../enums_v1/enumFormType'
import { RootState } from '../rootReducer'

export const selectSubmMain = (state: RootState) => state.submissions.submMain
export const selectSubmForm = (state: RootState) => state.submissions.submForm
export const selectSubmReq = (state: RootState) => state.submissions.submReq

export const selectSchemaFull = (state: RootState) => state.submissions.formSchema?.schema
export const selectSchemaFullReq = (state: RootState) => state.submissions.formSchemaReq?.schema
export const selectLoading = (state: RootState) => state.submissions.loading

export const selectSchemaIsV1 = (state: RootState) => !!state.submissions.formSchema?.metadata.verzeXsd
export const selectSumbIds = (state: RootState) => ({
  submMainId: state.submissions.submMain.id,
  subFormId: state.submissions.submForm.id,
  subReqId: state.submissions.submReq.id,
})

export const selectSubmSchemasFormId = (state: RootState) => state.submissions.formSchema?.id
export const selectSubmSchemasReqId = (state: RootState) => state.submissions.formSchemaReq?.id

export const selectSubmMainInfo = createSelector(selectSubmMain, (submMain) => {
  const submMainFormType = submMain.data?.druhFormulare
  const isDraft = isFormDraft(submMain.workflowPlaceCode)
  const isProfil = PROFILE_FORM_TYPES.includes(submMainFormType) || PROFILE_FORM_TYPES_V2.includes(submMainFormType)
  return {
    submMain: submMain,
    submMainData: submMain.data,
    isProfil: isProfil,
    isDraft: isDraft,
    formType: submMainFormType,
    formSlug: isProfil ? SLUG_FORM.PROFIL : SLUG_FORM.HLAVNI,
    loaded: !!Object.keys(submMain).length,
  }
})

//TOOD mozna vsechno zpracovat hned po nacteni dat => vyhodit ze selectoru

export const selectScopeToLabel = createSelector(selectSchemaFull, (schemaFull) =>
  findSchemaScopeToLabel(schemaFull?.layout),
)
export const selectScopeToLabelReq = createSelector(selectSchemaFullReq, (schemaFullReq) =>
  findSchemaScopeToLabel(schemaFullReq?.layout),
)

export const selectUiSchemaScopeOrder = createSelector(selectSchemaFull, (schemaFull) =>
  calculateUiScopesOrder(schemaFull?.layout),
)
export const selectUiSchemaScopeOrderReq = createSelector(selectSchemaFullReq, (schemaFullReq) =>
  calculateUiScopesOrder(schemaFullReq?.layout),
)

export const selectVersionForm = (state: RootState) => state.submissions.versionForm
export const selectVersionReq = (state: RootState) => state.submissions.versionReq
