export const DEFAULT_DATA_UOHS = {
  'ND-Company': {
    'OPT-200-Organization-Company': 'ORG-0002',
    'BT-500-Organization-Company': 'Úřad pro ochranu hospodářské soutěže',
    'ND-CompanyLegalEntity': [{ 'BT-501-Organization-Company': '65349423' }],
    'BT-505-Organization-Company': 'https://uohs.gov.cz',
    'ND-CompanyAddress': {
      'BT-510(a)-Organization-Company': 'třída Kpt. Jaroše 1926/7',
      'BT-513-Organization-Company': 'Brno',
      'BT-512-Organization-Company': '60200',
      'BT-507-Organization-Company': 'CZ064',
      'BT-514-Organization-Company': 'CZE',
    },
    'ND-CompanyContact': {
      'BT-506-Organization-Company': 'posta@uohs.cz',
      'BT-503-Organization-Company': '+420 542167111',
    },
  },
}
