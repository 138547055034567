import React from 'react'
import { useFormRenderContext } from '../context/RenderFormContext'
import RenderElements from './RenderElements'

const RenderFormElements = () => {
  const { uiSchemas } = useFormRenderContext()
  return <RenderElements uiSchemas={uiSchemas} />
}

export default React.memo(RenderFormElements)
