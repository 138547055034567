import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EformsApi from '../api/EformsApi'
import { useFilterContext } from '../context/FilterContext'
import { selectReloadFlag } from '../redux/global/selectors'
import { FILTER_MY_FORM } from '../tables/TableMyForms/FilterMyForms'
import { createFilterParams, createOrderParams } from '../utils/createParams'
import { hash } from '../utils/hash'
import { paramsTableType } from '../utils/paramsTableType'

let LASTPARAMHASH: number
let LASTTOTAL: number
export const useFetchForms = <T>(formSlug: string, otherParams?: any) => {
  const [data, setData] = useState<T>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [maxItems, setMaxItems] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const { sort, activeFilters, page, limit, variant, contextName } = useFilterContext()

  const reloadFlag = useSelector(selectReloadFlag)

  const fetchData = useCallback(() => {
    const { [FILTER_MY_FORM]: _, ...othersFilters } = activeFilters

    setError('')
    setLoading(true)

    const defParams = paramsTableType(contextName, variant)
    const filterParams = createFilterParams(othersFilters)
    const filterOrderParams = createOrderParams(sort.field, sort.order)

    const params = { ...defParams, ...filterParams, ...filterOrderParams, ...otherParams }

    EformsApi.getSubmissionsSearch(formSlug, page, limit, params)
      .then((result) => {
        setData(result.data)
        setMaxItems(result.maxItems)
      })
      .catch((error) => {
        setError(error.toString())
      })
      .finally(() => {
        setLoading(false)
      })

    const paramsHash = hash(JSON.stringify({ [formSlug]: params, reloadFlag: reloadFlag }))

    if (LASTPARAMHASH === paramsHash) {
      if (totalCount !== LASTTOTAL) {
        setTotalCount(LASTTOTAL)
      }
    } else {
      EformsApi.getSubmissionsSearch(formSlug, undefined, 0, params)
        .then((result) => {
          setTotalCount(result.totalCount)
          LASTPARAMHASH = paramsHash
          LASTTOTAL = result.totalCount
        })
        .catch((error) => {
          setError(error.toString())
        })
    }
  }, [page, limit, formSlug, variant, sort, activeFilters, reloadFlag])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, loading, error, totalCount, fetchData, maxItems: totalCount < maxItems ? totalCount : maxItems }
}
