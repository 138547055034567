import { useCombobox } from 'downshift'
import React, { useEffect, useState } from 'react'
import FormFullInit from '../../../services/FormFullInit'
import { useElementContext } from '../../context/ElementContext'
import { useElementEnum } from '../../hooks/useElementEnum'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputSelectAutocomplete = () => {
  const { field, errors } = useControlRegister()
  const { id, required, disabled, path } = useElementContext()
  const { enumOptions, enumLoading } = useElementEnum()

  const [searchText, setSearchText] = useState('')

  const items = enumOptions ?? []
  const filterItems = items.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()))
  const loading = enumLoading

  useEffect(() => {
    if (!enumLoading) {
      FormFullInit.removeInputSelectScopes(path)
    }
  }, [enumLoading])

  // nacteni ulozene hodnoty po nacteni ciselniu
  useEffect(() => {
    if (field.value && !inputValue && enumOptions?.length) {
      selectItem(field.value) // kvuli reloadu textu v itemToString
    }
  }, [enumOptions])

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
    inputValue,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setSearchText(inputValue ?? '')
      if (!inputValue || inputValue?.length === 0) {
        field.onChange('') // musi byt '' - kdyby bylo undefined tak to nacte default hodnotu ktera se nacetla ze souboru
        setSearchText('')
      }
    },
    // inputValue
    // selectedItem: items.find((item) => item.value === field.value),
    selectedItem: field.value || '',
    onSelectedItemChange({ selectedItem }) {
      // field.onChange(selectedItem?.value)
      field.onChange(selectedItem)
    },
    items: filterItems.map((i) => i.value),
    itemToString(itemVal) {
      const item = items.find((item) => item.value === itemVal)
      return item ? item.label : ''
    },
  })

  return (
    <WrapInputGov value={field.value} errors={errors}>
      {/*enum loading: {enumLoading ? 'yes' : 'no'} {enumKey}*/}
      {/*<br />*/}
      {/*rhf: {field.value}*/}
      {/*<br />*/}
      {/*inputvalue: {inputValue}*/}
      {/*<br />*/}
      {/*search: {searchText}*/}
      {/*<br />*/}
      {/*items: {JSON.stringify(filterItems, null, 2)}*/}
      <div className='gov-autocomplete'>
        <input
          {...getInputProps({
            ref: field.ref,
            onBlur: (e) => {
              // pokud nekdo vlozil kopirovanim tak je jen label - kontrola s nactenym
              const actItemByLabel = items.find((i) => i.label === inputValue)
              if (actItemByLabel) {
                if (field.value !== actItemByLabel.value) {
                  field.onChange(actItemByLabel.value)
                }
              } else {
                if (field.value) {
                  // obnoveni labelu dle vybrane polozky
                  selectItem(field.value)
                } else {
                  //smazani rozepsaneho labelu
                  selectItem(undefined)
                }
              }
              field.onBlur()
            },
            name: field.name,
          })}
          id={id}
          className='gov-form-control__input'
          type='text'
          disabled={disabled}
          aria-required={required ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-labelledby={id}
        />
        <ul
          className={`gov-autocomplete__results`}
          style={{ display: isOpen && (items.length || loading) ? 'block' : 'none' }}
          {...getMenuProps()}
        >
          {isOpen && loading && <li className={`gov-autocomplete__empty`}>načítám</li>}
          {isOpen && !loading && !filterItems.length && (
            <li className={`gov-autocomplete__empty`}>Zadané hodnotě neodpovídá žádná položka</li>
          )}
          {isOpen &&
            !loading &&
            filterItems.map((item, index) => (
              <li
                className={`gov-autocomplete__result ${highlightedIndex === index && 'selected'}`}
                style={{
                  // backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                  fontWeight: selectedItem === item ? 'bold' : 'normal',
                }}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </li>
            ))}
        </ul>
        <PartGovLabel {...getLabelProps()} />
      </div>
    </WrapInputGov>
  )
}

export default InputSelectAutocomplete
