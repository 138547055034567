import { scopeFullToPath } from './scopeModifs'

export const ARRAY_SCOPE_SEP = '[]'
const ARRAY_SCOPES = [
  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedureContractAdditionalNature/[]/BT-531-Procedure",
  "ND-PartProcurementScope/ND-PartContractAdditionalNature/[]/BT-531-Part",

  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedureAdditionalCommodityClassification/[]/BT-26(a)-Procedure",
  "ND-PartProcurementScope/ND-PartAdditionalClassification/[]/BT-26(a)-Part",
  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedureAdditionalCommodityClassification/[]/BT-263-Procedure",
  "ND-PartProcurementScope/ND-PartAdditionalClassification/[]/BT-263-Part",

  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/ND-ProcedurePlacePerformance/BT-5071-Procedure",
  "ND-PartProcurementScope/ND-PartPlacePerformance/[]/ND-PartPerformanceAddress/BT-5071-Part",
  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/ND-ProcedurePlacePerformance/BT-5141-Procedure",
  "ND-PartProcurementScope/ND-PartPlacePerformance/[]/ND-PartPerformanceAddress/BT-5141-Part",
  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/ND-ProcedurePlacePerformance/BT-727-Procedure",
  "ND-PartProcurementScope/ND-PartPlacePerformance/[]/ND-PartPerformanceAddress/BT-727-Part",
  "#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/BT-728-Procedure",
  "ND-PartProcurementScope/ND-PartPlacePerformance/[]/BT-728-Part",


  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedureContractAdditionalNature/[]/BT-531-Procedure',
  'ND-LotProcurementScope/ND-LotContractAdditionalNature/[]/BT-531-Lot',

  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedureAdditionalCommodityClassification/[]/BT-26(a)-Procedure',
  'ND-LotProcurementScope/ND-LotAdditionalClassification/[]/BT-26(a)-Lot',
  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedureAdditionalCommodityClassification/[]/BT-263-Procedure',
  'ND-LotProcurementScope/ND-LotAdditionalClassification/[]/BT-263-Lot',

  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/ND-ProcedurePlacePerformance/BT-5071-Procedure',
  'ND-LotProcurementScope/ND-LotPlacePerformance/[]/ND-LotPerformanceAddress/BT-5071-Lot',
  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/ND-ProcedurePlacePerformance/BT-5141-Procedure',
  'ND-LotProcurementScope/ND-LotPlacePerformance/[]/ND-LotPerformanceAddress/BT-5141-Lot',
  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/ND-ProcedurePlacePerformance/BT-727-Procedure',
  'ND-LotProcurementScope/ND-LotPlacePerformance/[]/ND-LotPerformanceAddress/BT-727-Lot',
  '#/ND-Root/ND-ProcedureProcurementScope/ND-ProcedurePlacePerformanceAdditionalInformation/[]/BT-728-Procedure',
  'ND-LotProcurementScope/ND-LotPlacePerformance/[]/BT-728-Lot',
]

const ARRAY_SCOPES_SHORT = ARRAY_SCOPES.map((s) => s.replace(ARRAY_SCOPE_SEP + '/', ''))

const replaceArrayScope = (scope: string) => {
  const i = ARRAY_SCOPES_SHORT.indexOf(scope)
  if (i !== -1) {
    return ARRAY_SCOPES[i]
  }
  return scope
}

export const extractCopyMap = (buttonCopyMap: { [fromScope: string]: string | string[] }, baseToPath: string) =>
  Object.entries(buttonCopyMap).map(([fromScope, toScopes]) => {
    fromScope = replaceArrayScope(fromScope) // TODO zrusit po uprave schema
    toScopes = Array.isArray(toScopes) ? toScopes : replaceArrayScope(toScopes) // TODO zrusit po uprave schema

    const toScope = Array.isArray(toScopes) ? toScopes.join('.') : toScopes
    const deleteScope = Array.isArray(toScopes) ? toScopes[0] : undefined
    return {
      fromPath: scopeFullToPath(fromScope),
      toPath: baseToPath + '.' + scopeFullToPath(toScope),
      deletePath: deleteScope ? baseToPath + '.' + scopeFullToPath(deleteScope) : undefined,
    }
  })
