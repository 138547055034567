import { GroupLayout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React from 'react'
import { withJsonFormsLayoutPropsCustom } from '../../core/withJsonFormsLayoutPropsCustom'
import { UiElTypesLayout } from '../uiElTypes'

const GovHiddenGroupRender = (props: LayoutProps) => {
  const { schema, uischema, renderers, enabled, visible, path, cells } = props

  const groupLayout = uischema as GroupLayout

  if (!visible) {
    return null
  }

  return (
    <div style={{ display: 'none' }}>
      {groupLayout.elements &&
        groupLayout.elements.map((child, index) => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={index}
          />
        ))}
    </div>
  )
}
export default withJsonFormsLayoutPropsCustom(GovHiddenGroupRender)

export const govHiddenGroupTester = rankWith(1000, uiTypeIs(UiElTypesLayout.HiddenGroup))

