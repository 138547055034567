import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import PartGovError from '../parts/PartGovError'
import { useControlRegister } from '../useControlRegister'

const InputObjectIdentifier = () => {
  const { field, errors } = useControlRegister()
  const { id } = useElementContext()

  return (
    <div id={id}>
      {errors?.length && (
        <div className='gov-form-control gov-form-control--error'>
          {errors?.map((error, index) => (
            <PartGovError key={index} text={error} />
          ))}
        </div>
      )}
      <input type='hidden' {...field} value={field.value ?? ''} />
    </div>
  )
}

export default InputObjectIdentifier
