import React from 'react'
import { useTranslation } from 'react-i18next'
import PageWrap from '../../components/PageWrap/PageWrap'
import VariantsTabs from '../../components/VariantsTabs/VariantsTabs'
import { FilterContextProvider } from '../../context/FilterContext'
import { VARIANTS_TABLE_NEW_CANCEL_CHANGE_OPTIONS } from '../../enums/enumTablesVariants'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import TableContracts from '../../tables/TableContracts/TableContracts'

const NoveZruseniPage = () => {
  const { t } = useTranslation()

  return (
    <PageWrap
      title={t('title.nove_zmeny', 'Nová zrušení a změny')}
      subtitle={t('title.nove_zmeny_text', 'Dnes zveřejněné změny a zrušení formulářů.')}
    >
      <FilterContextProvider name={TABLE_TYPES.newCancel}>
        <VariantsTabs variants={VARIANTS_TABLE_NEW_CANCEL_CHANGE_OPTIONS} />
        <TableContracts />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default NoveZruseniPage
