import has from 'lodash/has'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectForceOpenPath, selectForceToSection } from '../../../redux/formTree/formTreeSelectors'
import { useElementContext } from '../../context/ElementContext'
import { useFormTreeEl } from '../../hooks/useFormTreeEl'
import ActiveSectionWrap from '../../navigation/ActiveSectionWrap'
import SectionHeaderErrors from '../../navigation/SectionHeaderErrors'
import SectionHeaderNav from '../../navigation/SectionHeaderNav'
import SectionHeaderToggle from '../../navigation/SectionHeaderToggle'
import SectionHeaderWrap from '../../navigation/SectionHeaderWrap'
import RenderElements from '../../renders/RenderElements'
import NavigationAnchor from '../parts/NavigationAnchor'
import PartGovDescription from '../parts/PartGovDescription'
import PartGovErrorsBlock from '../parts/PartGovErrorsBlock'

const Group = () => {
  const [open, setOpen] = useState(true)

  const formTreeEl = useFormTreeEl()
  const forceOpenPath = useSelector(selectForceOpenPath)
  useEffect(() => {
    if (!open && forceOpenPath) {
      if (has(formTreeEl?.pathsInside, forceOpenPath)) {
        setOpen(true)
      }
    }
  }, [forceOpenPath])

  const forceOpenToSection = useSelector(selectForceToSection)
  useEffect(() => {
    if (!open && forceOpenToSection) {
      if (formTreeEl?.toInside?.includes(forceOpenToSection)) {
        setOpen(true)
      }
    }
  }, [forceOpenToSection])

  const { uiSchema, id } = useElementContext()

  const label = uiSchema.label
  const groupVirtual = uiSchema.options?.groupVirtual

  if (groupVirtual) {
    return uiSchema.elements && <RenderElements uiSchemas={uiSchema.elements} />
  }

  return (
    <ActiveSectionWrap>
      <NavigationAnchor>
        <SectionHeaderWrap>
          {uiSchema.elements && <SectionHeaderToggle open={open} setOpen={setOpen} />}
          <span className='form-title-text'>{label}</span>
          {uiSchema.labelId && <small>({uiSchema.labelId})</small>}
          {/*{required ? '*' : ''}*/}
          <SectionHeaderErrors />
          <SectionHeaderNav />
        </SectionHeaderWrap>

        <div className={'groupSectionWrap blockWrap custom-form u-mb--10 ' + (!open ? 'closed-form-block' : '')}>
          <div id={id}>
            <PartGovDescription text={uiSchema.options?.description} />
            <PartGovErrorsBlock />

            {uiSchema.elements && <RenderElements uiSchemas={uiSchema.elements} />}
          </div>
        </div>
      </NavigationAnchor>
    </ActiveSectionWrap>
  )
}

export default Group
