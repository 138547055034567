import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iOption } from '../../interfaces/iOption'

interface iDynamEnumSlice {
  enums: { [keys: string]: iOption[] }
}

const slice = createSlice({
  name: 'dynamEnum',
  initialState: {
    enums: {},
  } as iDynamEnumSlice,
  reducers: {
    clearDynamEnum(state) {
      state.enums = {}
    },
    setDynamEnum(state, action: PayloadAction<{ enumKey: string; enums: iOption[] }>) {
      const { enumKey, enums } = action.payload
      state.enums[enumKey] = enums
    },
    setDynamEnumAll(state, action: PayloadAction<{ [keys: string]: iOption[]}>) {
      state.enums = action.payload
    },
  },
})

export const { clearDynamEnum, setDynamEnum, setDynamEnumAll } = slice.actions
export default slice.reducer
