import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingInline from '../../../components/Loading/LoadingInline'
import { iOption } from '../../../interfaces/iOption'
import EnumLoader from '../../../services/EnumLoader'
import { useFormRenderContext } from '../../context/RenderFormContext'
import { useElementEnum } from '../../hooks/useElementEnum'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputAutocompleteTxt = () => {
  const { field } = useControlRegister()
  const { enumKey, enumOptions } = useElementEnum()
  const { formSchemaId } = useFormRenderContext()

  const [enumItems, setEnumItems] = useState<iOption[]>([])
  const [loadingFirst, setLoadingFirst] = useState(true)

  useEffect(() => {
    if (field.value && !enumItems.length && enumKey) {
      //nacteni labelu z ulozene value
      EnumLoader.getByCode(formSchemaId!, enumKey!, field.value)
        .then((res) => {
          setEnumItems(res)
          setLoadingFirst(false)
        })
        .catch((err) => {
          toast.error('Chyba načtení hodnoty do číselníku: ' + field.value)
        })
    } else {
      setLoadingFirst(false)
    }
  }, [field.value])

  if (loadingFirst) {
    return <LoadingInline />
  }
  const value = enumItems?.find((op) => op.value === field.value)?.label
  const valueOptions = enumOptions?.find((op) => op.value === field.value)?.label

  return (
    <div>
      <PartGovLabelTxt /> {enumKey ? value : valueOptions}
    </div>
  )
}

export default InputAutocompleteTxt
