import React from 'react'
import { useSelector } from 'react-redux'
import { selectSubmMainInfo } from '../../redux/submissions/selectors'
import { formReqToMetodic, formTypeToMetodic } from '../../utils/formTypeToMetodic'
import FormHeaderAdminInfo from './FormHeaderAdminInfo'
import FormHeaderInfo from './FormHeaderInfo'

const FormHeader = () => {
  const { isDraft, formType } = useSelector(selectSubmMainInfo)
  return (
    <div className='custom-detail u-mb--20'>
      <FormHeaderAdminInfo />
      <div className='custom-detail__top'>
        <FormHeaderInfo />
      </div>
      <div className='custom-detail__bottom'>
        {isDraft && (
          <div className='custom-detail__alert'>
            Doporučujeme uživatelům, aby si formulář průběžně ukládali, aby nedošlo ke ztrátě vyplněných dat. Žluté
            podbarvení položky neznamená povinnost jejího vyplnění, povinné položky jsou dány validačními pravidly a
            červeně se orámují po kliknutí na tlačítko VALIDOVAT.
          </div>
        )}

        {isDraft && (
          <div className='custom-detail__side'>
            <ul>
              <li>
                <a href={formTypeToMetodic(formType)} target='_blank' rel='noopener noreferrer'>
                  Metodické pokyny pro vyplnění formuláře
                </a>
              </li>
              <li>
                <a href={formReqToMetodic()} target='_blank' rel='noopener noreferrer'>
                  Metodické pokyny pro vyplnění Žádosti
                </a>
              </li>
              <li>
                <a href='https://isvz.nipez.cz/ciselniky' target='_blank' rel='noopener noreferrer'>
                  Číselníky a klasifikace
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormHeader
