import { ReactKeycloakProvider } from '@react-keycloak/web'
import React from 'react'
import { initAdminOptions, keycloakAdmin } from './keycloakAdmin'
import { LoadUserWrap } from './LoadUserWrap'

const KeycloakAdminProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <ReactKeycloakProvider authClient={keycloakAdmin} initOptions={initAdminOptions}>
      <LoadUserWrap>{children}</LoadUserWrap>
    </ReactKeycloakProvider>
  )
}

export default KeycloakAdminProvider
