import flatten from 'lodash/flatten'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useLocalStorage from '../../hooks/useLocalStorage'
import { iOption } from '../../interfaces/iOption'
import { selectDynamEnum } from '../../redux/dynamEnum/dynamEnumSelectors'
import { selectFormIsFullInit } from '../../redux/formTree/formTreeSelectors'
import { RootState } from '../../redux/rootReducer'
import EnumLoader from '../../services/EnumLoader'
import { scopeWithoutRepeat } from '../../utils/scopeModifs'
import { useElementContext } from '../context/ElementContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { ELEMENT_TYPE } from '../elements/ELEMENTS'
import { DATA_TYPE } from '../interfaces/iDataSchema'
import { iElementEnum } from '../interfaces/iElement'

const enumTrueFalse = [
  { value: 'true', label: 'Ano' },
  { value: 'false', label: 'Ne' },
]

export const useElementEnum = (): iElementEnum => {
  const staticEnumOptions = useElementEnumStatic()
  const { enumKey, dbEnumOptions, dbEnumLoading } = useElementEnumDB()
  const { isDynamicEnum, dynamicEnumOptions } = useElementEnumDynamic()
  const isFullInit = useSelector(selectFormIsFullInit)

  const dynEnum = isFullInit ? dynamicEnumOptions : undefined
  const nemumOptions = isDynamicEnum ? dynEnum : enumKey ? dbEnumOptions : staticEnumOptions
  return useMemo(
    () => ({
      enumOptions: nemumOptions,
      enumLoading: dbEnumLoading,
      enumKey: enumKey,
      isDynamicEnum: isDynamicEnum,
    }),
    [enumKey, dbEnumLoading, isDynamicEnum, nemumOptions],
  )
}

const useElementEnumStatic = () => {
  const { uiSchema, dataSchema } = useElementContext()
  const customEnum = uiSchema.options?.customEnum
  const dataEnum = dataSchema?.enum
  const isBoolean = dataSchema?.type === DATA_TYPE.BOOLEAN

  return useMemo(() => {
    // console.log('calculate enum', uiSchema.scope)
    if (customEnum) {
      return customEnum
        .filter((a) => a)
        .map((item: string) => {
          //TODO isBoolean
          const [value, label] = item.split(':', 2).map((p) => p.trim())
          return { value, label }
        })
    }

    if (dataEnum && Array.isArray(dataEnum)) {
      return dataEnum?.map((item) => ({
        label: item,
        value: item,
      }))
    }
    if (isBoolean) {
      return enumTrueFalse
    }
    return undefined
  }, [customEnum, dataEnum, isBoolean])
}
const useElementEnumDB = () => {
  const { uiSchema, dataSchema, disabled } = useElementContext()
  const dataEnum = dataSchema?.enum

  const { formSchemaId } = useFormRenderContext()

  const enumKey = dataEnum && 'key' in dataEnum ? dataEnum.key : undefined
  const loadFullEnum = !!enumKey && uiSchema.type !== ELEMENT_TYPE.Autocomplete

  const [savedValues, setSavedValues] = useLocalStorage<iOption[]>(`enum_${formSchemaId}_${enumKey}`, [])

  const [queryEnumData, setQueryEnumData] = useState<iOption[]>()
  const [queryEnumLoading, setQueryEnumLoading] = useState(true)

  useEffect(() => {
    if (!disabled && loadFullEnum && !savedValues.length) {
      setQueryEnumLoading(true)
      EnumLoader.search(formSchemaId!, enumKey!, 150)
        .then((options) => {
          setSavedValues(options)
          setQueryEnumData(options)
          // return options
        })
        .catch((err) => {
          toast.error('Nepodařilo se načíst číselník')
        })
        .finally(() => {
          setQueryEnumLoading(false)
        })
    } else {
      setQueryEnumLoading(false)
    }
  }, [])

  const dbEnumOptions = !disabled && savedValues.length ? savedValues : queryEnumData ? queryEnumData : undefined
  const dbEnumLoading = !disabled && loadFullEnum && !savedValues.length && queryEnumLoading

  return useMemo(() => ({ enumKey, dbEnumOptions, dbEnumLoading }), [enumKey, dbEnumOptions, dbEnumLoading])
}
const useElementEnumDynamic = () => {
  const { uiSchema } = useElementContext()
  const dynamicEnum = uiSchema.options?.dynamEnum ?? []
  const isDynamicEnum = !!dynamicEnum.length

  const dynamicEnumOptions = useSelector((state: RootState) => {
    if (!isDynamicEnum) {
      return []
    }
    const enums = selectDynamEnum(state)
    return findDynamicEnum(dynamicEnum, enums)
  }, shallowEqual)

  return useMemo(() => ({ isDynamicEnum, dynamicEnumOptions }), [isDynamicEnum, dynamicEnumOptions])
}

const findDynamicEnum = (dynamicEnum: { scope: string }[], enums: { [scope: string]: iOption[] }) => {
  const enumsOption = dynamicEnum.map((d) => {
    if (enums[d.scope]) {
      return enums[d.scope]
    }
    const enumInsideArray = Object.keys(enums)
      .filter((scope) => scopeWithoutRepeat(scope) === d.scope)
      .map((scope) => enums[scope])

    if (enumInsideArray.length) {
      return flatten(enumInsideArray)
    }
    return undefined
  })

  const dynamicEnumOptions: iOption[] | undefined = enumsOption.some((e) => e === undefined)
    ? undefined
    : (flatten(enumsOption) as iOption[])

  return dynamicEnumOptions
}
