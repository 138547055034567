import Keycloak, { KeycloakInitOptions } from 'keycloak-js'

// alternativa  https://github.com/dasniko/keycloak-reactjs-demo/blob/main/src/services/UserService.js

export const keycloakAdmin = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM_ADMIN!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID_ADMIN!,
})

export const initAdminOptions: KeycloakInitOptions = {
  // onLoad: "login-required",
  onLoad: 'check-sso',
  // onLoad: tokensGetToken() ? "check-sso" : undefined,
  pkceMethod: 'S256',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  checkLoginIframe: false,
  // enableLogging: process.env.NODE_ENV === "development",
  // token: tokensGetToken(),
  // idToken: tokensGetIdToken(),
  // refreshToken: tokensGetRefreshToken()
}
