import { ControlProps, OwnPropsOfEnum, rankWith, uiTypeIs } from '@jsonforms/core'
import { TranslateProps, withTranslateProps } from '@jsonforms/react'
import { scopePartToPath } from '../../../utils/scopeModifs'
import { withJsonFormsEnumPropsCustom } from '../../core/withJsonFormsEnumPropsCustom'
import DebugElement from '../../DebugElement'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

interface iOption {
  label: string
  value: string
}

const GovInputCheckboxMulti = (props: ControlProps & OwnPropsOfEnum & TranslateProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
    t,
  } = props

  const subScope = uischema.options?.checkMultiSubScope
  const checkMultiInline = uischema.options?.checkMultiInline

  const subPath = scopePartToPath(subScope)
  const subPathParts = subPath.split('.')
  const subOpts = subPathParts.reduce((prev, cur) => prev?.properties?.[cur], (schema as any).items)?.enum || []

  const actOptions: iOption[] = subOpts.map((value: string) => ({
    value: value,
    label: t(path + '.' + subPath + '.' + value, value),
  }))

  const values: string[] = data?.map((item: any) => subPathParts.reduce((prev, cur) => prev?.[cur], item)) || []

  const change = (value: string) => {
    const newValues = values.includes(value) ? values.filter((v: string) => v !== value) : [...values, value]
    const newData = newValues?.length
      ? newValues.map((val) =>
          subPathParts
            .slice()
            .reverse()
            .reduce((prev, cur) => ({ [cur]: prev }), val as any),
        )
      : undefined
    handleChange(path, newData)
  }

  if (!visible) {
    return null
  }
  const style = checkMultiInline ? { display: 'flex', gap: '10px 30px', flexWrap: 'wrap' as any } : undefined

  return (
    <WrapInputGov uischema={uischema} path={path} custom error={errors}>
      <DebugElement data={props} />
      {label && <InputGovLabel inputId={''} label={label} noLeftPadding />}
      <div style={style}>
        {actOptions?.map(({ label, value }, index) => (
          <div key={index}>
            <WrapInputGov custom>
              <input
                id={id + '_' + value}
                type='checkbox'
                checked={values.includes(value)}
                value={value}
                onChange={(e) => change(value)}
                disabled={!enabled}
                className='gov-form-control__checkbox'
                key={value}
                aria-required={required ? 'true' : 'false'}
                aria-disabled={!enabled ? 'true' : 'false'}
                aria-labelledby={id + '_' + value}
              />
              <InputGovLabel inputId={id + '_' + value} label={label} withIndicator />
            </WrapInputGov>
          </div>
        ))}
      </div>
    </WrapInputGov>
  )
}

export default withJsonFormsEnumPropsCustom(withTranslateProps(GovInputCheckboxMulti))

export const govInputCheckMultiTester = rankWith(3, uiTypeIs(UiElTypesInput.CheckboxMulti))
