import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import EformsApi from '../../api/EformsApi'
import { useFormTypeContext } from '../../context/FormTypeContext'

interface enumData {
  code: string
  enabled: boolean
  label: string
  parentCode?: string
}

const STORAGE_ENUM_KEY = 'VVZ_ENUMS_' + process.env.REACT_APP_VERSION
const loadEnums = () => JSON.parse(localStorage.getItem(STORAGE_ENUM_KEY) || '{}')

const saveEnums = () => {
  localStorage.setItem(STORAGE_ENUM_KEY, JSON.stringify(ENUMS))
}

const ENUMS: { [enumId: string]: { enumData?: enumData[]; expire?: string } } = loadEnums()
const ENUMSPROMISE: { [enumId: string]: any } = {}

export const useEnum = (enumId?: string) => {
  const [loading, setLoading] = useState(false)
  const [enumData, setEnumData] = useState<any>()
  const { formSchema } = useFormTypeContext()

  useEffect(() => {
    if (!enumId) {
      return
    }
    if (!ENUMS[enumId]) {
      ENUMS[enumId] = {}
    }

    const { enumData, expire } = ENUMS[enumId]
    if (enumData && expire && expire > moment().toISOString()) {
      setEnumData(enumData)
    } else {
      setLoading(true)
      if (!ENUMSPROMISE[enumId]) {
        ENUMSPROMISE[enumId] = EformsApi.getEnumerationsSearch(formSchema, enumId, 250)
          .then((data) => {
            ENUMS[enumId].enumData = data
            ENUMS[enumId].expire = moment().add(1, 'day').toISOString()
            saveEnums()
            return data
          })
          .catch((e) => {
            toast.error('Chyba načtení číselníku: ' + enumId)
          })
      }
      ENUMSPROMISE[enumId]
        .then((data: any) => {
          setEnumData(data)
        })
        .finally(() => setLoading(false))
    }
  }, [])

  return { loading, enumData }
}
