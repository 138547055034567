import React from 'react'
import { IndexesContextProvider } from '../context/IndexesContext'
import { iUIschema } from '../interfaces/iUiSchema'
import RenderElement from './RenderElement'

interface iRenderElements {
  uiSchemas?: iUIschema[]
}

const RenderElements = ({ uiSchemas }: iRenderElements) => {
  return (
    <>
      {uiSchemas?.map((uiEl, index) => (
        <IndexesContextProvider key={index} index={index}>
          <RenderElement uiSchema={uiEl} />
        </IndexesContextProvider>
      ))}
    </>
  )
}

export default React.memo(RenderElements)
