import { rankWith, uiTypeIs } from '@jsonforms/core'
import React from 'react'
import { withJsonFormsLabelPropsCustom } from '../../core/withJsonFormsLabelPropsCustom'
import { UiElTypesVisual } from '../uiElTypes'
import { CustomLabelProps } from './GovLabel'

const GovDescriptionRenderer = ({ visible, text, errors, uiOptions }: CustomLabelProps) => {
  if (!visible) {
    return null
  }
  const isEditNotif = uiOptions?.descriptionEditNotif
  if (isEditNotif) {
    return (
      <div className=' gov-alert gov-alert--copy'>
        <div className='gov-alert__content'>
          <p>{text}</p>
        </div>
      </div>
    )
  }
  return (
    <div
      className={errors ? 'error-ref-element' : ''}
      style={{
        whiteSpace: 'pre-line',
        marginBottom: 15,
        alignSelf: 'center',
      }}
    >
      {text}
    </div>
  )
}

export default withJsonFormsLabelPropsCustom(GovDescriptionRenderer)

export const govDescriptionRendererTester = rankWith(1000, uiTypeIs(UiElTypesVisual.Description))
