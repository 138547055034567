import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AlertError from '../../components/Alert/AlertError'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import Paginator from '../../components/Paginator/Paginator'
import { useFetchWPNews } from '../../hooks/useFetchWPNews'

const NewsList = () => {
  const { t } = useTranslation()
  const { posts, loading, error, totalCount } = useFetchWPNews()

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg={t('news.err_fetch_list')} />
  }

  return (
    <>
      <div className='gov-grid gov-grid--x2 u-mb--40'>
        {posts.map((item: any, index: number) => (
          <div key={index} className='gov-grid-tile'>
            <h3 dangerouslySetInnerHTML={{ __html: item.name }} />
            <p>
              <span className='gov-badge'> {item.date} </span>
            </p>
            <div dangerouslySetInnerHTML={{ __html: item.text }} />
            <Link
              to={LINKS.aktuality + '/' + item.link}
              className='gov-link  gov-link--standalone  gov-link--has-arrow  gov-link--small'
            >
              {t('news.vice', 'Více')}
            </Link>
          </div>
        ))}
      </div>

      <Paginator maxItems={totalCount} />
    </>
  )
}

export default NewsList
