import React from 'react'

interface iLoading {
  inversed?: boolean
  min?: boolean
}

const Loading = ({ inversed, min }: iLoading) => (
  <div className={'sg-loaders ' + (min ? 'u-mt--10' : 'u-mb--50 u-pt--40')}>
    <div className='sg-loaders__item'>
      <div className={'gov-loader ' + (inversed ? 'gov-loader--inversed' : '')}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </div>
  </div>
)

export default Loading
