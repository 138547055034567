import { iValidationPart } from '../../redux/form/formSlice'
import { replaceAllNamespace } from '../../enums_v1/enumNamespaceMap'
import { VvzErrorObject } from '../../redux/form/formSlice'

// TS synkatnicka validace
export const convertExtSyntaxError = (errorsExt: iValidationPart[] | undefined, schemaData: any) => {
  return (
    errorsExt
      ?.map((err, index) => parseSingleExtErrorMsg(err.chyba, schemaData, index))
      .reduce((prev, cur) => [...prev, ...cur], []) || []
  )
}

const findAllPathEndWith = (pathPart: string, schemaData: any, path: string) => {
  const ret: any[] = []

  for (const key in schemaData) {
    const thisPath = path + '/' + key
    if (key === pathPart) {
      ret.push(thisPath)
    } else if (schemaData[key].items?.properties) {
      ret.push(...findAllPathEndWith(pathPart, schemaData[key].items.properties, thisPath))
    } else if (schemaData[key].properties) {
      ret.push(...findAllPathEndWith(pathPart, schemaData[key].properties, thisPath))
    }
  }
  return ret
}

const parseSingleExtErrorMsg = (msg: string, schemaData: any, groupIndex: number): VvzErrorObject[] => {
  const err: VvzErrorObject[] = []

  const REGEX_ELEMENT_NAME = /"http[^"]+":[a-zA-Z_]*/g
  const elementsParts = msg.match(REGEX_ELEMENT_NAME)?.map((el) => replaceAllNamespace(el, '"', '":'))

  if (elementsParts?.length) {
    const elements: any = {}
    elementsParts?.forEach((el) => {
      elements[el] = findAllPathEndWith(el, schemaData, '')
    })
    Object.values(elements).forEach((elPaths: any) => {
      elPaths.forEach((path: any) => {
        err.push({
          instancePath: path,
          message: `Chyba v jednom z elementů (skupina ${groupIndex})`,
          detail: msg
        })
      })
    })
  } else {
    err.push({
      instancePath: '',
      message: msg,
      detail: msg
    })
  }
  return err
}


