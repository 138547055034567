import { useKeycloak } from '@react-keycloak/web'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../redux/store'
import { loadUserAction, logOutAction } from '../redux/user/actions'
import {
  selectUserHasFullAccess,
  selectUserIsLoged,
  selectUserLoading,
  selectUserName,
  selectUserNameFull,
} from '../redux/user/selectors'

export const useUser = () => {
  const dispatch = useAppDispatch()
  const { keycloak, initialized } = useKeycloak()
  const userIsLoged = useSelector(selectUserIsLoged) && keycloak.authenticated
  const userLoading = useSelector(selectUserLoading) || !initialized
  const username = useSelector(selectUserName)
  const usernameFull = useSelector(selectUserNameFull)
  const hasFullAccess = useSelector(selectUserHasFullAccess)
  const isAdmin = keycloak.realm === process.env.REACT_APP_KEYCLOAK_REALM_ADMIN && userIsLoged

  const loadUser = () => {
    dispatch(loadUserAction(keycloak))
  }

  const login = () => {
    keycloak.login()
  }

  const logout = () => {
    dispatch(logOutAction(keycloak))
  }

  return {
    initialized,
    username,
    usernameFull,
    userLoading,
    userIsLoged,
    login,
    logout,
    loadUser,
    hasFullAccess,
    isAdmin,
  }
}
