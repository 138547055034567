import flatten from 'lodash/flatten'
import { iUIschema } from '../interfaces/iUiSchema'

export const calculateUiScopesOrder = (uiSchemas: iUIschema[]): string[] => {
  if (!uiSchemas || !Array.isArray(uiSchemas)) {
    return []
  }
  return flatten(
    uiSchemas.map((el) =>
      flatten([
        el.scope ? el.scope : [], //
        el.elements ? calculateUiScopesOrder(el.elements) : [],
      ]),
    ),
  )
}
