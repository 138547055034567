import Tippy from '@tippyjs/react'
import React from 'react'
import 'tippy.js/dist/tippy.css'

interface iPartGovBulb {
  texts?: string[]
}

const PartGovBulb = ({ texts }: iPartGovBulb) => {
  if (!texts?.length) {
    return null
  }

  return (
    <div className='bulb-info-wrap'>
      <Tippy
        trigger='mouseenter focus click'
        content={texts.map((text, key) => (
          <div key={key}>{text}</div>
        ))}
      >
        <span className='gov-tooltip--icon u-color--grey-light' tabIndex={0}>
          <span aria-hidden='true' className='gov-icon gov-icon--16x16 gov-icon--lightbulb'></span>
        </span>
      </Tippy>
    </div>
  )
}

export default React.memo(PartGovBulb)
