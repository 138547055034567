import React from 'react'
import { useTranslation } from 'react-i18next'
import PageWrap from '../../components/PageWrap/PageWrap'
import VariantsTabs from '../../components/VariantsTabs/VariantsTabs'
import { FilterContextProvider } from '../../context/FilterContext'
import { VARIANTS_TABLE_PROFILES_OPTIONS } from '../../enums/enumTablesVariants'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import FilterProfiles from '../../tables/TableProfiles/FilterProfiles'
import TableProfiles from '../../tables/TableProfiles/TableProfiles'

const ProfilVyhledatPage = () => {
  const { t } = useTranslation()

  return (
    <PageWrap title={t('title.vyhledat_profil', 'Vyhledat profil')}>
      <FilterContextProvider name={TABLE_TYPES.profiles}>
        <FilterProfiles />
        <VariantsTabs variants={VARIANTS_TABLE_PROFILES_OPTIONS} />
        <TableProfiles />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default ProfilVyhledatPage
