import React from 'react'
import { FormRenderContexProvider } from '../context/RenderFormContext'
import { iDataSchemas } from '../interfaces/iDataSchema'
import { iUIschema } from '../interfaces/iUiSchema'
import RenderForm from '../renders/RenderForm'

interface iFormBaseSchemas {
  dataSchemas: iDataSchemas
  uiSchemas: iUIschema[]
  formSchemaId?: string
  validationMode?: string
}

const FormBaseSchemas = ({ uiSchemas, dataSchemas, formSchemaId, validationMode }: iFormBaseSchemas) => {
  return (
    <FormRenderContexProvider
      dataSchemas={dataSchemas}
      uiSchemas={uiSchemas}
      helpSimple={true}
      shouldUnregisterDefault={true}
      formSchemaId={formSchemaId}
      validationMode={validationMode}
    >
      <RenderForm />
    </FormRenderContexProvider>
  )
}

export default FormBaseSchemas
