import { deleteForbiddenFields, deleteForbiddenFieldsReq } from '../../redux/formErrors/formErrorsSlice'
import { useAppDispatch } from '../../redux/store'
import { useFormRenderContext } from '../context/RenderFormContext'

export const useFormDeleteForbiden = () => {
  const dispatch = useAppDispatch()
  const { isReq } = useFormRenderContext()

  const deleteForbiden = (path: string) => {
    dispatch(isReq ? deleteForbiddenFieldsReq(path) : deleteForbiddenFields(path))
  }
  return { deleteForbiden }
}
