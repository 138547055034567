export const calculateDataClearVisible = (data: any, visibleEnabledScopesSome: string[], scope: string = '#') => {
  if (!visibleEnabledScopesSome.length) {
    return data
  }
  const ret: any = {}
  for (const key in data) {
    const thisScope = scope + '/' + key
    if (visibleEnabledScopesSome.includes(thisScope)) {
      if (Array.isArray(data[key])) {
        ret[key] = data[key].map((dataItem: any, index: number) =>
          calculateDataClearVisible(dataItem, visibleEnabledScopesSome, thisScope + '/' + index),
        )
      } else if (typeof data[key] === 'object') {
        ret[key] = calculateDataClearVisible(data[key], visibleEnabledScopesSome, thisScope)
      } else {
        ret[key] = data[key]
      }
    }
  }
  return ret
}
