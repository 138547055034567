import React from 'react'
import ButtonGov from '../../components/Btns/ButtonGov'
import { useFilterContext } from '../../context/FilterContext'
import { useModal } from '../../hooks/useModal'
import { createFilterParams, createOrderParams } from '../../utils/createParams'
import { paramsTableType } from '../../utils/paramsTableType'
import { FILTER_MY_FORM } from '../TableMyForms/FilterMyForms'

interface iTableSaveData {
  totoalCount: number
}

const TableDataExport = ({ totoalCount }: iTableSaveData) => {
  const { openModalExport } = useModal()
  const { sort, activeFilters, variant, contextName } = useFilterContext()
  const noActiveFilter = !Object.values(activeFilters).some((val) => val !== undefined && val !== '')

  const clickExport = () => {
    const { [FILTER_MY_FORM]: _, ...othersFilters } = activeFilters
    const defParams = paramsTableType(contextName, variant)
    const filterParams = createFilterParams(othersFilters)
    const filterOrderParams = createOrderParams(sort.field, sort.order)

    const filter = { ...defParams, ...filterParams, ...filterOrderParams }

    const filterObject: any = {}
    Object.entries(filter).forEach(([filterName, filterVal]) => {
      if (filterName.endsWith(']')) {
        const [fName, fSubname] = filterName.slice(0, -1).split('[')
        if (!(fName in filterObject)) {
          filterObject[fName] = {}
        }
        filterObject[fName][fSubname] = filterVal
      } else {
        filterObject[filterName] = filterVal
      }
    })

    openModalExport({ filter: filterObject })
  }

  return (
    <>
      <ButtonGov
        style={{ float: 'left' }}
        variant='primary'
        className='u-mt--10'
        onClick={clickExport}
        disabled={noActiveFilter || !totoalCount}
      >
        Exportovat seznam
      </ButtonGov>
    </>
  )
}

export default TableDataExport
