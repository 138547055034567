import { toast } from 'react-toastify'
import EformsApi from '../api/EformsApi'
import { combineFormTranslate, combineSchemaTranslate } from '../builder/utils/combineSchemaTranslate'
import { TRANSLATE_DOMAIN_FORM, TRANSLATE_DOMAIN_LAYOUT } from '../interfaces/iEformsApi'
import { LocalStorageCache } from './LocalStorageCache'

class SchemaLoader {
  loadSchema = (schemaIri: string) => {
    return EformsApi.getFormSchemaByIri(schemaIri).catch((err) => {
      toast.error('Nepodařilo se načíst schéma pro formulář')
      throw err
    })
  }

  loadSchemaById = (formSchemaId: string) => {
    return EformsApi.getFormSchemaById(formSchemaId).catch((err) => {
      toast.error('Nepodařilo se načíst schéma pro formulář')
      throw err
    })
  }

  loadSchemaBySlug = (orgSlug: string, formSlug: string) => {
    return EformsApi.getFormBySlug(orgSlug, formSlug).then((form) => EformsApi.getFormSchemaByIri(form.currentSchema))
  }

  loadSchemaWithTranslate = (schemaIri: string) => {
    const formSchemaId = schemaIri.split('/').slice(-1)[0]
    return this.loadSchemaByIdWithTranslate(formSchemaId)
  }

  loadSchemaByIdWithTranslate = (formSchemaId: string) => {
    const storageKey = 'formSchema_' + formSchemaId
    const formSchema = LocalStorageCache.getItem(storageKey, undefined)
    if (formSchema) {
      return Promise.resolve(formSchema)
    }

    return this._loadSchemaByIdWithTranslate(formSchemaId).then((formSchema) => {
      LocalStorageCache.setItem(storageKey, formSchema)
      return formSchema
    })
  }

  _loadSchemaByIdWithTranslate = (formSchemaId: string) =>
    EformsApi.getFormSchemaById(formSchemaId)
      .catch((err) => {
        toast.error('Nepodařilo se načíst schéma pro formulář s překlady')
        throw err
      })
      .then(async (formSchema) => {
        if (!formSchema.metadata?.verzeXsd) {
          const translateData = await EformsApi.getFormSchemaTranslates(formSchemaId, [
            TRANSLATE_DOMAIN_LAYOUT,
            TRANSLATE_DOMAIN_FORM,
          ]).catch((err) => {
            toast.error('Nepodařilo se načíst překlady pro formulář')
          })
          if (translateData) {
            if (formSchema.schema.layout && translateData[TRANSLATE_DOMAIN_LAYOUT]) {
              formSchema.schema.layout = combineSchemaTranslate(
                formSchema.schema.layout,
                translateData[TRANSLATE_DOMAIN_LAYOUT],
              )
            }
            if (formSchema.schema.form && translateData[TRANSLATE_DOMAIN_FORM]) {
              formSchema.schema.form = combineFormTranslate(
                formSchema.schema.form,
                translateData[TRANSLATE_DOMAIN_FORM],
              )
            }
          }
        }
        return formSchema
      })
}

const SL = new SchemaLoader()
export default SL
