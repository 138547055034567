import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import ButtonGov from '../../../components/Btns/ButtonGov'
import AresModel from '../../../services/ModelSimpleAres'
import { extractCopyMap } from '../../../utils/extractCopyMap'
import { scopeFullToPath } from '../../../utils/scopeModifs'
import { useElementContext } from '../../context/ElementContext'
import { useBtnLoadClear } from '../../hooks/useBtnLoadClear'

const BtnLoadAres = () => {
  const { watch, setValue } = useFormContext()
  const { label, uiSchema, disabled } = useElementContext()
  const { clearRepeatElement } = useBtnLoadClear()
  const { buttonDestinationNodeId, buttonCopyMap } = uiSchema.options || {}

  const [loading, setLoading] = useState(false)

  const basePath = scopeFullToPath(buttonDestinationNodeId)
  const icoScope = Array.isArray(buttonCopyMap?.ico) ? buttonCopyMap?.ico?.join('/') : buttonCopyMap?.ico
  const icoPath = basePath + '.' + scopeFullToPath(icoScope)
  const ico = watch(icoPath)

  const clickLoadAres = () => {
    if (!ico || !/^\d{8}$/.test(ico)) {
      toast.error('IČO musí mít 8 číslic')
      return
    }

    if (buttonCopyMap) {
      setLoading(true)

      AresModel.aresDataByIco(ico)
        .then((aresData) => {
          clearRepeatElement(basePath)
          extractCopyMap(buttonCopyMap, basePath).forEach(({ fromPath, toPath, deletePath }) => {
            if (deletePath) {
              setValue(deletePath, [])
            }
            const val = aresData[fromPath as keyof typeof aresData] ?? ''
            setValue(toPath, val)
          })

          toast.success('Název a adresa načteny z ARES')
        })
        .catch((err) => {
          const code = err.response.status
          if (code === 400) {
            toast.error('Chybné IČO.')
          } else if (code === 404) {
            toast.error('Nenalezen subjekt se zadaným IČO.')
          } else {
            toast.error('Nepodařilo se načíst data z ARES')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  if (disabled) {
    return null
  }

  return (
    <div className='btnInlineWrap'>
      <ButtonGov variant='primary-green' size='small' disabled={!ico} loading={loading} onClick={clickLoadAres}>
        {label || 'Předvyplnit údaje podle IČO'}
      </ButtonGov>
    </div>
  )
}

export default BtnLoadAres
