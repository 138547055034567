import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ENV } from '../../ENV'
import { LINKS } from '../App/LINKS'

const Footer = () => {
  const { t } = useTranslation()
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const FOOTER_MENU = {
    //[LINKS.aktuality]: t('footermenu.aktuality', 'Aktuality a informace'),
    [LINKS.napoveda + '/provozni-rad']: t('footermenu.rad', 'Provozní řád'),
    [LINKS.informace + '/prohlaseni-o-pristupnosti']: t('footermenu.prohlaseni', 'Prohlášení o přístupnosti'),
    [LINKS.informace + '/zpracovani-osobnich-udaju-a-cookies']: t(
      'footermenu.cookies',
      'Zpracování osobních údajů a cookies',
    ),
  }

  const FOOTER_IMPORTANT_LINKS = {
    ...(ENV.REF_ENV
      ? { 'https://vvz.nipez.cz/': 'PRODUKČNÍ PROSTŘEDÍ VVZ' }
      : { 'https://ref.vvz.nipez.cz/': 'TESTOVACÍ PROSTŘEDÍ VVZ' }),
    'https://portal-vz.cz/': 'Portál o veřejných zakázkách',
    'https://nen.nipez.cz/': 'Národní elektronický nástroj',
    'https://ted.europa.eu/TED/browse/browseByMap.do': 'Úřední věstník EU',
    'https://isvz.nipez.cz/uvod': 'Informační systém o veřejných zakázkách',
    'https://skd.nipez.cz/ISVZ/SKD/ISVZ_SKD_text.aspx': 'Seznam kvalifikovaných dodavatelů',
  }

  return (
    <footer className='gov-container gov-container--wide gov-footer u-bg-color--grey-dark'>
      <div className='gov-container__content'>
        <section className='gov-footer__upper u-screen--only'>
          <div className='gov-footer__col'>
            <h3 className='gov-footer__headline'>{t('footer.podpora', 'Uživatelská podpora')}</h3>
            <ul className='gov-list--plain'>
              <li className='gov-footer__li-supp'>
                <span aria-hidden='true' className='gov-icon gov-icon--mail gov-footer__icon ' />
                <a
                  style={{ color: 'white' }}
                  target='_blank'
                  rel='noreferrer'
                  href='https://sd.nipez.cz/vvz?id=vvz_guest_question'
                  className='gov-footer__supp'
                >
                  Kontaktní formulář
                </a>
              </li>
              <li className='gov-footer__li-supp'>
                <span aria-hidden='true' className='gov-icon gov-icon--phone gov-footer__icon ' />
                <a href='tel:+420211154370' className='gov-footer__supp'>
                  +420 211 154 370
                </a>
              </li>
              <li>(v pracovní dny od 8:00 do 18:00)</li>
            </ul>
          </div>
          <div className='gov-footer__col'>
            <h3 className='gov-footer__headline'>{t('footer.informace', 'Užitečné informace')}</h3>
            <ul className='gov-list--plain'>
              {Object.entries(FOOTER_MENU).map(([link, title], index) => (
                <li key={index}>
                  <Link to={link} className='gov-link gov-link--standalone gov-link--inversed'>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='gov-footer__col'>
            <h3 className='gov-footer__headline'>{t('footer.dulodkazy', 'Důležité odkazy')}</h3>
            <ul className='gov-list--plain'>
              {Object.entries(FOOTER_IMPORTANT_LINKS).map(([link, title], index) => (
                <li key={index}>
                  <a
                    href={link}
                    className='gov-link gov-link--standalone gov-link--inversed'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* { <div className='gov-footer__col'>
            <h3 className='gov-footer__headline'>
              {t('footer.elpodani', 'Elektronická podání')}
            </h3>
            <p className='gov-footnote u-mb--25'>
              <Trans i18nKey='footer.elpodani_text' />
            </p>
          </div>} */}
        </section>
        <hr />
        <section className='gov-footer__lower'>
          <p className='gov-footnote'>
            {moment().year()} &copy; {t('footer.copy')}
          </p>
          <p className='gov-footnote'>
            {t('footer.verze', 'Verze')} {ENV.VERSION}
          </p>
        </section>
        <button
          onClick={goToTop}
          className='gov-button gov-button--primary-outlined gov-button--inversed gov-footer__scroll-up gov-js--scroll-up'
          aria-labelledby='fo-scrollBtn'
        >
          <span id='fo-scrollBtn' className='u-sr-only gov-button__label'>
            {t('footer.nahoru', 'Zpět nahoru')}
          </span>
        </button>
      </div>
    </footer>
  )
}

export default Footer
