import get from 'lodash/get'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ENV } from '../../ENV'
import {
  makeFindFormLiveForbiden,
  makeFindFormLiveHidden,
  makeFindFormLiveRequired,
} from '../../redux/formErrors/selectorsMake'
import { RootState } from '../../redux/rootReducer'
import { scopeFullToPath, scopeWithoutRepeat } from '../../utils/scopeModifs'
import { useDisabledArrayContextContext } from '../context/DisabledArrayContext'
import { ElementContextProvider } from '../context/ElementContext'
import { useFormRenderContext, useFormRenderDataSchemaScope } from '../context/RenderFormContext'
import { ELEMENTS_RENDER_THEME_MAP, NO_PRINT_ELEMENTS } from '../elements/ELEMENTS'
import DebugElement from '../form_elements/DebugElement'
import { calculateRelativeCondScope } from '../hooks/useElementIsVisible'
import { DATA_TYPE } from '../interfaces/iDataSchema'
import { iElement } from '../interfaces/iElement'
import { iUIschema } from '../interfaces/iUiSchema'
import { createRules } from '../utils/createRules'
import RenderSimpleArray from './RenderSimpleArray'
import VisibleWrap from './VisibleWrap'

interface iRenderElement {
  uiSchema: iUIschema
  isSimpleArrayItem?: boolean
}

const RenderElement = ({ uiSchema, isSimpleArrayItem }: iRenderElement) => {
  const { formModifs, dateSchemaDefaults, dateSchemaArrayDefaults, isReq, formDisabled, theme } = useFormRenderContext()

  const Element: any = ELEMENTS_RENDER_THEME_MAP[theme][uiSchema.type]

  const scope = uiSchema.scope
  const path = scopeFullToPath(scope)
  const id = path
  const simpleScope = scopeWithoutRepeat(uiSchema.scope || '')
  const dataSchemaScheme = useFormRenderDataSchemaScope(simpleScope)
  const dataSchema = isSimpleArrayItem ? dataSchemaScheme?.items : dataSchemaScheme

  const label = uiSchema.label
  const text = uiSchema.text

  const cond = dataSchema?.conditions
  const conditions = useMemo(() => (cond && scope ? calculateRelativeCondScope(cond, scope) : cond), [cond, scope])

  const findRequiredMsgs = useMemo(makeFindFormLiveRequired(isReq), [])
  const findForbidenMsgs = useMemo(makeFindFormLiveForbiden(isReq), [])
  const findLiveHidden = useMemo(makeFindFormLiveHidden(isReq), [])

  const requiredMsgs = useSelector((state: RootState) => findRequiredMsgs(state, path), shallowEqual)
  const forbidenMsgs = useSelector((state: RootState) => findForbidenMsgs(state, path), shallowEqual)
  const hiddenFieldsExt = useSelector((state: RootState) => findLiveHidden(state, path), shallowEqual)

  const requiredExt = !!requiredMsgs?.length
  const forbidenExt = !!forbidenMsgs?.length
  const hiddenExt = !!hiddenFieldsExt?.length

  const disabledArray = useDisabledArrayContextContext().disabled
  const disabled = formDisabled || !!uiSchema.options?.readOnly || forbidenExt || disabledArray

  const rules = useMemo(() => createRules(scope, disabled, dataSchema), [scope, disabled, dataSchema])

  const required =
    !disabled &&
    (dataSchema?.validations?.required ||
      !!dataSchema?.validations?.requiredOrOneOf ||
      requiredExt ||
      uiSchema.options?.markAsRequired)

  const defaultValue =
    dataSchema?.type === DATA_TYPE.ARRAY ? dateSchemaArrayDefaults[simpleScope] : get(dateSchemaDefaults, path)

  const elementsAttr: iElement = useMemo(
    () => ({
      required,
      disabled,
      label,
      id,
      path,
      dataSchema,
      uiSchema,
      rules,
      text,
      defaultValue,
      conditions,
      forbidenExt,
      requiredMsgs,
      forbidenMsgs,
    }),
    [
      required,
      disabled,
      label,
      id,
      path,
      dataSchema,
      uiSchema,
      rules,
      text,
      conditions,
      forbidenExt,
      requiredMsgs,
      forbidenMsgs,
    ],
  )

  if (hiddenExt) {
    return null
  }

  if (formModifs.isPritVersion && NO_PRINT_ELEMENTS.includes(uiSchema.type)) {
    return null
  }

  if (!Element) {
    return (
      <div style={{ color: 'red' }}>
        {' '}
        Neznámý element: {uiSchema.type} (theme: {theme})
      </div>
    )
  }

  const renderInside = () => {
    if (dataSchema?.type === DATA_TYPE.ARRAY && dataSchema.items?.type !== DATA_TYPE.OBJECT) {
      return <RenderSimpleArray />
    }
    if (conditions) {
      return (
        <VisibleWrap>
          <Element />
        </VisibleWrap>
      )
    }
    return <Element />
  }

  return (
    <>
      {ENV.DEBUG_MODE && <DebugElement elementData={elementsAttr} />}
      <ElementContextProvider element={elementsAttr}>{renderInside()}</ElementContextProvider>
    </>
  )
}

export default React.memo(RenderElement)
