export const uploadAcceptFileMimeTypes: { [ext: string]: string[] } = {
  'application/pkix-cert': ['.cer', '.der'],
  'application/x-x509-ca-cert': ['.crt', '.der'],
  'text/csv': ['.csv'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/EDI-X12': ['.edi'],
  'application/xml': ['.fo', '.xml'],
  'image/gif': ['.gif'],
  'text/html': ['.htm', '.html'],
  'application/isdoc': ['.isdoc'],
  'application/vnd.is-xpr': ['.isdocx'],
  'image/jpeg': ['.jfif', '.jpeg', '.jpg'],
  'application/json': ['.json'],
  'audio/mpeg': ['.mp2', '.mp3'],
  'video/mpeg': ['.mpeg', '.mpg'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/x-pkcs7-certificates': ['.p7b'],
  'application/pkcs7-mime': ['.p7c', '.pk7', '.p7m'],
  'application/x-pkcs7-certfact': ['.p7f'],
  'application/pkcs7-signature': ['.p7s'],
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/rtf': ['.rtf'],
  'image/tiff': ['.tif', '.tiff'],
  'application/timestamp-reply': ['.tsr'],
  'application/timestamp-query': ['.tst'],
  'text/plain': ['.txt'],
  'audio/wav': ['.wav'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.fujixerox.ddd-document': ['.zfo'],
}
