import React from 'react'
import { useSelector } from 'react-redux'
import { setFormUserFormName } from '../../redux/formState/formStateSlice'
import { selectFormUserFormName, selectFormWithRequest } from '../../redux/formState/selectors'
import { useAppDispatch } from '../../redux/store'
import { selectSubmMainInfo } from '../../redux/submissions/selectors'
import InputGov from '../Inputs/InputGov'

export const USERFORMNAME_ID = 'userFormName'
export const USERFORMNAME_LABEL = 'Vlastní pojmenování formuláře'

const FormNameInput = () => {
  const dispatch = useAppDispatch()
  const userFormName = useSelector(selectFormUserFormName)
  const withReq = useSelector(selectFormWithRequest)
  const { isDraft } = useSelector(selectSubmMainInfo)

  return (
    <div>
      {(isDraft || withReq) && ( // pokud ma pravo na upravu nebo muze cist zadost
        <div id={USERFORMNAME_ID}>
          <InputGov
            disabled={!isDraft}
            name={'uzivatelskyNazevFormulare'}
            value={userFormName}
            onChange={(e) => dispatch(setFormUserFormName(e.target.value))}
            label={USERFORMNAME_LABEL + (isDraft ? '*' : '')}
            error={isDraft && !userFormName ? 'Položka je povinná' : undefined}
          />
        </div>
      )}
    </div>
  )
}

export default FormNameInput
