import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import { UiElTypesInput } from '../uiElTypes'

const GovInputHidden = (props: ControlProps) => {
  const {
    data,
    schema,
    id,
    visible,
  } = props

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  return (
    <>
      <input
        id={elId}
        type='hidden'
        // disabled={!enabled}
        disabled={true}
        value={data || ''}
      />
    </>
  )
}

export default withJsonFormsControlPropsCustom(GovInputHidden)

export const govInputHiddenTester = rankWith(3, uiTypeIs(UiElTypesInput.Hidden))
