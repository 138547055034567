import merge from 'lodash/merge'
import set from 'lodash/set'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { DATA_SCHEMA_DEFAULTS_CONST } from '../services/SchemaDefaults'
import { formatDateShortTZ, formatDateTimeTZ } from '../utils/dateFormatFns'
import { DATA_TYPE_FORMAT, iDataSchema, iDataSchemas } from './interfaces/iDataSchema'
import { convertDataOrderLikeSchemaAndClear } from './utils/convertDataOrderLikeSchemaAndClear'

class GlobalFormsStore {
  editForm?: UseFormReturn
  reqForm?: UseFormReturn

  editFormSchema?: iDataSchemas
  reqFormSchema?: iDataSchemas

  reqFormSchemaDefCalc?: any
  editFormSchemaDefCalc?: any

  setForm(isReq: boolean, methods: UseFormReturn, dataSchemas: iDataSchemas, dateSchemaDefaultsCalculate: any) {
    if (isReq) {
      this.reqForm = methods
      this.reqFormSchema = dataSchemas
      this.reqFormSchemaDefCalc = dateSchemaDefaultsCalculate
    } else {
      this.editForm = methods
      this.editFormSchema = dataSchemas
      this.editFormSchemaDefCalc = dateSchemaDefaultsCalculate
    }
  }

  // validateLocal() {
  //   this.editForm?.trigger(undefined, { shouldFocus: false })
  //   this.reqForm?.trigger(undefined, { shouldFocus: false })
  //   return this.editForm?.formState.isValid && this.reqForm?.formState.isValid
  // }

  getEditFormDataFull() {
    const calcDefs = this.calculateDefs(this.editFormSchemaDefCalc)
    const vals = this.editForm?.getValues()
    return merge(vals, calcDefs)
  }

  getEditFormData(notClear = false) {
    const data = this.getEditFormDataFull()
    return  convertDataOrderLikeSchemaAndClear(data, this.editFormSchema ?? {}, notClear)
  }

  getReqFormDataFull() {
    const calcDefsReq = this.calculateDefs(this.reqFormSchemaDefCalc)
    const valsReq = this.reqForm?.getValues()
    return merge(valsReq, calcDefsReq)
  }

  getReqFormData(notClear = false) {
    const data = this.getReqFormDataFull()
    return convertDataOrderLikeSchemaAndClear(data, this.reqFormSchema ?? {}, notClear)
  }

  calculateDefs(defsCalc: { [paths: string]: iDataSchema }) {
    const res: any = {}
    for (const path in defsCalc) {
      const dataSchema = defsCalc[path]
      const schemaDefVal = dataSchema?.default
      if (schemaDefVal === DATA_SCHEMA_DEFAULTS_CONST.NOW) {
        const val =
          dataSchema?.format === DATA_TYPE_FORMAT.DATE
            ? formatDateShortTZ(new Date())
            : dataSchema?.format === DATA_TYPE_FORMAT.TIME
            ? formatDateTimeTZ(new Date())
            : undefined

        if (val !== undefined) {
          set(res, path, val)
        }
      }
    }
    return res
  }
}

const GFS = new GlobalFormsStore()
export default GFS
