import React from 'react'

interface iTextTrim {
  text: string
  length: number
}

const TextTrim = ({ text, length }: iTextTrim) => {
  if(!text?.length){
    return null
  }
  const shortText = text.length > length + 3 ? text.slice(0, length) + '...' : text
  return <span title={text}>{shortText}</span>
}

export default React.memo(TextTrim)
