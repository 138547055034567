import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import cs from './cs/main.json'
import en from './en/main.json'

// declare custom type options so the return is always a string.

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const resources = {
  cs: {
    translation: cs,
  },
  en: {
    translation: en,
  },
}

i18n
  // // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // // detect user language
  // // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use({
  //   type: 'postProcessor',
  //   name: 'nameOfProcessor',
  //   // @ts-ignore
  //   process: function (value, key, options, translator) {
  //     return 'T__'+value + '__T'
  //   },
  // })
  .init({
    returnNull: false,
    resources,
    lng: 'cs', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // postProcess: ['nameOfProcessor'],
    fallbackLng: 'cs',
    supportedLngs: ['cs', 'en'],
    // ns: ['ns1', 'ns2'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: process.env.NODE_ENV === 'development',
  })

export default i18n
