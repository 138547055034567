import { GroupLayout, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React from 'react'
import { withJsonFormsLayoutPropsCustom } from '../../core/withJsonFormsLayoutPropsCustom'
import DebugElement from '../../DebugElement'
import { UiElTypesLayout } from '../uiElTypes'
import { CustomLayoutProps } from './GovGroup'

const GovLayoutGroupInnerRender = (props: CustomLayoutProps) => {
  const { schema, uischema, renderers, enabled, visible, path, cells, label, required, errors } = props

  const groupLayout = uischema as GroupLayout

  if (!visible) {
    return null
  }

  return (
    <div className='custom-form__inner'>
      <DebugElement data={props} />
      {label && (
        <h2 className={(errors ? 'error-ref-element' : '') + ' custom-form__inner-title gov-title--delta'}>
          {label} {required ? '*' : ''}
        </h2>
      )}

      {groupLayout.elements &&
        groupLayout.elements.map((child, index) => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={index}
          />
        ))}
    </div>
  )
}
export default withJsonFormsLayoutPropsCustom(GovLayoutGroupInnerRender)

export const govLayoutGroupInnerTester = rankWith(1000, uiTypeIs(UiElTypesLayout.GroupInner))

