import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NotifApi from '../../api/NotifApi'
import { useModal } from '../../hooks/useModal'
import AlertError from '../Alert/AlertError'
import AlertSuccess from '../Alert/AlertSuccess'
import ButtonGov from '../Btns/ButtonGov'
import InputGov from '../Inputs/InputGov'
import Loading from '../Loading/Loading'

const ModalNotif = () => {
  const { t, i18n } = useTranslation()
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')

  const [sentLoading, setSentLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const [sentError, setSentError] = useState('')

  const { closeModal } = useModal()

  useEffect(() => {
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail(t('modalNotif.err_email', 'Zadejte platnou e-mailovou adresu'))
    } else {
      setErrorEmail('')
    }
  }, [email, t])

  const send = () => {
    setSentLoading(true)
    const lang = i18n.language === 'cs' ? 'CZ' : 'EN'
    NotifApi.sendNotif(email, lang)
      .then(() => {
        setSentOk(true)
      })
      .catch((err) => {
        setSentError(err)
      })
      .finally(() => {
        setSentLoading(false)
      })
  }

  if (sentLoading) {
    return <Loading />
  }
  if (sentError) {
    return <AlertError msg={t('modalNotif.err_odeslani', 'Chyba odeslání žádosti. Zkuste to prosím později')} />
  }
  if (sentOk) {
    return <AlertSuccess msg={t('modalNotif.uspesne_odeslana', 'Žádost úspěšně odeslána.')} />
  }

  return (
    <>
      <p>
        {t(
          'modalNotif.info',
          'Na uvedenou adresu vám bude zaslán e-mail s odkazem a pokyny pro aktivaci této služby, která je zdarma.',
        )}
      </p>

      <div className='u-mb--50'>
        <InputGov
          type='email'
          name='email'
          label={t('modalNotif.email', 'E-mailová adresa')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errorEmail}
        />
      </div>

      <ButtonGov variant='primary' className='u-me--20' onClick={send} disabled={!email || !!errorEmail}>
        {t('modalNotif.odeslat', 'Odeslat')}
      </ButtonGov>
      <ButtonGov variant='primary-outlined' onClick={closeModal}>
        {t('modalNotif.zavrit', 'Zavřít')}
      </ButtonGov>
    </>
  )
}

export default ModalNotif
