import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { saveSubmission } from '../../actions/saveSubmission'
import { useModal } from '../../hooks/useModal'
import { selectFormActionLoading } from '../../redux/formState/selectors'
import { useAppDispatch } from '../../redux/store'
import ButtonGov from '../Btns/ButtonGov'
import Loading from '../Loading/Loading'

const ModalConfirmSave = () => {
  const dispatch = useAppDispatch()
  const { closeModal } = useModal()
  const actionLoading = useSelector(selectFormActionLoading)
  const [done, setDone] = useState(false)

  const clickOk = () => {
    dispatch(saveSubmission())
    setDone(true)
  }

  useEffect(() => {
    if (done && !actionLoading) {
      closeModal()
    }
  }, [done, actionLoading])

  const clickCancel = () => {
    closeModal()
  }

  if (actionLoading) {
    return (
      <>
        Formulář se ukládá.
        <Loading />
      </>
    )
  }

  return (
    <>
      <div className='u-mb--50'>Opravdu chcete uložit formulář?</div>

      <ButtonGov variant='primary' className='u-me--20' onClick={clickOk}>
        Uložit
      </ButtonGov>

      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zrušit
      </ButtonGov>
    </>
  )
}

export default ModalConfirmSave
