import React from 'react'

interface iPartGovDescription {
  text?: string
}

const PartGovDescription = ({ text }: iPartGovDescription) => {
  if (!text) {
    return null
  }

  return (
    <span className='gov-form-control__message' style={{ whiteSpace: 'pre-wrap' }}>
      {text}
    </span>
  )
}

export default React.memo(PartGovDescription)
