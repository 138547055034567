import React from 'react'
import { useElementContext } from '../../context/ElementContext'

const HtmlContent = () => {
  const { text } = useElementContext()
  if (!text) {
    return null
  }
  return <div className='u-mb--10 htmlContentWrap' dangerouslySetInnerHTML={{ __html: text }} />
}

export default HtmlContent
