import Keycloak from 'keycloak-js'

let activeKeycloak: Keycloak

export const setActiveKeycloak = (keycloak: Keycloak) => {
  activeKeycloak = keycloak
}

export const getActiveKeycloak = () => activeKeycloak


