import { createSelector } from '@reduxjs/toolkit'
import { convertExtSemanticError } from '../../builder_v1/validations/convertExtSemanticError'
import { convertExtSyntaxError } from '../../builder_v1/validations/convertExtSyntaxError.ts'
import { selectSchemaFull, selectSchemaFullReq } from '../submissions/selectors'
import {
  selectFormAdditionalErrors,
  selectFormAdditionalErrorsRequest,
  selectFormErrorExternal,
} from './selectorsFormV1'

const EXT_VALIDATION_TYPE = {
  SYNTAKTICKA: 'SYNTAKTICKA',
  SEMANTICKA: 'SEMANTICKA',
}
export const selectFormErrorExt = createSelector(
  selectFormErrorExternal,
  selectSchemaFull,
  (errorExternal, schemaFull) =>
    schemaFull
      ? errorExternal?.typValidace === EXT_VALIDATION_TYPE.SYNTAKTICKA
        ? convertExtSyntaxError(errorExternal?.seznamChybFormular, schemaFull.properties)
        : convertExtSemanticError(errorExternal?.seznamChybFormular, schemaFull.properties)
      : [],
)
export const selectFormErrorExtReq = createSelector(
  selectFormErrorExternal,
  selectSchemaFullReq,
  (errorExternal, schemaFullReq) =>
    schemaFullReq
      ? errorExternal?.typValidace === EXT_VALIDATION_TYPE.SYNTAKTICKA
        ? convertExtSyntaxError(errorExternal?.seznamChybZadost, schemaFullReq.properties)
        : convertExtSemanticError(errorExternal?.seznamChybZadost, schemaFullReq.properties)
      : [],
)
export const selectFormError = createSelector(
  selectFormAdditionalErrors,
  selectFormErrorExt,
  (additionalErrors, errExtForm) => [...additionalErrors, ...errExtForm],
)
export const selectFormErrorReq = createSelector(
  selectFormAdditionalErrorsRequest,
  selectFormErrorExtReq,
  (additionalErrorsRequest, errExtReq) => [...additionalErrorsRequest, ...errExtReq],
)
export const selectFormErrorAll = createSelector(selectFormError, selectFormErrorReq, (err, errReq) => [
  ...err,
  ...errReq,
])
