import React from 'react'

interface iError {
  children: React.ReactNode
}

const AlertErrorBlock = ({ children }: iError) => (
  <div className='gov-alert'>
    <div className='gov-alert__content'>{children}</div>
  </div>
)

export default AlertErrorBlock
