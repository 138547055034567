import { iDataSchemas } from '../interfaces/iDataSchema'

export const convertDataOrderLikeSchemaAndClear = (data: any, dataSchemas: iDataSchemas, notClearObj = false): any => {
  if (data === undefined) {
    return undefined
  }

  const ret: any = {}
  for (const key in dataSchemas) {
    if (typeof data === 'object' && key in data) {
      const schemaItem = dataSchemas[key]
      const dataValue = data[key]
      if (schemaItem.items) {
        if (Array.isArray(dataValue)) {
          const schemaItemsProperties = schemaItem.items.properties
          const retArray = dataValue.map((item: any) =>
            schemaItemsProperties ? convertDataOrderLikeSchemaAndClear(item, schemaItemsProperties, notClearObj) : item,
          )
          if (
            retArray.some((v: any) => (typeof v === 'object' ? notClearObj || notEmptyObject(v) : notEmptyValue(v)))
          ) {
            ret[key] = retArray
          }
        }
      } else if (schemaItem.properties) {
        const retObject = convertDataOrderLikeSchemaAndClear(dataValue, schemaItem.properties, notClearObj)
        if (notClearObj || notEmptyObject(retObject)) {
          ret[key] = retObject
        }
      } else {
        if (notEmptyValue(dataValue)) {
          ret[key] = dataValue
        }
      }
    }
  }

  return ret
}
const notEmptyObject = (obj: any) => obj && Object.values(obj).some(notEmptyValue)
const notEmptyValue = (val: any) => val !== undefined && val !== ''
