import { DELIMETER_NAMESPACE_ALT } from '../builder_v1/specialChars'
import { FORM_TYPES } from './enumFormType'

export const NAMESPACE = {
  BASE: 'https://www.vvz.nipez.cz/zvz_xml/schemas/vvz/v1.0.0',
  NUTS: 'http://publications.europa.eu/resource/schema/ted/2021/nuts',

  CZTED: 'https://www.vvz.nipez.cz/vz_import/cz_ted_wrapper/v_0.1',
  CZDTT: 'https://www.vvz.nipez.cz/vz_import/cz_datatypes/v_0.1',

  CZF01: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f01/v_0.1',
  CZF02: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f02/v_0.1',
  CZF03: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f03/v_0.1',
  CZF04: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f04/v_0.1',
  CZF05: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f05/v_0.1',
  CZF06: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f06/v_0.1',
  CZF07: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cz_f07/v_0.1',

  BASE_REQ: 'https://www.vvz.nipez.cz/zvz_xml/schemas/zvz_cr/v_0.1',
}

export const NAMESPACE_OLD = {
  BASE: 'http://www.vvz.cz/zvz_xml/schemas/vvz/v1.0.0',

  CZTED: 'http://www.isvz.cz/vz_import/cz_ted_wrapper/v_0.1',
  CZDTT: 'http://www.isvz.cz/vz_import/cz_datatypes/v_0.1',

  CZF01: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f01/v_0.1',
  CZF02: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f02/v_0.1',
  CZF03: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f03/v_0.1',
  CZF04: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f04/v_0.1',
  CZF05: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f05/v_0.1',
  CZF06: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f06/v_0.1',
  CZF07: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cz_f07/v_0.1',

  BASE_REQ: 'http://www.isvz.cz/zvz_xml/schemas/zvz_cr/v_0.1',

  NUTS_16: 'http://publications.europa.eu/resource/schema/ted/2016/nuts',
  NUTS_16_SHORT: 'ted/2016/nuts',
}

export const enumNamespaceMapPrefix: any = {
  [NAMESPACE.BASE]: '',
  [NAMESPACE.BASE_REQ]: '',
  [NAMESPACE.NUTS]: 'n2021',

  [NAMESPACE.CZTED]: 'ted',
  [NAMESPACE.CZDTT]: 'dtt',
  [NAMESPACE.CZF01]: '',
  [NAMESPACE.CZF02]: '',
  [NAMESPACE.CZF03]: '',
  [NAMESPACE.CZF04]: '',
  [NAMESPACE.CZF05]: '',
  [NAMESPACE.CZF06]: '',
  [NAMESPACE.CZF07]: '',

  [NAMESPACE_OLD.BASE_REQ]: '',
  [NAMESPACE_OLD.CZTED]: 'ted',
  [NAMESPACE_OLD.CZDTT]: 'dtt',
  [NAMESPACE_OLD.CZF01]: '',
  [NAMESPACE_OLD.CZF02]: '',
  [NAMESPACE_OLD.CZF03]: '',
  [NAMESPACE_OLD.CZF04]: '',
  [NAMESPACE_OLD.CZF05]: '',
  [NAMESPACE_OLD.CZF06]: '',
  [NAMESPACE_OLD.CZF07]: '',

  [NAMESPACE_OLD.NUTS_16]: 'ted',
  [NAMESPACE_OLD.NUTS_16_SHORT]: 'ted',
}

export const formTypeToNamespace = {
  [FORM_TYPES.CZ01]: NAMESPACE.CZF01,
  [FORM_TYPES.CZ02]: NAMESPACE.CZF02,
  [FORM_TYPES.CZ03]: NAMESPACE.CZF03,
  [FORM_TYPES.CZ04]: NAMESPACE.CZF04,
  [FORM_TYPES.CZ05]: NAMESPACE.CZF05,
  [FORM_TYPES.CZ06]: NAMESPACE.CZF06,
  [FORM_TYPES.CZ07]: NAMESPACE.CZF07,
}

export const replaceAllNamespace = (path: string, namespacePrefix: string = '', namespaceSuffix: string = '') =>
  Object.keys(enumNamespaceMapPrefix).reduce(
    (prev, cur) =>
      prev.replaceAll(
        namespacePrefix + cur + namespaceSuffix,
        enumNamespaceMapPrefix[cur] + (enumNamespaceMapPrefix[cur] ? DELIMETER_NAMESPACE_ALT : ''),
      ),
    path,
  )
