import React from 'react'
import { useTranslation } from 'react-i18next'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import TableContracts from '../../tables/TableContracts/TableContracts'

const NoveZakazkyPage = () => {
  const { t } = useTranslation()

  return (
    <PageWrap
      title={t('title.nove_zakazky', 'Nové zakázky')}
      subtitle={t('title.nove_zakazky_text', 'Všechny formuláře zakládající zakázku zveřejněné dnes.')}
    >
      <FilterContextProvider name={TABLE_TYPES.newContracts}>
        <TableContracts />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default NoveZakazkyPage
