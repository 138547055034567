import React, { useContext } from 'react'

interface indexesContext {
  indexes: number[]
}

const IndexesContextBase = React.createContext({ indexes: [] } as indexesContext)

export const useIndexesContext = () => useContext(IndexesContextBase)

interface iIndexesContextProvider {
  index: number
  children: React.ReactNode
}

export const IndexesContextProvider = ({ index, children }: iIndexesContextProvider) => {
  const indexes = useIndexesContext().indexes

  const providerValue = React.useMemo(
    () => ({
      indexes: [...indexes, index],
    }),
    [indexes, index],
  )

  return <IndexesContextBase.Provider value={providerValue}>{children}</IndexesContextBase.Provider>
}
