import React, { useState } from 'react'
import { iValidationField } from '../../api/Validation2Api'
import { pathToScope, scopeWithoutRepeat } from '../../utils/scopeModifs'
import { iScopeToLabel } from '../utils/findSchemaScopeToLabel'
import ScrollBtn from './ScrollBtn'

const ErrorLine = ({ err, scopeToLabels }: { err: iValidationField; scopeToLabels: iScopeToLabel }) => {
  const getLabel = (path: string) => {
    const scope = scopeWithoutRepeat(pathToScope(path))
    return scopeToLabels[scope] || path
  }

  // const { openModalErrorDetail } = useModal()
  const [openDetail, setOpenDetail] = useState(false)
  // const body = [
  //   `id: ${submMain.id}`,
  //   `formType: ${formType}`,
  //   `message: ${err.chyba}`,
  //   `path: ${err.atribut}`,
  //   `detail: ${err.urovenChyby}`,
  // ]
  // const errorInfo = body.join('\n')
  return (
    <>
      {err.chyba}: <strong>{getLabel(err.atribut)}</strong>
      {!!err.atribut && <ScrollBtn path={err.atribut} />}
      {/*{err.detail && (*/}
      {/*<div>*/}
      {openDetail ? (
        <div>
          <small>
            <pre>{JSON.stringify(err, null, 2)}</pre>
          </small>
        </div>
      ) : (
        <>
          <button className='gov-link gov-link--small gov-link--standalone' onClick={() => setOpenDetail(true)}>
            Detail
          </button>
        </>
      )}
      {/*<button*/}
      {/*  className='gov-link gov-link--small gov-link--standalone'*/}
      {/*  onClick={() => openModalErrorDetail(errorInfo)}*/}
      {/*>*/}
      {/*  Zobrazit diagnostické informace pro podporu VVZ*/}
      {/*</button>*/}
      {/*</div>*/}
      {/*)}*/}
    </>
  )
}

export default ErrorLine
