import { ReactKeycloakProvider } from '@react-keycloak/web'
import React from 'react'
import { initOptions, keycloak } from './keycloak'
import { LoadUserWrap } from './LoadUserWrap'

const KeycloakUserProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
      <LoadUserWrap>{children}</LoadUserWrap>
    </ReactKeycloakProvider>
  )
}

export default KeycloakUserProvider
