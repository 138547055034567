export const VARIANTS_TABLE_PROFILES = {
  platne: 'platne',
  neaktivni: 'neaktivni',
  zrusene: 'zrusene',
}
export const VARIANTS_TABLE_CONTRACTS_HP = {
  sluzby: 'sluzby',
  dodavky: 'dodavky',
  stavebniprace: 'stavebniprace',
}
export const VARIANTS_TABLE_NEW_CANCEL_CHANGE = {
  zruseni: 'zruseni',
  zmeny: 'zmeny',
}

export const VARIANTS_TABLE_PROFILES_OPTIONS = [
  { label: 'tabs.platne', value: VARIANTS_TABLE_PROFILES.platne },
  { label: 'tabs.neaktivni', value: VARIANTS_TABLE_PROFILES.neaktivni },
  { label: 'tabs.zrusene', value: VARIANTS_TABLE_PROFILES.zrusene },
]

export const VARIANTS_TABLE_CONTRACTS_HP_OPTIONS = [
  { label: 'tabs.sluzby', value: VARIANTS_TABLE_CONTRACTS_HP.sluzby },
  { label: 'tabs.dodavky', value: VARIANTS_TABLE_CONTRACTS_HP.dodavky },
  { label: 'tabs.stavebni_prace', value: VARIANTS_TABLE_CONTRACTS_HP.stavebniprace },
]

export const VARIANTS_TABLE_NEW_CANCEL_CHANGE_OPTIONS = [
  { label: 'tabs.zruseni', value: VARIANTS_TABLE_NEW_CANCEL_CHANGE.zruseni },
  { label: 'tabs.zmeny', value: VARIANTS_TABLE_NEW_CANCEL_CHANGE.zmeny },
]
