import React, { useState } from 'react'
import { Dash, Plus } from 'react-bootstrap-icons'
import { iFormTree } from '../utils/createFormTree'
import { FormNavigationLink } from './FormNavigationLink'

interface iFormNavigationSection {
  formTree: iFormTree
}

export const FormNavigationSection = ({ formTree }: iFormNavigationSection) => {
  const hasChilds = formTree.childs?.some((child) => !child.isInput && (!child.isArray || child.childs?.length))
  const defOpen = formTree?.level !== undefined && formTree?.level < 1
  const [open, setOpen] = useState(defOpen)

  if (formTree.isInput) {
    return null
  }

  if (formTree.isHidden) {
    return null
  }

  if (formTree.isArray && hasChilds) {
    return (
      <>
        {formTree.childs?.map((childTree, index) => (
          <FormNavigationSection key={index} formTree={childTree} />
        ))}
      </>
    )
  }

  return (
    <li>
      <div className='nav-list-item'>
        {/*<div className={'nav-list-toggle-space ' + (formTree?.level === 0 && !hasChilds ? 'space-mini' : '')}>*/}
        <div className={'nav-list-toggle-space '}>
          {
            hasChilds ? (
              <button type='button' className='nav-list-toggle' onClick={() => setOpen(!open)}>
                {open ? <Dash /> : <Plus />}
              </button>
            ) : null
          }
        </div>
        <FormNavigationLink formTree={formTree} />
      </div>

      {open && hasChilds && (
        <ul className={`nav-list-inlevel nav-list-inlevel-${formTree.level}`}>
          {formTree.childs?.map((childTree, index) => (
            <FormNavigationSection key={index} formTree={childTree} />
          ))}
        </ul>
      )}
    </li>
  )
}
