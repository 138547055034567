import { combineReducers } from 'redux'
import dynamEum from './dynamEnum/dynamEnumSlice'
import formReducer from './form/formSlice'
import formErrorsSlice from './formErrors/formErrorsSlice'
import formStateSlice from './formState/formStateSlice'
import formTree from './formTree/formTreeSlice'
import globalReducer from './global/globalSlice'
import modalReducer from './modal/modalSlice'
import submissionsSlice from './submissions/submissionsSlice'
import userReducer from './user/userSlice'

const rootReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  modal: modalReducer,
  form: formReducer,
  formState: formStateSlice,
  submissions: submissionsSlice,
  formErrors: formErrorsSlice,
  formTree: formTree,
  dynamEum: dynamEum,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
