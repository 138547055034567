import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iFormTree } from '../../builder/utils/createFormTree'
import { ENV } from '../../ENV'

export const FORM_SIZE = {
  BIG: 1,
  EXTREME: 2,
}

interface iFormTreeSlide {
  formTree: iFormTree[]
  formTreeReq: iFormTree[]
  formArrayLength: { [path: string]: number }
  forceOpenPath: string
  forceOpenToSection: string
  isBig: number
  isFullInit: boolean
}

const slice = createSlice({
  name: 'formTree',
  initialState: {
    formTree: [],
    formTreeReq: [],
    formArrayLength: {},
    forceOpenPath: '',
    forceOpenToSection: '',
    isBig: 0,
    isFullInit: false,
  } as iFormTreeSlide,
  reducers: {
    clearFormTree(state) {
      state.formTree = []
      state.formTreeReq = []
      state.formArrayLength = {}
      state.isBig = 0
      state.isFullInit = false
    },
    setFormTree(state, action: PayloadAction<iFormTree[]>) {
      state.formTree = action.payload
    },
    setFormTreeReq(state, action: PayloadAction<iFormTree[]>) {
      state.formTreeReq = action.payload
    },
    setFormArrayLengthAll(state, action: PayloadAction<{ [path: string]: number }>) {
      // console.log('setFormArrayLengthAll', action.payload)
      state.formArrayLength = action.payload
    },
    setForceOpenPath(state, action: PayloadAction<string>) {
      if (!state.isBig) {
        state.forceOpenPath = action.payload
      }
    },
    setForceOpenToSection(state, action: PayloadAction<string>) {
      if (!state.isBig) {
        state.forceOpenToSection = action.payload
      }
    },
    setFormTreeIsBigDataArrayLength(state, action: PayloadAction<number>) {
      if (action.payload > ENV.FORM_TREE_SIZE_REPEATS_BIG) {
        const size = action.payload > ENV.FORM_TREE_SIZE_REPEATS_EXTREME ? FORM_SIZE.EXTREME : FORM_SIZE.BIG
        if (size !== state.isBig) {
          // console.log(
          //   'set is big form',
          //   action.payload,
          //   action.payload > ENV.FORM_TREE_SIZE_REPEATS_EXTREME ? FORM_SIZE.EXTREME : FORM_SIZE.BIG,
          // )
          state.isBig = size
        }
      }
    },
    setFormIsFullInit(state, action: PayloadAction<boolean>) {
      state.isFullInit = action.payload
    },
  },
})

export const {
  clearFormTree,
  setFormTree,
  setFormTreeReq,
  setFormArrayLengthAll,
  setForceOpenPath,
  setForceOpenToSection,
  setFormIsFullInit,
  setFormTreeIsBigDataArrayLength,
} = slice.actions
export default slice.reducer
