import React from 'react'

interface iSuccess {
  msg: string
}

const AlertSuccess = ({ msg }: iSuccess) => (
  <div className='gov-alert gov-alert--success'>
    <div className='gov-alert__content'>
      <p>{msg}</p>
    </div>
  </div>
)

export default AlertSuccess
