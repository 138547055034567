import axios from 'axios'

class AresGovApi {
  api = axios.create()

    // proxy na  https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty/26262525

  aresByIco = (ico: string) => this.api.get(`/aresgov/${ico}`).then((res) => res.data)
}

export default new AresGovApi()
