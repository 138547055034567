import { iUIschema } from '../interfaces/iUiSchema'
import { iUiSchemaRule, RULE_EFFECT } from '../interfaces/iUiSchemaRule'

export type iSchemaRules = { [path: string]: iUiSchemaRule[] }

const findAllRules = (uiSchema?: iUIschema, uiElIndex = '#'): { scope: string; rule: iUiSchemaRule }[] => {
  const scope = uiSchema?.scope ? uiSchema.scope : ''
  const rules = uiSchema?.allRules?.length
    ? uiSchema?.allRules.map((rule) => ({
        scope: scope,
        rule: {
          ...rule,
          uiElIndex: uiElIndex,
          likeType: uiSchema?.options?.likeType,
        },
      }))
    : []

  const hasEnableDisable = rules.some((r) => [RULE_EFFECT.ENABLE, RULE_EFFECT.DISABLE].includes(r.rule.effect as any))
  const hasShowHide = rules.some((r) => [RULE_EFFECT.SHOW, RULE_EFFECT.HIDE].includes(r.rule.effect as any))

  // prazdne pravidlo pro vzdy viditelne elementy => kvuli vyhodnoceni some
  if (!hasEnableDisable) {
    rules.push({
      scope: scope,
      rule: {
        effect: RULE_EFFECT.DISABLE,
        condition: { scope: '#', schema: { const: 'never' } },
        uiElIndex: uiElIndex,
        likeType: uiSchema?.options?.likeType,
      },
    })
  }
  if (!hasShowHide) {
    rules.push({
      scope: scope,
      rule: {
        effect: RULE_EFFECT.HIDE,
        condition: { scope: '#', schema: { const: 'never' } },
        uiElIndex: uiElIndex,
        likeType: uiSchema?.options?.likeType,
      },
    })
  }

  const childRules = uiSchema?.elements
    ? uiSchema.elements.reduce((prev: any[], el, index) => [...prev, ...findAllRules(el, uiElIndex + index)], [])
    : []

  return [...rules, ...childRules]
}

export const findAllRulesCompact = (uiSchema?: iUIschema): iSchemaRules => {
  const rulesAll = findAllRules(uiSchema)

  const rulesCompact: { [scope: string]: iUiSchemaRule[] } = {}
  rulesAll.forEach(({ scope, rule }) => {
    if (!(scope in rulesCompact)) {
      rulesCompact[scope] = []
    }
    rulesCompact[scope].push(rule)
  })

  return rulesCompact
}
