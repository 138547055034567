import { Buffer } from 'buffer'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import NewsletterApi from '../../api/NewsletterApi'
import { useModal } from '../../hooks/useModal'
import AlertError from '../Alert/AlertError'
import AlertSuccess from '../Alert/AlertSuccess'
import ButtonGov from '../Btns/ButtonGov'
import InputGov from '../Inputs/InputGov'
import Loading from '../Loading/Loading'

const sha256Base64 = async (input: string) => {
  const utf8 = new TextEncoder().encode(input) // Encoding the input to UTF-8
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8) // Calculating the hash
  return Buffer.from(new Uint8Array(hashBuffer)).toString('base64') // Converting the hash buffer directly to base64
}

const ModalNewsletter = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')

  const [sentLoading, setSentLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const [sentError, setSentError] = useState('')

  const { closeModal } = useModal()

  useEffect(() => {
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail(t('modalNotif.err_email', 'Zadejte platnou e-mailovou adresu'))
    } else {
      setErrorEmail('')
    }
  }, [email, t])
  
  const send = async () => {
    setSentLoading(true)
    const emailSalt = email + process.env.REACT_APP_NEWSLETTER_API_SALT
    const keyBase64 = await sha256Base64(emailSalt)
    NewsletterApi.subscribe(email, keyBase64)
      .then(() => {
        setSentOk(true)
      })
      .catch((err) => {
        const status = err.response?.status
        if (status === 400) {
          toast.error('Nevalidní e-mail')
        } else if (status === 401) {
          toast.error('Nevalidní bezpečnostní klíč')
        } else {
          toast.error('Neznámá chyba')
        }
        setSentError(err)
      })
      .finally(() => {
        setSentLoading(false)
      })
  }

  if (sentLoading) {
    return <Loading />
  }
  if (sentError) {
    return <AlertError msg={t('modalNotif.err_odeslani', 'Chyba odeslání žádosti. Zkuste to prosím později')} />
  }
  if (sentOk) {
    return (
      <AlertSuccess msg={t('modalNotif.uspesne_prihlaseni', 'E-mailová adresa úspěšně přihlášena k odběru novinek.')} />
    )
  }

  return (
    <>
      <p>{t('modalNotif.info_novinky', 'Na uvedenou adresu vám budou zasílány aktuality a informace z VVZ.')}</p>

      <div className='u-mb--50'>
        <InputGov
          type='email'
          name='email'
          label={t('modalNotif.email', 'E-mailová adresa')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errorEmail}
        />
      </div>

      <ButtonGov variant='primary' className='u-me--20' onClick={send} disabled={!email || !!errorEmail}>
        {t('modalNotif.prihlasit', 'Přihlásit k odběru novinek')}
      </ButtonGov>
      <ButtonGov variant='primary-outlined' onClick={closeModal}>
        {t('modalNotif.zavrit', 'Zavřít')}
      </ButtonGov>
    </>
  )
}

export default ModalNewsletter
