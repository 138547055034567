import { useElementContext } from '../context/ElementContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { useElementIsVisible } from '../hooks/useElementIsVisible'

interface iVisibleWrap {
  children: React.ReactNode
}

const VisibleWrapWatch = ({ children }: iVisibleWrap) => {
  const { formModifs } = useFormRenderContext()
  const { conditions } = useElementContext()

  const { isVisibleElement } = useElementIsVisible(conditions)

  const { isFullVersion } = formModifs
  const isVisible = isVisibleElement || isFullVersion

  if (!isVisible) {
    return null
  }

  return <>{children}</>
}

const VisibleWrap = ({ children }: iVisibleWrap) => {
  const { conditions } = useElementContext()
  return conditions ? <VisibleWrapWatch>{children}</VisibleWrapWatch> : <>{children}</>
}

export default VisibleWrap
