import { setForceOpenPath } from '../../redux/formTree/formTreeSlice'
import { useAppDispatch } from '../../redux/store'
import { scrollToPath } from '../utils/scrollToPath'

const ScrollBtn = ({ path }: { path: string }) => {
  const dispath = useAppDispatch()
  const scroll = () => {
    dispath(setForceOpenPath(path))
    setTimeout(() => {
      scrollToPath(path)
      // vymazat path - muze reagovat na dalsi kliknuti
      dispath(setForceOpenPath(''))
    }, 100)
  }

  return (
    <button title={path} className='gov-link gov-link--small gov-link--standalone' onClick={scroll}>
      Přejít na chybu
    </button>
  )
}
export default ScrollBtn
