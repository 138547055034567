import React from 'react'
import { NumericFormat } from 'react-number-format'
import { useElementContext } from '../../context/ElementContext'
import { DATA_TYPE } from '../../interfaces/iDataSchema'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputNumber = () => {
  const { field, errors } = useControlRegister()
  const { uiSchema, id, required, disabled, dataSchema } = useElementContext()

  const isInteger = dataSchema?.type === DATA_TYPE.INTEGER
  const allowNegative = uiSchema.options?.numberAllowNegative
  const numberPrecision = uiSchema.options?.numberPrecision

  return (
    <WrapInputGov value={field.value?.toString()} errors={errors}>
      <NumericFormat
        value={field.value ?? ''}
        onBlur={field.onBlur}
        name={field.name}
        getInputRef={field.ref}
        onValueChange={(values) => {
          const num = isInteger ? parseInt(values.value) : parseFloat(values.value)
          field.onChange(isNaN(num) ? '' : num)
        }}
        className={'gov-form-control__input'}
        id={id}
        disabled={disabled}
        allowNegative={!!allowNegative}
        thousandSeparator={' '}
        decimalSeparator={','}
        decimalScale={isInteger ? 0 : numberPrecision}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-labelledby={id}
      />
      <PartGovLabel />
    </WrapInputGov>
  )
}

export default InputNumber
