import get from 'lodash/get'
import set from 'lodash/set'
import { DATA_TYPE } from '../../enums_v1/enumDataType'
import { isVisibleRules } from '../rules/isVisibleRules'
import { modifRules } from '../rules/modifRules'
import { scopeToExistDataPaths } from './calculateDataSchemaArrayScopes'
import { iUiSchemaDefValue } from './calculateUiSchemaDefaultValues'

export const calculateDataWithUiDefault = (changedData: any, defaultUiValues: iUiSchemaDefValue[]) => {
  defaultUiValues.forEach((uiElDef) => {
    const { scopeWithRepeat, schemaType, rules, value, onlyEmpty, arrayCounter } = uiElDef
    if (scopeWithRepeat) {
      const paths = scopeToExistDataPaths(scopeWithRepeat, changedData)

      const visiblePaths = paths.filter((path) => {
        if (!rules) {
          return true
        }
        const pathScope = path.replaceAll('.', '/')
        const rulesModif = modifRules(pathScope, rules)
        const isVisibleObj = isVisibleRules(changedData, rulesModif)
        return isVisibleObj.enabled && isVisibleObj.visible
      })

      visiblePaths.forEach((path) => {
        if (onlyEmpty) {
          const oldVal = get(changedData, path)
          if (oldVal === undefined || (Array.isArray(oldVal) && oldVal.length === 0)) {
            set(changedData, path, value)
          }
        } else if (arrayCounter) {
          const isNumber = schemaType === DATA_TYPE.number || schemaType === DATA_TYPE.integer
          const matches = path.match(/\.(\d+)\./g)
          const countText = matches?.length ? matches[matches.length - 1] : undefined
          const countVal = countText ? parseInt(countText.slice(1, -1)) + 1 : undefined
          const count = countVal ? (isNumber ? countVal : countVal.toString()) : undefined
          set(changedData, path, count)
        } else {
          set(changedData, path, value)
        }
      })
    }
  })

  return changedData
}
