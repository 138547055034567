import flatten from 'lodash/flatten'
import { FORM_TYPES_V2 as FT2, FORM_TYPES_V2_NAMES, formTypeToCodeLabel } from './enumFormTypeV2'

export const enumFormsCategory = [
  {
    name: 'Plánování',
    types: [
      {
        name: 'Předběžné oznámení',
        forms: [FT2.F4, FT2.F5, FT2.F6],
      },
      {
        name: 'Předběžné oznámení použité za účelem zkrácení lhůty pro podání nabídek',
        forms: [FT2.F7, FT2.F8, FT2.F9],
      },
    ],
  },
  {
    name: 'Zahájení',
    types: [
      {
        name: 'Předběžné oznámení použité jako výzva k projevení předběžného zájmu',
        forms: [FT2.F10, FT2.F11],
      },
      {
        name: 'Předběžné oznámení použité jako výzva k projevení předběžného zájmu – zjednodušený režim',
        description: '(sociální a jiné zvláštní služby uvedené v příloze č. 4 ZZVZ)',
        forms: [FT2.F12, FT2.F13, FT2.F14],
      },
      {
        name: 'Systém kvalifikace',
        forms: [FT2.F15],
      },
      {
        name: 'Oznámení o zahájení zadávacího řízení',
        forms: [FT2.F16, FT2.F17, FT2.F18, FT2.F19],
      },
      {
        name: 'Oznámení o zahájení zadávacího řízení – zjednodušený režim',
        description: '(sociální a jiné zvláštní služby uvedené v příloze č. 4 ZZVZ)',
        forms: [FT2.F20, FT2.F21],
      },
      {
        name: 'Oznámení o poddodávce',
        forms: [FT2.F22],
      },
      {
        name: 'Oznámení o zahájení soutěže o návrh',
        forms: [FT2.F23, FT2.F24],
      },
    ],
  },
  {
    name: 'Oznámení o záměru uzavřít smlouvu ',
    types: [
      {
        name: 'Dobrovolné oznámení o záměru uzavřít smlouvu ',
        forms: [FT2.F25, FT2.F26, FT2.F27, FT2.F28],
      },
    ],
  },
  {
    name: 'Výsledek',
    types: [
      {
        name: 'Oznámení o výsledku zadávacího řízení',
        forms: [FT2.F29, FT2.F30, FT2.F31, FT2.F32],
      },
      {
        name: 'Oznámení o výsledku zadávacího řízení – zjednodušený režim',
        description: '(sociální a jiné zvláštní služby uvedené v příloze č. 4 ZZVZ)',
        forms: [FT2.F33, FT2.F34, FT2.F35],
      },
      {
        name: 'Oznámení o výsledku soutěže o návrh',
        forms: [FT2.F36, FT2.F37],
      },
    ],
  },
  {
    name: 'Změna závazku ze smlouvy',
    types: [
      {
        name: 'Oznámení o změně závazku ze smlouvy',
        forms: [FT2.F38, FT2.F39, FT2.F40],
      },
    ],
  },
  {
    name: 'Profil zadavatele',
    types: [
      {
        name: 'Uveřejnění informací k profilu zadavatele',
        forms: [FT2.FCZ05, FT2.FCZ06],
      },
    ],
  },
  {
    name: 'Nabídkové řízení pro výběr dopravce',
    types: [
      {
        name: 'Nabídkové řízení dle zákona č. 194/2010 Sb., o veřejných službách v přepravě cestujících a o změně dalších zákonů',
        forms: [FT2.FCZ07],
      },
    ],
  },
]

export const LINE_TYPE = {
  TITLE: 'TITLE',
  SUBTITLE: 'SUBTITLE',
  FORM: 'FORM',
}

export const enumFormsCategoryRows: {
  type: string
  name: string
  formType?: string
  formTypeLabel?: string
  description?: string
}[] = flatten(
  enumFormsCategory.map(({ name, types }) => [
    {
      type: LINE_TYPE.TITLE,
      name: name,
    },
    ...flatten(
      types.map(({ name, forms, description }) => [
        {
          type: LINE_TYPE.SUBTITLE,
          name: name,
          description: description,
        },
        ...forms.map((form) => ({
          type: LINE_TYPE.FORM,
          name: FORM_TYPES_V2_NAMES[form],
          formTypeLabel: formTypeToCodeLabel(form),
          formType: form,
        })),
      ]),
    ),
  ]),
)
