import React from 'react'
import { formatDateShortView, formatTimeView } from '../../../utils/dateFormatFns'
import { useElementDateTime } from '../../hooks/useElementDateTime'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputDateTxt = () => {
  const { field } = useControlRegister()

  const { isTime, selectedValue } = useElementDateTime(field.value)
  const dateString = isTime ? formatTimeView(selectedValue) : formatDateShortView(selectedValue)

  return (
    <div>
      <PartGovLabelTxt /> {dateString}
    </div>
  )
}
export default InputDateTxt
