import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import ButtonGov from '../../../components/Btns/ButtonGov'
import { ARRAY_SCOPE_SEP, extractCopyMap } from '../../../utils/extractCopyMap'
import { scopeFullToPath } from '../../../utils/scopeModifs'
import { useElementContext } from '../../context/ElementContext'
import { useFormDeleteForbiden } from '../../hooks/useFormDeleteForbiden'
import { useFormLiveValidation } from '../../hooks/useFormLiveValidation'

const BtnCopyData = () => {
  const { setValue, getValues } = useFormContext()
  const { label, uiSchema, disabled } = useElementContext()
  const { buttonDestinationNodeId, buttonCopyMap } = uiSchema.options || {}

  const [loading, setLoading] = useState(false)

  const basePath = scopeFullToPath(buttonDestinationNodeId)

  const { deleteForbiden } = useFormDeleteForbiden()
  const { validateFormLive } = useFormLiveValidation()

  const clickLoadData = () => {
    if (!buttonCopyMap) {
      return
    }
    setLoading(true)
    const deletedPaths: string[] = []
    extractCopyMap(buttonCopyMap, basePath).forEach(({ fromPath, toPath, deletePath }) => {
      if (fromPath.includes(ARRAY_SCOPE_SEP)) {
        const [fromPathArr, fromPathRel] = fromPath.split('.' + ARRAY_SCOPE_SEP + '.')
        const [toPathArr, toPathRel] = toPath.split('.' + ARRAY_SCOPE_SEP + '.')

        if (!deletedPaths.includes(toPathArr)) {
          deletedPaths.push(toPathArr)
          setValue(toPathArr, [])
          deleteForbiden(toPathArr)
        }

        const arrayVals = getValues(fromPathArr) ?? []
        for (let i = 0; i < arrayVals.length; i++) {
          const val = getValues(`${fromPathArr}.${i}.${fromPathRel}`) ?? ''
          setValue(`${toPathArr}.${i}.${toPathRel}`, val)
        }
      } else {
        if (deletePath) {
          setValue(deletePath, undefined)
        }
        const val = getValues(fromPath) ?? ''
        setValue(toPath, val)
      }
    })

    if (deletedPaths.length) {
      validateFormLive()
    }
    toast.success('Informace zkopírovány ze zadávacího postupu')
    setLoading(false)
  }

  if (disabled) {
    return null
  }

  return (
    <div className='btnInlineWrap'>
      <ButtonGov variant='primary-green' size='small' loading={loading} onClick={clickLoadData}>
        {label || 'Předvyplnit údaje ze zadávacího postupu'}
      </ButtonGov>
    </div>
  )
}

export default BtnCopyData
