import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonGov from '../../components/Btns/ButtonGov'
import PageWrap from '../../components/PageWrap/PageWrap'
import WizardForm from '../../components/Wizard/WizardForm'
import TableFormTypes from '../../tables/TableFormTypes/TableFormTypes'

const FormularePodatPage = () => {
  const { t } = useTranslation()

  const [showList, setShowList] = useState(false)
  return (
    <PageWrap
      title={
        showList
          ? t('title.podat_form', 'Podat formulář')
          : t('title.podat_form_wizard', 'Průvodce k určení cílového formuláře')
      }
      subElement={
        <ButtonGov variant='primary' size="small" className="u-mb--40" onClick={() => setShowList(!showList)}>
          {showList ? 'Použít průvodce' : 'Vybrat formulář bez průvodce'}
        </ButtonGov>
      }
    >
      {showList ? <TableFormTypes /> : <WizardForm />}
    </PageWrap>
  )
}

export default FormularePodatPage
