export const FORM_TYPES = {
  F01: 'F01',
  F02: 'F02',
  F03: 'F03',
  F04: 'F04',
  F05: 'F05',
  F06: 'F06',
  F07: 'F07',
  F08: 'F08',
  F12: 'F12',
  F13: 'F13',
  F14: 'F14',
  F15: 'F15',
  F16: 'F16',
  F17: 'F17',
  F18: 'F18',
  F19: 'F19',
  F20: 'F20',
  F21: 'F21',
  F22: 'F22',
  F23: 'F23',
  F24: 'F24',
  F25: 'F25',
  CZ01: 'CZ01',
  CZ02: 'CZ02',
  CZ03: 'CZ03',
  CZ04: 'CZ04',
  CZ05: 'CZ05',
  CZ06: 'CZ06',
  CZ07: 'CZ07',
}

export const FORM_TYPE_REQ = 'ZADOST'

export const PROFILE_FORM_TYPES = [FORM_TYPES.CZ05, FORM_TYPES.CZ06]
export const CZ_FORM_TYPES = [
  FORM_TYPES.CZ01,
  FORM_TYPES.CZ02,
  FORM_TYPES.CZ03,
  FORM_TYPES.CZ04,
  FORM_TYPES.CZ05,
  FORM_TYPES.CZ06,
  FORM_TYPES.CZ07,
]

export const DEFENSE_FORM_TYPES = [FORM_TYPES.F16, FORM_TYPES.F17, FORM_TYPES.F18, FORM_TYPES.F19]

export const enumFormType = [
  { code: FORM_TYPES.F01, name: 'Předběžné oznámení' },
  { code: FORM_TYPES.F02, name: 'Oznámení o zahájení zadávacího řízení' },
  { code: FORM_TYPES.F03, name: 'Oznámení o výsledku zadávacího řízení' },
  { code: FORM_TYPES.F04, name: 'Pravidelné předběžné oznámení – veřejné služby' },
  { code: FORM_TYPES.F05, name: 'Oznámení o zahájení zadávacího řízení – veřejné služby' },
  { code: FORM_TYPES.F06, name: 'Oznámení o výsledku zadávacího řízení – veřejné služby' },
  { code: FORM_TYPES.F07, name: 'Systém kvalifikace - veřejné služby ' },
  { code: FORM_TYPES.F08, name: 'Oznámení na profilu zadavatele' },
  { code: FORM_TYPES.F12, name: 'Oznámení soutěže o návrh' },
  { code: FORM_TYPES.F13, name: 'Výsledky soutěže o návrh' },
  { code: FORM_TYPES.F14, name: 'Oprava - Oznámení změn nebo dodatečných informací' },
  { code: FORM_TYPES.F15, name: 'Oznámení o dobrovolné průhlednosti ex ante' },
  { code: FORM_TYPES.F16, name: 'Oznámení předběžných informací – obrana a bezpečnost' },
  { code: FORM_TYPES.F17, name: 'Oznámení o zakázce – obrana a bezpečnost' },
  { code: FORM_TYPES.F18, name: 'Oznámení o zadání zakázky – obrana a bezpečnost' },
  { code: FORM_TYPES.F19, name: 'Oznámení o subdodávce – obrana a bezpečnost' },
  { code: FORM_TYPES.F20, name: 'Oznámení o změně' },
  { code: FORM_TYPES.F21, name: 'Sociální a jiné zvláštní služby – veřejné zakázky' },
  { code: FORM_TYPES.F22, name: 'Sociální a jiné zvláštní služby – veřejné služby' },
  { code: FORM_TYPES.F23, name: 'Sociální a jiné zvláštní služby – koncese' },
  { code: FORM_TYPES.F24, name: 'Oznámení o zahájení koncesního řízení' },
  { code: FORM_TYPES.F25, name: 'Oznámení o výsledku koncesního řízení' },
  { code: FORM_TYPES.CZ01, name: 'Předběžné oznámení podlimitního zadávacího řízení' },
  { code: FORM_TYPES.CZ02, name: 'Oznámení o zahájení podlimitního zadávacího řízení' },
  { code: FORM_TYPES.CZ03, name: 'Oznámení o výsledku podlimitního zadávacího řízení' },
  { code: FORM_TYPES.CZ04, name: 'Oprava národního formuláře' },
  { code: FORM_TYPES.CZ05, name: 'Oznámení profilu zadavatele' },
  { code: FORM_TYPES.CZ06, name: 'Zrušení/zneaktivnění profilu zadavatele' },
  {
    code: FORM_TYPES.CZ07,
    name: 'Oznámení o zahájení nabídkového řízení pro výběr dopravce k uzavření smlouvy o veřejných službách v přepravě cestujících',
  },
]

export const optionsFormTypeV1 = enumFormType
  .filter(({ code }) => !PROFILE_FORM_TYPES.includes(code) && code !== FORM_TYPES.CZ07)
  .map(({ code, name }) => ({
    value: code,
    label: `${code} - ${name}`,
  }))

export const optionsFormTypeProfilV1 = enumFormType
  .filter(({ code }) => PROFILE_FORM_TYPES.includes(code))
  .map(({ code, name }) => ({
    value: code,
    label: `${code} - ${name}`,
  }))

export const optionsFormTypeV1Group = [{ label: 'Staré formuláře', options: optionsFormTypeV1 }]
export const getFormNameByTypeV1 = (formType: string) => enumFormType.find(({ code }) => code === formType)?.name || ''

export const formTypeToFormSlugV1 = (formType: string) => 'vvz-' + formType.toLowerCase() + '-edit'
