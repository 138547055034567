import React, { useState } from 'react'
import { ENV } from '../ENV'

interface iDebugElement {
  data?: any
}

const DebugElement = ({ data }: iDebugElement) => {
  const [open, setOpen] = useState(false)
  const { rootSchema, renderers, schema, config, uischema, ...others } = data
  const { elements, ...uischemaNoelements } = uischema

  const aData = {
    uischema: uischemaNoelements,
    validations: schema?.validations,
    // schema,
    ...others,
  }

  if (!ENV.DEBUG_MODE) {
    return null
  }

  return (
    <div style={{ position: 'absolute', top: -15, left: -15, background: 'rgba(220,220,220,0.8)' }}>
      <small onClick={() => setOpen(!open)}>
        {uischema.type}
        {/*- {data.path}*/}
      </small>
      {open && (
        <div
          style={{
            fontSize: 14,
            zIndex: 9999999,
            position: 'absolute',
            background: 'rgba(260,260,260,0.8)',
            border: '1px solid black',
          }}
        >
          <pre>{JSON.stringify(aData, null, 2)}</pre>
        </div>
      )}
    </div>
  )
}

export default DebugElement
