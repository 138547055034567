import { ControlElement } from '@jsonforms/core/src/models'
import React from 'react'
import { ELEMENT_ID_SUFIX_WRAP } from '../utils/scrollToPathV1'
import InputGovDescription from './InputGovDescription'
import InputGovError from './InputGovError'
import InputGovHelp from './InputGovHelp'

interface iWrapInputGov {
  uischema?: ControlElement
  path?: string
  // scope?: string
  value?: string
  description?: string
  openLabel?: boolean
  error?: string
  custom?: boolean
  children: React.ReactNode
}

const WrapInputGov = ({ uischema, path, value, description, openLabel, error, custom, children }: iWrapInputGov) => {
  const showHelp = uischema?.options?.showHelp

  const classes = ['gov-form-control']
  if (custom) {
    classes.push('gov-form-control--custom')
  }
  if (value || openLabel) {
    classes.push('not-empty')
  }

  if (error) {
    classes.push('gov-form-control--error')
  }
  return (
    <div id={path ? path + ELEMENT_ID_SUFIX_WRAP : undefined} className={classes.join(' ')}>
      {children}

      <InputGovDescription text={description} />
      <InputGovError text={error} />

      {path && showHelp && <InputGovHelp path={path} />}
    </div>
  )
}

export default WrapInputGov
