import { iEformsTranslatesBlock } from '../../interfaces/iEformsApi'
import { iEformsFormSchemaForm } from '../../interfaces/iEformsFormSchema'
import { iUIschema } from '../interfaces/iUiSchema'

export const combineSchemaTranslate = (uiSchemas: iUIschema[], translates: iEformsTranslatesBlock): iUIschema[] => {
  return uiSchemas.map((uiSchema) => ({
    ...uiSchema,
    elements: uiSchema.elements ? combineSchemaTranslate(uiSchema.elements, translates) : undefined,
    label: translateItem(uiSchema.label, translates),
    text: translateItem(uiSchema.text, translates),
    options: uiSchema.options
      ? {
          ...uiSchema.options,
          description: translateItem(uiSchema.options?.description, translates),
          descriptionBefore: translateItem(uiSchema.options?.descriptionBefore, translates),
        }
      : undefined,
  }))
}

export const combineFormTranslate = (
  schemaForm: iEformsFormSchemaForm,
  translates: iEformsTranslatesBlock,
): iEformsFormSchemaForm => ({
  title: translateItem(schemaForm.title, translates),
  description: translateItem(schemaForm.description, translates),
})

const translateItem = (name: string | undefined | false, translates: iEformsTranslatesBlock) => {
  if (name === false) {
    return undefined
  }
  if (!name) {
    return name
  }

  return translates[name] ?? name
}
