import axios from 'axios'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

class ValidationConnect2Api {
  api = axios.create({
    baseURL: process.env.REACT_APP_VALIDATION_COONNECT_V2_API_URL,
  })

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
  }

  validationFormConection = (
    evidencniCisloZakazky: string,
    evidencniCisloFormulare: string,
    kodSouvisejicihoFormulare: string,
    datumValidace: string,
    druhPodavanehoFormulare: string,
    formularOpravuje: boolean,
  ) => {
    return this.api
      .post('/podani/existuje-navaznost', {
        evidencniCisloZakazky, //: "Z2023-100013",
        evidencniCisloFormulare, //: "F2023-100013",
        kodSouvisejicihoFormulare, //: "5B85RO",
        datumValidace, //: "2023-01-17T09:01:00+01:00",  --NOW()
        druhPodavanehoFormulare, //: "F02"
        formularOpravuje, // true/flase
      })
      .then((res) => res.data)
  }
}

const VA = new ValidationConnect2Api()
export default VA
