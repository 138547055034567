export const WORKFLOW_PLACES = {
  ROZPRACOVANO: 'ROZPRACOVANO',
  PREDANO_K_UVEREJNENI: 'PREDANO_K_UVEREJNENI',
  ODESLANO_TED: 'ODESLANO_TED',
  ZAMITNUTO_TED: 'ZAMITNUTO_TED',
  UVEREJNENO_TED: 'UVEREJNENO_TED',
  UVEREJNENO_VVZ: 'UVEREJNENO_VVZ',
  ROZHODNE_OPERATOR: 'ROZHODNE_OPERATOR',
  NEUVEREJNENO_VVZ: 'NEUVEREJNENO_VVZ',
  STORNOVANO: 'STORNOVANO',
  ZRUSENO: 'ZRUSENO',
}

export const WORKFLOW_PLACES_PROFIL = {
  ROZPRACOVANO: 'ROZPRACOVANO',
  PREDANO_K_UVEREJNENI: 'PREDANO_K_UVEREJNENI',
  UVEREJNENO_VVZ: 'UVEREJNENO_VVZ',
  NEUVEREJNENO_VVZ: 'NEUVEREJNENO_VVZ',
  STORNOVANO: 'STORNOVANO',
  ZRUSENO: 'ZRUSENO',
}

export const WORKFLOW_PLACES_EDIT_FORM = {
  ROZPRACOVANO: 'ROZPRACOVANO',
  UZAMCENO: 'UZAMCENO',
}

export const WORKFLOW_PLACES_LABEL = {
  [WORKFLOW_PLACES.ROZPRACOVANO]: 'Rozpracováno',
  [WORKFLOW_PLACES.PREDANO_K_UVEREJNENI]: 'Předáno k uveřejnění',
  [WORKFLOW_PLACES.ODESLANO_TED]: 'Odesláno do TED',
  [WORKFLOW_PLACES.ZAMITNUTO_TED]: 'Zamítnuto v TED',
  [WORKFLOW_PLACES.UVEREJNENO_TED]: 'Uveřejněno v TED',
  [WORKFLOW_PLACES.UVEREJNENO_VVZ]: 'Uveřejněno ve VVZ',
  [WORKFLOW_PLACES.ROZHODNE_OPERATOR]: 'Rozhodne operátor',
  [WORKFLOW_PLACES.NEUVEREJNENO_VVZ]: 'Neuveřejněno ve VVZ',
  [WORKFLOW_PLACES.STORNOVANO]: 'Stornováno',
  [WORKFLOW_PLACES.ZRUSENO]: 'Zrušeno',
}

export const workwlowPlaceOptions = Object.keys(WORKFLOW_PLACES).map((code) => ({
  label: WORKFLOW_PLACES_LABEL[code],
  value: code,
}))

export const workwlowPlaceProfilOptions = Object.keys(WORKFLOW_PLACES_PROFIL).map((code) => ({
  label: WORKFLOW_PLACES_LABEL[code],
  value: code,
}))

export const isFormDraft = (workflowPlace: string) =>
  [WORKFLOW_PLACES.ROZPRACOVANO, WORKFLOW_PLACES.ZRUSENO].includes(workflowPlace)
