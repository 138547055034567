import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import LoadingInline from '../../../components/Loading/LoadingInline'
import { iOption } from '../../../interfaces/iOption'
import { selectFormIsFullInit } from '../../../redux/formTree/formTreeSelectors'
import EnumLoader from '../../../services/EnumLoader'
import FormFullInit from '../../../services/FormFullInit'
import { useElementContext } from '../../context/ElementContext'
import { useFormRenderContext } from '../../context/RenderFormContext'
import { useElementEnum } from '../../hooks/useElementEnum'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputSelect = () => {
  const { field, errors } = useControlRegister()
  const { id, required, disabled, path } = useElementContext()
  const { formSchemaId } = useFormRenderContext()
  const { enumOptions, isDynamicEnum, enumLoading, enumKey } = useElementEnum()

  const isFullInit = useSelector(selectFormIsFullInit)

  useEffect(() => {
    // vymazani hodnoty kdyz se zmeni options a hodnota tam neni
    const existEnumKey = enumOptions?.some((opt) => opt.value === field.value)
    if (
      isFullInit &&
      field.value &&
      !disabled &&
      !existEnumKey &&
      ((isDynamicEnum && enumOptions !== undefined) || enumOptions?.length)
    ) {
      toast.info(`Smazána neplatná hodnota: ${field.value} (${path})`)
      field.onChange('') // undefined nevymaze
    }
  }, [enumOptions, isFullInit])

  const [loadingFirst, setLoadingFirst] = useState(true)
  const [singleOptions, setSingleOptions] = useState<iOption[]>([])

  useEffect(() => {
    if (field.value && enumKey && disabled && !enumOptions?.length && !singleOptions?.length) {
      //nacteni labelu z ulozene value
      EnumLoader.getByCode(formSchemaId!, enumKey!, field.value)
        .then((res) => {
          setLoadingFirst(false)
          setSingleOptions(res)
        })
        .catch((err) => {
          toast.error('Chyba načtení hodnoty do číselníku: ' + field.value)
        })
    } else {
      setLoadingFirst(false)
    }
  }, [field.value])

  useEffect(() => {
    if (!enumLoading && !loadingFirst) {
      FormFullInit.removeInputSelectScopes(path)
    }
  }, [enumLoading, loadingFirst])

  if (enumLoading || loadingFirst) {
    return <LoadingInline />
  }

  const options = singleOptions?.length ? singleOptions : enumOptions

  return (
    <WrapInputGov value={field.value} errors={errors}>
      <div className={'gov-select ' + (disabled ? 'selectDisabled' : '')}>
        <select
          {...field}
          id={id}
          disabled={disabled}
          aria-required={required ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-labelledby={id}
          title={field.value}
        >
          <option value=''></option>
          {options?.map(({ label, value }) => (
            <option key={value} value={value} title={label}>
              {label}
            </option>
          ))}
        </select>
        <PartGovLabel />
      </div>
    </WrapInputGov>
  )
}

export default InputSelect
