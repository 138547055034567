import React, { useState } from 'react'
import HelpApi from '../../api/HelpApi'
import { ENV } from '../../ENV'
import { useEffectStart } from '../../hooks/useEffectStart'
import { useModal } from '../../hooks/useModal'
import ButtonGov from '../Btns/ButtonGov'
import Loading from '../Loading/Loading'

const ModalHelp = () => {
  const { closeModal, dataHelp } = useModal()
  const [loading, setLoading] = useState(false)
  const [helpText, setHelpText] = useState('')
  const [error, setError] = useState('')

  useEffectStart(() => {
    setLoading(true)
    HelpApi.getHelpText(formType, xpath)
      .then((res) => {
        setHelpText(res)
      })
      .catch((e) => {
        const msg = 'Nepodařilo se získat nápovědu pro tuto položku.'
        setError(msg)
      })
      .finally(() => setLoading(false))
  })

  if (!dataHelp) {
    return null
  }
  const { formType, xpath } = dataHelp

  const clickCancel = () => {
    closeModal()
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='u-mb--50'>
        {error ? (
          <>
            {error}
            {ENV.DEBUG_MODE ? (
              <div>
                <small> Cesta: {xpath} </small>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          helpText
        )}
      </div>
      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zavřít
      </ButtonGov>
    </>
  )
}

export default ModalHelp
