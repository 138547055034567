import { TEXTS } from './translate_texts'

const TEXTS_MAIN: { [path: string]: any } = {
  ...TEXTS
}
const findSpec = (path: string): string => {
  const ret = path.split('.').reduce((prev, cur) => {
    if (prev && prev[cur]) {
      const val = prev[cur]
      if (typeof val === 'string' && val.startsWith('@')) {
        // odkaz ve stejne urovni
        return prev[val.slice(1)]
      } else {
        return val
      }
    }
    return undefined
  }, TEXTS_MAIN)

  if (ret && typeof ret === 'string') {
    return ret
  }
  //  zanorit o uroven na "_"
  if (ret && typeof ret._ === 'string') {
    return ret._
  }
  const indexDot = path.indexOf('.')
  if (indexDot === -1) {
    return ''
  }
  return findSpec(path.substring(indexDot + 1))
}

export const translatePath =
  (formType: string) =>
  (path: string, defVal = '?' + path, context?: any): string | undefined => {
    if (formType) {
      path = formType + '.' + path
    }

    path = path.replace(/\.\d+\./g, '.') // zruseni opakovacich sekci
    const specVal = findSpec(path)

    if (specVal) {
      return specVal
    }
    return defVal
  }
