import flatten from 'lodash/flatten'
import has from 'lodash/has'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import set from 'lodash/set'
import { anchorLinks } from '../../utils/anchorLinks'
import { scopeFullToPath } from '../../utils/scopeModifs'
import { ELEMENT_TYPE } from '../elements/ELEMENTS'
import { iUIschema } from '../interfaces/iUiSchema'
import { modifUiSchemaArray } from './modifUiSchemaArray'

export interface iFormTree {
  isArray?: boolean
  isInput?: boolean
  level?: number
  uiSchema?: Omit<iUIschema, 'elements' | 'options'>
  pathsInside?: string[]
  text?: string
  to?: string
  toInside?: string[]
  childs?: iFormTree[]
  errorsCount?: number
  isHidden?: boolean
}

const MENU_ELEMENT_TYPE = [ELEMENT_TYPE.Group, ELEMENT_TYPE.Array, ELEMENT_TYPE.Upload]

export const createFormTree = (
  uiSchemas: iUIschema[],
  formArrayLengths: {
    [path: string]: number
  },
  errorsPaths?: string[],
  hiddenPaths?: string[],
) => {
  const uiSchemaToFormTree = (uiSchemaItem: iUIschema, level: number, arrayIndexes: number[], repeatIndex?: number) => {
    const childsTree = uiSchemaItem.elements?.length
      ? createFormTreePart(uiSchemaItem.elements, level + 1, arrayIndexes)
      : undefined

    const thisPathsObj = {}
    if (uiSchemaItem.scope) {
      set(thisPathsObj, scopeFullToPath(uiSchemaItem.scope), {})
    }
    const childsPathsObj = childsTree?.map((formSection) => formSection.pathsInside ?? []) || []
    const pathsInside = merge({}, thisPathsObj, ...childsPathsObj)
    const errorsCount = errorsPaths?.filter((errPath) => has(pathsInside, errPath)).length

    if (!MENU_ELEMENT_TYPE.includes(uiSchemaItem.type)) {
      return {
        isInput: true,
        pathsInside: pathsInside, // kvuli navratu ze zanoreni a zapsani do parent elementu
        errorsCount: errorsCount,
      }
    }

    const anchorLink = anchorLinks(uiSchemaItem)
    const baseText = (uiSchemaItem?.label || uiSchemaItem?.text) ?? ''

    const path = scopeFullToPath(uiSchemaItem?.scope)
    const isHidden = hiddenPaths?.some((p) => path.startsWith(p))
    const toInside = childsTree ? flatten(childsTree.map((a) => a.toInside ?? [])) : []
    const to = anchorLink && arrayIndexes.length ? anchorLink + '_' + arrayIndexes.join('_') : anchorLink
    if (to) {
      toInside.push(to)
    }
    return {
      uiSchema: omit(uiSchemaItem, 'elements', 'options'),
      text: repeatIndex ? repeatIndex + ') ' + baseText : baseText,
      to: to,
      toInside: toInside,
      pathsInside: pathsInside,
      childs: childsTree,
      level: level,
      errorsCount: errorsCount,
      isHidden: isHidden,
    }
  }

  const createFormTreePart = (uiSchemas: iUIschema[], level = 0, arrayIndexes: number[] = []): iFormTree[] => {
    const ret: iFormTree[] = []

    uiSchemas.forEach((uiSchema) => {
      if (uiSchema.type === ELEMENT_TYPE.Array && uiSchema.scope) {
        const path = scopeFullToPath(uiSchema.scope)
        const arrayLength = formArrayLengths[path] || 0

        const retArr: iFormTree[] = []
        for (let index = 0; index < arrayLength; index++) {
          const uiSchemaFull = modifUiSchemaArray(uiSchema, uiSchema.scope, index)
          const arrayIndexesAct = [...arrayIndexes, index]
          const repeatIndex = arrayLength > 1 ? index + 1 : undefined
          const formTreeElArr = uiSchemaToFormTree(uiSchemaFull, level, arrayIndexesAct, repeatIndex)
          retArr.push(formTreeElArr)
        }
        const pathsInside = merge({}, ...retArr.map((a) => a.pathsInside))
        const errorsCount = retArr.reduce((sum, a) => sum + (a.errorsCount || 0), 0)
        const isHidden = hiddenPaths?.some((p) => path.startsWith(p))
        const toInside = flatten(retArr.map((a) => a.toInside ?? []))
        ret.push({
          isArray: true,
          uiSchema: uiSchema,
          pathsInside: pathsInside,
          errorsCount: errorsCount,
          isHidden: isHidden,
          childs: retArr,
          toInside: toInside,
        })
      } else {
        const formTreeEl = uiSchemaToFormTree(uiSchema, level, arrayIndexes, undefined)
        ret.push(formTreeEl)
      }
    })

    return ret
  }

  return createFormTreePart(uiSchemas)
}
