import axios, { AxiosInstance } from 'axios'
import { getActiveKeycloak } from '../keycloak/activeKeycloak'
import store from '../redux/store'
import { logOutAction } from '../redux/user/actions'

let refreshTokenPromise: Promise<any> | null // this holds any in-progress token refresh requests
export const interceptorResponseAuthToken = (axiosApiInstance: AxiosInstance) => {
   axiosApiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // if (error.response?.status === 503) {
      //   redirectServiceUnavailable();
      //   return Promise.reject(error);
      // }
      const originalRequest = error.config

      if (error.response?.status !== 401 || originalRequest._retry) {
        return Promise.reject(error)
      }
      originalRequest._retry = true

      const keycloak = getActiveKeycloak()
      if(!keycloak){
        return Promise.reject(error)
      }

      if (!refreshTokenPromise) {
        refreshTokenPromise = keycloak.updateToken(-1)
          .catch((error) => {

            const errMsg = `Uživatel byl odhlášen. ${
              error.response?.status === 401 ? 'Relace vypršela.' : 'Chyba obnovení tokenu.'
            }`

            store.dispatch(logOutAction(keycloak, errMsg))

            return Promise.reject(error)
          })
          .finally(() => {
          refreshTokenPromise = null // clear state
        })
      }

      return refreshTokenPromise
        .then((refreshed) => {
          if (refreshed) {
            originalRequest.headers['Authorization'] = `Bearer ${keycloak.token}`
            // axiosApiInstance.defaults.headers["Authorization"] = `Bearer ${keycloak.token}`;
            return axios(originalRequest)
          } else {
            return Promise.reject(error)
          }
        })
    },
  )
}
