import React from 'react'
import { Link } from 'react-router-dom'
import AlertErrorPage from '../../components/Alert/AlertErrorPage'
import { LINKS } from '../../components/App/LINKS'

const NotFoundPage = () => {
  return (
    <AlertErrorPage
      msg={
        <>
          404 - Požadovaná stránka nebyla nalezena. <Link to={LINKS.home}>Návrat na úvodní stránku</Link>.
        </>
      }
    />
  )
}

export default NotFoundPage
