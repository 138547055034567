import Keycloak from 'keycloak-js'
import { toast } from 'react-toastify'
import { setActiveKeycloak } from '../../keycloak/activeKeycloak'
import { AppThunk } from '../store'
import { clearUser, setUser, setUserLoading } from './userSlice'

export const loadUserAction = (keycloak: Keycloak): AppThunk => {
  return async (dispatch) => {
    if (keycloak.authenticated && keycloak.token) {
      dispatch(setUserLoading(true))
      keycloak
        .loadUserProfile()
        .then((data) => {
          dispatch(setUser(data as any))
          setActiveKeycloak(keycloak)
        })
        .catch((e) => {
          toast.error('Chyba ziskání informací o uživatli')
        })
        .finally(() => dispatch(setUserLoading(false)))
    } else {
      dispatch(setUserLoading(false))
    }
  }
}

export const logOutAction = (keycloak: Keycloak, errMsg?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setUserLoading(true))

    dispatch(clearUser())
    // tokensClear()

    keycloak.logout({ redirectUri: window.location.origin }).then(() => {
      dispatch(setUserLoading(false))
    })

    if (errMsg) {
      toast.error(errMsg)
    } else {
      toast.success('Uživatel byl odhlášen')
    }
  }
}
