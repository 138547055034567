import React from 'react'
import { useModal } from '../../hooks/useModal'

interface iModalWrap {
  type: string
  title: string
  children?: React.ReactNode
}

const ModalWrapGov = ({ type, title, children }: iModalWrap) => {
  const { open, closeModal } = useModal()

  if (open !== type) {
    return null
  }

  return (
    <div id={'modal-' + type} className={'gov-modal is-active is-visible'}>
      <div className='gov-modal__content'>
        <div className='gov-modal__header'>
          <h2> {title} </h2>
        </div>
        <div className='gov-modal__content-inner'>{children}</div>
        <button
          className='gov-button gov-button--secondary gov-modal__close'
          onClick={closeModal}
          aria-labelledby='modal-close'
        >
          <span id='modal-close' className='u-sr-only gov-button__label'>

            Zavřít modální okno
          </span>
        </button>
      </div>
      <div className='gov-modal__backdrop' onClick={closeModal}></div>
    </div>
  )
}

export default ModalWrapGov
