import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LINKS } from '../../components/App/LINKS'
import ButtonGov from '../../components/Btns/ButtonGov'
import ButtonGovLink from '../../components/Btns/ButtonGovLink'
import { WORKFLOW_PLACES } from '../../enums/enumWorkflowPlaces'
import { useModal } from '../../hooks/useModal'

interface iActionBtns {
  formId: string
  workflowPlaceCode: string
  isV1: boolean
}

// kvuli hover efektu pres disable tlacitko - zavre popup + zruseni odkazu na link
const DisabledBtn = ({ children }: { children: React.ReactNode }) => (
  <span className='gov-button gov-button--small gov-button--disabled'>{children}</span>
)

const ActionBtns = ({ formId, workflowPlaceCode, isV1 }: iActionBtns) => {
  const { openModalCopyForm, openModalConfirmCancel, openModalConfirmReopen, openModalDeleteForm } = useModal()

  const isDraft = workflowPlaceCode === WORKFLOW_PLACES.ROZPRACOVANO
  const isCancel = workflowPlaceCode === WORKFLOW_PLACES.ZRUSENO
  const isCopyAble = !isDraft && !isCancel && !isV1

  const [show, setShow] = useState(false)

  const openModalCancel = (formId: string) => {
    openModalConfirmCancel(formId)
  }

  const openModalReopen = (formId: string) => {
    openModalConfirmReopen(formId)
  }

  return (
    <div onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)}>
      <Link to={''} className='gov-link gov-link--has-arrow'>
        Akce
      </Link>
      <div style={{ position: 'absolute' }}>
        {show && (
          <div className='tableActionDropdown'>
            {isDraft ? (
              <ButtonGovLink disabled={!isDraft} size='small' to={LINKS.vyhledatFormular + '/' + formId}>
                Upravit
              </ButtonGovLink>
            ) : (
              <DisabledBtn>Upravit</DisabledBtn>
            )}

            {!isDraft ? (
              <ButtonGovLink disabled={isDraft} size='small' to={LINKS.vyhledatFormular + '/' + formId}>
                Zobrazit
              </ButtonGovLink>
            ) : (
              <DisabledBtn>Zobrazit</DisabledBtn>
            )}
            {isDraft ? (
              <ButtonGov size='small' onClick={() => openModalCancel(formId)}>
                Zrušit
              </ButtonGov>
            ) : (
              <DisabledBtn>Zrušit</DisabledBtn>
            )}

            {isCancel && !isV1 ? (
              <ButtonGov size='small' onClick={() => openModalReopen(formId)}>
                Obnovit
              </ButtonGov>
            ) : (
              <DisabledBtn>Obnovit</DisabledBtn>
            )}
            {isCopyAble ? (
              <ButtonGov size='small' onClick={() => openModalCopyForm(formId)}>
                Kopírovat
              </ButtonGov>
            ) : (
              <DisabledBtn>Kopírovat</DisabledBtn>
            )}

            {isDraft ? (
              <ButtonGov size='small' onClick={() => openModalDeleteForm(formId)}>
                Smazat
              </ButtonGov>
            ) : (
              <DisabledBtn>Smazat</DisabledBtn>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ActionBtns
