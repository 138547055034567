import { iValidationResponse } from '../../api/Validation2Api'
import {
  setForbiddenFields,
  setFormErrors,
  setMandatoryFields,
  setOpenErrorPanel,
  setReqErrors,
} from '../../redux/formErrors/formErrorsSlice'
import { AppThunk } from '../../redux/store'
import { selectUiSchemaScopeOrder, selectUiSchemaScopeOrderReq } from '../../redux/submissions/selectors'
import { proccessErrors, proccessFields } from './processErrors'

export const processValidateResponse = (validResponse: iValidationResponse): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()

    const scopeOrder = selectUiSchemaScopeOrder(state)
    const scopeOrderReq = selectUiSchemaScopeOrderReq(state)

    const { seznamChybFormular, seznamChybZadost, povinneFieldy, zakazaneFieldy } = validResponse

    const formErrors = proccessErrors(seznamChybFormular ?? [], scopeOrder)
    const reqErrors = proccessErrors(seznamChybZadost ?? [], scopeOrderReq)

    const mandatoryFields = proccessFields(povinneFieldy)
    const forbiddenFields = proccessFields(zakazaneFieldy)

    if (forbiddenFields.length) {
      dispatch(setForbiddenFields(forbiddenFields))
    }
    if (mandatoryFields.length) {
      dispatch(setMandatoryFields(mandatoryFields))
    }

    dispatch(setFormErrors(formErrors))
    dispatch(setReqErrors(reqErrors))
    dispatch(setOpenErrorPanel(true))
  }
}
