import axios from 'axios'
import { iValidationFieldSimple } from './Validation2Api'

const API_URL = process.env.REACT_APP_VISIBLE_FIELDS_API_URL

interface iResHiddenFields {
  skryteFieldy: iValidationFieldSimple[]
}

class VisibleFieldsApi {
  api = axios.create({
    baseURL: API_URL,
  })

  hiddenFields = (formId: string) => this.api.get<iResHiddenFields>(`/skryte-fieldy/${formId}`).then((res) => res.data)
}

export default new VisibleFieldsApi()
