import { LabelProps, rankWith, uiTypeIs } from '@jsonforms/core'
import React from 'react'
import { ELEMENT_ID_SUFIX_LABEL } from '../../utils/scrollToPathV1'
import { withJsonFormsLabelPropsCustom } from '../../core/withJsonFormsLabelPropsCustom'
import { UiElTypesVisual } from '../uiElTypes'

export interface CustomLabelProps extends LabelProps {
  required?: boolean
  errors?: string
  uiOptions?: any
}

const GovLabelRenderer = ({ text, visible, required, errors, path }: CustomLabelProps) => {
  if (!visible) {
    return null
  }
  return (
    <div id={path ? path + ELEMENT_ID_SUFIX_LABEL : undefined}>
      <strong className={errors ? 'error-ref-element' : ''} style={{ display: 'block', paddingBottom: 10 }}>
        {text}
        {required && '*'}
      </strong>
    </div>
  )
}

export default withJsonFormsLabelPropsCustom(GovLabelRenderer)

export const govLabelRendererTester = rankWith(1000, uiTypeIs(UiElTypesVisual.Label))
