import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const GovInputCheckbox = (props: ControlProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
  } = props

  const change = (value: boolean) => {
    handleChange(path, value ? 'true' : 'false')
  }

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov uischema={uischema} path={path} error={errors} description={description} custom>
      <DebugElement data={props} />
      <input
        id={elId}
        className='gov-form-control__checkbox'
        type='checkbox'
        disabled={!enabled}
        checked={data === 'true'}
        onChange={(e: any) => change(e.target.checked)}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={!enabled ? 'true' : 'false'}
        aria-labelledby={elId}
      />
      <InputGovLabel inputId={elId} label={label} required={required} withIndicator />
    </WrapInputGov>
  )
}

export default withJsonFormsControlPropsCustom(GovInputCheckbox)

export const govInputCheckBooleanTester = rankWith(10, uiTypeIs(UiElTypesInput.CheckboxBoolean))
