import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import EformsApi from '../api/EformsApi'
import { useFilterContext } from '../context/FilterContext'
import { selectReloadFlag } from '../redux/global/selectors'
import { FILTER_FORM } from '../tables/TableContractsAdmin/FilterContractsAdmin'
import { createFilterParams, createOrderParams } from '../utils/createParams'
import { hash } from '../utils/hash'

let LASTPARAMHASH: number
let LASTTOTAL: number
export const useFetchFormsAdmin = <T>() => {
  const [data, setData] = useState<T>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [maxItems, setMaxItems] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const { sort, activeFilters, page, limit, isEmpty } = useFilterContext()
  const reloadFlag = useSelector(selectReloadFlag)

  const fetchData = useCallback(() => {
    const { [FILTER_FORM]: formSlug, ...othersFilters } = activeFilters
    if (!formSlug) {
      setData(undefined)
      return
    }

    setError('')
    setLoading(true)
    const filterParams = createFilterParams(othersFilters)
    const filterOrderParams = createOrderParams(sort.field, sort.order)

    const params = { ...filterParams, ...filterOrderParams }

    EformsApi.getSubmissionsSearch(formSlug, page, limit, params)
      .then((result) => {
        setData(result.data)
        setMaxItems(result.maxItems)
      })
      .catch((error) => {
        setError(error.toString())
      })
      .finally(() => {
        setLoading(false)
      })

    const paramsHash = hash(JSON.stringify({ [formSlug]: params }))
    if (LASTPARAMHASH === paramsHash) {
      if (totalCount !== LASTTOTAL) {
        setTotalCount(LASTTOTAL)
      }
    } else {
      EformsApi.getSubmissionsSearch(formSlug, undefined, 0, params)
        .then((result) => {
          setTotalCount(result.totalCount)
          LASTPARAMHASH = paramsHash
          LASTTOTAL = result.totalCount
        })
        .catch((error) => {
          setError(error.toString())
        })
    }
  }, [page, limit, sort, activeFilters])

  const firstRender = useRef(true)

  useEffect(() => {
    if (!firstRender.current || !isEmpty) {
      fetchData()
    } else {
      firstRender.current = false
    }
  }, [fetchData, reloadFlag])

  return { data, loading, error, totalCount, fetchData, maxItems: totalCount < maxItems ? totalCount : maxItems }
}
