import { iUIschema } from '../interfaces/iUiSchema'
import { iUiSchemaRule } from '../interfaces/iUiSchemaRule'

export const convertUiSchemaInheritRules = (uiSchema: iUIschema, parentRules?: iUiSchemaRule[]): iUIschema => {
  const allRules = [...(parentRules ? parentRules : [])]
  if (uiSchema.rule) {
    allRules.push(uiSchema.rule)
  }
  return {
    ...uiSchema,
    elements: uiSchema.elements ? uiSchema.elements.map((el) => convertUiSchemaInheritRules(el, allRules)) : undefined,
    allRules: allRules?.length ? allRules : undefined,
  }
}
