import { ControlProps, or, rankWith, uiTypeIs } from '@jsonforms/core'
import moment from 'moment/moment'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import {
  DATE_FORMAT_DATEPICKER_SHORT,
  DATE_FORMAT_DATEPICKER_TIME,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_TIME,
} from '../../../utils/dateFormat'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const GovInputDate = (props: ControlProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
  } = props

  const [openLabel, setOpenLabel] = useState(false)

  const isTime = (uischema as any).type === UiElTypesInput.Time ? true : undefined

  const change = (value?: string) => {
    handleChange(path, value)
  }

  if (!visible) {
    return null
  }

  const dateValue = data ? (isTime ? moment(data, 'HH:mm') : moment(data)) : undefined
  const value = dateValue?.isValid() ? dateValue?.toDate() : undefined

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov
      uischema={uischema}
      value={data}
      description={description}
      error={errors}
      path={path}
      openLabel={openLabel}
    >
      <DebugElement data={props} />
      <DatePicker
        timeCaption={'Čas'}
        showTimeSelect={isTime}
        showTimeSelectOnly={isTime}
        timeIntervals={isTime ? 5 : undefined}
        isClearable={enabled}
        dateFormat={isTime ? DATE_FORMAT_DATEPICKER_TIME : DATE_FORMAT_DATEPICKER_SHORT}
        disabled={!enabled}
        onChange={(date) => {
          // const dateString = date ? moment(date).toISOString(true).split('T') : ''
          const dateString = date ? moment(date).format(isTime ? DATE_FORMAT_TIME : DATE_FORMAT_SHORT) : undefined
          change(dateString)
        }}
        selected={value}
        id={elId}
        className='gov-form-control__input'
        onFocus={() => {
          setOpenLabel(true)
        }}
        onBlur={() => {
          setOpenLabel(false)
        }}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={!enabled ? 'true' : 'false'}
        aria-labelledby={elId}
        autoComplete='off'
      />
      <InputGovLabel label={label} inputId={elId} required={required} />
    </WrapInputGov>
  )
}

export default withJsonFormsControlPropsCustom(GovInputDate)

export const govInputDateTester = rankWith(3, or(uiTypeIs(UiElTypesInput.Date), uiTypeIs(UiElTypesInput.Time)))
