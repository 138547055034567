import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iEformsFormSchema } from '../../interfaces/iEformsFormSchema'
import { iEformsSubmission, iSubmissionDataHlavni } from '../../interfaces/iEformsSubmission'

interface iSubmsState {
  submMain: iSubmissionDataHlavni // | iSubmissionDataProfil
  submForm: iEformsSubmission<any>
  submReq: iEformsSubmission<any>
  formSchema?: iEformsFormSchema
  formSchemaReq?: iEformsFormSchema
  versionForm: number
  versionReq: number
  loading: boolean
}

const slice = createSlice({
  name: 'submissions',
  initialState: {
    submMain: {},
    submForm: {},
    submReq: {},
    formSchema: undefined,
    formSchemaReq: undefined,
    loading: false,
  } as iSubmsState,
  reducers: {
    setSubmMain(state, action: PayloadAction<iEformsSubmission<any>>) {
      state.submMain = action.payload
    },
    setSubmForm(state, action: PayloadAction<iEformsSubmission<any>>) {
      state.submForm = action.payload
    },
    setSubmReq(state, action: PayloadAction<iEformsSubmission<any>>) {
      state.submReq = action.payload
    },
    setFormSchema(state, action: PayloadAction<iEformsFormSchema>) {
      state.formSchema = action.payload
    },
    setFormSchemaReq(state, action: PayloadAction<iEformsFormSchema>) {
      state.formSchemaReq = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setFormVersion(state, action: PayloadAction<number>) {
      state.versionForm = action.payload
    },
    setReqVersion(state, action: PayloadAction<number>) {
      state.versionReq = action.payload
    },
    clearSubmissions(state) {
      state.submMain = {} as any
      state.submForm = {} as any
      state.submReq = {} as any
      state.formSchema = undefined
      state.formSchemaReq = undefined
      state.loading = false
      state.versionForm = 0
      state.versionReq = 0
    },
  },
})

export const {
  setSubmMain,
  setSubmForm,
  setSubmReq,
  setFormSchema,
  setFormSchemaReq,
  setLoading,
  clearSubmissions,
  setFormVersion,
  setReqVersion,
} = slice.actions
export default slice.reducer
