import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectFormTree, selectFormTreeReq } from '../../redux/formTree/formTreeSelectors'
import { treeIndexesToPath } from '../../utils/formTreeSibling'
import { useIndexesContext } from '../context/IndexesContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { iFormTree } from '../utils/createFormTree'

const SectionHeaderErrors = () => {
  const { isReq } = useFormRenderContext()
  const formTree = useSelector(isReq ? selectFormTreeReq : selectFormTree)
  const indexes = useIndexesContext().indexes

  const treePath = treeIndexesToPath(indexes)
  const formTreeEl: iFormTree | undefined = get(formTree, treePath)

  if (!formTreeEl?.errorsCount) {
    return null
  }
  return <span className='err-count-header'>{formTreeEl.errorsCount}</span>
}

export default SectionHeaderErrors
