import { toast } from 'react-toastify'
import SendFormApi from '../api/SendFormApi'
import GlobalFormsStore from '../builder/GlobalFormsStore'
import { clearFormAndReqErrorsState } from '../redux/formErrors/formErrorsSlice'
import { setFormActionLoading, setFormResponseSent } from '../redux/formState/formStateSlice'
import { selectFormUserFormName } from '../redux/formState/selectors'
import { AppThunk } from '../redux/store'
import { selectSubmMainInfo, selectSumbIds } from '../redux/submissions/selectors'
import { selectUserName } from '../redux/user/selectors'
import { saveSubmissionParts } from './saveSubmissionParts'
import { processValidateResponse } from './utils/processValidateResponse'

export const sendSubmission = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(setFormActionLoading(true))
    dispatch(clearFormAndReqErrorsState())
    const userFormName = selectFormUserFormName(state)
    const username = selectUserName(state)

    const { submMainId } = selectSumbIds(state)

    const formData = GlobalFormsStore.getEditFormData()
    const reqData = GlobalFormsStore.getReqFormData()
    const { submMainData } = selectSubmMainInfo(state)

    saveSubmissionParts(state)
      .then((res) => {
        toast.success('Formulář uložen před odesláním')
        const hashForm = res[0]?.dataHash
        const hashReq = res[1]?.dataHash
        // TODO posilat data co se vratila z eforms?
        SendFormApi.senForm(
          submMainId,
          userFormName,
          username,
          formData,
          reqData,
          hashForm,
          hashReq,
          submMainData.formularOpravuje,
        )
          .then((res) => {
            toast.success('Formulář odeslán')
            dispatch(setFormResponseSent(res))
          })
          .catch((err) => {
            const code = err.response?.status
            if (code === 401) {
              toast.error('Uživatel není přihlášen.')
            } else if (code === 403) {
              toast.error('IEN není aktivní či registrováný ve VVZ.')
            } else if (code === 409) {
              toast.error('Formulář je již zpracován. Prosím obnovte stránku.')
            } else if (code === 422) {
              toast.error('Data obsahují validační chyby.')
              const validErrors = err.response.data
              dispatch(processValidateResponse(validErrors))
            } else if (code === 500) {
              toast.error('Nepodařilo se provést validaci a odeslat formulář.')
            } else {
              toast.error('Neznámá chyba odeslání formuláře')
            }
          })
          .finally(() => dispatch(setFormActionLoading(false)))
      })
      .catch(() => dispatch(setFormActionLoading(false)))
  }
}
