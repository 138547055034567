import isArray from 'lodash/isArray'
import mergeWith from 'lodash/mergeWith'

export const defaultDeepNoArray = (data: any, defaults: any) => {
  return mergeWith(isArray(data) ? [] : {}, defaults, data, (defVal, dataVal) => {
    if (isArray(dataVal)) { // pokud je v datech pole tak zustane jak je
      return dataVal
    }
    // if (isArray(defVal)) { // pokud je v defaultech pole tak se vlozi jen kdyz neni neni nic v datech nebo je prazdne pole
    //   return dataVal?.length ? dataVal : defVal
    // }
  })
}
