import GovLayoutArrayRender, { govLayoutArrayTester } from './GovArray'
import GovArrayInlineRender, { govLayoutArrayInlineTester } from './GovArrayInline'
import GovFormRootRender, { govFormRootTester } from './GovFormRoot'
import GovLayoutGroupRender, { govLayoutGroupTester } from './GovGroup'
import GovLayoutGroupInnerRender, { govLayoutGroupInnerTester } from './GovGroupInner'
import GovHiddenGroupRender, { govHiddenGroupTester } from './GovHiddenGroup'
import GovLayoutRowRender, { govLayoutRowTester } from './GovRow'

export const rederersLayout = [
  { renderer: GovFormRootRender, tester: govFormRootTester },
  { renderer: GovLayoutRowRender, tester: govLayoutRowTester },
  { renderer: GovLayoutGroupRender, tester: govLayoutGroupTester },
  { renderer: GovLayoutGroupInnerRender, tester: govLayoutGroupInnerTester },
  { renderer: GovLayoutArrayRender, tester: govLayoutArrayTester },
  { renderer: GovArrayInlineRender, tester: govLayoutArrayInlineTester },
  { renderer: GovHiddenGroupRender, tester: govHiddenGroupTester },
]
