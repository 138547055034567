import React from 'react'
import FormBaseSchemas from '../../builder/forms/FormBaseSchemas'
import { iDataSchemas } from '../../builder/interfaces/iDataSchema'
import { iUIschema } from '../../builder/interfaces/iUiSchema'
import { ENV } from '../../ENV'
import uiSchemas from './schema.layout.json'
import dataSchemas from './schema.properties.json'

const WizardForm = () => {
  return (
    <div className='wizard-form'>
      <FormBaseSchemas
        dataSchemas={dataSchemas as iDataSchemas}
        uiSchemas={uiSchemas as iUIschema[]}
        formSchemaId={ENV.EFORM_ZADOST_SCHEMA_ID}
        validationMode={'all'}
      />
    </div>
  )
}

export default WizardForm
