import { iUIschema } from '../interfaces/iUiSchema'
import { translatePath } from '../translate/translate'
import { getTranlateLabelFullKey, getTranlateTextFullKey } from '../utils/getTranslateUiSchema'

export interface iScopeToLabels {
  [scope: string]: string[]
}

/*
rekurzivne najde v celem ui schematu mapovani vsechny scope => [labels]
prelozi dle aktualniho formulare
hleda vsechny label atributy pro uielementy se scope a vsechny text atributy pro uielementy se scopeRef
pouziva se ve vypisu validacnich chyb
 */
export const findAllScopeToLabels = (formType: string, uischema?: iUIschema): iScopeToLabels => {
  if (!uischema) {
    return {}
  }

  const ret: any = {}

  uischema.elements?.forEach((el) => {
    const childLabels = findAllScopeToLabels(formType, el)
    for (const scop in childLabels) {
      if (!ret[scop]) {
        ret[scop] = []
      }
      ret[scop].push(...childLabels[scop])
    }
  })

  if (uischema.scope) {
    const scope = uischema.scope
    if (!ret[scope]) {
      ret[scope] = []
    }
    const translateFullKeyLabel = getTranlateLabelFullKey(uischema)
    if (uischema.label !== false) {
      const label = uischema.label !== undefined ? uischema.label : translatePath(formType)(translateFullKeyLabel)
      ret[scope].push(label)
    }
  } else if (uischema.scopeRef) {
    const scope = uischema.scopeRef
    if (!ret[scope]) {
      ret[scope] = []
    }
    const translateFullKeyText = getTranlateTextFullKey(uischema)
    const text = uischema.text !== undefined ? uischema.text : translatePath(formType)(translateFullKeyText)
    ret[scope].push(text)
  }

  return ret
}
