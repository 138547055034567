import { AxiosInstance } from 'axios'
import { getActiveKeycloak } from '../keycloak/activeKeycloak'

export const interceptorRequestAuthToken = (axiosApiInstance: AxiosInstance) => {
  axiosApiInstance.interceptors.request.use((config) => {
    const token = getActiveKeycloak()?.token

    if (token && config.headers?.['Authorization'] !== 'none') {
      // if (!config.headers) {
      //   config.headers = {}
      // }
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  })
}
