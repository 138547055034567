const CDZ_URL = process.env.REACT_APP_CDZ_URL!

export const LINKS = {
  home: '/',
  admin: '/admin',

  aktuality: '/aktuality-a-informace',
  informace: '/uzitecne-informace',
  mojeVyhlaseni: '/moje-vyhlaseni',
  podatFormular: '/podat-formular',
  vyhledatFormular: '/vyhledat-formular',
  textFormular: '/text-formular',
  vyhledatProfil: '/vyhledat-profil',
  napoveda: '/napoveda-dokumentace',
  napovedaMetodickePokyny: '/napoveda-dokumentace/metodicke-pokyny',
  formulareZakazky: '/formulare-zakazky',
  formulareProfilu: '/formulare-profilu',

  noveVsechna: '/nove-vsechna-uverejneni',
  noveZakazky: '/nove-zakazky',
  noveZruseni: '/nove-zruseni-zmeny',
  noveVitezove: '/nove-vitezove-zakazek',

  stornovane: '/stornovane-formulare',

  cdzMujUcet: CDZ_URL + 'muj-ucet',
  cdzRegistrace: CDZ_URL + 'registrace',

  notFound404: '/404-not-found'
}
