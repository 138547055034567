import React from 'react'
import { useElementContext } from '../../context/ElementContext'

const TITLE_LEVEL = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
}
const Title = () => {
  const { text, uiSchema } = useElementContext()

  const titleLevel = uiSchema.options?.titleLevel

  const attrs = {
    className: 'u-mb--10',
    style: { whiteSpace: 'pre-wrap' } as any,
  }

  if (titleLevel === TITLE_LEVEL.H1) {
    return <h1 {...attrs}>{text}</h1>
  }
  if (titleLevel === TITLE_LEVEL.H2) {
    return <h2 {...attrs}>{text}</h2>
  }
  if (titleLevel === TITLE_LEVEL.H3) {
    return <h3 {...attrs}> {text} </h3>
  }

  return <h4 {...attrs}>{text}</h4>
}

export default Title
