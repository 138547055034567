import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectUserHasFullAccess } from '../../redux/user/selectors'
import AlertErrorPage from '../Alert/AlertErrorPage'
import { LINKS } from './LINKS'

const FREE_PAGES = [LINKS.napoveda, LINKS.aktuality, LINKS.informace, LINKS.admin]

const ProtectOrgUser = ({ children }: { children: React.ReactNode }) => {
  const hasFullAccess = useSelector(selectUserHasFullAccess)
  const { pathname } = useLocation()
  const isFreePage = pathname === LINKS.home || FREE_PAGES.some((link) => pathname.startsWith(link))

  if (hasFullAccess || isFreePage) {
    return <>{children}</>
  }

  return (
    <div style={{ maxWidth: 600, margin: '50px auto 50px auto' }}>
      <AlertErrorPage msg='Je nám líto, ale nemáte oprávnění zobrazit stránku.' />
    </div>
  )
}

export default ProtectOrgUser
