import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ButtonGov from '../../../components/Btns/ButtonGov'
import { selectUserName, selectUserSubjekt } from '../../../redux/user/selectors'
import IamModel from '../../../services/ModelSimpleIam'
import { extractCopyMap } from '../../../utils/extractCopyMap'
import { scopeFullToPath } from '../../../utils/scopeModifs'
import { useElementContext } from '../../context/ElementContext'
import { useBtnLoadClear } from '../../hooks/useBtnLoadClear'

const BtnLoadProfile = () => {
  const { setValue } = useFormContext()
  const { label, uiSchema, disabled } = useElementContext()
  const { clearRepeatElement } = useBtnLoadClear()
  const { buttonDestinationNodeId, buttonCopyMap } = uiSchema.options || {}

  const [loading, setLoading] = useState(false)

  const subjekt = useSelector(selectUserSubjekt)
  const username = useSelector(selectUserName)

  const basePath = scopeFullToPath(buttonDestinationNodeId)

  const clickLoadProfile = () => {
    if (!buttonCopyMap) {
      return
    }
    setLoading(true)
    IamModel.iamDataByName(username, subjekt)
      .then((iamData) => {
        clearRepeatElement(basePath)
        extractCopyMap(buttonCopyMap, basePath).forEach(({ fromPath, toPath, deletePath }) => {
          if (deletePath) {
            setValue(deletePath, [])
          }
          const val = iamData[fromPath as keyof typeof iamData] ?? ''
          setValue(toPath, val)
        })
        toast.success('Název a adresa načteny z profilu uživatele a organizace')
      })
      .catch((err) => {
        toast.error('Nepodařilo se načíst data z profilu')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (disabled) {
    return null
  }

  return (
    <div className='btnInlineWrap'>
      <ButtonGov variant='primary-green' size='small' loading={loading} onClick={clickLoadProfile}>
        {label || 'Předvyplnit údaje podle profilu'}
      </ButtonGov>
    </div>
  )
}

export default BtnLoadProfile
