import { createAjv } from '@jsonforms/core'

const ajv = createAjv({
  allErrors: true,
  verbose: true,
  // useDefaults: true // nastavuje default hodnoty ze schema
  // schemaId:'',
})

export const SCHEMA_DATA_FORMAT = {
  date: 'date',
  time: 'time',
  uri: 'uri',
  email: 'email',
  //custom
  tel: 'tel',
  ico: 'ico',
}

ajv.addFormat(SCHEMA_DATA_FORMAT.time, /^[0-2][0-9]:[0-6][0-9]$/)
ajv.addFormat(SCHEMA_DATA_FORMAT.tel, /^\+[0-9]{1,3} [0-9 ]{1,} ?(\/ ?\+[0-9]{1,3} [0-9 ]{1,})?$/)
ajv.addFormat(SCHEMA_DATA_FORMAT.ico, /^[a-zA-Z0-9]{1,100}$/)

export default ajv
