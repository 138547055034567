import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LINKS } from '../../../components/App/LINKS'
import ButtonGov from '../../../components/Btns/ButtonGov'
import LoadingInline from '../../../components/Loading/LoadingInline'
import { FORM_TYPES_V2_NAMES, formTypeToCodeLabel } from '../../../enums/enumFormTypeV2'
import { getNextTypesActivityWizard } from '../../../enums/enumPrevFormWizard'
import { FormsCreator } from '../../../services/FormsCreator'
import { getErrorMessage } from '../../../utils/errorMessage'
import { scopeFullToPath } from '../../../utils/scopeModifs'
import { useElementContext } from '../../context/ElementContext'
import { useFormRenderContext } from '../../context/RenderFormContext'

const BtnSearchFormPrev = () => {
  const { watch } = useFormContext()
  const { setLoadingAction, loadingAction } = useFormRenderContext()
  const { text, uiSchema } = useElementContext()

  const { btnSearchMapping } = uiSchema.options || {}

  const btnSearchMappingPaths = btnSearchMapping?.map(scopeFullToPath) || []
  const [evCisloZakazky, evCisloFormulare, kodSouvisFormulare, activity] = watch(btnSearchMappingPaths)

  const navigate = useNavigate()

  const [prevFormType, setPrevFormType] = useState('')
  const [newFormTypes, setNewFormTypes] = useState<string[]>([])

  useEffect(() => {
    setPrevFormType('')
    setNewFormTypes([])
  }, [evCisloZakazky, evCisloFormulare, kodSouvisFormulare, activity])

  const searchForm = async () => {
    if (!evCisloFormulare || !evCisloZakazky || !kodSouvisFormulare) {
      toast.error('Vyplňte všechny potřebné údaje')
      return
    }

    setLoadingAction(true)

    try {
      const formCreator = new FormsCreator('')
      formCreator.setPrevNumbers(evCisloFormulare, evCisloZakazky, kodSouvisFormulare)
      await formCreator.loadPrevMain()
      await formCreator.loadPrevReq()
      const prevFormType = formCreator.getPrevFormType()

      setPrevFormType(prevFormType)

      const newFormTypes = getNextTypesActivityWizard(activity, prevFormType)

      setNewFormTypes(newFormTypes)
      if (!newFormTypes.length) {
        toast.error('Ve zvoleném typu aktivity neexistuje typ formuláře pro navázání na formulář typu ' + prevFormType)
        return
      }

      toast.success('Načteno předchozí oznámení')
    } catch (e) {
      toast.error(getErrorMessage(e))
    } finally {
      setLoadingAction(false)
    }
  }

  const createForm = async (formType: string) => {
    setLoadingAction(true)
    try {
      const formsCreator = new FormsCreator(formType)
      await formsCreator.createEmptyFormData()

      formsCreator.setPrevNumbers(evCisloFormulare, evCisloZakazky, kodSouvisFormulare)
      await formsCreator.loadPrevAllData()

      const formId = await formsCreator.createForms()
      navigate(LINKS.vyhledatFormular + '/' + formId)
    } catch (e) {
      toast.error(getErrorMessage(e))
    } finally {
      setLoadingAction(false)
    }
  }

  if (loadingAction) {
    return <LoadingInline />
  }

  const disabledSearch = !!newFormTypes.length || !evCisloFormulare || !evCisloZakazky || !kodSouvisFormulare

  return (
    <>
      <div className='u-mb--30'>
        <ButtonGov variant='primary' disabled={disabledSearch} onClick={searchForm}>
          {text}
        </ButtonGov>

        {prevFormType && <div>(Vyhledaný formulář je typu {prevFormType})</div>}
      </div>

      {newFormTypes.map((formType) => (
        <div key={formType} className='u-mb--20'>
          <ButtonGov variant='primary' onClick={() => createForm(formType)}>
            Navázat formulářem &nbsp;
            <small>
              "{formTypeToCodeLabel(formType)} - {FORM_TYPES_V2_NAMES[formType]}"
            </small>
          </ButtonGov>
        </div>
      ))}
    </>
  )
}

export default BtnSearchFormPrev
