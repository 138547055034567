import { ArrayLayoutProps, composePaths, rankWith, uiTypeIs } from '@jsonforms/core'
import { UISchemaElement } from '@jsonforms/core/src/models/uischema'
import { JsonFormsDispatch } from '@jsonforms/react'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import ButtonGov from '../../../components/Btns/ButtonGov'
import { selectSubmMainInfo } from '../../../redux/submissions/selectors'
import { convertUiSchemaArray } from '../../convertors/convertUiSchemaArray'
import { withJsonFormsArrayLayoutPropsCustom } from '../../core/withJsonFormsArrayLayoutPropsCustom'
import DebugElement from '../../DebugElement'
import { iUIschema } from '../../interfaces/iUiSchema'
import WrapBlockGov from '../../components/WrapBlockGov'
import { UiElTypesLayout } from '../uiElTypes'

export const GovArrayRenderer = (props: ArrayLayoutProps) => {
  const {
    visible,
    enabled,
    uischema,
    schema,
    label,
    renderers,
    cells,
    data,
    path,
    errors,
    addItem,
    removeItems,
  } = props

  const readOnly = !useSelector(selectSubmMainInfo).isDraft

  const defaultItemValue = {}

  const addItemCb = useCallback(
    (p: string, value: any) => {
      return addItem(p, value)()
    },
    [addItem],
  )

  const removeItemsCb = useCallback(
    (path: string, toDelete: number[]) => {
      return removeItems ? removeItems(path, toDelete)() : () => {}
    },
    [removeItems],
  )

  const elements: UISchemaElement[] = (uischema as any).elements
  const options = (uischema as iUIschema).options
  const minUiItems = options?.minItems || 0
  const maxUiItems = options?.maxItems || undefined
  const arrayVirtual = options?.arrayVirtual || undefined
  const isFull = maxUiItems && data >= maxUiItems

  if (!visible) {
    return null
  }

  const len = (enabled || data) ? data : minUiItems
  const toRender = Array(len)
    .fill(0)
    .map((_, indexRepeater) => {
      return (
        <div key={indexRepeater}>
          <div className={arrayVirtual ? '' : 'custom-form u-mb--30'}>
            {label && <h2 className='custom-form__title gov-title--delta'>{label}</h2>}
            {indexRepeater >= minUiItems && enabled && (
              <div style={{ textAlign: 'right', marginTop: -20, marginBottom: 20 }}>
                <ButtonGov variant='primary' onClick={() => removeItemsCb(path, [indexRepeater])}>
                  Odstranit
                </ButtonGov>
              </div>
            )}
            {elements.map((child, index) => (
              <JsonFormsDispatch
                schema={schema}
                uischema={convertUiSchemaArray(child as iUIschema, uischema.scope, indexRepeater) as any}
                path={composePaths(path, `${indexRepeater}`)}
                enabled={enabled}
                renderers={renderers}
                cells={cells}
                key={index}
              />
            ))}
          </div>
        </div>
      )
    })
  return (
    <WrapBlockGov path={path} error={errors}>
      <DebugElement data={props} />
      {toRender}
      {!isFull && !readOnly && (
        <div className='u-mb--30'>
          <ButtonGov disabled={!enabled} variant='primary' onClick={() => addItemCb(path, defaultItemValue)}>
            Vložit další
          </ButtonGov>
        </div>
      )}
    </WrapBlockGov>
  )
}

export default withJsonFormsArrayLayoutPropsCustom(GovArrayRenderer)

export const govLayoutArrayTester = rankWith(1000, uiTypeIs(UiElTypesLayout.Array))

