import { GroupLayout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React from 'react'
import { withJsonFormsLayoutPropsCustom } from '../../core/withJsonFormsLayoutPropsCustom'
import DebugElement from '../../DebugElement'
import { UiElTypesLayout } from '../uiElTypes'

const GovLayoutRowRender = (props: LayoutProps) => {
  const { schema, uischema, renderers, enabled, visible, path, cells } = props

  const groupLayout = uischema as GroupLayout
  if (!visible) {
    return null
  }
  const classes = ['custom-form__wrap']
  if (uischema.options?.rowType === 'bigSmall') {
    classes.push('custom-form__wrap--special')
  }
  if (uischema.options?.rowType === 'alignLeft') {
    classes.push('alignLeft')
  }
  if (uischema.options?.rowType === 'alignRight') {
    classes.push('alignRight')
  }

  return (
    <>
      <DebugElement data={props} />
      {/*{label}*/}
      <div className={classes.join(' ')}>
        {groupLayout.elements?.length &&
          groupLayout.elements.map((child, index) => (
            <JsonFormsDispatch
              schema={schema}
              uischema={child}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
              key={index}
            />
          ))}
      </div>
    </>
  )
}
export default withJsonFormsLayoutPropsCustom(GovLayoutRowRender)

export const govLayoutRowTester = rankWith(1000, uiTypeIs(UiElTypesLayout.Row))

