import { toast } from 'react-toastify'
import Validation2Api from '../api/Validation2Api'
import GlobalFormsStore from '../builder/GlobalFormsStore'
import { clearFormAndReqErrorsState } from '../redux/formErrors/formErrorsSlice'
import { setFormActionLoading, setIsValid } from '../redux/formState/formStateSlice'
import { selectFormUserFormName } from '../redux/formState/selectors'
import { AppThunk } from '../redux/store'
import { selectSubmMainInfo } from '../redux/submissions/selectors'
import { processValidateResponse } from './utils/processValidateResponse'

export const validateAll = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(setFormActionLoading(true))
    dispatch(clearFormAndReqErrorsState())

    const userFormName = selectFormUserFormName(state)
    if (!userFormName) {
      toast.error('Název formuláře nemůže být prázdný')
      dispatch(setFormActionLoading(false))
      return
    }

    const formData = GlobalFormsStore.getEditFormData()
    const reqData = GlobalFormsStore.getReqFormData()
    const { submMainData } = selectSubmMainInfo(state)

    Validation2Api.validateAll(formData, reqData, submMainData.formularOpravuje)
      .then((res) => {
        toast.success('Data neobsahují chyby')
        dispatch(setIsValid())
      })
      .catch((err) => {
        const code = err.response?.status
        if (code === 401) {
          toast.error('Nepřihlášený uživatel')
        } else if (code === 403) {
          toast.error('Nedostatečné oprávnění k validaci')
        } else if (code === 422) {
          toast.error('Data obsahují validační chyby')
          const validErrors = err.response.data
          dispatch(processValidateResponse(validErrors))
        } else if (code === 500) {
          toast.error('Nepodařilo se provést validaci')
        } else {
          toast.error('Neznámá chyba validace')
        }
      })
      .finally(() => dispatch(setFormActionLoading(false)))
  }
}
