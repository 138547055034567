import React from 'react'
import { useModal } from '../../hooks/useModal'
import ButtonGov from '../Btns/ButtonGov'

const ModalErrorDetail = () => {
  const { closeModal, dataErrorDetail } = useModal()

  const clickCancel = () => {
    closeModal()
  }

  return (
    <>
      <div className='u-mb--50'>
        Vážený uživateli,
        <br />
        <br />
        při validaci Vámi vyplněného formuláře, došlo k blíže nespecifikované chybě a je potřeba ji konzultovat s
        technickou podporou věstníku. Nechte si prosím toto okno s oznámením otevřené a kontaktujte nás na telefonním
        čísle <strong>+420 211 154 370</strong> kde se s vámi pokusíme tuto chybu vyřešit.
        <br />
        <br />
        <br />
        - - - - - - - - - - - - Popis chybového hlášení - - - - - - - - - - - -
        <br />
        <pre style={{whiteSpace:'break-spaces'}}>{dataErrorDetail}</pre>
        <br />
        - - - - - - - - - - - - Konec chybového hlášení - - - - - - - - - - - -
        <br />
        <br />
        <br />
        Určeno výhradně pro účel uživatelské podpory VVZ v souladu s Provozním řádem VVZ.
        <br />
        Provozovatel VVZ.
      </div>

      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zavřít
      </ButtonGov>
    </>
  )
}

export default ModalErrorDetail
