import React, { useContext } from 'react'
import { iElement } from '../interfaces/iElement'

const ElementContextBase = React.createContext({} as iElement)

export const useElementContext = () => useContext(ElementContextBase)

interface iElementContextProvider {
  element: iElement
  children: React.ReactNode
}

export const ElementContextProvider = ({ element, children }: iElementContextProvider) => {
  // console.log('change elment context', element)
  // const val = React.useMemo(() => element, [element])
  return <ElementContextBase.Provider value={element}>{children}</ElementContextBase.Provider>
}
