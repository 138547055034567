import BtnCopyData from './actions/BtnCopyData'
import BtnCreateForm from './actions/BtnCreateForm'
import BtnLoadAres from './actions/BtnLoadAres'
import BtnLoadProfile from './actions/BtnLoadProfile'
import BtnSearchFormPrev from './actions/BtnSearchFormPrev'
import BtnSearchFormRepair from './actions/BtnSearchFormRepair'
import InputArrayIdentifier from './inputs/InputArrayIdentifier'
import InputAutocomplete from './inputs/InputAutocomplete'
import InputCheckbox from './inputs/InputCheckbox'
import InputDate from './inputs/InputDate'
import InputNumber from './inputs/InputNumber'
import InputObjectIdentifier from './inputs/InputObjectIdentifier'
import InputRadio from './inputs/InputRadio'
import InputSelect from './inputs/InputSelect'
import InputSelectAutocomplete from './inputs/InputSelectAutocomplete'
import InputText from './inputs/InputText'
import InputTextArea from './inputs/InputTextArea'
import InputUpload from './inputs/InputUpload'
import InputAutocompleteTxt from './inputs_txt/InputAutocompleteTxt'
import InputCheckboxTxt from './inputs_txt/InputCheckboxTxt'
import InputDateTxt from './inputs_txt/InputDateTxt'
import InputNumberTxt from './inputs_txt/InputNumberTxt'
import InputRadioTxt from './inputs_txt/InputRadioTxt'
import InputTextAreaTxt from './inputs_txt/InputTextAreaTxt'
import InputTextTxt from './inputs_txt/InputTextTxt'
import ArrayGroup from './layout/ArrayGroup'
import Group from './layout/Group'
import Row from './layout/Row'
import ArrayGroupTxt from './layout_txt/ArrayGroupTxt'
import GroupTxt from './layout_txt/GroupTxt'
import RowTxt from './layout_txt/RowTxt'
import HtmlContent from './visual/HtmlContent'
import TextContent from './visual/TextContent'
import Title from './visual/Title'

export const ELEMENT_TYPE = {
  // FormRoot: 'FormRoot',
  // GroupInner: 'GroupInner',
  // ArrayInline: 'ArrayInline',
  Group: 'Group',
  Row: 'Row',
  Array: 'Array',

  Text: 'Text',
  Textarea: 'Textarea',
  Checkbox: 'Checkbox',
  Number: 'Number',
  // CheckboxMulti: 'CheckboxMulti',
  Radio: 'Radio',
  Select: 'Select',
  Date: 'Date',
  Upload: 'Upload',
  Autocomplete: 'Autocomplete',
  SelectAutocomplete: 'SelectAutocomplete',

  TextContent: 'TextContent',
  HtmlContent: 'HtmlContent',
  Title: 'Title',

  ArrayIdentifier: 'ArrayIdentifier',
  ObjectIdentifier: 'ObjectIdentifier',

  BtnCreateForm: 'BtnCreateForm',
  BtnSearchFormPrev: 'BtnSearchFormPrev',
  BtnSearchFormRepair: 'BtnSearchFormRepair',

  ButtonCopyAres: 'ButtonCopyAres',
  ButtonCopyOrganizationProfile: 'ButtonCopyOrganizationProfile',
  ButtonCopy: 'ButtonCopy',
}

export const ELEMENT_THEME = {
  DEFAULT: 'default',
  TXT: 'txt',
}

export const ELEMENTS_RENDER_THEME_MAP = {
  [ELEMENT_THEME.DEFAULT]: {
    [ELEMENT_TYPE.Text]: InputText,
    [ELEMENT_TYPE.Number]: InputNumber,
    [ELEMENT_TYPE.Textarea]: InputTextArea,
    [ELEMENT_TYPE.Select]: InputSelect,
    [ELEMENT_TYPE.Radio]: InputRadio,
    [ELEMENT_TYPE.Array]: ArrayGroup,
    [ELEMENT_TYPE.Row]: Row,
    [ELEMENT_TYPE.Group]: Group,
    [ELEMENT_TYPE.Checkbox]: InputCheckbox,
    [ELEMENT_TYPE.TextContent]: TextContent,
    [ELEMENT_TYPE.HtmlContent]: HtmlContent,
    [ELEMENT_TYPE.Title]: Title,
    [ELEMENT_TYPE.Date]: InputDate,
    [ELEMENT_TYPE.Upload]: InputUpload,
    [ELEMENT_TYPE.Autocomplete]: InputAutocomplete,
    [ELEMENT_TYPE.SelectAutocomplete]: InputSelectAutocomplete,
    [ELEMENT_TYPE.ArrayIdentifier]: InputArrayIdentifier,
    [ELEMENT_TYPE.ObjectIdentifier]: InputObjectIdentifier,
    [ELEMENT_TYPE.BtnCreateForm]: BtnCreateForm,
    [ELEMENT_TYPE.BtnSearchFormPrev]: BtnSearchFormPrev,
    [ELEMENT_TYPE.BtnSearchFormRepair]: BtnSearchFormRepair,
    [ELEMENT_TYPE.ButtonCopyAres]: BtnLoadAres,
    [ELEMENT_TYPE.ButtonCopyOrganizationProfile]: BtnLoadProfile,
    [ELEMENT_TYPE.ButtonCopy]: BtnCopyData,
  },
  [ELEMENT_THEME.TXT]: {
    [ELEMENT_TYPE.Text]: InputTextTxt,
    [ELEMENT_TYPE.Number]: InputNumberTxt,
    [ELEMENT_TYPE.Textarea]: InputTextAreaTxt,
    [ELEMENT_TYPE.Select]: InputAutocompleteTxt,
    [ELEMENT_TYPE.Radio]: InputRadioTxt,
    [ELEMENT_TYPE.Array]: ArrayGroupTxt,
    [ELEMENT_TYPE.Row]: RowTxt,
    [ELEMENT_TYPE.Group]: GroupTxt,
    [ELEMENT_TYPE.Checkbox]: InputCheckboxTxt,
    [ELEMENT_TYPE.TextContent]: TextContent,
    [ELEMENT_TYPE.HtmlContent]: HtmlContent,
    [ELEMENT_TYPE.Title]: Title,
    [ELEMENT_TYPE.Date]: InputDateTxt,
    [ELEMENT_TYPE.Upload]: () => {},
    [ELEMENT_TYPE.Autocomplete]: InputAutocompleteTxt,
    [ELEMENT_TYPE.SelectAutocomplete]: InputAutocompleteTxt,
    [ELEMENT_TYPE.ArrayIdentifier]: () => {},
    [ELEMENT_TYPE.ObjectIdentifier]: () => {},
    [ELEMENT_TYPE.BtnCreateForm]: () => {},
    [ELEMENT_TYPE.BtnSearchFormPrev]: () => {},
    [ELEMENT_TYPE.BtnSearchFormRepair]: () => {},
    [ELEMENT_TYPE.ButtonCopyAres]: () => {},
    [ELEMENT_TYPE.ButtonCopyOrganizationProfile]: () => {},
    [ELEMENT_TYPE.ButtonCopy]: () => {},
  },
}

export const NO_PRINT_ELEMENTS = [
  ELEMENT_TYPE.Upload,
  ELEMENT_TYPE.ArrayIdentifier,
  ELEMENT_TYPE.ObjectIdentifier,
  ELEMENT_TYPE.BtnCreateForm,
  ELEMENT_TYPE.BtnSearchFormPrev,
  ELEMENT_TYPE.ButtonCopyAres,
  ELEMENT_TYPE.ButtonCopyOrganizationProfile,
  ELEMENT_TYPE.ButtonCopy,
]
