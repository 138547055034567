import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SLUG_FORM } from '../../api/EformsApi'
import AlertError from '../../components/Alert/AlertError'
import AlertInfo from '../../components/Alert/AlertInfo'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import Paginator from '../../components/Paginator/Paginator'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import TextTrim from '../../components/TextTrim/TextTrim'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { isFormDraft } from '../../enums/enumWorkflowPlaces'
import { useFetchForms } from '../../hooks/useFetchForms'
import { iSubmissionDataHlavni } from '../../interfaces/iEformsSubmission'
import { dateFormatShort } from '../../utils/dateFormat'
import SortTableHeader from '../components/sort/SortTableHeader'
import TableDataExport from '../components/TableDataExport'

interface iTableContracts {
  noPerPage?: boolean
  showDodavatel?: boolean
  noLinks?: boolean
}

const TableContracts = ({ noPerPage, showDodavatel, noLinks }: iTableContracts) => {
  const { t } = useTranslation()
  const { data, loading, error, totalCount, maxItems } = useFetchForms<iSubmissionDataHlavni[]>(SLUG_FORM.HLAVNI)

  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
        <table className='gov-table gov-sortable-table gov-table--mobile-block'>
          <thead>
            <tr>
              <th>
                <SortTableHeader name={'variableId'}>{t('tableContracts.ev_form', 'Ev. č. formuláře')}</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.evCisloZakazkyVvz'}>
                  {t('tableContracts.ev_zakazky', 'Ev. č. zakázky')}
                </SortTableHeader>
              </th>
              <th>
                {showDodavatel ? (
                  <SortTableHeader name={'data.nazevDodavatele'}>
                    {t('tableContracts.nazev_dodavatele', 'Název dodavatele')}
                  </SortTableHeader>
                ) : (
                  <SortTableHeader name={'data.nazevZadavatele'}>
                    {t('tableContracts.nazev_zadavatele', 'Název zadavatele')}
                  </SortTableHeader>
                )}
              </th>
              <th>
                <SortTableHeader name={'data.nazevZakazky'}>
                  {t('tableContracts.nazev_zakazky', 'Název zakázky')}
                </SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.druhFormulare'}>{t('tableContracts.druh', 'Druh')}</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.datumUverejneniVvz'}>
                  {t('tableContracts.datum_uverejneni', 'Dat. uveřejnění')}
                </SortTableHeader>
              </th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((form, index) => (
              <tr key={index}>
                <td>
                  {noLinks ? (
                    <> {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}</>
                  ) : (
                    <Link to={LINKS.vyhledatFormular + '/' + form.id}>
                      {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}
                    </Link>
                  )}
                </td>
                <td>
                  {noLinks ? (
                    <>{form.data.evCisloZakazkyVvz}</>
                  ) : (
                    <Link to={LINKS.formulareZakazky + '/' + form.data.evCisloZakazkyVvz}>
                      {form.data.evCisloZakazkyVvz}
                    </Link>
                  )}
                </td>
                <td className='cellContractName'>
                  <TextTrim
                    text={showDodavatel ? form.data.dodavatele?.[0].nazev : form.data.zadavatele?.[0].nazev}
                    length={200}
                  />
                </td>
                <td className='cellContractName'>
                  <TextTrim text={form.data.nazevZakazky} length={200} />
                </td>
                <td className='gov-table__cell--narrow'>{formTypeToCodeLabel(form.data.druhFormulare)}</td>
                <td className='gov-table__cell--narrow'>{dateFormatShort(form.data.datumUverejneniVvz)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {!data?.length && <AlertInfo />}
      </div>
      {!noPerPage && <TableDataExport totoalCount={totalCount} />}
      {noPerPage ? <Paginator maxItems={maxItems} /> : <PaginatorPerPage totalCount={totalCount} maxItems={maxItems} />}
    </>
  )
}

export default TableContracts
