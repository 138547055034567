import get from 'lodash/get'
import has from 'lodash/has'
import { toast } from 'react-toastify'
import EformsApi from '../api/EformsApi'
import GlobalFormsStore from '../builder/GlobalFormsStore'
import { selectFormUserFormName } from '../redux/formState/selectors'
import { selectSubmMainInfo, selectSumbIds } from '../redux/submissions/selectors'
import { scopeFullToPath } from '../utils/scopeModifs'
import { formTitlePathByType } from './utils/formTitlePathByType'

const REQ_REQ_PATH = ['ND-Root.BT-001-DruhFormulare', 'ND-Root.BT-003-PredmetUverejneni', 'metadata.subType']
const notEmptyReq = (reqData: any) => REQ_REQ_PATH.every((path) => has(reqData, path))

export const saveSubmissionParts = (state: any) => {
  const data = GlobalFormsStore.getEditFormData()
  const dataRequest = GlobalFormsStore.getReqFormData()

  const { submMainId, subFormId, subReqId } = selectSumbIds(state)
  const userFormName = selectFormUserFormName(state)
  const { isProfil, submMainData, formType } = selectSubmMainInfo(state)

  const namePath = formTitlePathByType(formType)
  const name = get(data, scopeFullToPath(namePath))

  const patchMainSub: any[] = []

  if (submMainData.uzivatelskyNazevFormulare !== userFormName) {
    patchMainSub.push({
      op: 'add',
      path: '/uzivatelskyNazevFormulare',
      value: userFormName || '',
    })
  }
  if ((isProfil && (submMainData as any).nazevProfilu !== name) || (!isProfil && submMainData.nazevZakazky !== name)) {
    patchMainSub.push({
      op: 'add',
      path: isProfil ? '/nazevProfilu' : '/nazevZakazky',
      value: name,
    })
  }

  const isReqOk = notEmptyReq(dataRequest)

  return Promise.all([
    EformsApi.updateForm(subFormId, data).catch((err) => {
      toast.error('Nepodařilo se uložit editační formulář')
      throw err
    }),

    isReqOk
      ? EformsApi.updateForm(subReqId, dataRequest).catch((err) => {
          toast.error('Nepodařilo se uložit formulář žádosti')
          throw err
        })
      : Promise.reject().catch((err) => {
          toast.error(
            // 'Nepodařilo se uložit formulář žádost. Prosím, obnovte stránku a akci opakujte.',
            'Pokud požadujete uložit formulář žádost, prosím obnovte stránku a akci opakujte.'
          )
          throw err
        }),

    patchMainSub.length
      ? EformsApi.updateFormWorkflow(submMainId, undefined, patchMainSub).catch((err) => {
          toast.error('Nepodařilo se uložit hlavní formulář')
          throw err
        })
      : undefined,
  ])
}
