import { sortBy } from 'lodash'
import { iValidationField, iValidationFieldSimple } from '../../api/Validation2Api'
import { jsonPathToShort, pathWithoutRepeat } from '../../utils/jsonPaths'
import { pathToScope } from '../../utils/scopeModifs'

export const proccessErrors = (extErrors: iValidationField[], scopesOrder: string[], source?: string) => {
  const errors: iValidationField[] =
    extErrors?.map((err) => ({
      ...err,
      atribut: jsonPathToShort(err.atribut),
      source: source,
    })) ?? []
  return sortBy(errors, [(a) => scopesOrder.indexOf(pathToScope(pathWithoutRepeat(a.atribut)))])
}

export const proccessFields = (fields?: string[] | iValidationFieldSimple[]) =>
  fields?.map((field) =>
    typeof field === 'string'
      ? { atribut: jsonPathToShort(field) }
      : {
        atribut: jsonPathToShort(field.atribut),
        chyba: field.chyba,
      },
  ) ?? []
