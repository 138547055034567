import { createSelector } from '@reduxjs/toolkit'
import { convertDataSchemaEnums } from '../../builder_v1/convertors/convertDataSchemaEnums'
import { convertDataSchemaMergeValidations } from '../../builder_v1/convertors/convertDataSchemaMergeValidations'
import { findAllScopeToLabels } from '../../builder_v1/validations/findAllScopeToLabels'
import { selectSchemaFull, selectSchemaFullReq, selectSubmMainInfo } from './selectors'

export const selectSchemaScopeToLabels = createSelector(
  selectSchemaFull,
  selectSubmMainInfo,
  (schemaFull, submMainInfo) => findAllScopeToLabels(submMainInfo.formType, schemaFull?.layout),
)
export const selectSchemaScopeToLabelsReq = createSelector(selectSchemaFullReq, (schemaFullReq) =>
  findAllScopeToLabels('', schemaFullReq?.layout),
)
export const selectSchemaModifEnums = createSelector(selectSchemaFull, (schemaFull) =>
  convertDataSchemaEnums(schemaFull?.properties),
)
export const selectSchemaModifEnumsReq = createSelector(selectSchemaFullReq, (schemaFullReq) =>
  convertDataSchemaEnums(schemaFullReq?.properties),
)
export const selectSchemaWithValidation = createSelector(
  selectSchemaModifEnums,
  selectSchemaFull,
  (schemaData, schemaFull) => convertDataSchemaMergeValidations(schemaData, schemaFull?.validations),
)
export const selectSchemaWithValidationReq = createSelector(
  selectSchemaModifEnumsReq,
  selectSchemaFullReq,
  (schemaDataReq, schemaFullReq) => convertDataSchemaMergeValidations(schemaDataReq, schemaFullReq?.validations),
)
