import React from 'react'
import { SLUG_FORM } from '../../api/EformsApi'
import { useFilterContextInput } from '../../context/FilterContext'
import { optionsFormTypeProfilV2, optionsFormTypeV2 } from '../../enums/enumFormTypeV2'
import { workwlowPlaceOptions } from '../../enums/enumWorkflowPlaces'
import { optionsFormTypeV1Group } from '../../enums_v1/enumFormType'
import FilterFormWrap from '../components/FilterFormWrap'
import DateFilterInput from '../components/filterInputs/DateFilterInput'
import RadioFilterInput from '../components/filterInputs/RadioFilterInput'
import SelectFilterInput from '../components/filterInputs/SelectFilterInput'
import TextFilterInput from '../components/filterInputs/TextFilterInput'

const FORM_OPTIONS = [
  { value: SLUG_FORM.HLAVNI, label: 'zakázky' },
  { value: SLUG_FORM.PROFIL, label: 'profily' },
]

export const FILTER_FORM = 'filterForm'
const FilterContractsAdmin = () => {
  const { value: mainFormType } = useFilterContextInput(FILTER_FORM)

  const optionsType = mainFormType === SLUG_FORM.HLAVNI ? optionsFormTypeV2 : optionsFormTypeProfilV2
  const optionsGroup = mainFormType === SLUG_FORM.HLAVNI ? optionsFormTypeV1Group : undefined

  return (
    <FilterFormWrap className='custom-form u-mb--50'>
      <div className='custom-form__wrap'>
        <RadioFilterInput
          name={FILTER_FORM}
          label='Formuláře pro'
          options={FORM_OPTIONS}
          defaultValue={SLUG_FORM.HLAVNI}
        />
      </div>
      <div className='custom-form__wrap custom-form__wrap--special'>
        <SelectFilterInput
          name='data.druhFormulare'
          label='Druh formuláře'
          options={optionsType}
          optgroup={optionsGroup}
        />
        <SelectFilterInput name='workflowPlace' label='Stav formuláře' options={workwlowPlaceOptions} />
      </div>
      <div className='custom-form__wrap'>
        <TextFilterInput name='variableId' label='Evidenční číslo formuláře' />
        <TextFilterInput name='data.icoZadavatele' label='IČO zadavatele' />
      </div>
      <div className='custom-form__wrap'>
        <DateFilterInput withTime name='data.lhutaUverejneniTed[lt]' label='Lhůta pro uveřejnění v TED do' />
        <DateFilterInput withTime name='data.lhutaUverejneniVvz[lt]' label='Lhůta pro uveřejnění ve VVZ do' />
      </div>
      <div className='custom-form__wrap'>
        <DateFilterInput withTime name='createdAt[gte]' label='Datum vytvoření OD' />
        <DateFilterInput withTime name='createdAt[lte]' label='Datum vytvoření DO' />
      </div>
      {/*<div className='custom-form__wrap'>*/}
      {/*  <DateFilterInput withTime name='updatedAt[gte]' label='Datum změny OD' />*/}
      {/*  <DateFilterInput withTime name='updatedAt[lte]' label='Datum změny DO' />*/}
      {/*</div>*/}
      <div className='custom-form__wrap'>
        <TextFilterInput name='owner' label='UUID vlastníka' />
        <TextFilterInput name='organization' label='UUID organizace' />
      </div>
    </FilterFormWrap>
  )
}

export default FilterContractsAdmin
