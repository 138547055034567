import React from 'react'
import { Link } from 'react-router-dom'
import AlertError from '../../components/Alert/AlertError'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import { useFetchWPHelps } from '../../hooks/useFetchWPHelps'

const HelpsList = () => {
  const { helps, loading, error } = useFetchWPHelps()

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <AlertError msg='Chyba získání nápovědy' />
  }

  const renderInside = (name: string) => (
    <span style={{ fontSize: 20, fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: name }} />
  )

  return (
    <>
      <div className='gov-grid u-mb--40'>
        {helps.map((item: any, index: number) => (
          <div key={index} className='gov-grid-tile'>
            {item.acf?.externallink ? (
              <a
                href={item.acf?.externallink}
                target='_blank'
                className='gov-link  gov-link--standalone  gov-link--has-arrow'
                rel='noreferrer noopener'
              >
                {renderInside(item.name)}
              </a>
            ) : (
              <Link
                to={LINKS.napoveda + '/' + item.slug}
                className='gov-link  gov-link--standalone  gov-link--has-arrow '
              >
                {renderInside(item.name)}
              </Link>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default HelpsList
