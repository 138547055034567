import { iUIschema } from '../interfaces/iUiSchema'
import { scopeAddRepeat } from '../../utils/scopeModifs'
import { modifRules } from '../rules/modifRules'

export const convertUiSchemaArray = (
  uiSchema: iUIschema,
  arrayScope: string,
  index: number,
  parentScope = '',
): iUIschema => {
  const scope = uiSchema.scope ? scopeAddRepeat(uiSchema.scope, arrayScope, index) : undefined
  const scopeRef = uiSchema.scopeRef ? scopeAddRepeat(uiSchema.scopeRef, arrayScope, index) : undefined
  const actParentScope = parentScope ? parentScope : scopeAddRepeat(arrayScope, arrayScope, index)

  return {
    ...uiSchema,
    scope: scope,
    scopeRef: scopeRef,
    elements: uiSchema.elements
      ? uiSchema.elements.map((el) => convertUiSchemaArray(el, arrayScope, index, scope || actParentScope))
      : undefined,
    exclude: uiSchema.exclude ? uiSchema.exclude.map((ex) => scopeAddRepeat(ex, arrayScope, index)) : undefined,
    allRules: uiSchema.allRules ? modifRules(scope || actParentScope, uiSchema.allRules) : undefined,
  }
}
