export const UiElTypesInput = {
  AresBtn: 'AresBtn',
  IamBtn: 'IamBtn',
  Select: 'Select',
  Checkbox: 'Checkbox',
  CheckboxBoolean: 'CheckboxBoolean',
  Date: 'Date',
  Time: 'Time',
  Hidden: 'Hidden',
  Radio: 'Radio',
  RadioAlone: 'RadioAlone',
  RadioTrueFalse: 'RadioTrueFalse',
  Text: 'Text',
  Textarea: 'Textarea',
  DateParts: 'DateParts',
  CheckboxMulti: 'CheckboxMulti',
}

export const UiElTypesLayout = {
  Array: 'Array',
  ArrayInline: 'ArrayInline',
  FormRoot: 'FormRoot',
  Group: 'Group',
  GroupInner: 'GroupInner',
  Row: 'Row',
  HiddenGroup: 'HiddenGroup',
}

export const UiElTypesVisual = {
  Description: 'Description',
  Label: 'Label',
  SectionTitle: 'SectionTitle',
}
