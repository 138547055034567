import { rankWith, uiTypeIs } from '@jsonforms/core'
import React from 'react'
import { withJsonFormsLabelPropsCustom } from '../../core/withJsonFormsLabelPropsCustom'
import { UiElTypesVisual } from '../uiElTypes'
import { CustomLabelProps } from './GovLabel'

const GovSectionTitleRenderer = ({ text, errors }: CustomLabelProps) => (
  <h2 className={(errors ? 'error-ref-element' : '') + ' u-mb--40'}>{text}</h2>
)

export default withJsonFormsLabelPropsCustom(GovSectionTitleRenderer)

export const govSectionTitleRendererTester = rankWith(1000, uiTypeIs(UiElTypesVisual.SectionTitle))
