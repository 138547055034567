import get from 'lodash/get'
import { iValidationPart } from '../../redux/form/formSlice'
import { replaceAllNamespace } from '../../enums_v1/enumNamespaceMap'
import { VvzErrorObject } from '../../redux/form/formSlice'
import { KEY_P_FORMATTEXT, KEY_P_FORMATTEXT_2 } from '../specialChars'

// TS semanticka validace
export const convertExtSemanticError = (errorsExt: iValidationPart[] | undefined, schemaData: any): VvzErrorObject[] =>
  errorsExt?.filter((err) => err.atribut).map((err) => parseExtSemanticError(err, schemaData)) || []

const parseExtSemanticError = (err: iValidationPart, schemaData: any) => {
  const pathScope = fullXpathToShort(err.atribut!)

  // nalezeni prislusneho schema blocku + namapovani na el_text_nest a el_attrs
  const pathWithProperties = pathScope
    .slice(1)
    .replaceAll('/', '.')
    .replaceAll('.', '.properties.')
    .replace(/\.properties\.\d+/g, '.items')
  const schemaBlock = get(schemaData, pathWithProperties)

  let actPathScope = pathScope
  if (schemaBlock?.type === 'object') {
    if (schemaBlock?.properties?.el_text_nest) {
      actPathScope += '/el_text_nest'
    } else if (schemaBlock?.properties?.el_attrs?.properties) {
      const firstAttr = Object.keys(schemaBlock.properties.el_attrs.properties)[0]
      actPathScope += '/el_attrs/' + firstAttr
    } else if (schemaBlock?.properties?.[KEY_P_FORMATTEXT]) {
      actPathScope += '/' + KEY_P_FORMATTEXT
    } else if (schemaBlock?.properties?.[KEY_P_FORMATTEXT_2]) {
      actPathScope += '/' + KEY_P_FORMATTEXT_2
    }
  }

  return {
    instancePath: actPathScope,
    message: err.chyba,
    // detail: err.atribut,
  }
}
const fullXpathToShort = (fullPath: string) => {
  let path = replaceAllNamespace(fullPath, 'Q{', '}')
    .replace(/\[(\d+)]/g, (old, num) => '/' + (parseInt(num) - 1).toString()) // '[1]' => '/0'
    .replaceAll('Q{}', '')
    .replace(/\/FORMULAR\/[^/]+/, '')
    .replace('/ZADOST', '')
    .replaceAll('@', 'el_attrs/')

  return path
}
