import { FORM_TYPES_V2 } from '../enums/enumFormTypeV2'

export const getPathMapReqByFormType = (formType: string) => {
  const pathMap = ['ND-Root.ND-StatistickeUdajeFormulare', 'ND-Root.ND-KontaktniOsoba', 'ND-Root.ND-ZaslaniPotvrzeni']

  if ([FORM_TYPES_V2.F29, FORM_TYPES_V2.F30].includes(formType)) {
    pathMap.push('ND-Root.ND-NizkoemisniVozidla')
  }
  return pathMap
}
