import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import { scopeToExistDataPaths } from './calculateDataSchemaArrayScopes'

export const calculateDataWithDefault = (data: any, defaultValues: { [scope: string]: string }) => {
  const newData = cloneDeep(data)
  for (const scopeDefault in defaultValues) {
    const defVal = defaultValues[scopeDefault]

    const paths = scopeToExistDataPaths(scopeDefault, data)

    paths.forEach((path) => {
      const oldVal = get(newData, path)
      if (oldVal === undefined || oldVal === null) {
        set(newData, path, defVal)
      }
    })
  }
  return newData
}