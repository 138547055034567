import { useSelector } from 'react-redux'
import { iEformsSubmission } from '../interfaces/iEformsSubmission'
import { selectFormActionLoading } from '../redux/formState/selectors'
import {
  iModalData,
  iModalDataExport,
  iModalDataHelp,
  iModalDataNewRepairForm,
  setModalClose,
  setModalOpen,
} from '../redux/modal/modalSlice'
import { selectModalData, selectModalOpen } from '../redux/modal/selectors'
import { useAppDispatch } from '../redux/store'

export const MODAL_TYPE = {
  notif: 'notif',
  newsletter: 'newsletter',
  changeWorkflow: 'changeWorkflow',
  confirm: 'confirm',
  confirmSubmit: 'confirmSubmit',
  confirmSave: 'confirmSave',
  confirmCancelForm: 'confirmCancelForm',
  confirmReopenForm: 'confirmReopenForm',
  newForm: 'newForm',
  copyForm: 'copyForm',
  deleteForm: 'deleteForm',
  help: 'help',
  helpSimple: 'helpSimple',
  helpAdvance: 'helpAdvance',
  errorDetail: 'errorDetail',
  export: 'export',
}

export const useModal = () => {
  const dispatch = useAppDispatch()

  const open = useSelector(selectModalOpen)
  const data = useSelector(selectModalData)

  const dataContract = data.modalDataContract
  const dataConfirm = data.modalDataConfirm
  const dataNewForm = data.modalDataNewForm
  const dataCopyForm = data.modalDataCopyForm
  const dataHelp = data.modalDataHelp
  const dataNewRepairForm = data.modalDataNewRepairForm
  const dataErrorDetail = data.modalDataErrorDetail
  const dataExport = data.modalDataExport
  const dataHelpSimple = data.modalDataHelpSimple
  const dataDeleteForm = data.modalDataDeleteForm
  const dataHelpAdvance = data.modalDataHelpAdvance

  const actionLoading = useSelector(selectFormActionLoading)

  const closeModal = () => {
    if (!actionLoading) {
      document.body.style.overflow = 'initial'
      dispatch(setModalClose())
    }
  }

  const openModal = (type: string, modalData?: Partial<iModalData>) => {
    document.body.style.overflow = 'hidden'
    dispatch(setModalOpen({ modal: type, modalData: modalData }))
  }

  const openModalNotif = () => openModal(MODAL_TYPE.notif)

  const openModalNewsletter = () => openModal(MODAL_TYPE.newsletter)

  const openModalChangeWorkflow = (contract: iEformsSubmission<any>) => {
    openModal(MODAL_TYPE.changeWorkflow, { modalDataContract: contract })
  }

  const openModalConfirmCancel = (formId: string) => {
    openModal(MODAL_TYPE.confirmCancelForm, { modalDataConfirm: formId })
  }
  const openModalConfirmReopen = (formId: string) => {
    openModal(MODAL_TYPE.confirmReopenForm, { modalDataConfirm: formId })
  }
  const openModalConfirmSend = () => {
    openModal(MODAL_TYPE.confirmSubmit)
  }
  const openModalConfirmSave = () => {
    openModal(MODAL_TYPE.confirmSave)
  }

  const openModalNewForm = (formType: string | string[], dataNewRepairForm?: iModalDataNewRepairForm) => {
    openModal(MODAL_TYPE.newForm, { modalDataNewForm: formType, modalDataNewRepairForm: dataNewRepairForm })
  }

  const openModalCopyForm = (mainSubmId: string) => {
    openModal(MODAL_TYPE.copyForm, { modalDataCopyForm: mainSubmId })
  }

  const openModalHelp = (help: iModalDataHelp) => {
    openModal(MODAL_TYPE.help, { modalDataHelp: help })
  }
  const openModalHelpSimple = (helpText: string) => {
    openModal(MODAL_TYPE.helpSimple, { modalDataHelpSimple: helpText })
  }
  const openModalHelpAdvance = (helpText: string, isReq: boolean) => {
    openModal(MODAL_TYPE.helpAdvance, { modalDataHelpAdvance: { key: helpText, isReq: isReq } })
  }
  const openModalErrorDetail = (errDetail: any) => {
    openModal(MODAL_TYPE.errorDetail, { modalDataErrorDetail: errDetail })
  }

  const openModalExport = (exportData: iModalDataExport) => {
    openModal(MODAL_TYPE.export, { modalDataExport: exportData })
  }

  const openModalDeleteForm = (mainSubmId: string) => {
    openModal(MODAL_TYPE.deleteForm, { modalDataDeleteForm: mainSubmId })
  }

  return {
    open,
    openModal,
    closeModal,
    dataContract,
    openModalNotif,
    openModalNewsletter,
    openModalChangeWorkflow,
    openModalConfirmCancel,
    openModalConfirmReopen,
    openModalConfirmSend,
    openModalConfirmSave,
    openModalNewForm,
    openModalCopyForm,
    openModalHelp,
    openModalHelpSimple,
    openModalErrorDetail,
    dataConfirm,
    dataNewForm,
    dataCopyForm,
    dataHelp,
    dataNewRepairForm,
    dataErrorDetail,
    openModalExport,
    dataExport,
    dataHelpSimple,
    dataHelpAdvance,
    openModalHelpAdvance,
    openModalDeleteForm,
    dataDeleteForm,
  }
}
