import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonGov from '../../components/Btns/ButtonGov'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import { useModal } from '../../hooks/useModal'
import FilterContracts from '../../tables/TableContracts/FilterContracts'
import TableContracts from '../../tables/TableContracts/TableContracts'

const FormularVyhledatPage = () => {
  const { t } = useTranslation()
  const { openModalNotif } = useModal()

  return (
    <PageWrap title={t('title.vyhledat_form', 'Vyhledat formulář')}>
      <ButtonGov variant='primary' className='u-mb--10 ' onClick={openModalNotif}>
        {t('modalNotif.odebirat_upozorneni', 'Odebírat upozornění na nové zakázky')}
      </ButtonGov>
      <FilterContextProvider name={TABLE_TYPES.contracts}>
        <FilterContracts />
        <TableContracts />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default FormularVyhledatPage
