export const FORM_TYPES_V2 = {
  F4: '4',
  F5: '5',
  F6: '6',
  F7: '7',
  F8: '8',
  F9: '9',
  F10: '10',
  F11: '11',
  F12: '12',
  F13: '13',
  F14: '14',
  F15: '15',
  F16: '16',
  F17: '17',
  F18: '18',
  F19: '19',
  F20: '20',
  F21: '21',
  F22: '22',
  F23: '23',
  F24: '24',
  F25: '25',
  F26: '26',
  F27: '27',
  F28: '28',
  F29: '29',
  F30: '30',
  F31: '31',
  F32: '32',
  F33: '33',
  F34: '34',
  F35: '35',
  F36: '36',
  F37: '37',
  F38: '38',
  F39: '39',
  F40: '40',

  FCZ05: 'CZ05',
  FCZ06: 'CZ06',
  FCZ07: 'CZ07',
}

const FORM_TYPES_V2_CZ_CODE_LABEL = {
  [FORM_TYPES_V2.FCZ05]: 'OP',
  [FORM_TYPES_V2.FCZ06]: 'ZP',
  [FORM_TYPES_V2.FCZ07]: 'D',
}
export const formTypeToCodeLabel = (formType: string) => FORM_TYPES_V2_CZ_CODE_LABEL[formType] ?? formType

export const FORM_TYPES_V2_NAMES = {
  [FORM_TYPES_V2.F4]: 'Předběžné oznámení – obecná veřejná zakázka',
  [FORM_TYPES_V2.F5]: 'Předběžné oznámení – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F6]: 'Předběžné oznámení – veřejná zakázka v oblasti obrany nebo bezpečnosti',
  [FORM_TYPES_V2.F7]: 'Předběžné oznámení použité za účelem zkrácení lhůty pro podání nabídek – obecná veřejná zakázka',
  [FORM_TYPES_V2.F8]:
    'Předběžné oznámení použité za účelem zkrácení lhůty pro podání nabídek – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F9]:
    'Předběžné oznámení použité za účelem zkrácení lhůty pro podání nabídek – veřejná zakázka v oblasti obrany nebo bezpečnosti',
  [FORM_TYPES_V2.F10]: 'Předběžné oznámení použité jako výzva k projevení předběžného zájmu – obecná veřejná zakázka',
  [FORM_TYPES_V2.F11]:
    'Předběžné oznámení použité jako výzva k projevení předběžného zájmu – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F12]:
    'Předběžné oznámení použité jako výzva k projevení předběžného zájmu – veřejná zakázka ve zjednodušeném režimu',
  [FORM_TYPES_V2.F13]:
    'Předběžné oznámení použité jako výzva k projevení předběžného zájmu – sektorová veřejná zakázka ve zjednodušeném režimu',
  [FORM_TYPES_V2.F14]:
    'Předběžné oznámení použité jako výzva k projevení předběžného zájmu – koncese ve zjednodušeném režimu',
  [FORM_TYPES_V2.F15]: 'Oznámení o zavedení systému kvalifikace – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F16]: 'Oznámení o zahájení zadávacího řízení – obecná veřejná zakázka',
  [FORM_TYPES_V2.F17]: 'Oznámení o zahájení zadávacího řízení – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F18]: 'Oznámení o zahájení zadávacího řízení – veřejná zakázka v oblasti obrany nebo bezpečnosti',
  [FORM_TYPES_V2.F19]: 'Oznámení o zahájení koncesního řízení',
  [FORM_TYPES_V2.F20]: 'Oznámení o zahájení zadávacího řízení – veřejná zakázka ve zjednodušeném režimu',
  [FORM_TYPES_V2.F21]: 'Oznámení o zahájení zadávacího řízení – sektorová veřejná zakázka ve zjednodušeném režimu',
  [FORM_TYPES_V2.F22]: 'Oznámení o poddodávce – veřejná zakázka v oblasti obrany nebo bezpečnosti',
  [FORM_TYPES_V2.F23]: 'Oznámení o zahájení soutěže o návrh',
  [FORM_TYPES_V2.F24]: 'Oznámení o zahájení soutěže o návrh – relevantní činnost',
  [FORM_TYPES_V2.F25]: 'Dobrovolné oznámení o záměru uzavřít smlouvu – obecná veřejná zakázka',
  [FORM_TYPES_V2.F26]: 'Dobrovolné oznámení o záměru uzavřít smlouvu – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F27]:
    'Dobrovolné oznámení o záměru uzavřít smlouvu – veřejná zakázka v oblasti obrany nebo bezpečnosti',
  [FORM_TYPES_V2.F28]: 'Dobrovolné oznámení o záměru uzavřít koncesní smlouvu',

  [FORM_TYPES_V2.F29]: 'Oznámení o výsledku zadávacího řízení – obecná veřejná zakázka',
  [FORM_TYPES_V2.F30]: 'Oznámení o výsledku zadávacího řízení – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F31]: 'Oznámení o výsledku zadávacího řízení – veřejná zakázka v oblasti obrany nebo bezpečnosti',
  [FORM_TYPES_V2.F32]: 'Oznámení o výsledku koncesního řízení',
  [FORM_TYPES_V2.F33]: 'Oznámení o výsledku zadávacího řízení – veřejná zakázka ve zjednodušeném režimu',
  [FORM_TYPES_V2.F34]: 'Oznámení o výsledku zadávacího řízení – sektorová veřejná zakázka ve zjednodušeném režimu',
  [FORM_TYPES_V2.F35]: 'Oznámení o výsledku koncesního řízení – koncese ve zjednodušeném režimu',
  [FORM_TYPES_V2.F36]: 'Oznámení o výsledku soutěže o návrh',
  [FORM_TYPES_V2.F37]: 'Oznámení o výsledku soutěže o návrh – relevantní činnost',
  [FORM_TYPES_V2.F38]: 'Oznámení o změně závazku ze smlouvy – obecná veřejná zakázka',
  [FORM_TYPES_V2.F39]: 'Oznámení o změně závazku ze smlouvy – sektorová veřejná zakázka',
  [FORM_TYPES_V2.F40]: 'Oznámení o změně závazku ze smlouvy – koncese',

  [FORM_TYPES_V2.FCZ05]: 'Oznámení profilu zadavatele',
  [FORM_TYPES_V2.FCZ06]: 'Zrušení/zneaktivnění profilu zadavatele',
  [FORM_TYPES_V2.FCZ07]:
    'Oznámení o zahájení nabídkového řízení pro výběr dopravce k uzavření smlouvy o veřejných službách v přepravě cestujících',
}

export const FORM_TYPE_REQ_V2 = 'ZADOST'

export const PROFILE_FORM_TYPES_V2 = [FORM_TYPES_V2.FCZ05, FORM_TYPES_V2.FCZ06]
export const CZ_FORM_TYPES_V2 = [FORM_TYPES_V2.FCZ05, FORM_TYPES_V2.FCZ06, FORM_TYPES_V2.FCZ07]

export const optionsFormTypeV2 = Object.values(FORM_TYPES_V2)
  .filter((code) => !PROFILE_FORM_TYPES_V2.includes(code))
  .map((code) => ({
    value: code,
    label: `${formTypeToCodeLabel(code)} - ${FORM_TYPES_V2_NAMES[code]}`,
  }))
export const optionsFormTypeProfilV2 = Object.values(FORM_TYPES_V2)
  .filter((code) => PROFILE_FORM_TYPES_V2.includes(code))
  .map((code) => ({
    value: code,
    label: `${FORM_TYPES_V2_CZ_CODE_LABEL[code]} - ${FORM_TYPES_V2_NAMES[code]}`,
  }))
export const formTypeToFormSlugV2 = (formType: string) => 'vvz2-oznameni-' + formType.toLowerCase()

export const getFormNameByTypeV2 = (formType: string) => FORM_TYPES_V2_NAMES[formType] ?? ''
