import { FORM_TYPES as FT1 } from '../enums_v1/enumFormType'
import { FORM_TYPES_V2 as FT2 } from './enumFormTypeV2'

const enumNextFormActivityWizard: any = {
  zahajeni: [FT2.F15, FT2.F16, FT2.F17, FT2.F18, FT2.F19, FT2.F20, FT2.F21, FT2.F22],
  zamer: [FT2.F25, FT2.F26, FT2.F27, FT2.F28],
  vysledek: [FT2.F29, FT2.F30, FT2.F31, FT2.F32, FT2.F33, FT2.F34, FT2.F35, FT2.F36, FT2.F37],
  zmena: [FT2.F38, FT2.F39, FT2.F40],
  profil: [FT2.FCZ06],
}

const FORM_PREV_V2 = {
  [FT2.F15]: [FT2.F5, FT2.F8],
  [FT2.F16]: [FT2.F4, FT2.F7],
  [FT2.F17]: [FT2.F5, FT2.F8],
  [FT2.F18]: [FT2.F6, FT2.F9],
  [FT2.F19]: [FT2.F4, FT2.F7],
  [FT2.F20]: [FT2.F4, FT2.F7],
  [FT2.F21]: [FT2.F5, FT2.F8],
  [FT2.F22]: [FT2.F31],

  [FT2.F25]: [FT2.F4, FT2.F7],
  [FT2.F26]: [FT2.F5, FT2.F8],
  [FT2.F27]: [FT2.F6, FT2.F9],
  [FT2.F28]: [FT2.F4, FT2.F5, FT2.F6, FT2.F7, FT2.F8],

  [FT2.F29]: [FT2.F10, FT2.F16, FT2.F29],
  [FT2.F30]: [FT2.F11, FT2.F15, FT2.F17, FT2.F30],
  [FT2.F31]: [FT2.F18, FT2.F31],
  [FT2.F32]: [FT2.F19, FT2.F32],
  [FT2.F33]: [FT2.F12, FT2.F20, FT2.F33],
  [FT2.F34]: [FT2.F13, FT2.F21, FT2.F34],
  [FT2.F35]: [FT2.F14, FT2.F35],
  [FT2.F36]: [FT2.F23],
  [FT2.F37]: [FT2.F24],

  [FT2.F38]: [FT2.F29, FT2.F31, FT2.F33],
  [FT2.F39]: [FT2.F30, FT2.F34],
  [FT2.F40]: [FT2.F32, FT2.F35],

  [FT2.FCZ06]: [FT2.FCZ05],
}

const FORM_PREV_V1 = {
  [FT2.F16]: [FT1.F01, FT1.CZ01],
  [FT2.F17]: [FT1.F04],
  [FT2.F18]: [FT1.F16],
  [FT2.F19]: [FT1.F24],
  [FT2.F20]: [FT1.F01],
  [FT2.F21]: [FT1.F04],
  [FT2.F22]: [FT1.F18],
  [FT2.F25]: [FT1.F01, FT1.CZ01],
  [FT2.F26]: [FT1.F04],
  [FT2.F27]: [FT1.F16],
  [FT2.F28]: [FT1.F01, FT1.F04],
  [FT2.F29]: [FT1.F01, FT1.F02, FT1.F03, FT1.CZ01, FT1.CZ02, FT1.CZ03],
  [FT2.F30]: [FT1.F04, FT1.F05, FT1.F06, FT1.F07],
  [FT2.F31]: [FT1.F17, FT1.F18],
  [FT2.F32]: [FT1.F01, FT1.F24, FT1.F25],
  [FT2.F33]: [FT1.F21],
  [FT2.F34]: [FT1.F22],
  [FT2.F35]: [FT1.F23],
  [FT2.F36]: [FT1.F12],
  [FT2.F37]: [FT1.F12],
  [FT2.F38]: [FT1.F03, FT1.CZ03],
  [FT2.F39]: [FT1.F06],
  [FT2.F40]: [FT1.F25],
}

const getFormNextTypes = (prevFormType: string): string[] =>
  [...Object.entries(FORM_PREV_V1), ...Object.entries(FORM_PREV_V2)]
    .filter(([_, vals]) => vals.includes(prevFormType))
    .map(([key, _]) => key)
    .filter((value, index, array) => array.indexOf(value) === index)

export const getNextTypesActivityWizard = (activity: string, prevFormType: string) => {
  const typesAct = enumNextFormActivityWizard[activity]
  const nextTypes = getFormNextTypes(prevFormType)
  return nextTypes.filter((formType) => typesAct?.includes(formType))
}
