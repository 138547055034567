import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import RenderElements from '../../renders/RenderElements'

const GroupTxt = () => {
  const { uiSchema } = useElementContext()

  const label = uiSchema.label
  const groupVirtual = uiSchema.options?.groupVirtual

  if (groupVirtual) {
    return uiSchema.elements && <RenderElements uiSchemas={uiSchema.elements} />
  }
  return (
    <div className={'custom-form u-mb--10 u-mt--10 custom-form-txt'}>
      <h2 className={' custom-form__title gov-title--delta'}>{label}</h2>
      {uiSchema.elements && <RenderElements uiSchemas={uiSchema.elements} />}
    </div>
  )
}

export default GroupTxt
