// Odstraneni enumu s Key enum id
import { iSchemaDatas } from '../interfaces/iSchemaData'

export const convertDataSchemaEnums = (dataSchema?: iSchemaDatas): any => {
  if (dataSchema === undefined) {
    return undefined
  }

  const ret: any = {}
  for (const key in dataSchema) {
    const schemaItem = dataSchema[key]
    if (schemaItem.items) {
      ret[key] = {
        ...schemaItem,
        items: { ...schemaItem.items, properties: convertDataSchemaEnums(schemaItem.items.properties) },
        properties: undefined,
      }
    } else if (schemaItem.properties) {
      ret[key] = { ...schemaItem, properties: convertDataSchemaEnums(schemaItem.properties) }
    } else if (schemaItem.enum && !Array.isArray(schemaItem.enum)) {
      ret[key] = { ...schemaItem, enum: undefined }
    } else {
      ret[key] = { ...schemaItem }
    }
  }

  return ret
}
