import React from 'react'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputTextTxt = () => {
  const { field } = useControlRegister()

  return (
    <div>
      <PartGovLabelTxt /> {field.value}
    </div>
  )
}

export default InputTextTxt
