import get from 'lodash/get'
import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectDynamEnum } from '../../redux/dynamEnum/dynamEnumSelectors'
import DynamEnumDebounce from '../../services/DynamEnumDebounce'
import { equalJson } from '../../utils/equalJson'
import { scopeFullToPath } from '../../utils/scopeModifs'

interface iDynamEnumWatch {
  arrayScope: string
  idPath: string
  labelPath?: string
}

const DynamEnumWatch = ({ arrayScope, idPath, labelPath }: iDynamEnumWatch) => {
  const pathArray = scopeFullToPath(arrayScope)

  const enums = useSelector(selectDynamEnum)

  // TODO sledovat jen konkretni prvek?
  const arrayValues = useWatch({ name: pathArray })

  useEffect(() => {
    const options = arrayValues
      ?.map((item: any) => {
        const id = get(item, idPath)
        const label = labelPath ? get(item, labelPath) : id
        return {
          value: id,
          label: label || id,
        }
      })
      .filter((item: any) => item.value)

    if (!equalJson(enums[arrayScope], options)) {
      // console.log('array changed ', arrayScope, enums[arrayScope], '->', options)
      DynamEnumDebounce.setDynEnam(arrayScope, options ?? [])
    }
  }, [arrayValues])

  useEffect(() => {
    return () => {
      DynamEnumDebounce.setDynEnam(arrayScope, [])
    }
  }, [])

  return null
}

export default DynamEnumWatch
