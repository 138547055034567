import React, { useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

interface iErrorBoundaryVvz {
  children?: React.ReactNode
}

const FallbackComponentVVZ = ({ error, resetErrorBoundary }: FallbackProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      role='alert'
      style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', height: '95vh', justifyContent: 'center' }}
    >
      <div style={{ fontSize: '2rem', color: 'var(--gov-color-error)', marginBottom: '30px' }}>Něco se pokazilo</div>

      <div>
        {open ? (
          <div>
            <pre>{error.message}</pre>
            <pre>{error.stack}</pre>
          </div>
        ) : (
          <button onClick={() => setOpen(true)}>Zobrazit detail chyby</button>
        )}
      </div>
      <div>
        <button onClick={resetErrorBoundary}>Načíst znovu</button>
      </div>
    </div>
  )
}

const ErrorBoundaryVvz = ({ children }: iErrorBoundaryVvz) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponentVVZ}
      // onReset={() => (location.href = '/')}
      // onReset={() => {
      //   // reset the state of your app so the error doesn't happen again
      //   console.log('ON reset')
      // }}
      // onError={(error: Error, info: { componentStack: string }) => {
      //   console.log('ON error', error, info)
      // }}
    >
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryVvz
