import React from 'react'
import { useUser } from '../../hooks/useUser'
import { LINKS } from '../App/LINKS'
import Loading from '../Loading/Loading'

const AdminHeader = () => {
  const { userLoading, userIsLoged, login, logout, usernameFull } = useUser()

  const clickLogout = () => {
    logout()
  }

  const clickLogin = () => {
    login()
  }

  if (userLoading) {
    return <Loading min inversed />
  }

  if (userIsLoged) {
    return (
      <>
        <a href={LINKS.cdzMujUcet} className='gov-button gov-button--inversed'>
          {usernameFull}
        </a>
        <button onClick={clickLogout} className='gov-button gov-button--small gov-button--inversed'>
          <span className='gov-icon gov-icon--exit'></span>
        </button>
      </>
    )
  } else {
    return (
      <>
        <button onClick={clickLogin} className='gov-button  gov-button--primary-outlined  gov-button--inversed '>
          Přihlásit se jako admin
        </button>
      </>
    )
  }
}

export default AdminHeader
