import moment from 'moment/moment'
import { iEformsFilterParam } from "../interfaces/iEformsApi";
import { iFilter } from '../context/FilterContext'
import { DATE_FORMAT_ISO8601 } from './dateFormat'

const ASC = 'ASC'
const DESC = 'DESC'

export const createFilterParams = (filtersActive: iFilter) => {
  const filters: iEformsFilterParam = {}

  for (const filterName in filtersActive) {
    let value = filtersActive[filterName]
    if (value !== '') {
      if (filterName.endsWith('[lte]')) { // pro vsechny lte prida den navic - filtr DO
        value = moment(value).add(1, 'day').format(DATE_FORMAT_ISO8601)
      }
      filters[filterName] = value
    }
  }
  return filters
}

export const createOrderParams = (sort: string, sortOrder: boolean) => {
  if (sort) {
    return { order: { [sort]: sortOrder ? ASC : DESC } }
  }

  return {}
}
