import { CZ_FORM_TYPES_V2, PROFILE_FORM_TYPES_V2 } from '../../enums/enumFormTypeV2'

export const formTitlePathByType = (formType: string) => {
  if (CZ_FORM_TYPES_V2.includes(formType)) {
    if (PROFILE_FORM_TYPES_V2.includes(formType)) {
      return 'ND-Root.ND-Profile.BT-518-ProfileName'
    } else {
      return 'ND-Root.ND-ObjectContract.BT-704-Title'
    }
  }

  return 'ND-Root.ND-ProcedureProcurementScope.BT-21-Procedure'
}
