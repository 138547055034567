import { iUIschema } from '../interfaces/iUiSchema'

export const scopeAddRepeat = (scope: string, arrayScope: string, index: number) =>
  scope.replace(arrayScope, arrayScope + '/' + index)

export const modifUiSchemaArray = (uiSchema: iUIschema, arrayScope: string, index: number): iUIschema => {
  const scope = uiSchema.scope ? scopeAddRepeat(uiSchema.scope, arrayScope, index) : undefined
  const buttonDestinationNodeId = uiSchema.options?.buttonDestinationNodeId
    ? scopeAddRepeat(uiSchema.options.buttonDestinationNodeId, arrayScope, index)
    : undefined

  return {
    ...uiSchema,
    scope: scope,
    options: uiSchema.options ? { ...uiSchema.options, buttonDestinationNodeId: buttonDestinationNodeId } : undefined,
    elements: uiSchema.elements ? uiSchema.elements.map((el) => modifUiSchemaArray(el, arrayScope, index)) : undefined,
  }
}
