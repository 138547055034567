import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { USERFORMNAME_ID, USERFORMNAME_LABEL } from '../../components/FormNameInput/FormNameInput'
import { setOpenErrorPanel } from '../../redux/formErrors/formErrorsSlice'
import { selectFormErrorActive, selectFormReqErrorActive, selectOpenErrorPanel } from '../../redux/formErrors/selectors'
import { selectFormUserFormName } from '../../redux/formState/selectors'
import { useAppDispatch } from '../../redux/store'
import { selectScopeToLabel, selectScopeToLabelReq } from '../../redux/submissions/selectors'
import { equalJson } from '../../utils/equalJson'
import { pathToScope } from '../../utils/scopeModifs'
import { ErrorPanelBlock } from './ErrorPanelBlock'

const ErrorPanel = () => {
  const dispatch = useAppDispatch()
  const open = useSelector(selectOpenErrorPanel)

  const scopeToLabelsForm = useSelector(selectScopeToLabel)
  const scopeToLabelReq = useSelector(selectScopeToLabelReq)

  const userFormName = useSelector(selectFormUserFormName)

  const inputNmeError = useMemo(
    () =>
      userFormName
        ? []
        : [{ atribut: USERFORMNAME_ID, chyba: 'Název formuláře nemůže být prázdný', urovenChyby: 'ERROR' }],
    [userFormName],
  )

  const scopeToLabelsNameInput = { [pathToScope(USERFORMNAME_ID)]: USERFORMNAME_LABEL }

  const formErrorActive = useSelector(selectFormErrorActive, equalJson)
  const formErrorReqActive = useSelector(selectFormReqErrorActive, equalJson)

  const hasErrors = !!formErrorActive?.length || !!formErrorReqActive?.length || !!inputNmeError.length

  if (!hasErrors || !open) {
    return null
  }

  const close = () => {
    dispatch(setOpenErrorPanel(false))
  }

  return (
    <div className={'errorPanelFix'}>
      <button
        className='gov-button gov-button--secondary gov-modal__close'
        onClick={close}
        aria-labelledby='modal-close'
        style={{ top: -20, right: -30 }}
      >
        <span id='modal-close' className='u-sr-only gov-button__label'>
          Zavřít
        </span>
      </button>

      <ErrorPanelBlock title='' errors={inputNmeError} scopeToLabels={scopeToLabelsNameInput} />
      <ErrorPanelBlock
        title='Při validaci byly zjištěny následující chyby ve Formuláři:'
        errors={formErrorActive}
        scopeToLabels={scopeToLabelsForm}
      />

      <ErrorPanelBlock
        title='Při validaci byly zjištěny následující chyby v Žádosti o uveřejnění:'
        errors={formErrorReqActive}
        scopeToLabels={scopeToLabelReq}
      />
    </div>
  )
}

export default ErrorPanel
