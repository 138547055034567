import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import TableContractForms from '../../tables/TableContractForms/TableContractForms'

const FormulareProfiluPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <PageWrap
      title={t('title.formulare_profilu', 'Formuláře profilu')}
      subtitle={t('title.evidencni_cislo_profilu', 'Evidenční číslo profilu:') + ' ' + id}
    >
      <FilterContextProvider name={TABLE_TYPES.profileForms}>
        <TableContractForms id={id!} isProfil />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default FormulareProfiluPage
