import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface iValidationPart {
  chyba: string
  cisloRadku?: string
  poziceNaRadku?: string
  atribut?: string
}
interface iValidationResponse {
  typValidace: string
  seznamChybFormular?: iValidationPart[]
  seznamChybZadost?: iValidationPart[]
}
export interface VvzErrorObject {
  instancePath: string
  message: string
  detail?: string
}

interface iFormState {
  data: any
  dataRequest: any
  additionalErrors: VvzErrorObject[]
  additionalErrorsRequest: VvzErrorObject[]
  errorExternal?: iValidationResponse
}

const slice = createSlice({
  name: 'form',
  initialState: {
    data: {},
    dataRequest: {},
    additionalErrors: [],
    additionalErrorsRequest: [],
    errorExternal: undefined,
  } as iFormState,
  reducers: {
    setFormData(state, action: PayloadAction<any>) {
      state.data = action.payload
    },
    setFormDataRequest(state, action: PayloadAction<any>) {
      state.dataRequest = action.payload
    },
    setFormAdditionalErrors(state, action: PayloadAction<VvzErrorObject[]>) {
      state.additionalErrors = action.payload
    },
    setFormAdditionalErrorsRequest(state, action: PayloadAction<VvzErrorObject[]>) {
      state.additionalErrorsRequest = action.payload
    },
    setFormErrorExternal(state, action: PayloadAction<iValidationResponse | undefined>) {
      state.errorExternal = action.payload
    },
    clearFormErrors(state) {
      state.additionalErrors = []
      state.additionalErrorsRequest = []
      state.errorExternal = undefined
    },
    clearFormDataState(state) {
      state.data = {}
      state.dataRequest = {}
    },
  },
})

export const {
  setFormData,
  setFormAdditionalErrors,
  setFormDataRequest,
  setFormAdditionalErrorsRequest,
  setFormErrorExternal,
  clearFormErrors,
  clearFormDataState,
} = slice.actions
export default slice.reducer
