import { AxiosError } from 'axios'
import defaultsDeep from 'lodash/defaultsDeep'
import get from 'lodash/get'
import merge from 'lodash/merge'
import set from 'lodash/set'
import unset from 'lodash/unset'
import moment from 'moment/moment'
import { loadXmlFile } from '../actions/utils/loadXmlFile'
import EformsApi, { SLUG_FORM } from '../api/EformsApi'
import ValidationConnect2Api from '../api/ValidationConnect2Api'
import { convertDataKeysVals } from '../builder/utils/convertDataKeysVals'
import { convertDataOrderLikeSchemaSimple } from '../builder/utils/convertDataOrderLikeSchemaSimple'
import { DEFAULT_DATA_NOTICE } from '../default_data/defaultDataNotice'
import { isPrevWithTedNum } from '../default_data/defaultDataPrevTedNum'
import { getPathMapReqByFormType } from '../default_data/defaultDataReq'
import { DEFAULT_DATA_UOHS } from '../default_data/defaultDataUohs'
import {
  CZ_FORM_TYPES_V2,
  FORM_TYPES_V2 as FT2,
  formTypeToCodeLabel,
  formTypeToFormSlugV2,
  getFormNameByTypeV2,
  PROFILE_FORM_TYPES_V2,
} from '../enums/enumFormTypeV2'
import { PROFILE_FORM_TYPES } from '../enums_v1/enumFormType'
import { ENV } from '../ENV'
import { iEformsFormSchema } from '../interfaces/iEformsFormSchema'
import { iEformsSubmission } from '../interfaces/iEformsSubmission'
import store from '../redux/store'
import { selectUserName, selectUserSubjekt } from '../redux/user/selectors'
import { convertEvCisloTedOld, convertVariableToEvCisloTed } from '../utils/convertVariableToEvCisloTed'
import IamModel, { iIamModel } from './ModelSimpleIam'
import SchemaLoader from './SchemaLoader'

const UCEL_FORMULARE = {
  ZAKLADA: 'zaklada',
  NAVAZUJE: 'navazuje',
}

const PREDMET_UVEREJNENI = {
  PROFIL: 'profil',
  ZAKAZKA: 'zakazka',
}
const FORMAT_POTVRZENI = {
  EMAIL: 'email',
}

export class FormsCreator {
  formType
  orgSlug
  username

  isProfile
  mainFormSlug
  editFormSlug

  mainFormData: any = {}
  reqFormData: any = {}
  editFormData: any = {}

  evCisloZakazky?: string
  evCisloFormulare?: string
  kodSouvisFormulare?: string

  relatedId?: string
  prevResMain?: iEformsSubmission<any>
  prevResReq?: iEformsSubmission<any>
  editFormSchema?: iEformsFormSchema

  isRepair = false
  repairType: string = ''
  tedNum?: string = ''
  iamData?: iIamModel

  copyFromMain?: string
  copyFromEdit?: string
  copyFromReq?: string

  constructor(formType: string) {
    const state = store.getState()
    this.formType = formType
    this.orgSlug = selectUserSubjekt(state)
    this.username = selectUserName(state)

    this.isProfile = PROFILE_FORM_TYPES.includes(this.formType) || PROFILE_FORM_TYPES_V2.includes(this.formType)
    this.mainFormSlug = this.isProfile ? SLUG_FORM.PROFIL : SLUG_FORM.HLAVNI
    this.editFormSlug = this.formType ? formTypeToFormSlugV2(this.formType) : ''
  }

  getPrevFormType = () => this.prevResMain?.data.druhFormulare
  isPrevFormV1 = () => this.prevResMain?.data.verzeXsd
  isCzForm = () => CZ_FORM_TYPES_V2.includes(this.formType)

  loadFormSchema = async () => {
    this.editFormSchema = await SchemaLoader.loadSchemaBySlug(this.orgSlug, this.editFormSlug).catch((err) => {
      throw new Error('Nepodařilo se načíst schema pro formuláře')
    })
  }

  loadIamData = async () => {
    try {
      this.iamData = await IamModel.iamDataByName(this.username, this.orgSlug)
    } catch (e) {
      console.error(e)
    }
  }

  loadSchemaAdnUser = async () => {
    await Promise.all([this.loadFormSchema(), await this.loadIamData()])
  }

  /*
  Vytvoreni defaultnih formularu
   */

  createMainFormData = () => {
    const verzeXsd = this.editFormSchema?.metadata.verzeXsd
    const verzeSdk = this.editFormSchema?.metadata.verzeSdk

    this.mainFormData = {
      verzeXsd: verzeXsd,
      verzeSdk: verzeSdk,
      druhFormulare: this.formType,
      uzivatelskyNazevFormulare: `${formTypeToCodeLabel(this.formType)} ${getFormNameByTypeV2(this.formType)}`,
      formularOpravuje: false,
    }
  }

  createReqFormData = () => {
    this.reqFormData = {
      'ND-Root': {
        'BT-001-DruhFormulare': this.formType,
        'BT-003-PredmetUverejneni': this.isProfile ? PREDMET_UVEREJNENI.PROFIL : PREDMET_UVEREJNENI.ZAKAZKA,
        'BT-004-UcelFormulare': UCEL_FORMULARE.ZAKLADA,
      },
    }
    this.setUserToReqFormData()
  }

  addReqData = (reData: any) => {
    this.reqFormData = merge(this.reqFormData, reData)
  }

  setUserToReqFormData = () => {
    const iamData = this.iamData
    if (iamData) {
      set(this.reqFormData, 'ND-Root.ND-KontaktniOsoba', {
        'BT-015-Jmeno': iamData.userNameFirst,
        'BT-016-Prijmeni': iamData.userNameLast,
        'BT-018-Email': iamData.email,
        'BT-017-Telefon': iamData.phone,
      })
      set(this.reqFormData, 'ND-Root.ND-ZaslaniPotvrzeni', {
        'BT-019-FormatPotvrzeni': FORMAT_POTVRZENI.EMAIL,
        'BT-020-PotvrzeniEmail': iamData.email,
      })
    }
  }

  createEditFormData = () => {
    if (!this.isCzForm()) {
      const defaultNotice = DEFAULT_DATA_NOTICE[this.formType]
      if (defaultNotice) {
        set(this.editFormData, 'ND-Root.BT-01-notice', defaultNotice['BT-01-Notice'])
        set(this.editFormData, 'ND-Root.ND-ProcedureTerms', {
          'ND-LocalLegalBasisWithID': [
            {
              'BT-01(c)-Procedure': defaultNotice['BT-01(c)-Procedure'],
              'BT-01(d)-Procedure': defaultNotice['BT-01(d)-Procedure'],
            },
          ],
        })
      }

      if ([FT2.F23, FT2.F24, FT2.F36, FT2.F37].includes(this.formType)) {
        set(this.editFormData, 'ND-Root.ND-ProcedureProcurementScope.BT-23-Procedure', 'services')
      }

      this.addEditFormDataUserOrgs()
    }
  }

  addEditFormDataUserOrgs = () => {
    const userOrg = {
      'ND-Company': {
        'OPT-200-Organization-Company': 'ORG-0001',
      },
    }
    const iamData = this.iamData

    if (iamData) {
      merge(userOrg, {
        'ND-Company': {
          'BT-500-Organization-Company': iamData.name,
          'ND-CompanyLegalEntity': [{ 'BT-501-Organization-Company': iamData.ico }],
          'ND-CompanyAddress': {
            'BT-510(a)-Organization-Company': iamData.address,
            'BT-513-Organization-Company': iamData.town,
            'BT-512-Organization-Company': iamData.postalCode,
            'BT-514-Organization-Company': iamData.countryCode,
          },
          'ND-CompanyContact': {
            'BT-506-Organization-Company': iamData.email,
            'BT-503-Organization-Company': iamData.phone,
          },
        },
      })
    }

    const orgs = [FT2.F22].includes(this.formType) ? [userOrg] : [userOrg, DEFAULT_DATA_UOHS]
    set(this.editFormData, 'ND-Root.ND-RootExtension.ND-Organizations.ND-Organization', orgs)
  }
  createEmptyFormData = async () => {
    await this.loadSchemaAdnUser()
    this.createMainFormData()
    this.createEditFormData()
    this.createReqFormData()
  }

  /*
  Kopie formularu
   */

  copyMainFormData = (resMain: iEformsSubmission<any>) => {
    this.createMainFormData()
    this.copyFromMain = resMain.id
    this.mainFormData['uzivatelskyNazevFormulare'] = 'Kopie: ' + resMain.data.uzivatelskyNazevFormulare
  }

  copyReqFormData = (resReq?: iEformsSubmission<any>) => {
    if (resReq?.data) {
      this.copyFromReq = resReq?.id
      this.reqFormData = resReq.data
    } else {
      this.createReqFormData()
    }

    set(this.reqFormData, 'ND-Root.BT-004-UcelFormulare', UCEL_FORMULARE.ZAKLADA)
    set(this.reqFormData, 'ND-Root.ND-ExistujiciZakazka', undefined)
    set(this.reqFormData, 'ND-Root.BT-002-KodProSouvisejiciFormulare', undefined)
  }

  copyEditFormData = async (resMain: iEformsSubmission<any>) => {
    // nacteni souvisejici editacni podani
    const resEdit = await EformsApi.getSubmissionsChildrenSearch(resMain.id, this.editFormSlug, {
      limit: 1,
    }).then((data: iEformsSubmission<any>[]) => data[0])

    if (!resEdit) {
      throw new Error('Nebyl nalezen editační formulář k hlavnímu formuláři.')
    }
    this.copyFromEdit = resEdit.id
    this.editFormData = resEdit.data
    unset(this.editFormData, 'ND-Root.ND-RootExtension.ND-Changes')

    // Pro vysledkove  formulare tj. 29 - 37 nekopirovat scope:
    if ([FT2.F29, FT2.F30, FT2.F31, FT2.F32, FT2.F33, FT2.F34, FT2.F35, FT2.F36, FT2.F37].includes(this.formType)) {
      unset(this.editFormData, 'ND-Root.ND-RootExtension.ND-NoticeResult.BT-161-NoticeResult')

      const arrayTender = get(this.editFormData, 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender')
      for (let i = 0; i < arrayTender?.length; i++) {
        unset(this.editFormData, `ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.${i}.BT-720-Tender`)
      }

      const arraySettled = get(this.editFormData, 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-SettledContract')
      for (let i = 0; i < arraySettled?.length; i++) {
        unset(this.editFormData, `ND-Root.ND-RootExtension.ND-NoticeResult.ND-SettledContract.${i}.BT-1451-Contract`)
        unset(this.editFormData, `ND-Root.ND-RootExtension.ND-NoticeResult.ND-SettledContract.${i}.BT-145-Contract`)
      }
    }
  }

  copyFormData = async (resMain: iEformsSubmission<any>, resReq?: iEformsSubmission<any>) => {
    await this.loadSchemaAdnUser()
    this.copyMainFormData(resMain)
    this.copyReqFormData(resReq)
    await this.copyEditFormData(resMain)
  }

  /*
  Zalozeni formularu
   */
  getEditFormDataBySchema() {
    return convertDataOrderLikeSchemaSimple(this.editFormData, this.editFormSchema?.schema.properties)
  }

  createForms() {
    return EformsApi.newForm({
      formSlug: this.mainFormSlug,
      orgSlug: this.orgSlug,
      data: this.mainFormData,
      children: undefined,
      parent: undefined,
      related: this.relatedId,
      copyFrom: this.copyFromMain,
    })
      .then((resMain) => {
        const resMainId = resMain.id
        return Promise.all([
          EformsApi.newForm({
            formSlug: this.editFormSlug,
            orgSlug: this.orgSlug,
            data: this.getEditFormDataBySchema(),
            children: undefined,
            parent: resMainId,
            copyFrom: this.copyFromEdit,
          }).catch((e) => {
            throw new Error('Nepodařilo se vytvořit editační formulář')
          }),
          EformsApi.newForm({
            formSlug: SLUG_FORM.ZADOST2,
            orgSlug: this.orgSlug,
            data: this.reqFormData,
            children: undefined,
            parent: resMainId,
            copyFrom: this.copyFromReq,
          }).catch((e) => {
            throw new Error('Nepodařilo se vytvořit editační žádost')
          }),
        ]).then(() => {
          return resMainId
        })
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          throw new Error('Nepodařilo se vytvořit hlavní formulář')
        }
        throw err
      })
  }

  /*
   Navazujici a opravne formulare
   */

  setPrevNumbers = (evCisloFormulare: string, evCisloZakazky: string, kodSouvisFormulare: string) => {
    this.evCisloFormulare = evCisloFormulare
    this.evCisloZakazky = evCisloZakazky
    this.kodSouvisFormulare = kodSouvisFormulare
  }

  initRepair = (repairType: string, tedNum?: string) => {
    this.isRepair = true
    this.repairType = repairType
    this.tedNum = tedNum
  }

  copyMainPrev = (resMain: iEformsSubmission<any>) => {
    this.relatedId = resMain.id
    this.repairType = resMain.data?.formularOpravuje
  }

  loadPrevAllData = async () => {
    this.initPrevData()
    await this.loadPrevMain()
    await this.validatePrev()
    await this.loadPrevReq()
    await this.loadPrevEdit()
  }

  initPrevData() {
    //navazujici formular hlavni
    if (this.isProfile) {
      this.mainFormData.evCisloProfiluVvz = this.evCisloZakazky
    } else {
      this.mainFormData.evCisloZakazkyVvz = this.evCisloZakazky
    }
    this.mainFormData.formularOpravuje = this.isRepair
    this.mainFormData.evCisloVvzSouvisejicihoFormulare = this.evCisloFormulare

    // navazujici formular zadost
    set(this.reqFormData, 'ND-Root.BT-004-UcelFormulare', UCEL_FORMULARE.NAVAZUJE)
    set(this.reqFormData, 'ND-Root.ND-ExistujiciZakazka', {
      'BT-005-EvidencniCislo': this.evCisloZakazky,
      'BT-006-EvidencniCisloFormulare': this.evCisloFormulare,
      'BT-007-KodProSouvisejiciFormular': this.kodSouvisFormulare,
      'BT-008-FormularOpravuje': this.isRepair,
    })
  }

  loadPrevMain = async () => {
    // nacteni souvisejici hlavni podani
    this.prevResMain = await EformsApi.getSubmissionsSearch(SLUG_FORM.HLAVNI, 1, 1, {
      variableId: this.evCisloFormulare,
    }).then((res) => {
      if (res.data.length) {
        return res.data[0]
      } else {
        return EformsApi.getSubmissionsSearch(SLUG_FORM.PROFIL, 1, 1, { variableId: this.evCisloFormulare }).then(
          (res) => res.data[0],
        )
      }
    })
    if (!this.prevResMain) {
      throw new Error('Nenalezen formulář s evidenčním číslem ' + this.evCisloFormulare + '.')
    }
    if (this.prevResMain.data.formularZneplatnen) {
      throw new Error('Není možné navázat formulář na zneplatněný formulář')
    }

    this.relatedId = this.prevResMain.id

    const prevFormType = this.prevResMain?.data.druhFormulare

    const prevResMainEvCislo =
      PROFILE_FORM_TYPES.includes(prevFormType) || PROFILE_FORM_TYPES_V2.includes(prevFormType)
        ? this.prevResMain.data.evCisloProfiluVvz
        : this.prevResMain.data.evCisloZakazkyVvz

    if (prevResMainEvCislo !== this.evCisloZakazky) {
      throw new Error('Evidenční číslo zakázky neodpovídá vyhledanému formuláři.')
    }
  }

  validatePrev = async () => {
    const nowDate = moment().toISOString()
    const validFormConection = await ValidationConnect2Api.validationFormConection(
      this.evCisloZakazky!,
      this.evCisloFormulare!,
      this.kodSouvisFormulare!,
      nowDate,
      this.formType,
      this.isRepair,
    ).catch((err) => {
      throw new Error('Nepodařilo se provést kontrolu návaznosti')
    })

    if (validFormConection !== true) {
      throw new Error('Není možné navázat formulář')
    }
  }

  loadPrevReq = async () => {
    if (!this.prevResMain) {
      throw new Error('Neexistuje predchozí hlavní formulář pro žádost')
    }
    const prevResMainId = this.prevResMain?.id

    // nacteni souvisejici zadost
    this.prevResReq = await EformsApi.getSubmissionsChildrenSearch(
      prevResMainId,
      this.isPrevFormV1() ? SLUG_FORM.ZADOST : SLUG_FORM.ZADOST2,
      {
        limit: 1,
      },
    ).then((data) => data[0])

    if (this.prevResReq) {
      const prevResReqDataSouvZadost = this.isPrevFormV1()
        ? this.prevResReq?.data.kodProSouvisejiciFormulare
        : this.prevResReq?.data['ND-Root']['BT-002-KodProSouvisejiciFormulare']
      if (prevResReqDataSouvZadost && prevResReqDataSouvZadost !== this.kodSouvisFormulare) {
        throw new Error(
          'Kód pro související formuláře neodpovídá formuláři s evidenčním číslem ' + this.evCisloFormulare + '.',
        )
      }

      if (!this.isPrevFormV1()) {
        const reqData = this.prevResReq?.data
        const pathMap = getPathMapReqByFormType(this.formType)
        pathMap.forEach((path) => {
          set(this.reqFormData, path, get(reqData, path))
        })
      }
    }
  }

  loadPrevEditData = async () => {
    const prevResMainId = this.prevResMain!.id
    const prevFormType = this.prevResMain?.data.druhFormulare
    if (!this.isPrevFormV1()) {
      // kopirovani dat jen z vvz2
      const prevEditFormSlug = formTypeToFormSlugV2(prevFormType)
      // nacteni souvisejici editacni podani
      const prevResEdit = await EformsApi.getSubmissionsChildrenSearch(prevResMainId, prevEditFormSlug, {
        limit: 1,
      }).then((data) => data[0])

      if (!prevResEdit) {
        throw new Error('Nebyl nalezen editační formulář k hlavnímu formuláři.')
      }
      return prevResEdit.data
    } else {
      const prevData = await loadXmlFile(this.prevResMain!)
      if (!prevData) {
        console.error('Nepodařilo se zpracovat data starého formuláře.')
      }
      return prevData ?? {}
    }
  }

  calculatePrevTedNum = () => {
    const prevEvCisloTed = this.prevResMain!.data.evCisloTed
    if (this.isPrevFormV1()) {
      const oldTedNum = this.tedNum || (prevEvCisloTed ? prevEvCisloTed : undefined)
      if (oldTedNum) {
        return convertEvCisloTedOld(oldTedNum)
      }
      // pokud  ma stare variableID tak - UUID main formuláře
      if (!this.prevResMain?.variableId.startsWith('F')) {
        return this.prevResMain!.id + '-01'
      }
    } else if (prevEvCisloTed) {
      return prevEvCisloTed
    }

    return convertVariableToEvCisloTed(this.prevResMain!.variableId)
  }

  loadPrevEdit = async () => {
    if (!this.prevResMain) {
      throw new Error('Neexistuje predchozí hlavní formulář pro editaci')
    }

    const prevEditData = await this.loadPrevEditData()

    if (!this.isPrevFormV1()) {
      defaultsDeep(this.editFormData, prevEditData)
      //  nechat organizace z prevEditData aby prepsaly defaultni organizace z createForm
      const prevOrgs = get(prevEditData, 'ND-Root.ND-RootExtension.ND-Organizations.ND-Organization')
      if (prevOrgs) {
        set(this.editFormData, 'ND-Root.ND-RootExtension.ND-Organizations.ND-Organization', prevOrgs)
      }

      const partsData = get(prevEditData, 'ND-Root.ND-Part')
      if (partsData) {
        const lotsData = convertDataKeysVals(
          partsData,
          (key) => key.replace('Part', 'Lot'),
          (value: string) => value.replace(/^PAR-0/, 'LOT-0'),
        )
        set(this.editFormData, 'ND-Root.ND-Lot', lotsData)
      }
      unset(this.editFormData, 'ND-Root.ND-RootExtension.ND-Changes')
    }

    const tedNum = this.calculatePrevTedNum()
    if (ENV.DEBUG_MODE) {
      console.log('DEBUG load prev info', {
        tedNum: tedNum,
        isRepair: this.isRepair,
        isCzForm: this.isCzForm(),
        getPrevFormType: this.getPrevFormType(),
        isPrevFormV1: this.isPrevFormV1(),
      })
    }

    if (!this.isCzForm()) {
      if (this.isRepair) {
        // opravny
        set(this.editFormData, 'ND-Root.ND-RootExtension.ND-Changes', {
          'ND-ChangeReason': { 'BT-140-notice': this.repairType },
          'BT-758-notice': tedNum,
        })
      } else {
        //neopravny
        if (isPrevWithTedNum(this.getPrevFormType(), prevEditData)) {
          const parts = get(this.editFormData, 'ND-Root.ND-Lot')
          const partIndexs = Array.from(Array(parts?.length || 1).keys())
          partIndexs.forEach((index) => {
            set(
              this.editFormData,
              `ND-Root.ND-Lot.${index}.ND-LotTenderingProcess.ND-LotPreviousPlanning.0.BT-125(i)-Lot`,
              tedNum,
            )
          })
        }

        set(this.editFormData, 'ND-Root.ND-RootExtension.ND-ContractModification.0.BT-1501(n)-Contract', tedNum)
      }

      if (this.isPrevFormV1() && (this.tedNum || this.prevResMain?.data.evCisloTed)) {
        // navazuje na stare
        set(
          this.editFormData,
          'ND-Root.ND-ProcedureTenderingProcess.ND-PreviousNoticeReference.0.OPP-090-Procedure',
          tedNum,
        )
      }
    }
  }
}
