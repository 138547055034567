import React from 'react'

interface iLoadingInline {
  inversed?: boolean
}

const LoadingInline = ({ inversed }: iLoadingInline) => (
  <span style={{ display: 'inline-block' }}>
    <span className={'gov-loader ' + (inversed ? 'gov-loader--inversed' : '')}>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </span>
  </span>
)

export default LoadingInline
