import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import GlobalFormsStore from '../../builder/GlobalFormsStore'
import { FORM_TYPES_V2 } from '../../enums/enumFormTypeV2'
import { selectSubmMainInfo } from '../../redux/submissions/selectors'

const PATH_PRICE = 'ND-Root.ND-ProcedureProcurementScope.ND-ProcedureValueEstimate.BT-27-Procedure._value'
const PATH_PRICE_ATTR_CURR =
  'ND-Root.ND-ProcedureProcurementScope.ND-ProcedureValueEstimate.BT-27-Procedure._currencyID'
const PATH_PRICE_EXT =
  'ND-Root.ND-ProcedureProcurementScope.ND-ProcedureValueEstimate.ND-ProcedureValueEstimateExtension.BT-271-Procedure._value'
const PATH_PRICE_EXT_ATTR_CURR =
  'ND-Root.ND-ProcedureProcurementScope.ND-ProcedureValueEstimate.ND-ProcedureValueEstimateExtension.BT-271-Procedure._currencyID'

const PATH_PRICE_CZ7 = 'ND-Root.ND-ObjectContract.BT-707-ValEstimatedTotal'

const PATH_PRICE_REQ = 'ND-Root.ND-StatistickeUdajeFormulare.BT-012-CelkovaPredpokladanaHodnotaKcBezDph'

const VALID_CURRENCY = 'CZK'

let prevSetValue = ''

const getPricesPath = (formType: string) => {
  let path = formType === FORM_TYPES_V2.FCZ07 ? PATH_PRICE_CZ7 : PATH_PRICE
  let pathCurr = PATH_PRICE_ATTR_CURR
  let pathExt = PATH_PRICE_EXT
  let pathCurrExt = PATH_PRICE_EXT_ATTR_CURR
  return { path, pathCurr, pathExt, pathCurrExt }
}
const FormConnectionV2 = () => {
  const submMain = useSelector(selectSubmMainInfo)
  const formType = submMain.formType
  const { path, pathCurr, pathExt, pathCurrExt } = getPricesPath(formType)

  const valPrice = useWatch({
    control: GlobalFormsStore.editForm?.control,
    name: path,
    disabled: !path,
  })

  const currency = useWatch({
    control: GlobalFormsStore.editForm?.control,
    name: pathCurr,
    disabled: !pathCurr,
  })

  const valPriceExt = useWatch({
    control: GlobalFormsStore.editForm?.control,
    name: pathExt,
    disabled: !pathExt,
  })

  const currencyExt = useWatch({
    control: GlobalFormsStore.editForm?.control,
    name: pathCurrExt,
    disabled: !pathCurrExt,
  })

  const oldReqPrice = useWatch({
    control: GlobalFormsStore.reqForm?.control,
    name: PATH_PRICE_REQ,
  })

  useEffect(() => {
    change(valPrice, currency)
  }, [valPrice, currency])

  useEffect(() => {
    change(valPriceExt, currencyExt)
  }, [valPriceExt, currencyExt])

  const change = (val: string, curr: string) => {
    if (curr && curr !== VALID_CURRENCY) {
      // vymazani pokud se zmeni mena
      if (oldReqPrice && oldReqPrice === prevSetValue) {
        changeReqPrice('')
      }
      return
    }

    if (!oldReqPrice || oldReqPrice === prevSetValue) {
      changeReqPrice(val)
    }
  }

  const changeReqPrice = (valPrice: string) => {
    prevSetValue = valPrice
    GlobalFormsStore.reqForm?.setValue(PATH_PRICE_REQ, valPrice)
  }

  return null
}

export default FormConnectionV2
