import React, { useContext } from 'react'

const defValue = {
  formType: '',
  formSchema: '',
  isRequest: false,
}

const FormTypeContextBase = React.createContext(defValue)

export const useFormTypeContext = () => useContext(FormTypeContextBase)

interface iFormTypeContexProvider {
  formType: string
  formSchema: string
  isRequest?: boolean
  children: React.ReactNode
}

export const FormTypeContexProvider = ({ formType, formSchema, isRequest, children }: iFormTypeContexProvider) => {
  // const [formDisabled, setFormDisabled] = useState(false)

  const providerValue = React.useMemo(
    () => ({
      formType: formType,
      formSchema: formSchema,
      isRequest: isRequest || false,
    }),
    [formType, isRequest],
  )

  return <FormTypeContextBase.Provider value={providerValue}>{children}</FormTypeContextBase.Provider>
}

// export const withFormContextBase = (WrapedComp: any) => (props: any) =>
//   (
//     <FormContexProvider>
//       <WrapedComp {...props} />
//     </FormContexProvider>
//   )
