import { iSchemaCondition } from './iSchemaCondition'
import { iSchemaValidations } from './iSchemaValidations'

export const DATA_TYPE = {
  STRING: 'string',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  INTEGER: 'integer',
  OBJECT: 'object',
  ARRAY: 'array',
}

export const DATA_TYPE_FORMAT = {
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'date-time',
  URI: 'uri',
  EMAIL: 'email',
}

export interface iDataSchemas {
  [name: string]: iDataSchema
}

export interface iDataSchema {
  type: string //'array' | 'object' | 'string' | 'number' | 'integer' | 'boolean'
  properties?: iDataSchemas
  items?: iDataSchema
  enum?: string[] | { key: string }
  default?: any
  defaultMode?: string // DATA_SCHEMA_DEFAULT_MODE
  format?: string // 'date'| 'time' | uri|email|tel|ico
  pattern?: string // regexp
  validations?: iSchemaValidations
  conditions?: iSchemaCondition
  maxLength?: number
  minItems?: number
  maxItems?: number
}
