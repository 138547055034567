import React, { useContext } from 'react'

interface iNavigationContext {
  arrayIndexes: string
}

const NavigationContextBase = React.createContext({ arrayIndexes: '' } as iNavigationContext)

export const useNavigationContext = () => useContext(NavigationContextBase)

interface iNavContextProvider {
  arrayIndexes: string
  children: React.ReactNode
}

export const NavigationContextProvider = ({ arrayIndexes, children }: iNavContextProvider) => {
  const providerValue = React.useMemo(
    () => ({
      arrayIndexes: arrayIndexes,
    }),
    [arrayIndexes],
  )

  return <NavigationContextBase.Provider value={providerValue}>{children}</NavigationContextBase.Provider>
}
