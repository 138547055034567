import { iSchemaDatas } from '../interfaces/iSchemaData'

const SUBSCOPE_PUBLICATIONS = '/el_attrs/PUBLICATION'

export const calculateDataScopeNoPublic = (data: iSchemaDatas, scope: string = '#') => {
  const ret: string[] = []
  for (const key in data) {
    const thisScope = scope + '/' + key

    if (thisScope.includes(SUBSCOPE_PUBLICATIONS)) {
      ret.push(thisScope.replaceAll(SUBSCOPE_PUBLICATIONS, ''))
    }
    const thisItem = data[key]
    if (thisItem.properties) {
      ret.push(...calculateDataScopeNoPublic(thisItem.properties, thisScope))
    } else if (thisItem.items?.properties) {
      ret.push(...calculateDataScopeNoPublic(thisItem.items.properties, thisScope))
    }
  }
  return ret
}
