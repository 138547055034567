import React from 'react'
import InputGovDescription from './InputGovDescription'
import InputGovError from './InputGovError'

interface iWrapBlockGov {
  path?: string
  description?: string
  error?: string
  children: React.ReactNode
}

const WrapBlockGov = ({ path, description, error, children }: iWrapBlockGov) => {
  return (
    <div
      id={path ? path + '_wrap' : undefined}
      className={error ? ' blockGovError' : undefined}
      style={{ position: 'relative' }}
    >
      <InputGovDescription text={description} />
      {error && (
        <div className='blockGovErrorText'>
          <InputGovError text={error} />
        </div>
      )}
      {children}
    </div>
  )
}

export default WrapBlockGov
