export const TABLE_TYPES = {
  myForms: 'myForms',
  profiles: 'profiles',
  contracts: 'contracts',
  contractsHp: 'contractsHp',

  newsList: 'newsList',

  newAll: 'newAll',
  newWins: 'newWins',
  newCancel: 'newCancel',
  newContracts: 'newContracts',

  adminContracts: 'adminContracts',

  stornoContracts: 'stornoContracts',

  contractForms: 'contractForms',
  profileForms: 'profileForms',
  profileFormsRepair: 'profileFormsRepair',
}
