import WPApi from '../api/WPApi'
import { useFetch } from './useFetch'

export const useFetchWPHelps = () => {
  const page = 1
  const limit = 100

  const { data, loading, error } = useFetch(() => WPApi.getDocs({ page: page, per_page: limit }))

  const helps = data?.data.map((post: any) => ({
    name: post.title.rendered,
    slug: post.slug,
    acf: post.acf,
  }))

  return { helps, loading, error }
}
