import { useSelector } from 'react-redux'
import { validateLive } from '../../actions/validateLive'
import { CZ_FORM_TYPES_V2 } from '../../enums/enumFormTypeV2'
import { selectFormIsFullInit } from '../../redux/formTree/formTreeSelectors'
import { useAppDispatch } from '../../redux/store'
import { useFormRenderContext } from '../context/RenderFormContext'

const FORM_STABLE_FIELD_PATH = 'ND-Root.ND-RootExtension.OPP-070-notice'
const FORM_STABLE_FIELD_PATH_CZ = 'ND-Root.BT-001-DruhFormulare'
const REQ_STABLE_FIELD_PATH = 'ND-Root.BT-001-DruhFormulare'

export const useFormLiveValidation = () => {
  const dispatch = useAppDispatch()

  const { isReq, liveValidation, formType } = useFormRenderContext()
  const isFullInit = useSelector(selectFormIsFullInit)

  const validateFormLive = (force?: boolean) => {
    if ((!liveValidation || !isFullInit) && !force) {
      return
    }
    const stableFieldPath = isReq
      ? REQ_STABLE_FIELD_PATH
      : formType && CZ_FORM_TYPES_V2.includes(formType)
      ? FORM_STABLE_FIELD_PATH_CZ
      : FORM_STABLE_FIELD_PATH

    dispatch(validateLive(isReq, stableFieldPath))
  }

  return { validateFormLive }
}
