import React from 'react'
import { anchorLinks } from '../../../utils/anchorLinks'
import { useElementContext } from '../../context/ElementContext'
import { NavigationContextProvider, useNavigationContext } from '../../context/NavigationContext'

interface iNavigationAnchor {
  indexArray?: number
  children: React.ReactNode
}

const NavigationAnchor = ({ indexArray, children }: iNavigationAnchor) => {
  const { uiSchema } = useElementContext()
  const arrayIndexes = useNavigationContext().arrayIndexes
  const anchorSufix = arrayIndexes + (indexArray !== undefined ? '_' + indexArray : '')
  return (
    <div id={anchorLinks(uiSchema) + anchorSufix} className='nav-anchor-section'>
      <NavigationContextProvider arrayIndexes={anchorSufix}>{children}</NavigationContextProvider>
    </div>
  )
}

export default NavigationAnchor
