import VisibleFieldsApi from '../api/VisibleFieldsApi'
import { setGlobalHidden } from '../redux/formErrors/formErrorsSlice'
import { AppThunk } from '../redux/store'
import FormFullInit from '../services/FormFullInit'
import { proccessFields } from './utils/processErrors'

export const loadGlobalHidden = (formId: string): AppThunk => {
  return (dispatch) => {
    VisibleFieldsApi.hiddenFields(formId!)
      .then((res) => {
        const globalHidden = proccessFields(res.skryteFieldy)
        dispatch(setGlobalHidden(globalHidden))
        FormFullInit.setHiddenPaths(globalHidden.map((h) => h.atribut))
      })
      .catch((e) => {
        console.log(e)
      })
  }
}
