import React, { useContext, useState } from 'react'
import { iEformsFileData } from '../../interfaces/iEformsApi'

interface iRenderFormFilesContext {
  formFiles: { [id: string]: iEformsFileData }
  addFormFile: (file: iEformsFileData) => void
  removeFormFile: (fileId: string) => void
  isFileUploading: boolean
  addUploadingPath: (path: string) => void
  removeUploadingPath: (path: string) => void
}

const FormFilesRenderContextBase = React.createContext({} as iRenderFormFilesContext)

export const useFormFilesRenderContext = () => useContext(FormFilesRenderContextBase)

interface iFormFilesRenderContexProvider {
  files?: { [id: string]: iEformsFileData }
  children: React.ReactNode
}

export const FormFilesRenderContexProvider = ({ files = {}, children }: iFormFilesRenderContexProvider) => {
  const [formFiles, setFormFiles] = useState(files)
  const [uploadingPaths, setUploadingPaths] = useState<string[]>([])

  const providerValue = React.useMemo(
    () => ({
      formFiles: formFiles,
      addFormFile: (file: iEformsFileData) => {
        setFormFiles((formFiles) => ({ ...formFiles, [file.id]: file }))
      },
      removeFormFile: (fileId: string) => {
        setFormFiles((formFiles) => {
          const copy = { ...formFiles }
          delete copy[fileId]
          // const { [fileId]: tmp, ...copy } = formFiles;
          return copy
        })
      },
      // uploadingPaths: uploadingPaths,
      isFileUploading: !!uploadingPaths.length,
      addUploadingPath: (path: string) => {
        setUploadingPaths((uploadingPaths) =>
          uploadingPaths.includes(path) ? uploadingPaths : [...uploadingPaths, path],
        )
      },
      removeUploadingPath: (path: string) => {
        setUploadingPaths((uploadingPaths) => uploadingPaths.filter((p) => p !== path))
      },
    }),
    [formFiles, uploadingPaths],
  )

  return <FormFilesRenderContextBase.Provider value={providerValue}>{children}</FormFilesRenderContextBase.Provider>
}

