import { ControlProps, OwnPropsOfEnum, rankWith, uiTypeIs } from '@jsonforms/core'
import { useEffect, useState } from 'react'
import Loading from '../../../components/Loading/Loading'
import { useEnum } from '../../hooks/useEnum'
import { withJsonFormsEnumPropsCustom } from '../../core/withJsonFormsEnumPropsCustom'
import DebugElement from '../../DebugElement'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

interface iOption {
  label: string
  value: string
}

const GovInputSelect = (props: ControlProps & OwnPropsOfEnum) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
    options,
  } = props

  const enumId = uischema.options?.enumId
  const disabled = !enabled

  const [actOptions, setActOptions] = useState((enumId ? [] : options) as iOption[])
  const { enumData, loading } = useEnum(visible ? enumId : undefined)

  useEffect(() => {
    if (enumData && visible) {
      const options = enumData.map(({ code }: any) => ({ value: code, label: code }))
      setActOptions(options)
    }
  }, [enumData])

  if (!visible) {
    return null
  }

  if (loading) {
    return <Loading />
  }

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov uischema={uischema} value={data} description={description} error={errors} path={path}>
      <DebugElement data={props} />
      <div className={'gov-select ' + (disabled ? 'selectDisabled' : '')}>
        <select
          id={elId}
          disabled={disabled}
          value={data !== undefined ? data : ''}
          onChange={(e) => handleChange(path, e.target.value || undefined)}
          aria-required={required ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-labelledby={elId}
        >
          <option value=''></option>
          {actOptions?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>

        <InputGovLabel label={label} inputId={elId} required={required} />
      </div>
    </WrapInputGov>
  )
}

export default withJsonFormsEnumPropsCustom(GovInputSelect)

export const govInputSelectTester = rankWith(3, uiTypeIs(UiElTypesInput.Select))
