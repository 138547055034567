import { createSelector } from '@reduxjs/toolkit'
import concat from 'lodash/concat'
import { RootState } from '../rootReducer'

export const selectFormErrors = (state: RootState) => state.formErrors.formErrors
export const selectFormReqErrors = (state: RootState) => state.formErrors.reqErrors

export const selectFormLiveErrors = (state: RootState) => state.formErrors.formLiveErrors
export const selectFormReqLiveErrors = (state: RootState) => state.formErrors.reqLiveErrors

export const selectFormErrorActive = createSelector(
  selectFormErrors,
  selectFormLiveErrors,
  (formError, formErrorLive) => concat(formErrorLive ?? [], formError ?? []),
)

export const selectFormReqErrorActive = createSelector(
  selectFormReqErrors,
  selectFormReqLiveErrors,
  (formErrorReq, formErrorReqLive) => concat(formErrorReqLive ?? [], formErrorReq ?? []),
)

export const selectFormMandatory = (state: RootState) => state.formErrors.mandatoryFields
export const selectFormForbidden = (state: RootState) => state.formErrors.forbiddenFields
export const selectFormReqMandatory = (state: RootState) => state.formErrors.mandatoryFieldsReq
export const selectFormReqForbidden = (state: RootState) => state.formErrors.forbiddenFieldsReq
export const selectGlobalHidden = (state: RootState) => state.formErrors.globalHidden
export const selectFormHiddenOnly = (state: RootState) => state.formErrors.formHidden
export const selectFormReqHiddenOnly = (state: RootState) => state.formErrors.reqHidden
export const selectFormHidden = createSelector(selectFormHiddenOnly, selectGlobalHidden, (formHidden, global) =>
  concat(formHidden ?? [], global ?? []),
)
export const selectFormReqHidden = createSelector(selectFormReqHiddenOnly, selectGlobalHidden, (reqHidden, global) =>
  concat(reqHidden ?? [], global ?? []),
)
export const selectOpenErrorPanel = (state: RootState) => state.formErrors.openPanel
