import axios from 'axios'

const API_URL = process.env.REACT_APP_WP_API_URL

interface iWPApiParams {
  page: number
  per_page: number
  categories?: number[]
  tags?: number[]
  post_type?: string
}

// https://developer.wordpress.org/rest-api/reference/
class WPApi {
  api = axios.create({
    baseURL: API_URL + 'wp-json/wp/v2/',
  })

  getList = (postType = 'posts', params: any) =>
    this.api
      .get(`/${postType}`, {
        params: params,
      })
      .then((res) => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-wp-total']),
      }))

  getBySlug = (slug: string, postType = 'posts') =>
    this.api
      .get(`/${postType}/`, {
        params: {
          slug: slug,
        },
      })
      .then((res) => res.data[0])

  getById = (id: string, postType = 'posts') => this.api.get(`/${postType}/${id}`).then((res) => res.data)

  /*******************/

  getPosts = (params: iWPApiParams) => this.getList('posts', params)
  // getPost = (id: string) => this.getById(id)
  getPostBySlug = (slug: string) => this.getBySlug(slug)

  getDocs = (params: iWPApiParams) => this.getList('napoveda-dokumentace', params)

  getDocBySlug = (slug: string) => this.getBySlug(slug, 'napoveda-dokumentace')
}

export default new WPApi()
