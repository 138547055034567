export const ELEMENT_ID_SUFIX_WRAP = '_wrap'
export const ELEMENT_ID_SUFIX_LABEL = '_label'
export const scrollToPathV1 = (path: string) => {
  const elId = path.slice(1).replaceAll('/', '.') + ELEMENT_ID_SUFIX_WRAP
  const element = document.getElementById(elId)
  const elIdLabel = path.slice(1).replaceAll('/', '.') + ELEMENT_ID_SUFIX_LABEL
  const elementLabel = document.getElementById(elIdLabel)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  } else if (elementLabel) {
    elementLabel.scrollIntoView({ behavior: 'smooth' })
  }
}
