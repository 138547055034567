import React, { ChangeEventHandler } from 'react'
import InputGovLabel from '../InputsUtils/InputGovLabel'
import WrapInputGov from '../InputsUtils/WrapInputGov'

interface iSelectInput {
  name: string
  label?: string
  description?: string
  value?: string
  onChange?: ChangeEventHandler<any>
  options: { label: string; value: string }[]
  error?: string
  tooltip?: string
  disabled?: boolean
  required?: boolean
}

const SelectInput = ({
  name,
  label,
  description,
  value,
  onChange,
  options,
  error,
  tooltip,
  disabled,
  required,
}: iSelectInput) => {
  const id = 'select-' + name
  return (
    <>
      <WrapInputGov value={value} error={error} description={description} tooltip={tooltip}>
        <div className={'gov-select ' + (disabled ? 'selectDisabled' : '')}>
          <select
            id={id}
            onChange={onChange}
            value={value}
            name={name}
            disabled={disabled}
            aria-required={required ? 'true' : 'false'}
            aria-disabled={disabled ? 'true' : 'false'}
          >
            <option value=''></option>
            {options?.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <InputGovLabel inputId={id} label={label} required={required} />
        </div>
      </WrapInputGov>
    </>
  )
}

export default SelectInput
