import React from 'react'
import { Link } from 'react-router-dom'
import { SLUG_FORM } from '../../api/EformsApi'
import AlertError from '../../components/Alert/AlertError'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import TextTrim from '../../components/TextTrim/TextTrim'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { isFormDraft } from '../../enums/enumWorkflowPlaces'
import { useFetchForms } from '../../hooks/useFetchForms'
import { useNavigateToFormVariableId } from '../../hooks/useNavigateToFormVariableId'
import { iSubmissionDataHlavni } from '../../interfaces/iEformsSubmission'
import { dateFormatShort } from '../../utils/dateFormat'

interface iTableContractFormsProfileRepair {
  id: string
}

const TableContractFormsProfileRepair = ({ id }: iTableContractFormsProfileRepair) => {
  const formSlug = SLUG_FORM.HLAVNI
  const { data, loading, error, totalCount, maxItems } = useFetchForms<iSubmissionDataHlavni[]>(formSlug, {
    'data.evCisloZakazkyVvz': id,
    'order[data.datumUverejneniVvz]': 'DESC',
    'order[createdAt]': 'DESC',
  })

  const { navigateToFormVariableId } = useNavigateToFormVariableId(SLUG_FORM.PROFIL)

  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }
  if (!data?.length) {
    return null
  }

  return (
    <>
      <h2>Opravné formuláře</h2>
      <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
        <table className='gov-table gov-sortable-table gov-table--mobile-block'>
          <thead>
            <tr>
              <th>Ev. č. formuláře</th>
              <th>Navazuje na formulář</th>
              <th>Název zadavatele</th>
              <th>Název profilu</th>
              <th>Druh</th>
              <th>Dat. uveřejnění</th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((form, index) => (
              <tr key={index}>
                <td>
                  <Link to={LINKS.vyhledatFormular + '/' + form.id}>
                    {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}
                  </Link>
                </td>
                <td>
                  {form.data.evCisloVvzSouvisejicihoFormulare && (
                    <Link to={''} onClick={() => navigateToFormVariableId(form.data.evCisloVvzSouvisejicihoFormulare!)}>
                      {form.data.evCisloVvzSouvisejicihoFormulare}
                    </Link>
                  )}
                </td>
                <td>{form.data.zadavatele?.map((z) => z.nazev).join(', ')}</td>
                <td className='cellContractName'>
                  <TextTrim text={form.data.nazevZakazky} length={200} />
                </td>
                <td className='gov-table__cell--narrow'>{formTypeToCodeLabel(form.data.druhFormulare)}</td>
                <td className='gov-table__cell--narrow'>{dateFormatShort(form.data.datumUverejneniVvz)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginatorPerPage totalCount={totalCount} maxItems={maxItems} />
    </>
  )
}

export default TableContractFormsProfileRepair
