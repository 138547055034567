import axios from 'axios'
import { Buffer } from 'buffer'

const API_URL = process.env.REACT_APP_HELP_API_URL

class HelpApi {
  api = axios.create({
    baseURL: API_URL,
  })

  getHelpText = (typFormulare: string, xPath: string) => {
    const xPathBase64 = Buffer.from(xPath).toString('base64')
    return this.api
      .get(`/napoveda/text-napovedy/${typFormulare}/${xPathBase64}`) //
      .then((res) => res.data.text)
  }
}

export default new HelpApi()
