import format from 'date-fns/format'
import parse from 'date-fns/parse'

export const dateParseShort = (date?: string) => (date ? parse(date, DATE_FORMAT_SHORT, new Date()) : undefined)
export const dateParseShortTZ = (date?: string) => (date ? parse(date, DATE_FORMAT_SHORT_TZ, new Date()) : undefined)
export const dateParseTime = (date?: string) => (date ? parse(date, DATE_FORMAT_TIME, new Date()) : undefined)
export const dateParseTimeTZ = (date?: string, baseDate?: string) =>
  date ? parse(date, DATE_FORMAT_TIME_TZ, baseDate ? new Date(baseDate) : new Date()) : undefined
// export const dateParseTimeTZ = (date?: string) => (date ? parse(date.slice(0,8), DATE_FORMAT_TIME_TZ.slice(0,8), new Date()) : undefined)

export const formatDateShort = (date?: Date | null) => (date ? format(date, DATE_FORMAT_SHORT) : undefined)
export const formatDateTime = (date?: Date | null) => (date ? format(date, DATE_FORMAT_TIME) : undefined)
export const formatDateShortTZ = (date?: Date | null) => (date ? format(date, DATE_FORMAT_SHORT_TZ) : undefined)
export const formatDateTimeTZ = (date?: Date | null) => (date ? format(date, DATE_FORMAT_TIME_TZ) : undefined)

export const formatDateShortView = (date?: Date | null) =>  (date ? format(date, DATE_FORMAT_DATEPICKER_SHORT) : undefined)
export const formatDateFullView = (date?: Date | null) => (date ? format(date, DATE_FORMAT_DATEPICKER_FULL) : undefined)
export const formatTimeView = (date?: Date | null) => (date ? format(date, DATE_FORMAT_DATEPICKER_TIME) : undefined)

export const DATE_FORMAT_ISO8601 = 'yyyy-MM-ddTHH:mm:ssZ'
export const DATE_FORMAT_SHORT = 'yyyy-MM-dd'
export const DATE_FORMAT_SHORT_TZ = 'yyyy-MM-ddxxx'
export const DATE_FORMAT_TIME = 'HH:mm'
export const DATE_FORMAT_TIME_TZ = 'HH:mm:ssxxx'

export const DATE_FORMAT_DATEPICKER_SHORT = 'dd.MM.yyyy'
export const DATE_FORMAT_DATEPICKER_SHORT_MONTH = 'MM.yyyy'
export const DATE_FORMAT_DATEPICKER_FULL = 'dd.MM.yyyy HH:mm'
export const DATE_FORMAT_DATEPICKER_TIME = 'HH:mm'
