import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import WPApi from '../../api/WPApi'
import AlertError from '../../components/Alert/AlertError'
import Loading from '../../components/Loading/Loading'
import PageWrap from '../../components/PageWrap/PageWrap'
import { useFetch } from '../../hooks/useFetch'
import { dateFormatShort } from '../../utils/dateFormat'

const InformaceDetailPage = () => {
  const { t } = useTranslation()
  const { slug } = useParams()

  const { data: post, loading, error, fetchData } = useFetch(() => WPApi.getPostBySlug(slug!))

  useEffect(() => {
    fetchData()
  }, [slug])

  if (loading) {
    return <Loading />
  }
  if (error || !post) {
    return <AlertError msg={t('news.err_fetch_detail')} />
  }

  return (
    <PageWrap title={post.title.rendered}>
      <span className='gov-badge u-mb--20'> {dateFormatShort(post.date)} </span>

      <div className='u-mb--50' dangerouslySetInnerHTML={{ __html: post.content.rendered }} />

    </PageWrap>
  )
}

export default InformaceDetailPage
