import {
  AnyAction,
  ControlProps,
  DispatchPropsOfControl,
  getConfig,
  getData,
  getSchema,
  getTranslator,
  JsonFormsState,
  OwnPropsOfControl,
  Resolve,
  StatePropsOfControl,
  update,
} from '@jsonforms/core'
import { JsonFormsStateContext, withJsonFormsContext } from '@jsonforms/react'
import React, { ComponentType, Dispatch, useMemo } from 'react'
import { scopeFullToPath, scopeWithoutRepeat } from '../../utils/scopeModifs'
import { iUIschema } from '../interfaces/iUiSchema'
import { isVisibleRules } from '../rules/isVisibleRules'
import { getErrorsFromScope } from '../utils/getErrorsFromScope'
import { getSchemaItem } from '../utils/getSchemaItem'
import { getTranlateLabelFullKey } from '../utils/getTranslateUiSchema'

export interface CustomPropsOfControl extends Omit<OwnPropsOfControl, 'uischema'> {
  uischema: iUIschema
}

export interface CustomStatePropsOfControl extends Omit<StatePropsOfControl, 'uischema'> {
  uischema: iUIschema
}

export const withJsonFormsControlPropsCustom = (
  Component: ComponentType<ControlProps>,
  memoize = true,
): ComponentType<CustomPropsOfControl> =>
  withJsonFormsContext(withContextToControlPropsCustom(memoize ? React.memo(Component) : Component))

const withContextToControlPropsCustom =
  (Component: ComponentType<ControlProps>): ComponentType<CustomPropsOfControl> =>
  ({ ctx, props }: JsonFormsStateContext /*& ControlProps*/) => {
    // ({ ctx, props }: any) => {
    const controlProps = ctxToControlPropsCustom(ctx, props)
    // const dispatchProps = ctxDispatchToControlPropsCustom(ctx.dispatch)
    const excludes = (controlProps.uischema as iUIschema).exclude
    const dispatchProps = useMemo(
      () => mapDispatchToControlPropsCustom(ctx.dispatch, excludes),
      [ctx.dispatch, excludes],
    )
    return <Component {...props} {...controlProps} {...dispatchProps} />
  }

export const ctxToControlPropsCustom = (ctx: JsonFormsStateContext, props: CustomPropsOfControl) =>
  mapStateToControlPropsCustom({ jsonforms: { ...ctx } }, props)

// export const ctxDispatchToControlPropsCustom = (dispatch: Dispatch<ReducerAction<any>>): DispatchPropsOfControl =>
//   useMemo(() => mapDispatchToControlPropsCustom(dispatch as any), [dispatch])

export const mapDispatchToControlPropsCustom = (
  dispatch: Dispatch<AnyAction>,
  excludes?: string[],
): DispatchPropsOfControl => ({
  handleChange(path, value) {
    if (excludes) {
      excludes.forEach((ex) => {
        //TOTO relativni scope ne jen posledni cast
        const exPath = ex.startsWith('#') ? scopeFullToPath(ex) : path.slice(0, path.lastIndexOf('.')) + '.' + ex
        dispatch(update(exPath, () => undefined))
      })
    }

    dispatch(update(path, () => value))
  },
})

export const mapStateToControlPropsCustom = (
  state: JsonFormsState,
  ownProps: CustomPropsOfControl,
): CustomStatePropsOfControl => {
  const { uischema } = ownProps
  const rootData = getData(state)
  const path = uischema?.scope ? scopeFullToPath(uischema.scope) : ''
  const id = ownProps.id || ''
  const rootSchema = getSchema(state)

  const resolvedSchema = getSchemaItem(rootSchema, uischema.scope)
  const errors = getErrorsFromScope(state, uischema.scope)

  const data = Resolve.data(rootData, path)

  const allRules = uischema.allRules!
  const { enabled, visible } = isVisibleRules(rootData, allRules)
  const required = enabled && (resolvedSchema as any)?.validations?.required && !uischema.options?.likeType

  const config = getConfig(state)
  const simpleScope = scopeWithoutRepeat(uischema?.scope || '')
  const isNoPublic =
    config?.noPubliScopes?.some((noPublicScope: string) => simpleScope.startsWith(noPublicScope)) && data === undefined

  const schema = resolvedSchema ?? rootSchema

  const label = uischema.label
  const t = getTranslator()(state)
  const translateFullKey = getTranlateLabelFullKey(uischema)
  const translateText = label !== undefined ? label : t(translateFullKey, translateFullKey)

  return {
    data,
    errors: errors.map((e) => e.message).join(', '),
    label: translateText,
    visible: visible && !isNoPublic,
    enabled: enabled && !state.jsonforms.readonly,
    id: id,
    path,
    required,
    uischema: uischema,
    schema,
    config,
    cells: ownProps.cells || state.jsonforms.cells,
    rootSchema,
  }
}

