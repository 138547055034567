import get from 'lodash/get'
import { iFormTree } from '../builder/utils/createFormTree'

export const treeIndexesToPath = (indexes: number[]) => indexes.join('.childs.')

export const getSiblingTreeEl = (formTree: iFormTree[], indexes: number[], dir: number): iFormTree | undefined => {
  const actIndexes = [...indexes]
  const lastId = actIndexes.length - 1
  actIndexes[lastId] += dir
  const path = treeIndexesToPath(actIndexes)
  const formTreeEl: iFormTree | undefined = get(formTree, path)
  if (formTreeEl?.isInput) {
    return undefined
  }

  // pokud je prvek hidden, preskok na dalsi
  if (formTreeEl?.isHidden) {
    return getSiblingTreeEl(formTree, actIndexes, dir)
  }
  // pokud je sousedni prvek array tak se prejde na jeho prvni/posledni prvek
  if (formTreeEl?.isArray && formTreeEl.childs?.length) {
    return dir < 0 ? formTreeEl.childs.slice(-1)[0] : formTreeEl.childs[0]
  }
  // pokud se nenasel element a je tento prvek v array, tak se bude hledat soused pro parenta
  if (!formTreeEl && actIndexes.length > 1) {
    const parentIndexes = actIndexes.slice(0, -1)
    const parentPath = treeIndexesToPath(parentIndexes)
    const parentEl = get(formTree, parentPath)
    if (parentEl?.isArray) {
      return getSiblingTreeEl(formTree, parentIndexes, dir)
    }
  }

  return formTreeEl
}

