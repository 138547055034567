import React from 'react'
import { useModal } from '../../hooks/useModal'
import ButtonGov from '../Btns/ButtonGov'

const ModalHelpSimple = () => {
  const { closeModal, dataHelpSimple } = useModal()

  if (!dataHelpSimple) {
    return null
  }

  const clickCancel = () => {
    closeModal()
  }

  return (
    <>
      <div className='u-mb--50' style={{ whiteSpace: 'pre-wrap' }}>
        {dataHelpSimple}
      </div>
      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zavřít
      </ButtonGov>
    </>
  )
}

export default ModalHelpSimple
