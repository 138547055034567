import GovAresBtn, { govAresBtnTester } from './GovAresBtn'
import GovIamBtn, { govIamBtnTester } from './GovIamBtn'
import GovInputAutocomplete, { govInputAutocompleteTester } from './GovInputAutocomplete'
import GovInputCheck, { govInputCheckTester } from './GovInputCheckbox'
import GovInputCheckboxBoolean, { govInputCheckBooleanTester } from './GovInputCheckboxBoolean'
import GovInputCheckboxMulti, { govInputCheckMultiTester } from './GovInputCheckboxMulti'
import GovInputDate, { govInputDateTester } from './GovInputDate'
import GovInputDateParts, { govInputDatePartsTester } from './GovInputDateParts'
import GovInputHidden, { govInputHiddenTester } from './GovInputHidden'
import GovInputRadio, { govInputRadioTester } from './GovInputRadio'
import GovInputRadioAlone, { govInputRadioAloneTester } from './GovInputRadioAlone'
import GovInputRadioTrueFalse, { govInputRadioTrueFalseTester } from './GovInputRadioTrueFalse'
import GovInputSelect, { govInputSelectTester } from './GovInputSelect'
import GovInput, { govInputTester } from './GovInputText'
import GovInputTextarea, { govInputTextareaTester } from './GovInputTextarea'

export const rederersInput = [
  { renderer: GovInput, tester: govInputTester },
  { renderer: GovInputCheck, tester: govInputCheckTester },
  { renderer: GovInputCheckboxBoolean, tester: govInputCheckBooleanTester },
  { renderer: GovInputCheckboxMulti, tester: govInputCheckMultiTester},
  { renderer: GovInputTextarea, tester: govInputTextareaTester },
  { renderer: GovInputSelect, tester: govInputSelectTester },
  { renderer: GovInputRadio, tester: govInputRadioTester },
  { renderer: GovInputRadioAlone, tester: govInputRadioAloneTester },
  { renderer: GovInputRadioTrueFalse, tester: govInputRadioTrueFalseTester },
  { renderer: GovInputHidden, tester: govInputHiddenTester },
  { renderer: GovAresBtn, tester: govAresBtnTester },
  { renderer: GovIamBtn, tester: govIamBtnTester },
  { renderer: GovInputDate, tester: govInputDateTester },
  { renderer: GovInputDateParts, tester: govInputDatePartsTester },
  { renderer: GovInputAutocomplete, tester: govInputAutocompleteTester },
]
