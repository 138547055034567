import axios from 'axios'

const API_URL = process.env.REACT_APP_NOTIF_API_URL

class NotifApi {
  api = axios.create({
    baseURL: API_URL,
  })

  sendNotif = (email: string, lang: string = 'CZ') =>
    this.api
      .post(`/iniciacni-email`, {
        email: email,
        lokalizace: lang, //"CZ" / "EN"
      })
      .then((res) => res.data)
}

export default new NotifApi()
