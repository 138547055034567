import { iUIschema } from '../interfaces/iUiSchema'

export interface iScopeToLabel {
  [scope: string]: string
}

export const findSchemaScopeToLabel = (uischemas?: iUIschema[]): iScopeToLabel => {
  if (!uischemas || !Array.isArray(uischemas)) {
    return {}
  }

  let ret: any = {}

  uischemas.forEach((el) => {
    if (el.scope && el.label) {
      ret[el.scope] = el.label
    }
    const childLabels = findSchemaScopeToLabel(el.elements)
    ret = { ...ret, ...childLabels }
  })

  return ret
}
