import React from 'react'

interface iFormInputLabel {
  inputId: string
  label?: string
  required?: boolean
  withIndicator?: boolean
  noLeftPadding?: boolean
}

const InputGovLabel = ({ inputId, label, required, withIndicator, noLeftPadding }: iFormInputLabel) => {
  if (!label) {
    return null
  }

  return (
    <>
      <label className={'gov-form-control__label ' + (noLeftPadding ? 'noLeftPadding' : '')} htmlFor={inputId}>
        {label}
        {required && '*'}
      </label>
      {withIndicator && <span className='gov-form-control__indicator'></span>}
    </>
  )
}

export default InputGovLabel
