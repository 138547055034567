import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AlertError from '../../components/Alert/AlertError'
import { LINKS } from '../../components/App/LINKS'
import ButtonGov from '../../components/Btns/ButtonGov'
import ButtonGovLink from '../../components/Btns/ButtonGovLink'
import Loading from '../../components/Loading/Loading'
import { useFetchWPNews } from '../../hooks/useFetchWPNews'
import { useModal } from '../../hooks/useModal'

const NewsHp = () => {
  const { t } = useTranslation()
  const { posts, loading, error } = useFetchWPNews(true)
  const { openModalNewsletter } = useModal()

  const NewsHpList = () => {
    if (loading) {
      return <Loading />
    }
    if (error) {
      return <AlertError msg={t('news.err_fetch_list')} />
    }
    return (
      <div className='gov-actuality--links'>
        {posts.map((item: any, index: number) => (
          <Link key={index} to={LINKS.aktuality + '/' + item.link} className='gov-actuality--link'>
            <div className='gov-actuality--single'>
              <h3 dangerouslySetInnerHTML={{ __html: item.name }} className='gov-actuality--single-title' />
              <p>
                <span className='gov-actuality--date'> {item.date} </span>
              </p>
              <div dangerouslySetInnerHTML={{ __html: item.text }} className='gov-actuality--text' />
            </div>
          </Link>
        ))}
      </div>
    )
  }

  return (
    <div className='u-mb--40'>
      <h2 className='gov-actuality--title'>{t('news.aktuality_informace', 'Aktuality a informace')}</h2>
      <NewsHpList />
      <div className='gov-actuality--button u-align--right'>
        <ButtonGov variant='primary' className='u-me--10 ' onClick={openModalNewsletter}>
          {t('modalNotif.odebirat_informace', 'Odebírat informace o nových aktualitách')}
        </ButtonGov>
        <ButtonGovLink to={LINKS.aktuality} variant='primary-outlined'>
          {t('news.dalsi_aktuality', 'Další aktuality')}
        </ButtonGovLink>
      </div>
    </div>
  )
}

export default NewsHp
