import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import {
  selectFormErrors,
  selectFormForbidden,
  selectFormHidden,
  selectFormLiveErrors,
  selectFormMandatory,
  selectFormReqErrors,
  selectFormReqForbidden,
  selectFormReqHidden,
  selectFormReqLiveErrors,
  selectFormReqMandatory,
} from './selectors'

export const makeFindFormLiveErrors = (isReq: boolean) => () =>
  createSelector(
    isReq ? selectFormReqLiveErrors : selectFormLiveErrors,
    (_: RootState, path: string) => path,
    (items, path) => (path && items ? items.filter((item) => item.atribut === path) : []),
  )

export const makeFindFormErrors = (isReq: boolean) => () =>
  createSelector(
    isReq ? selectFormReqErrors : selectFormErrors,
    (_: RootState, path: string) => path,
    (items, path) => (path && items ? items.filter((item) => item.atribut === path) : []),
  )

export const makeFindFormLiveRequired = (isReq: boolean) => () =>
  createSelector(
    isReq ? selectFormReqMandatory : selectFormMandatory,
    (_: RootState, path: string) => path,
    (items, path: string) =>
      path && items ? items.filter((item) => item.atribut === path).map((item) => item.chyba ?? '') : [],
  )

export const makeFindFormLiveForbiden = (isReq: boolean) => () =>
  createSelector(
    isReq ? selectFormReqForbidden : selectFormForbidden,
    (_: RootState, path: string) => path,
    (items, path) =>
      path && items ? items.filter((item) => item.atribut === path).map((item) => item.chyba ?? '') : [],
  )

export const makeFindFormLiveHidden = (isReq: boolean) => () =>
  createSelector(
    isReq ? selectFormReqHidden : selectFormHidden,
    (_: RootState, path: string) => path,
    (items, path) => (path && items ? items.filter((item) => path.startsWith(item.atribut)) : []),
  )

export const makeFindFormLiveForbidenRe = (isReq: boolean) => () =>
  createSelector(
    isReq ? selectFormReqForbidden : selectFormForbidden,
    (_: RootState, path?: RegExp) => path,
    (items, path) =>
      path && items ? items.filter((item) => path.test(item.atribut)).map((item) => item.chyba ?? '') : [],
  )
