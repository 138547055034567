import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iSendFormResponse } from '../../api/SendFormApi'

interface iFormState {
  actionLoading: boolean
  userFormName: string
  withRequest: boolean
  formLoadErr: string
  responseSent?: iSendFormResponse
  isValid: boolean
  loadFormData: boolean
  loadReqData: boolean
}

const slice = createSlice({
  name: 'formState',
  initialState: {
    actionLoading: false,
    userFormName: '',
    withRequest: true,
    formLoadErr: '',
    responseSent: undefined,
    isValid: false,
    loadFormData: false,
    loadReqData: false,
  } as iFormState,
  reducers: {
    clearFormState(state) {
      state.formLoadErr = ''
      state.withRequest = true
      state.actionLoading = false
      state.userFormName = ''
      state.isValid = false
    },
    setFormActionLoading(state, action: PayloadAction<boolean>) {
      state.actionLoading = action.payload
    },
    setFormUserFormName(state, action: PayloadAction<string>) {
      state.userFormName = action.payload
      state.isValid = false
    },
    setFormWithRequest(state, action: PayloadAction<boolean>) {
      state.withRequest = action.payload
    },
    setFormLoadErr(state, action: PayloadAction<string>) {
      state.formLoadErr = action.payload
    },
    setFormResponseSent(state, action: PayloadAction<iSendFormResponse | undefined>) {
      state.responseSent = action.payload
    },
    setIsValid(state) {
      state.isValid = true
    },
    clearIsValid(state) {
      state.isValid = false
    },
    setLoadFormData(state, action: PayloadAction<boolean>) {
      state.loadFormData = action.payload
    },
    setLoadReqData(state, action: PayloadAction<boolean>) {
      state.loadReqData = action.payload
    },
  },
})

export const {
  clearFormState,
  setFormActionLoading,
  setFormUserFormName,
  setFormWithRequest,
  setFormLoadErr,
  setFormResponseSent,
  setIsValid,
  clearIsValid,
  setLoadFormData,
  setLoadReqData,
} = slice.actions
export default slice.reducer
