import Ajv from 'ajv'
import addFormats from 'ajv-formats'

const ajv = new Ajv({
  allErrors: true,
  verbose: true,
})

export const SCHEMA_DATA_FORMAT = {
  // date: 'date',
  // time: 'time',
  uri: 'uri',
  email: 'email',
}

addFormats(ajv, { mode: 'fast', formats: Object.values(SCHEMA_DATA_FORMAT) as any })

// ajv.addFormat(SCHEMA_DATA_FORMAT.time, /^[0-2][0-9]:[0-6][0-9]$/)

export default ajv
