import get from 'lodash/get'
import { iSchemaDatas } from '../interfaces/iSchemaData'
import { iUIschema } from '../interfaces/iUiSchema'
import { iUiSchemaRule } from '../interfaces/iUiSchemaRule'
import { UiElTypesInput, UiElTypesLayout } from '../elements/uiElTypes'
import { scopeToScopeWithRepeat } from './calculateDataSchemaArrayScopes'

export interface iUiSchemaDefValue {
  scopeWithRepeat: string
  rules?: iUiSchemaRule[]
  value?: any
  onlyEmpty?: boolean
  arrayCounter?: boolean
  schemaType?: string
}

export const calculateUiSchemaDefaultValues = (
  uiSchema?: iUIschema,
  dataSchema?: iSchemaDatas,
  arrayScopes: string[] = [],
) => {
  if (!uiSchema || !dataSchema) {
    return []
  }
  const ret: iUiSchemaDefValue[] = []

  if (uiSchema.scope) {
    const { scopeWithRepeat, dataSchemaPath } = scopeToScopeWithRepeat(uiSchema.scope, arrayScopes)

    const e = {
      scopeWithRepeat: scopeWithRepeat,
      rules: uiSchema.allRules,
    }

    if (uiSchema.type === UiElTypesInput.Hidden) {
      if (uiSchema.options?.fixedValue) {
        ret.push({
          ...e,
          value: uiSchema.options.fixedValue,
        })
      } else if (uiSchema.options?.arrayCounter) {
        const dataSchemaItem = get(dataSchema, dataSchemaPath)
        ret.push({
          ...e,
          arrayCounter: true,
          schemaType: Array.isArray(dataSchemaItem.type) ? dataSchemaItem.type[0] : dataSchemaItem.type,
        })
      }
    } else if ([UiElTypesLayout.Array, UiElTypesLayout.ArrayInline].includes(uiSchema.type)) {
      if (uiSchema.options?.minItems) {
        ret.push({
          ...e,
          value: Array(uiSchema.options.minItems).fill({}),
          onlyEmpty: true,
        })
      }
    }
  }

  uiSchema.elements?.forEach((el) => {
    const childRet = calculateUiSchemaDefaultValues(el, dataSchema, arrayScopes)
    ret.push(...childRet)
  })

  return ret
}
