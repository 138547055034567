import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import PartGovBulb from './PartGovBulb'
import PartGovDescription from './PartGovDescription'
import PartGovError from './PartGovError'
import PartGovHelp from './PartGovHelp'

interface iWrapInputGov {
  value?: string
  openLabel?: boolean
  errors?: string[]
  custom?: boolean
  children: React.ReactNode
}

const WrapInputGov = ({ value, openLabel, errors, custom, children }: iWrapInputGov) => {
  const { uiSchema, requiredMsgs, forbidenMsgs } = useElementContext()

  const classes = ['gov-form-control']
  if (custom) {
    classes.push('gov-form-control--custom')
  }
  if (value || openLabel) {
    classes.push('not-empty')
  }

  if (errors) {
    classes.push('gov-form-control--error')
  }

  const cssWidth = uiSchema?.options?.cssWidth
  const css = cssWidth ? { width: cssWidth + '%', maxWidth: cssWidth + '%', minWidth: cssWidth + '%' } : undefined

  const helpText = uiSchema?.options?.helpText

  const bulbTexts = [...requiredMsgs, ...forbidenMsgs].filter((t) => t)
  return (
    <div className='formElementWrap' style={css}>
      <PartGovDescription text={uiSchema?.options?.descriptionBefore} />
      <div className={classes.join(' ')}>
        {children}
        <PartGovDescription text={uiSchema?.options?.description} />
        {errors?.map((error, index) => (
          <PartGovError key={index} text={error} />
        ))}
        <PartGovBulb texts={bulbTexts} />
        {helpText && <PartGovHelp text={helpText} />}
      </div>
    </div>
  )
}

export default WrapInputGov
