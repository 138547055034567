export const valueToBoolean = (value?: string | boolean) => {
  if (value === undefined || value === '' || value === null) {
    return value
  }
  if (typeof value === 'string') {
    if (['true', '1'].includes(value.toLowerCase())) {
      return true
    }
    if (['false', '0'].includes(value.toLowerCase())) {
      return false
    }
  }

  return !!value
}
