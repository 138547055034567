import React from 'react'
import { useElementContext } from '../../context/ElementContext'

const PartGovLabelTxt = () => {
  const { label, uiSchema } = useElementContext()

  // if (!label) {
  //   return null
  // }

  return (
    <strong>
      {label} {uiSchema.labelId && <small>({uiSchema.labelId})</small>}:
    </strong>
  )
}

export default React.memo(PartGovLabelTxt)
