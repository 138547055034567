import { useEffect } from 'react'
import WPApi from '../api/WPApi'
import { useFilterContext } from '../context/FilterContext'
import { dateFormatShort } from '../utils/dateFormat'
import { useFetch } from './useFetch'

export const useFetchWPNews = (isHP?: boolean) => {
  const { page: activePage, limit: activeLimit } = useFilterContext()

  const page = isHP ? 1 : activePage
  const limit = isHP ? 4 : activeLimit

  const { data, loading, error, fetchData } = useFetch(
    () => WPApi.getPosts({ page: page, per_page: limit, categories: [6] }),
    true,
  )

  useEffect(() => {
    fetchData()
  }, [page, limit])

  const posts = data?.data.map((post: any) => ({
    name: post.title.rendered,
    date: dateFormatShort(post.date),
    text: post.excerpt.rendered,
    link: post.slug,
  }))

  const totalCount = data?.totalCount || 0

  return { posts, loading, error, totalCount }
}
