import React from 'react'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputTextAreaTxt = () => {
  const { field } = useControlRegister()

  return (
    <div>
      <PartGovLabelTxt />
      <div style={{ whiteSpace: 'pre-wrap' }}>{field.value}</div>
    </div>
  )
}

export default InputTextAreaTxt
