import React from 'react'
import { useTranslation } from 'react-i18next'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import FilterMyForms from '../../tables/TableMyForms/FilterMyForms'
import TableMyForms from '../../tables/TableMyForms/TableMyForms'

const FormulareMojePage = () => {
  const { t } = useTranslation()

  return (
    <PageWrap title={t('title.seznam_mych_formularu', 'Moje vyhlášení')}>
      <FilterContextProvider name={TABLE_TYPES.myForms}>
        <FilterMyForms />
        <TableMyForms />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default FormulareMojePage
