import React, { ChangeEventHandler } from 'react'
import InputGovDescription from '../../components/InputsUtils/InputGovDescription'
import InputGovError from '../../components/InputsUtils/InputGovError'
import InputGovTooltip from '../../components/InputsUtils/InputGovTooltip'

interface iRadioInput {
  name: string
  label?: string
  description?: string
  value?: string
  onChange?: ChangeEventHandler<any>
  options: { label: string; value: string }[]
  error?: string
  tooltip?: string
  columnCount?: number
  disabled?: boolean
}

const RadioInput = ({
  name,
  description,
  value,
  onChange,
  options,
  error,
  tooltip,
  columnCount = 1,
  disabled,
}: iRadioInput) => {
  const id = 'radio-' + name
  const selectValue = value
  return (
    <>
      <div style={{ columnCount: columnCount }}>
        {options.map(({ label, value }, index) => (
          <div
            key={index}
            className={'gov-form-control gov-form-control--custom ' + (error ? ' gov-form-control--error' : '')}
          >
            <input
              id={`${id}_${value}`}
              // defaultChecked={defaultValue ? defaultValue === value : undefined}
              onChange={onChange}
              value={value}
              checked={selectValue === value}
              className='gov-form-control__radio'
              type='radio'
              disabled={disabled}
            />
            <label className='gov-form-control__label' htmlFor={`${id}_${value}`}>
              {label}
            </label>
            <span className='gov-form-control__indicator'></span>
          </div>
        ))}
      </div>

      <div className={error ? ' gov-form-control--error' : ''}>
        <InputGovDescription text={description} />
        <InputGovError text={error} />
        <InputGovTooltip text={tooltip} />
      </div>
    </>
  )
}

export default RadioInput
