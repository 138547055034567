import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import FormDetailNew from '../../components/FormDetailNew/FormDetailNew'
import Loading from '../../components/Loading/Loading'
import PageWrap from '../../components/PageWrap/PageWrap'
import SentInfo from '../../components/SentInfo/SentInfo'
import { setFormResponseSent } from '../../redux/formState/formStateSlice'
import { selectFormResponseSent } from '../../redux/formState/selectors'
import { useAppDispatch } from '../../redux/store'
import { selectSchemaFull, selectSchemaIsV1 } from '../../redux/submissions/selectors'

const FormularDetailPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const responseSent = useSelector(selectFormResponseSent)
  const isV1 = useSelector(selectSchemaIsV1)

  const formSchema = useSelector(selectSchemaFull)
  const formTitle = formSchema?.form?.title
  const formDesc = formSchema?.form?.description

  useEffect(() => {
    return () => {
      dispatch(setFormResponseSent(undefined))
    }
  }, [])

  if (responseSent) {
    return (
      <PageWrap title='Informace o podání formuláře'>
        <SentInfo response={responseSent} />
      </PageWrap>
    )
  }

  return (
    <PageWrap title={formTitle} subtitle={formDesc} fullWidth={!isV1}>
      <Suspense fallback={<Loading />}>
        <FormDetailNew key={id} id={id!} />
      </Suspense>
    </PageWrap>
  )
}

export default FormularDetailPage
