import get from 'lodash/get'
import React from 'react'
import { useSelector } from 'react-redux'
import AlertError from '../components/Alert/AlertError'
import { FORM_TYPE_REQ } from '../enums_v1/enumFormType'
import { selectFormData, selectFormDataRequest } from '../redux/form/selectorsFormV1'
import { selectSchemaFull, selectSchemaFullReq, selectSubmMainInfo } from '../redux/submissions/selectors'
import { scopeFullToPath } from '../utils/scopeModifs'
import { convertUiSchemaArray } from './convertors/convertUiSchemaArray'
import { convertUiSchemaInheritRules } from './convertors/convertUiSchemaInheritRules'
import { UiElTypesInput, UiElTypesLayout, UiElTypesVisual } from './elements/uiElTypes'
import { iUIschema } from './interfaces/iUiSchema'
import { isVisibleRules } from './rules/isVisibleRules'
import { translatePath } from './translate/translate'
import { getTranlateLabelFullKey, getTranlateTextFullKey } from './utils/getTranslateUiSchema'

interface iFormText {
  isReq?: boolean
}

const FormTextV1 = ({ isReq }: iFormText) => {
  const data = useSelector(isReq ? selectFormDataRequest : selectFormData)

  const schemaUi = useSelector(isReq ? selectSchemaFullReq : selectSchemaFull)?.layout
  const submMain = useSelector(selectSubmMainInfo)
  const formType = isReq ? FORM_TYPE_REQ : submMain.formType

  if (!schemaUi) {
    if (isReq) {
      return null
    } else {
      return <AlertError msg={'Nepodařilo se načíst schéma formuláře'} />
    }
  }
  const schemaUiWithAllRules = convertUiSchemaInheritRules(schemaUi)

  const getData = (path?: string) => (path ? get(data, path) : '')

  const getLabel = (path?: string, schemaLabel?: string | boolean) => {
    if (schemaLabel === false) {
      return undefined
    }
    if (path) {
      return translate(path, schemaLabel as string)
    }
    return schemaLabel
  }

  const getSchemLabel = (uischema: iUIschema) => {
    const schemaLabel = uischema.label
    if (schemaLabel === false) {
      return undefined
    }
    const translateFullKey = getTranlateLabelFullKey(uischema).replace(/\.\d+$/, '')
    return schemaLabel !== undefined ? schemaLabel : translate(translateFullKey, translateFullKey)
  }

  const getSchemText = (uischema: iUIschema) => {
    const schemaText = uischema.text
    const translateFullKey = getTranlateTextFullKey(uischema)
    return schemaText !== undefined ? schemaText : translate(translateFullKey, translateFullKey)
  }

  const translate = (path: string, defVal?: string) => translatePath(formType)(path, defVal)

  const makeEl = (schemaEl: any) => {
    //TOTO visible + nopublic
    const allRules = schemaEl.allRules!
    const { visible } = isVisibleRules(data, allRules)
    if (!visible) {
      return null
    }

    const type = schemaEl.type
    const path = schemaEl.scope ? scopeFullToPath(schemaEl.scope) : ''

    if (
      [
        UiElTypesInput.Hidden,
        UiElTypesVisual.Description,
        UiElTypesInput.AresBtn,
        UiElTypesInput.IamBtn,
        UiElTypesLayout.HiddenGroup,
      ].includes(type)
    ) {
      return null
    }

    if ([UiElTypesLayout.FormRoot].includes(type)) {
      return makeBox(schemaEl)
    }
    if ([UiElTypesLayout.Group].includes(type)) {
      return makeBox(schemaEl, true)
    }
    if ([UiElTypesLayout.GroupInner, UiElTypesLayout.Row].includes(type)) {
      return makeBox(schemaEl)
    }
    if ([UiElTypesLayout.Array, UiElTypesLayout.ArrayInline].includes(type)) {
      const withBorder = type === UiElTypesLayout.Array
      const data = getData(path)
      return data
        ? Array(data.length || schemaEl.options.minItems || 0)
            .fill(null)
            .map((_, index) => makeBox(convertUiSchemaArray(schemaEl, schemaEl.scope, index), withBorder))
        : null
    }

    if ([UiElTypesVisual.SectionTitle].includes(type)) {
      const text = getSchemText(schemaEl)
      return (
        <div style={{ marginTop: 5, textDecoration: 'underline', fontStyle: 'italic' }}>
          <strong>{text}</strong>
        </div>
      )
    }

    if ([UiElTypesVisual.Label].includes(type)) {
      const text = getSchemText(schemaEl)
      if (text === '/') {
        return null
      }
      return <strong>{text}</strong>
    }

    if (
      [
        UiElTypesInput.Text,
        UiElTypesInput.Radio,
        UiElTypesInput.RadioAlone,
        UiElTypesInput.RadioTrueFalse,
        UiElTypesInput.Textarea,
        UiElTypesInput.Date,
        UiElTypesInput.Checkbox,
        UiElTypesInput.Select,
        UiElTypesInput.CheckboxBoolean,
        UiElTypesInput.Time,
        UiElTypesInput.DateParts,
        UiElTypesInput.CheckboxMulti,
      ].includes(type)
    ) {
      // if (schemaEl.options?.likeType) {
      //   return null
      // }
      let data = getData(path)
      const label = getSchemLabel(schemaEl)

      switch (type) {
        case UiElTypesInput.Checkbox:
          data = data ? 'Ano' : 'Ne'
          break
        case UiElTypesInput.RadioTrueFalse:
          data = getLabel(path + '.' + (data ? 'yes' : 'no'))
          break
        case UiElTypesInput.Radio:
        case UiElTypesInput.Select:
          data = data ? getLabel(path + '.' + data, data) : data
          break
        case UiElTypesInput.DateParts:
          data = data ? data.DAY + '.' + data.MONTH + '.' + data.YEAR : ''
          break
        case UiElTypesInput.CheckboxMulti:
          data = data
            ? data.map((d: any) => getLabel(path + '.el_attrs.VALUE.' + d.el_attrs.VALUE, d.el_attrs.VALUE)).join(', ')
            : ''
          break
      }

      if (!label && !data) {
        return null
      }
      if (type === UiElTypesInput.RadioAlone) {
        return data ? <div>{label}</div> : null
      }
      return (
        <div>
          {label ? label + ':' : ''} {data || ' - '}
        </div>
      )
    }

    return <div style={{ color: 'var(--gov-color-error)' }}>Neznamy typ: {type}</div>
  }
  const makeBox = (schemaBox: any, withBorder?: boolean) => {
    const label = schemaBox.type !== UiElTypesLayout.ArrayInline ? getSchemLabel(schemaBox) : ''
    return (
      <div style={withBorder ? { border: '1px solid grey', margin: '10px 0', padding: 5 } : undefined}>
        {label && <strong>{label}</strong>}
        {schemaBox.elements && schemaBox.elements.map((el: any) => <div>{makeEl(el)}</div>)}
      </div>
    )
  }

  return <div style={{ margin: '20px 0 50px 0' }}>{makeEl(schemaUiWithAllRules)}</div>
}

export default FormTextV1
