import { useEffect } from 'react'
import { useUser } from '../hooks/useUser'

interface iLoadUserWrap {
  children: JSX.Element
}

export const LoadUserWrap = ({ children }: iLoadUserWrap) => {
  const { loadUser, initialized } = useUser()

  useEffect(() => {
    if (initialized) {
      loadUser()
    }
  }, [initialized])

  return children
}
