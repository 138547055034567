import React from 'react'
import { useElementContext } from '../../context/ElementContext'

const TextContent = () => {
  const { text } = useElementContext()

  return (
    <div className='u-mb--10 textContentWrap' style={{ whiteSpace: 'pre-wrap' }}>
      {text}
    </div>
  )
}

export default TextContent
