import React from 'react'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputNumberTxt = () => {
  const { field } = useControlRegister()

  return (
    <div>
      <PartGovLabelTxt /> {field.value?.toLocaleString()}
    </div>
  )
}

export default InputNumberTxt
