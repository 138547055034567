import React, { useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectDynamEnum } from '../../redux/dynamEnum/dynamEnumSelectors'
import {
  selectFormForbidden,
  selectFormHidden,
  selectFormMandatory,
  selectFormReqForbidden,
  selectFormReqHidden,
  selectFormReqMandatory,
  selectGlobalHidden,
} from '../../redux/formErrors/selectors'
import {
  selectScopeToLabel,
  selectScopeToLabelReq,
  selectUiSchemaScopeOrder,
  selectUiSchemaScopeOrderReq,
} from '../../redux/submissions/selectors'
import { useFormRenderContext } from '../context/RenderFormContext'
import { convertDataOrderLikeSchemaAndClear } from '../utils/convertDataOrderLikeSchemaAndClear'

const Tabs = ({ tabs }: { tabs: { [name: string]: any } }) => {
  const [tab, setTab] = useState(Object.keys(tabs)[0])

  return (
    <div>
      {Object.keys(tabs).map((tabName, index) => (
        <span
          key={index}
          onClick={() => setTab(tabName)}
          style={{ background: tab === tabName ? 'grey' : 'white', padding: '0 5px', cursor: 'pointer' }}
        >
          {tabName}
        </span>
      ))}

      {tabs[tab]()}
    </div>
  )
}

const DebugFormDataTabs = ({ isReq }: { isReq: boolean }) => {
  // const formState = useFormState()
  const {
    submission,
    dataSchemas,
    uiSchemas,
    dateSchemaArrayScopes,
    dateSchemaDefaults,
    dateSchemaArrayDefaults,
    dateSchemaDefaultsCalculate,
    dateSchemaScope,
  } = useFormRenderContext()
  // const { formFiles } = useFormFilesRenderContext()

  const scopeToLabelsForm = useSelector(isReq ? selectScopeToLabelReq : selectScopeToLabel)

  const fullData = useWatch()
  const data = useMemo(() => convertDataOrderLikeSchemaAndClear(fullData, dataSchemas), [fullData, dataSchemas])
  const dataLive = useMemo(
    () => convertDataOrderLikeSchemaAndClear(fullData, dataSchemas, true),
    [fullData, dataSchemas],
  )
  const uiSchemaScopeOrder = useSelector(isReq ? selectUiSchemaScopeOrderReq : selectUiSchemaScopeOrder)

  const mandatoryFieldsExt = useSelector(isReq ? selectFormReqMandatory : selectFormMandatory)
  const forbiddenFieldsExt = useSelector(isReq ? selectFormReqForbidden : selectFormForbidden)
  const hiddenFieldsExt = useSelector(isReq ? selectFormReqHidden : selectFormHidden)
  const globalHidden = useSelector(selectGlobalHidden)

  const dynamEnum = useSelector(selectDynamEnum)
  return (
    <Tabs
      tabs={{
        data: () => <pre>{JSON.stringify(data, null, 1)}</pre>,
        dataLive: () => <pre>{JSON.stringify(dataLive, null, 1)}</pre>,
        fullData: () => <pre>{JSON.stringify(fullData, null, 1)}</pre>,
        // files: () => <pre>{JSON.stringify(formFiles, null, 1)}</pre>,
        dataSchema: () => <pre>{JSON.stringify(dataSchemas, null, 1)}</pre>,
        uiSchema: () => <pre>{JSON.stringify(uiSchemas, null, 1)}</pre>,
        subms: () => <pre>{JSON.stringify(submission, null, 1)}</pre>,
        scopeToLabel: () => <pre>{JSON.stringify(scopeToLabelsForm, null, 1)}</pre>,
        dataSchemaScope: () => <pre>{JSON.stringify(dateSchemaScope, null, 1)}</pre>,
        default: () => <pre>{JSON.stringify(dateSchemaDefaults, null, 1)}</pre>,
        defaultArray: () => <pre>{JSON.stringify(dateSchemaArrayDefaults, null, 1)}</pre>,
        defaultCalc: () => <pre>{JSON.stringify(dateSchemaDefaultsCalculate, null, 1)}</pre>,
        order: () => <pre>{JSON.stringify(uiSchemaScopeOrder, null, 1)}</pre>,
        array: () => <pre>{JSON.stringify(dateSchemaArrayScopes, null, 1)}</pre>,
        forbidden: () => <pre>{JSON.stringify(forbiddenFieldsExt, null, 1)}</pre>,
        mandatory: () => <pre>{JSON.stringify(mandatoryFieldsExt, null, 1)}</pre>,
        hidden: () => <pre>{JSON.stringify(hiddenFieldsExt, null, 1)}</pre>,
        globalHidden: () => <pre>{JSON.stringify(globalHidden, null, 1)}</pre>,
        dynamEnum: () => <pre>{JSON.stringify(dynamEnum, null, 1)}</pre>,
        // mounted: <pre>{JSON.stringify(mountedInputs, null, 1)}</pre>,
        // rhf: () => (
        //   <pre>
        //     {JSON.stringify(
        //       {
        //         formState: formState,
        //         dirtyFielsd: formState.dirtyFields,
        //         touchFields: formState.touchedFields,
        //       },
        //       null,
        //       1,
        //     )}
        //   </pre>
        // ),
      }}
    />
  )
}

const DebugFormData = ({ isReq }: { isReq: boolean }) => {
  const [open, setOpen] = useState(false)

  if (!open) {
    return (
      <span
        style={{ position: 'fixed', right: 0, top: isReq ? 20 : 0, background: 'white', cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        Debug {isReq && 'req'}
      </span>
    )
  }

  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        top: isReq ? 20 : 0,
        bottom: 0,
        width: 320,
        background: 'white',
        color: 'black',
        overflow: 'auto',
        fontSize: 13,
        zIndex: 90,
      }}
    >
      <span onClick={() => setOpen(false)}>Close</span>
      <br />
      <DebugFormDataTabs isReq={isReq} />
    </div>
  )
}

export default DebugFormData
