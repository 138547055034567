import { GroupLayout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React from 'react'
import { withJsonFormsLayoutPropsCustom } from '../../core/withJsonFormsLayoutPropsCustom'
import { UiElTypesLayout } from '../uiElTypes'

const GovFormRootRender = (props: LayoutProps) => {
  const { schema, uischema, renderers, enabled, visible, path, cells, label } = props

  const groupLayout = uischema as GroupLayout

  return (
    <div className='formRoot u-mb--50'>
      {label && <h1 className='u-mb--10'>{label}</h1>}
      {visible &&
        groupLayout.elements.map((child, index) => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={index}
          />
        ))}
    </div>
  )
}
export default withJsonFormsLayoutPropsCustom(GovFormRootRender)

export const govFormRootTester = rankWith(1000, uiTypeIs(UiElTypesLayout.FormRoot))

