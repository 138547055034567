import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { loadOnlyFormDataFromXml } from '../../../actions/loadSubmissionsXml'
import ButtonGov from '../../../components/Btns/ButtonGov'
import LoadingInline from '../../../components/Loading/LoadingInline'
import { useModal } from '../../../hooks/useModal'
import { FormsCreator } from '../../../services/FormsCreator'
import { RepairFormHelper } from '../../../services/RepairFormHelper'
import { getErrorMessage } from '../../../utils/errorMessage'
import { scopeFullToPath } from '../../../utils/scopeModifs'
import { useElementContext } from '../../context/ElementContext'
import { useFormRenderContext } from '../../context/RenderFormContext'

const BtnSearchFormRepair = () => {
  const { openModalNewForm } = useModal()

  const { watch } = useFormContext()
  const { setLoadingAction, loadingAction } = useFormRenderContext()
  const { text, uiSchema } = useElementContext()

  const { btnSearchMapping } = uiSchema.options || {}

  const btnSearchMappingPaths = btnSearchMapping?.map(scopeFullToPath) || []
  const [evCisloZakazky, evCisloFormulare, kodSouvisFormulare] = watch(btnSearchMappingPaths)

  const [prevFormType, setPrevFormType] = useState('')
  const [repairData, setRepairData] = useState<any>({})

  useEffect(() => {
    setPrevFormType('')
    setRepairData({})
  }, [evCisloZakazky, evCisloFormulare, kodSouvisFormulare])

  const searchForm = async () => {
    if (!evCisloFormulare || !evCisloZakazky || !kodSouvisFormulare) {
      toast.error('Vyplňte všechny potřebné údaje')
      return
    }

    setLoadingAction(true)
    setRepairData({})
    try {
      const formCreator = new FormsCreator('')
      formCreator.setPrevNumbers(evCisloFormulare, evCisloZakazky, kodSouvisFormulare)
      await formCreator.loadPrevMain()
      await formCreator.loadPrevReq()

      const dataV1 = formCreator.isPrevFormV1() ? await loadOnlyFormDataFromXml(formCreator.prevResMain!) : undefined

      const RForm = new RepairFormHelper(formCreator.prevResMain!, formCreator.prevResReq, dataV1)
      if (RForm.hasRepariForm()) {
        const prevFormType = formCreator.getPrevFormType()
        setPrevFormType(prevFormType)

        const dataNewRepairForm = RForm.getDataRepairForm()
        const newFormType = RForm.getFormTypeToRepair()
        if (!dataNewRepairForm.kodSouvisFormulare) {
          dataNewRepairForm.kodSouvisFormulare = kodSouvisFormulare
        }

        setRepairData({
          dataNewRepairForm: dataNewRepairForm,
          newFormType: newFormType,
        })
      } else {
        toast.error('Pro vyhledaný formulář nelze vytvořit opravný')
      }

      toast.success('Načteno předchozí oznámení')
    } catch (e) {
      toast.error(getErrorMessage(e))
    } finally {
      setLoadingAction(false)
    }
  }

  if (loadingAction) {
    return <LoadingInline />
  }

  const openRepairModal = () => {
    openModalNewForm(repairData.newFormType, repairData.dataNewRepairForm)
  }

  const hasRepair = !!repairData.newFormType?.length

  const disabledSearch = hasRepair || !evCisloFormulare || !evCisloZakazky || !kodSouvisFormulare

  return (
    <>
      <div className='u-mb--30'>
        <ButtonGov variant='primary' disabled={disabledSearch} onClick={searchForm}>
          {text}
        </ButtonGov>

        {prevFormType && <div>(Vyhledaný formulář je typu {prevFormType})</div>}
      </div>
      {hasRepair && (
        <ButtonGov variant='primary' onClick={() => openRepairModal()}>
          Vytvořit opravný formulář pro {evCisloFormulare}
        </ButtonGov>
      )}
    </>
  )
}

export default BtnSearchFormRepair
