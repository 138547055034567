import { DATA_TYPE, iDataSchemas } from '../interfaces/iDataSchema'

export const calculateArraysScope = (items: iDataSchemas, parentScope = '#') => {
  let ret: string[] = []

  Object.entries(items).forEach(([name, child]) => {
    const scope = parentScope + '/' + name
    if (child.type === DATA_TYPE.ARRAY) {
      ret.push(scope)
    }
    if (child.properties) {
      const childScopes = calculateArraysScope(child.properties, scope)
      ret.push(...childScopes)
    } else if (child.items?.properties) {
      const childScopes = calculateArraysScope(child.items.properties, scope)
      ret.push(...childScopes)
    }
  })
  return ret
}
