import React from 'react'
import { useTranslation } from 'react-i18next'
import { LINKS } from '../../components/App/LINKS'
import ButtonGovLink from '../../components/Btns/ButtonGovLink'
import VariantsTabs from '../../components/VariantsTabs/VariantsTabs'
import { FilterContextProvider } from '../../context/FilterContext'
import { VARIANTS_TABLE_CONTRACTS_HP_OPTIONS } from '../../enums/enumTablesVariants'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import { useUser } from '../../hooks/useUser'
import TableContracts from '../../tables/TableContracts/TableContracts'
import NewsHp from './NewsHp'

const HomePage = () => {
  const { t } = useTranslation()
  const { hasFullAccess } = useUser()

  const changesItems = [
    {
      name: t('hp.vsechna_uverejneni'),
      text: t('hp.vsechna_uverejneni_text'),
      link: LINKS.noveVsechna,
    },
    {
      name: t('hp.nove_zakazky'),
      text: t('hp.nove_zakazky_text'),
      link: LINKS.noveZakazky,
    },
    {
      name: t('hp.nove_zruseni'),
      text: t('hp.nove_zruseni_text'),
      link: LINKS.noveZruseni,
    },
    {
      name: t('hp.vitezove'),
      text: t('hp.vitezove_text'),
      link: LINKS.noveVitezove,
    },
  ]

  return (
    <>
      {/*{!hasFullAccess && (*/}
      {/*  <div className='custom-intro'>*/}
      {/*    <div className='gov-container gov-container--wide u-pt--60'>*/}
      {/*      <div className='gov-container__content'>*/}
      {/*        <>*/}
      {/*          <div className='custom-intro__perex hp-intro-block'>*/}
      {/*            <p className={'hp-intro-title'}>Vítejte na novém Věstníku veřejných zakázek</p>*/}
      {/*            <div>*/}
      {/*              <p className={'hp-intro-text'}>*/}
      {/*                Od 1. března 2023 je zde možné registrovat zadavatele. Doporučujeme Vám provést registraci dříve,*/}
      {/*                než bude 3. dubna 2023 spuštěn ostrý provoz s možností podávat a uveřejňovat formuláře.*/}
      {/*              </p>*/}
      {/*              <p className={'hp-intro-text'}>*/}
      {/*                Ke spuštění ostrého provozu nového Věstníku je nutné stanovit přechodné období od středy 29.*/}
      {/*                března do neděle 2. dubna 2023, kdy bude stávající Věstník (*/}
      {/*                <a*/}
      {/*                  href='https://www.vestnikverejnychzakazek.cz/'*/}
      {/*                  target='_blank'*/}
      {/*                  rel='noreferrer'*/}
      {/*                  className={'hp-intro-link'}*/}
      {/*                >*/}
      {/*                  www.vestnikverejnychzakazek.cz*/}
      {/*                </a>*/}
      {/*                ) mimo provoz a nebude možné odesílat a uveřejňovat žádné formuláře. Všechny formuláře podané*/}
      {/*                prostřednictvím stávajícího Věstníku od října 2016 do 28. března 2023 budou od 3. dubna 2023 k*/}
      {/*                dispozici na tomto webu.*/}
      {/*              </p>*/}
      {/*              <p className={'hp-intro-text'}>*/}
      {/*                Princip vyplňování a podávání formulářů bude pro uživatele totožný jako ve stávajícím Věstníku.*/}
      {/*                Tato služba bude nově pro všechny uživatele zdarma.*/}
      {/*              </p>*/}
      {/*              <p className={'hp-intro-text'}>*/}
      {/*                Více informací naleznete{' '}*/}
      {/*                <Link to={LINKS.napoveda} className={'hp-intro-link'}>*/}
      {/*                  zde*/}
      {/*                </Link>*/}
      {/*                .*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className='custom-intro__actions'>*/}
      {/*            <p>*/}
      {/*              <a*/}
      {/*                className='gov-button  gov-button--primary gov-button--large gov-button--inversed'*/}
      {/*                href={LINKS.cdzRegistrace}*/}
      {/*              >*/}
      {/*                REGISTROVAT na VVZ*/}
      {/*              </a>*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      <div className='gov-container gov-container--wide u-pt--60'>
        <div className='gov-container__content container_bg_white'>
          <NewsHp />

          {hasFullAccess && (
            <>
              <div className='u-mb--60'>
                <h2 className='gov-actuality--title'>{t('hp.aktualni_zakazky')}</h2>
                <div className='hp-news-links'>
                  <h3>{t('hp.dnesni_zverejneni')}:</h3>
                  {changesItems.map((item, index) => (
                    <ButtonGovLink key={index} to={item.link} variant='primary' title={item.text}>
                      {item.name}
                    </ButtonGovLink>
                  ))}
                </div>
              </div>

              <h2 className='gov-actuality--title'>{t('hp.zakazy_s_lhutou')}</h2>
              <FilterContextProvider name={TABLE_TYPES.contractsHp}>
                <VariantsTabs variants={VARIANTS_TABLE_CONTRACTS_HP_OPTIONS} />
                <TableContracts noPerPage />
              </FilterContextProvider>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default HomePage
