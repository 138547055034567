
import { iUiSchemaRule, iUISchemaRuleCondition } from '../interfaces/iUiSchemaRule'

export const modifRules = (scope: string, rules: iUiSchemaRule[]) => {
  if (!rules) {
    return rules
  }

  const scopeShort = scope

  const numberParts = scopeShort.matchAll(/\/\d+\//g)
  const arrayParts: string[][] = []

  for (const numPart of numberParts as any) {
    const endPart = scopeShort.indexOf('/', numPart.index + 1)
    const full = scopeShort.slice(0, endPart + 1)
    const part = scopeShort.slice(0, numPart.index + 1)
    arrayParts.push([full, part])
  }

  //pokud scope konci cislem tak pridat cele - bez koncove /
  const lastPart = scopeShort.split('/').slice(-1)[0]
  if (!isNaN(parseInt(lastPart))) {
    arrayParts.push([scopeShort, scopeShort.slice(0, scopeShort.length - lastPart.length - 1)])
  }
  if (!arrayParts.length) {
    return rules
  }

  const modifConditions = (condition: iUISchemaRuleCondition):iUISchemaRuleCondition => {
    if ('conditions' in condition) {
      return {
        type: condition.type,
        conditions: condition.conditions.map(modifConditions),
      }
    }

    let modifScope = condition.scope
    arrayParts.forEach(([full, part]) => {
      // pokud uz je array scope nahrazeno tak neaplikovat + pokud je stejne tak je to pravidlo na root array
      if (!modifScope.includes(full) && modifScope !== part) {
        modifScope = modifScope.replace(part, full)
      }
    })
    return {
      ...condition,
      scope: modifScope,
    }
  }

  const modifRules = rules.map((rule) => ({
    ...rule,
    condition: modifConditions(rule.condition),
  }))

  return modifRules
}
