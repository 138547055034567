import React from 'react'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputCheckboxTxt = () => {
  const { field } = useControlRegister()

  return (
    <div>
      <PartGovLabelTxt /> {field.value ? 'Ano' : 'Ne'}
    </div>
  )
}

export default InputCheckboxTxt
