import get from 'lodash/get'
import { FORM_TYPES as FT1 } from '../enums_v1/enumFormType'
import { FORM_TYPES_V2 as FT2 } from './enumFormTypeV2'

export const PATH_NOTICE_TYPE = 'NOTICE.el_attrs.TYPE'
const PATH_LEGAL_BASIS = 'LEGAL_BASIS.el_attrs.VALUE'

export const OPT_NOTICE_TYPE = {
  PRI_ONLY: 'PRI_ONLY',
  PRI_REDUCING_TIME_LIMITS: 'PRI_REDUCING_TIME_LIMITS',
  PRI_CALL_COMPETITION: 'PRI_CALL_COMPETITION',
  PER_ONLY: 'PER_ONLY',
  PER_REDUCING_TIME_LIMITS: 'PER_REDUCING_TIME_LIMITS',
  PER_CALL_COMPETITION: 'PER_CALL_COMPETITION',
  CONTRACT: 'CONTRACT',
  AWARD_CONTRACT: 'AWARD_CONTRACT',
  QSU_ONLY: 'QSU_ONLY',
  QSU_CALL_COMPETITION: 'QSU_CALL_COMPETITION',
  PRI: 'PRI',
  CONCESSION_AWARD_CONTRACT: 'CONCESSION_AWARD_CONTRACT',
}
const OPT_LEGAL_BASIS = {
  OPT_32014L0024: '32014L0024',
  OPT_32014L0025: '32014L0025',
  OPT_32009L0081: '32009L0081',
  OPT_32014L0023: '32014L0023',
}
export const FORM_REPAIR_TYPE = {
  [FT1.F01]: (data: any) => {
    const nType = get(data, PATH_NOTICE_TYPE)
    const posibles: any = {
      [OPT_NOTICE_TYPE.PRI_ONLY]: FT2.F4,
      [OPT_NOTICE_TYPE.PRI_REDUCING_TIME_LIMITS]: FT2.F7,
      [OPT_NOTICE_TYPE.PRI_CALL_COMPETITION]: FT2.F10,
    }
    return posibles[nType]
  },

  [FT1.F02]: FT2.F16,
  [FT1.F03]: FT2.F29,
  [FT1.F04]: (data: any) => {
    const nType = get(data, PATH_NOTICE_TYPE)
    const posibles: any = {
      [OPT_NOTICE_TYPE.PER_ONLY]: FT2.F5,
      [OPT_NOTICE_TYPE.PER_REDUCING_TIME_LIMITS]: FT2.F8,
      [OPT_NOTICE_TYPE.PER_CALL_COMPETITION]: FT2.F11,
    }
    return posibles[nType]
  },

  [FT1.F05]: FT2.F17,
  [FT1.F06]: FT2.F30,
  [FT1.F07]: FT2.F15,
  [FT1.F08]: undefined,
  [FT1.F12]: (data: any) => {
    const nType = get(data, PATH_LEGAL_BASIS)
    const posibles: any = {
      [OPT_LEGAL_BASIS.OPT_32014L0024]: FT2.F23,
      [OPT_LEGAL_BASIS.OPT_32014L0025]: FT2.F24,
    }
    return posibles[nType]
  },
  [FT1.F13]: (data: any) => {
    const nType = get(data, PATH_LEGAL_BASIS)
    const posibles: any = {
      [OPT_LEGAL_BASIS.OPT_32014L0024]: FT2.F36,
      [OPT_LEGAL_BASIS.OPT_32014L0025]: FT2.F37,
    }
    return posibles[nType]
  },
  [FT1.F14]: undefined,
  [FT1.F15]: (data: any) => {
    const nType = get(data, PATH_LEGAL_BASIS)
    const posibles: any = {
      [OPT_LEGAL_BASIS.OPT_32014L0024]: FT2.F25,
      [OPT_LEGAL_BASIS.OPT_32014L0025]: FT2.F26,
      [OPT_LEGAL_BASIS.OPT_32009L0081]: FT2.F27,
      [OPT_LEGAL_BASIS.OPT_32014L0023]: FT2.F28,
    }
    return posibles[nType]
  },

  [FT1.F16]: [FT2.F6, FT2.F9],

  [FT1.F17]: FT2.F18,
  [FT1.F18]: FT2.F31,
  [FT1.F19]: FT2.F22,
  [FT1.F20]: (data: any) => {
    const nType = get(data, PATH_LEGAL_BASIS)
    const posibles: any = {
      [OPT_LEGAL_BASIS.OPT_32014L0024]: FT2.F38,
      [OPT_LEGAL_BASIS.OPT_32014L0025]: FT2.F39,
      [OPT_LEGAL_BASIS.OPT_32014L0023]: FT2.F40,
    }
    return posibles[nType]
  },

  [FT1.F21]: (data: any) => {
    const nType = get(data, PATH_NOTICE_TYPE)
    const posibles: any = {
      [OPT_NOTICE_TYPE.PRI_ONLY]: FT2.F4,
      [OPT_NOTICE_TYPE.PRI_CALL_COMPETITION]: FT2.F12,
      [OPT_NOTICE_TYPE.CONTRACT]: FT2.F20,
      [OPT_NOTICE_TYPE.AWARD_CONTRACT]: FT2.F33,
    }
    return posibles[nType]
  },
  [FT1.F22]: (data: any) => {
    const nType = get(data, PATH_NOTICE_TYPE)
    const posibles: any = {
      [OPT_NOTICE_TYPE.PER_ONLY]: FT2.F5,
      [OPT_NOTICE_TYPE.PER_CALL_COMPETITION]: FT2.F13,
      [OPT_NOTICE_TYPE.QSU_ONLY]: FT2.F15,
      [OPT_NOTICE_TYPE.QSU_CALL_COMPETITION]: FT2.F15,
      [OPT_NOTICE_TYPE.CONTRACT]: FT2.F21,
      [OPT_NOTICE_TYPE.AWARD_CONTRACT]: FT2.F34,
    }
    return posibles[nType]
  },
  [FT1.F23]: (data: any) => {
    const nType = get(data, PATH_NOTICE_TYPE)
    const posibles: any = {
      [OPT_NOTICE_TYPE.PRI]: FT2.F14,
      [OPT_NOTICE_TYPE.CONCESSION_AWARD_CONTRACT]: FT2.F35,
    }
    return posibles[nType]
  },

  [FT1.F24]: FT2.F19,
  [FT1.F25]: FT2.F32,

  [FT1.CZ01]: (data: any) => {
    const nType = get(data, PATH_NOTICE_TYPE)
    const posibles: any = {
      [OPT_NOTICE_TYPE.PRI_ONLY]: FT2.F4,
      [OPT_NOTICE_TYPE.PRI_CALL_COMPETITION]: FT2.F7,
      [OPT_NOTICE_TYPE.PRI_REDUCING_TIME_LIMITS]: FT2.F10,
    }
    return posibles[nType]
  },

  [FT1.CZ02]: FT2.F16,
  [FT1.CZ03]: FT2.F29,
  [FT1.CZ04]: undefined,
  //
  [FT2.FCZ05]: FT2.FCZ05,
  [FT2.FCZ06]: FT2.FCZ06,
  [FT2.FCZ07]: FT2.FCZ07,
  //
  [FT2.F4]: FT2.F4,
  [FT2.F5]: FT2.F5,
  [FT2.F6]: FT2.F6,
  [FT2.F7]: FT2.F7,
  [FT2.F8]: FT2.F8,
  [FT2.F9]: FT2.F9,
  [FT2.F10]: FT2.F10,
  [FT2.F11]: FT2.F11,
  [FT2.F12]: FT2.F12,
  [FT2.F13]: FT2.F13,
  [FT2.F14]: FT2.F14,
  [FT2.F15]: FT2.F15,
  [FT2.F16]: FT2.F16,
  [FT2.F17]: FT2.F17,
  [FT2.F18]: FT2.F18,
  [FT2.F19]: FT2.F19,
  [FT2.F20]: FT2.F20,
  [FT2.F21]: FT2.F21,
  [FT2.F22]: FT2.F22,
  [FT2.F23]: FT2.F23,
  [FT2.F24]: FT2.F24,
  [FT2.F25]: FT2.F25,
  [FT2.F26]: FT2.F26,
  [FT2.F27]: FT2.F27,
  [FT2.F28]: FT2.F28,
  [FT2.F29]: FT2.F29,
  [FT2.F30]: FT2.F30,
  [FT2.F31]: FT2.F31,
  [FT2.F32]: FT2.F32,
  [FT2.F33]: FT2.F33,
  [FT2.F34]: FT2.F34,
  [FT2.F35]: FT2.F35,
  [FT2.F36]: FT2.F36,
  [FT2.F37]: FT2.F37,
  [FT2.F38]: FT2.F38,
  [FT2.F39]: FT2.F39,
  [FT2.F40]: FT2.F40,
}
