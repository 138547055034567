import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFilterContext } from '../../context/FilterContext'

interface iPerPage {
  totalCount: number
}

const PerPage = ({ totalCount }: iPerPage) => {
  const { t } = useTranslation()
  const setLimitValue = (value: number) => {
    setLimit(value)
  }
  const { limit, setLimit } = useFilterContext()

  const options = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ]

  return (
    <div className='gov-form-control not-empty' style={{ display: 'inline-block', float: 'right' }}>
      <div className='gov-select'>
        <select
          id='perPage'
          value={limit}
          onChange={(e) => setLimitValue(parseInt(e.target.value))}
          name='custom-select'
          aria-required='false'
          aria-disabled='false'
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <label className='gov-form-control__label ' htmlFor='perPage'>
          {t('global.pocet_radku', 'Počet řádků')}
        </label>
      </div>
      <small style={{ paddingLeft: '1.25rem' }}>
        {t('global.celkem', 'Celkem:')} {totalCount.toLocaleString()}
      </small>
    </div>
  )
}

export default PerPage
