import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSection } from '../../redux/global/globalSlice'
import { selectActiveSection } from '../../redux/global/selectors'
import { useIndexesContext } from './IndexesContext'
import { useFormRenderContext } from './RenderFormContext'

interface ActiveSectionContext {
  clickActiveSection: (e?: any) => void
  isActive: boolean
}

const ActiveSectionContextBase = React.createContext({} as ActiveSectionContext)

export const useActiveSectionContext = () => useContext(ActiveSectionContextBase)

interface iActiveSectionContextProvider {
  children: React.ReactNode
}

export const ActiveSectionContextProvider = ({ children }: iActiveSectionContextProvider) => {
  const dispatch = useDispatch()
  const { isReq } = useFormRenderContext()
  const indexes = (isReq ? 'r' : 'f') + useIndexesContext().indexes.join()
  const activeSection = useSelector(selectActiveSection)

  const isActive = activeSection === indexes

  const clickActiveSection = useCallback(
    (e?: any) => {
      // console.log('ACTIVE section', indexes, e)
      dispatch(setActiveSection(indexes))
      e?.stopPropagation()
    },
    [dispatch, indexes],
  )

  const providerValue = React.useMemo(
    () => ({
      clickActiveSection: clickActiveSection,
      isActive: isActive,
    }),
    [clickActiveSection, isActive],
  )

  return <ActiveSectionContextBase.Provider value={providerValue}>{children}</ActiveSectionContextBase.Provider>
}
