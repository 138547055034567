import flatten from 'lodash/flatten'
import zipObject from 'lodash/zipObject'
import path from 'path-browserify'
import { useWatch } from 'react-hook-form'
import ajv from '../utils/ajv'
import { iSchemaCondition, iSchemaConditionSchema } from '../interfaces/iSchemaCondition'
import { scopeFullToPath } from "../../utils/scopeModifs";

export const useElementIsVisible = (cond?: iSchemaCondition) => {
  const condScopes = findCondSchema(cond)
  const condPaths = condScopes.map((s) => scopeFullToPath(s))
  const condPathWatch = useWatch({ name: condPaths, disabled: !condPaths.length })
  const condScopesValues = zipObject(condScopes, condPathWatch)
  // console.log('useWatch', condPaths, condScopesValues)
  const isVisibleElement = cond ? resolveConditionTree(cond, condScopesValues) : true
  return { isVisibleElement }
}

const findCondSchema = (conds?: iSchemaCondition): string[] => {
  if (!conds) {
    return []
  }
  if ('type' in conds) {
    return flatten(conds.conditions.map((c) => findCondSchema(c)))
  } else {
    return [conds.scope]
  }
}

const resolveCondition = (schema: iSchemaConditionSchema = {}, dataItem: any) => {
  if (schema?.type === 'boolean' && schema?.enum) {
    schema.enum = schema.enum
      .filter((en) => en)
      .map((en) =>
        typeof en !== 'string' ? en : en.toLowerCase() === 'true' ? true : en.toLowerCase() === 'false' ? false : en,
      )
  }
  //TODO type number => prevest na cislo
  const res = ajv.validate(schema, dataItem)
  return res
}

const resolveConditionTree = (condition: iSchemaCondition, condScopeValues: { [scope: string]: any }): boolean => {
  if ('type' in condition) {
    if (condition.type === 'AND') {
      return condition.conditions.every((c) => resolveConditionTree(c, condScopeValues))
    } else {
      return condition.conditions.some((c) => resolveConditionTree(c, condScopeValues))
    }
  } else {
    const dataConditionVal = condScopeValues[condition.scope]
    const res = resolveCondition(condition.schema, dataConditionVal)
    return condition?.not ? !res : res
  }
}

export const calculateRelativeCondScope = (cond: iSchemaCondition, scope: string): iSchemaCondition => {
  if ('type' in cond) {
    return {
      ...cond,
      conditions: cond.conditions.map((c) => calculateRelativeCondScope(c, scope)),
    }
  } else {
    if (cond.scope.startsWith('#')) {
      return cond
    } else {
      return { ...cond, scope: path.join(scope, cond.scope) }
    }
  }
}
