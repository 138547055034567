import React from 'react'
import { useFormTypeContext } from '../../context/FormTypeContext'
import { useModal } from '../../hooks/useModal'
import { DELIMETER_NAMESPACE, DELIMETER_NAMESPACE_ALT } from '../specialChars'

interface iInputGovHelp {
  path: string
}

const InputGovHelp = ({ path }: iInputGovHelp) => {
  const { openModalHelp } = useModal()
  const formType = useFormTypeContext().formType

  const xpath =
    '#/' +
    path
      .replaceAll('.', '/')
      .replaceAll(/\/\d+\//g, '/')
      .replaceAll('/el_attrs/', '/@')
      .replaceAll('/el_nest_text', '')
      .replaceAll(DELIMETER_NAMESPACE_ALT, DELIMETER_NAMESPACE)

  const clickHelp = () => {
    openModalHelp({ formType: formType, xpath: xpath })
  }

  return (
    <span className='gov-form-control__message inputHelp'>
      <a className='gov-link gov-link--small gov-modal__trigger' onClick={clickHelp}>
        <span className='gov-icon gov-icon--info'></span>
      </a>
    </span>
  )
}

export default InputGovHelp
