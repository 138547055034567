import { FORM_TYPES } from './enumFormType'

const formTypesToXmlRootMap = {
  [FORM_TYPES.F01]: 'F01_2014',
  [FORM_TYPES.F02]: 'F02_2014',
  [FORM_TYPES.F03]: 'F03_2014',
  [FORM_TYPES.F04]: 'F04_2014',
  [FORM_TYPES.F05]: 'F05_2014',
  [FORM_TYPES.F06]: 'F06_2014',
  [FORM_TYPES.F07]: 'F07_2014',
  [FORM_TYPES.F08]: 'F08_2014',
  [FORM_TYPES.F12]: 'F12_2014',
  [FORM_TYPES.F13]: 'F13_2014',
  [FORM_TYPES.F14]: 'F14_2014',
  [FORM_TYPES.F15]: 'F15_2014',
  [FORM_TYPES.F16]: 'PRIOR_INFORMATION_DEFENCE',
  [FORM_TYPES.F17]: 'CONTRACT_DEFENCE',
  [FORM_TYPES.F18]: 'CONTRACT_AWARD_DEFENCE',
  [FORM_TYPES.F19]: 'CONTRACT_CONCESSIONAIRE_DEFENCE',
  [FORM_TYPES.F20]: 'F20_2014',
  [FORM_TYPES.F21]: 'F21_2014',
  [FORM_TYPES.F22]: 'F22_2014',
  [FORM_TYPES.F23]: 'F23_2014',
  [FORM_TYPES.F24]: 'F24_2014',
  [FORM_TYPES.F25]: 'F25_2014',
  [FORM_TYPES.CZ01]: 'cz_F01',
  [FORM_TYPES.CZ02]: 'cz_F02',
  [FORM_TYPES.CZ03]: 'cz_F03',
  [FORM_TYPES.CZ04]: 'cz_F04',
  [FORM_TYPES.CZ05]: 'cz_F05',
  [FORM_TYPES.CZ06]: 'cz_F06',
  [FORM_TYPES.CZ07]: 'cz_F07',
}

export const formTypesToXmlRoot = (formType: string, version?: string) => {
  if (formType === FORM_TYPES.CZ05 && version === 'R2.0.8.S02') {
    return 'NOTICE'
  }

  return formTypesToXmlRootMap[formType]
}
