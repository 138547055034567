import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { validateAll } from '../actions/validateAll'
import { LINKS } from '../components/App/LINKS'
import ButtonGov from '../components/Btns/ButtonGov'
import ButtonGovLink from '../components/Btns/ButtonGovLink'
import Loading from '../components/Loading/Loading'
import { formTypeToCodeLabel } from '../enums/enumFormTypeV2'
import { useModal } from '../hooks/useModal'
import { useUser } from '../hooks/useUser'
import { selectFormData } from '../redux/form/selectorsFormV1'
import { selectFormActionLoading, selectFormIsValid, selectFormWithRequest } from '../redux/formState/selectors'
import { selectFormIsFullInit } from '../redux/formTree/formTreeSelectors'
import { useAppDispatch } from '../redux/store'
import { selectSchemaIsV1, selectSubmMainInfo, selectSubmReq } from '../redux/submissions/selectors'
import { RepairFormHelper } from '../services/RepairFormHelper'
import ErrorPanel from './errors/ErrorPanel'

interface iFormActionsGlobal {
  id: string
  formRef: any
}

const FormActionsGlobal = ({ id, formRef }: iFormActionsGlobal) => {
  const { t } = useTranslation()

  const { userIsLoged, isAdmin } = useUser()
  const { openModalConfirmSend, openModalConfirmSave, openModalCopyForm, openModalNewForm } = useModal()

  const dispatch = useAppDispatch()

  const actionLoading = useSelector(selectFormActionLoading)
  const isFullInit = useSelector(selectFormIsFullInit)

  const { isDraft, formType, submMain } = useSelector(selectSubmMainInfo)
  const isFormEditable = isDraft

  const submReq = useSelector(selectSubmReq)
  const withReq = useSelector(selectFormWithRequest)
  const dataV1 = useSelector(selectFormData)
  const isV1 = useSelector(selectSchemaIsV1)
  const isCopyAble = userIsLoged && !isV1 && (!isDraft || isAdmin)

  const isValid = useSelector(selectFormIsValid)

  const clickSave = () => {
    openModalConfirmSave()
  }

  const clickSend = () => {
    openModalConfirmSend()
  }

  const clickValidation = () => {
    dispatch(validateAll())
  }

  const handlePrint = useReactToPrint({
    content: () => formRef.current,
    documentTitle: 'VVZ - Formulář ' + formTypeToCodeLabel(formType),
    onBeforeGetContent: () => {
      document.querySelectorAll('.closed-form-block').forEach(function (element: any) {
        element.style.display = 'block'
      })
    },
    onAfterPrint: () => {
      document.querySelectorAll('.closed-form-block').forEach(function (element: any) {
        element.style.display = 'none'
      })
    },
  })

  const RForm = new RepairFormHelper(submMain, submReq, dataV1)
  const hasRepairForm = userIsLoged && withReq && RForm.hasRepariForm() && !submMain.data.formularZneplatnen

  const openNewRepairForm = () => {
    const dataNewRepairForm = RForm.getDataRepairForm()
    const newFormType = RForm.getFormTypeToRepair()
    if (newFormType) {
      openModalNewForm(newFormType, dataNewRepairForm)
    }
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className='stickyFormActions'>
      {(actionLoading || !isFullInit) ? (
        <Loading />
      ) : (
        <>
          <ErrorPanel />
          <div className='custom-actions'>
            <div className='custom-actions__item'>
              <p>
                <ButtonGov variant='primary-outlined' className="gov-footer__scroll-up scrollinform" onClick={goToTop}>
                  <span id='fo-scrollBtn' className='u-sr-only gov-button__label'>
                    {t('footer.nahoru', 'Zpět nahoru')}
                  </span>
                </ButtonGov>
              </p>

              <p>
                <ButtonGovLink variant='primary-outlined' to={LINKS.textFormular + '/' + id} openNewTab>
                  Tisk TXT
                </ButtonGovLink>
              </p>
              <p>
                <button onClick={handlePrint} className='gov-button gov-button--primary-outlined'>
                  Export PDF
                </button>
              </p>
              {isCopyAble && (
                <p>
                  <ButtonGov variant='primary-outlined' onClick={() => openModalCopyForm(id)}>
                    Kopírovat formulář
                  </ButtonGov>
                </p>
              )}
              {hasRepairForm && (
                <p>
                  <ButtonGov variant='primary-outlined' onClick={openNewRepairForm}>
                    Opravný formulář
                  </ButtonGov>
                </p>
              )}
            </div>

            {isFormEditable && (
              <div className='custom-actions__item'>
                <p>
                  <button onClick={clickValidation} type='submit' className='gov-button gov-button--primary-outlined'>
                    Validovat
                  </button>
                </p>

                <p>
                  <button onClick={clickSave} type='submit' className='gov-button gov-button--primary'>
                    Uložit
                  </button>
                </p>

                <p>
                  <button
                    title={isValid ? 'Odeslat formulář' : 'Nejdříve validujte data ve formuláři'}
                    disabled={!isValid}
                    onClick={clickSend}
                    type='submit'
                    className='gov-button gov-button--primary'
                  >
                    Podat formulář
                  </button>
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default FormActionsGlobal
