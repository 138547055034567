import React from 'react'
import { ActiveSectionContextProvider, useActiveSectionContext } from '../context/ActiveSectionContext'

interface iActiveSectionWrap {
  children: React.ReactNode
}

const ActiveSectionWrap = ({ children }: iActiveSectionWrap) => {
  return (
    <ActiveSectionContextProvider>
      <ActiveSectionOnClick>{children}</ActiveSectionOnClick>
    </ActiveSectionContextProvider>
  )
}

const ActiveSectionOnClick = ({ children }: iActiveSectionWrap) => {
  const { clickActiveSection, isActive } = useActiveSectionContext()
  return (
    <div className={isActive ? 'active-section' : ''} onClick={clickActiveSection}>
      {children}
    </div>
  )
}

export default ActiveSectionWrap
