import { formTypeToCodeLabel } from '../enums/enumFormTypeV2'

const WP_FILE_URL = process.env.REACT_APP_WP_API_URL + 'wp-content/uploads/2024/05/'

export const formTypeToMetodic = (formType: string) => {
  const formTypeLabel =  formTypeToCodeLabel(formType)
  return `${WP_FILE_URL}Popisy-a-napoveda-k-jednotlivym-polim-formulare-${formTypeLabel}.pdf`
}

export const formReqToMetodic = () => {
  return `${WP_FILE_URL}Popisy-a-napoveda-k-jednotlivym-polim-formulare-Zadost-o-uverejneni.pdf`
}
