import React from 'react'
import { iSendFormResponse } from '../../api/SendFormApi'
import { dateFormatFull } from '../../utils/dateFormat'
import { LINKS } from '../App/LINKS'
import ButtonGovLink from '../Btns/ButtonGovLink'

interface iSentInfo {
  response: iSendFormResponse
}

const SentInfo = ({ response }: iSentInfo) => {
  // const formType = response.cisloObjednavky
  return (
    <>
      <div className='custom-detail u-mb--20'>
        <blockquote>
          Podání formuláře proběhlo úspěšně. O průběhu jeho zpracování budete informováni zvolenou cestou.
        </blockquote>
      </div>
      <div className='custom-detail u-mb--50'>
        {/*Základní údaje o podání:*/}
        <p>
          <span className='sentInfoLineTitle'>Datum a čas přijetí ve VVZ:</span>{' '}
          {dateFormatFull(response.datumACasPrijeti)}
          <br />
          <span className='sentInfoLineTitle'>Evidenční číslo formuláře:</span> {response.evidencniCisloFormulare}
          <br />
          <span className='sentInfoLineTitle'>Evidenční číslo zakázky:</span> {response.evidencniCislo}
          <br />
          <span className='sentInfoLineTitle'>Kód pro související formuláře:</span> {response.kodProSouvisejiciFormular}
        </p>
        {/*{[FT.CZ01, FT.CZ02, FT.CZ03, FT.CZ04].includes(formType) && (*/}
        {/*  <p>*/}
        {/*    <blockquote>*/}
        {/*      Uveřejnění formuláře ve Věstníku VZ proběhne během několika sekund.*/}
        {/*      <br />*/}
        {/*      Nezapomeňte následně uveřejnit související zadávací dokumentaci na Profilu zadavatele.*/}
        {/*    </blockquote>*/}
        {/*  </p>*/}
        {/*)}*/}
      </div>
      <ButtonGovLink to={LINKS.mojeVyhlaseni} variant='primary' className='u-mb--20'>
        Moje vyhlášení
      </ButtonGovLink>
    </>
  )
}

export default SentInfo
