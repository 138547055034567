import React, { useEffect } from 'react'
import { ENV } from '../../ENV'
import { useMobileMenu } from '../../hooks/useMobileMenu'
import LangueSwitch from '../LangueSwitch/LangueSwitch'
import AdminHeader from './AdminHeader'
import Menu from './Menu'
import logo from './mmr_cr_negativni.svg'
import UserHeader from './UserHeader'

interface iHeader {
  isAdmin?: boolean
}

const Header = ({ isAdmin }: iHeader) => {
  const { mobileMenuOpen, setMenuOpen } = useMobileMenu()
  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : 'initial'
  }, [mobileMenuOpen])
  return (
    <header
      className={
        'gov-container gov-container--wide gov-container--no-y-offset gov-header ' +
        (mobileMenuOpen ? ' is-fixed ' : '') +
        (ENV.REF_ENV ? ' ref-header ' : '')
      }
    >
      <div className='gov-container__content'>
        <div className='gov-header__holder'>
          <div className='gov-header__left' style={{ display: 'flex' }}>
            <a
              href='https://mmr.cz/cs/uvod'
              target='_blank'
              rel='noreferrer noopener'
              className='gov-footer__image-link'
            >
              <img alt='MMR logo' src={logo} className='mmr-logo' />
            </a>

            {ENV.REF_ENV ? (
              <a className='gov-logo gov-logo--inversed ref-header-logo' href='/'>
                VĚSTNÍK VEŘEJNÝCH ZAKÁZEK
                <small>TESTOVACÍ PROSTŘEDÍ</small>
              </a>
            ) : (
              <a className='gov-logo gov-logo--inversed' href='/'>
                VĚSTNÍK VEŘEJNÝCH ZAKÁZEK
              </a>
            )}
          </div>
          <div className='gov-header__right'>
            <div className='gov-header__item gov-header__item--login'>{isAdmin ? <AdminHeader /> : <UserHeader />}</div>
            <div className='gov-header__fixed-items'>
              <div className='gov-header__item gov-header__item--mobile'>
                <button
                  onClick={() => setMenuOpen(!mobileMenuOpen)}
                  className={'gov-header__hamburger ' + (mobileMenuOpen ? 'active' : '')}
                >
                  <span></span>
                  <span></span>
                  <div>MENU</div>
                </button>
              </div>
              <LangueSwitch />
            </div>
          </div>
        </div>
        <hr className='gov-header__separator' />
        <div className={'gov-header__overlay ' + (mobileMenuOpen ? 'active' : '')}></div>
        <Menu mobile={mobileMenuOpen} />
      </div>
    </header>
  )
}

export default Header
