import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iEformsFilterOrderParam } from '../../interfaces/iEformsApi'
import { iEformsSubmission } from '../../interfaces/iEformsSubmission'

export interface iModalDataHelp {
  formType: string
  xpath: string
}

interface iModallState {
  modalOpen: string
  modalData: iModalData
}

export interface iModalDataNewRepairForm {
  needTedNum: boolean
  evCisloZakazky: string
  evCisloFormulare: string
  kodSouvisFormulare: string
}

interface iModalDataHelpAdv {
  key: string
  isReq: boolean
}

export interface iModalDataExport {
  filter: iEformsFilterOrderParam
}

export interface iModalData {
  modalDataContract?: iEformsSubmission<any>
  modalDataConfirm?: string
  modalDataNewForm?: string | string[]
  modalDataCopyForm?: string
  modalDataDeleteForm?: string
  modalDataHelp?: iModalDataHelp
  modalDataHelpSimple?: string
  modalDataHelpAdvance?: iModalDataHelpAdv
  modalDataNewRepairForm?: iModalDataNewRepairForm
  modalDataErrorDetail?: any
  modalDataExport?: iModalDataExport
}

const slice = createSlice({
  name: 'modal',
  initialState: {
    modalOpen: '',
    modalData: {},
  } as iModallState,
  reducers: {
    setModalOpen(state, action: PayloadAction<{ modal: string; modalData?: Partial<iModalData> }>) {
      const { modal, modalData } = action.payload
      state.modalOpen = modal
      state.modalData = { ...state.modalData, ...modalData }
    },
    setModalClose(state) {
      state.modalOpen = ''
      state.modalData = {}
    },
  },
})

export const { setModalOpen, setModalClose } = slice.actions
export default slice.reducer
