import {
  getCells,
  getConfig,
  getData,
  getRenderers,
  getSchema,
  getTranslator,
  JsonFormsState,
  layoutDefaultProps,
  LayoutProps,
  OwnPropsOfJsonFormsRenderer,
  OwnPropsOfLayout,
  Resolve,
} from '@jsonforms/core'
import { JsonFormsStateContext, withJsonFormsContext } from '@jsonforms/react'
import React, { ComponentType } from 'react'
import { iUIschema } from '../interfaces/iUiSchema'
import { scopeWithoutRepeat } from '../../utils/scopeModifs'
import { isVisibleRules } from '../rules/isVisibleRules'
import { getErrorsFromScope } from '../utils/getErrorsFromScope'
import { getSchemaItem } from '../utils/getSchemaItem'
import { getTranlateLabelFullKey } from '../utils/getTranslateUiSchema'

export interface CustomPropsOfLayout extends Omit<OwnPropsOfLayout, 'uischema'> {
  uischema: iUIschema
}

export interface CustomLayoutProps extends Omit<LayoutProps, 'uischema'> {
  uischema: iUIschema
  errors?: string
  required?: boolean
}

export const withJsonFormsLayoutPropsCustom = <T extends CustomLayoutProps>(
  Component: any, // ComponentType<T>,
  memoize = true,
): ComponentType<T & CustomPropsOfLayout> =>
  withJsonFormsContext(withContextToLayoutPropsCustom(memoize ? React.memo(Component) : Component))

const withContextToLayoutPropsCustom =
  (Component: ComponentType<CustomLayoutProps>): ComponentType<OwnPropsOfJsonFormsRenderer> =>
  ({ ctx, props }: JsonFormsStateContext /*& LayoutProps*/) => {
    const layoutProps = ctxToLayoutPropsCustom(ctx, props)
    return <Component {...props} {...layoutProps} />
  }

export const ctxToLayoutPropsCustom = (ctx: JsonFormsStateContext, props: CustomPropsOfLayout): CustomLayoutProps =>
  mapStateToLayoutPropsCustom({ jsonforms: { ...ctx } }, props)

export const mapStateToLayoutPropsCustom = (
  state: JsonFormsState,
  ownProps: CustomPropsOfLayout,
): CustomLayoutProps => {
  const rootData = getData(state)
  const { uischema } = ownProps

  const allRules = uischema.allRules!
  const { enabled, visible } = isVisibleRules(rootData, allRules)

  const path = ownProps.path || ''
  const data = Resolve.data(rootData, path)

  const config = getConfig(state)
  const simpleScope = scopeWithoutRepeat(uischema?.scope || '')
  const isNoPublic =
    config?.noPubliScopes?.some((noPublicScope: string) => simpleScope.startsWith(noPublicScope)) && data === undefined

  const label = uischema.label
  const t = getTranslator()(state)
  const translateFullKey = getTranlateLabelFullKey(uischema)
  const translateText = label !== undefined ? label : t(translateFullKey, translateFullKey)

  const rootSchema = getSchema(state)
  const resolvedSchema = getSchemaItem(rootSchema, uischema.scope)
  const errors = getErrorsFromScope(state, uischema.scope)

  const required = enabled && (resolvedSchema as any)?.validations?.required

  return {
    ...layoutDefaultProps,
    renderers: ownProps.renderers || getRenderers(state),
    cells: ownProps.cells || getCells(state),
    visible: visible && !isNoPublic,
    enabled: enabled && !state.jsonforms.readonly,
    path,
    data,
    uischema: uischema,
    schema: ownProps.schema!,
    config: getConfig(state),
    label: translateText,
    errors: errors.map((e) => e.message).join(', '),
    required,
  }
}
