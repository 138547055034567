import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { MODAL_TYPE, useModal } from '../../hooks/useModal'
import ModalChangeWorkflow from './ModalChangeWorkflow'
import ModalConfirmCancelForm from './ModalConfirmCancelForm'
import ModalConfirmReopenForm from './ModalConfirmReopenForm'
import ModalConfirmSave from './ModalConfirmSave'
import ModalConfirmSubmit from './ModalConfirmSubmit'
import ModalCopyForm from './ModalCopyForm'
import ModalDeleteForm from './ModalDeleteForm'
import ModalErrorDetail from './ModalErrorDetail'
import ModalExport from './ModalExport'
import ModalHelp from './ModalHelp'
import ModalHelpAdvance from './ModalHelpAdvance'
import ModalHelpSimple from './ModalHelpSimple'
import ModalNewForm from './ModalNewForm'
import ModalNewsletter from './ModalNewsletter'
import ModalNotif from './ModalNotif'
import ModalWrapGov from './ModalWrapGov'

const ModalManager = () => {
  const { dataNewForm, dataNewRepairForm, closeModal } = useModal()
  const { t } = useTranslation()

  const location = useLocation()
  useEffect(() => {
    closeModal()
  }, [location])

  return (
    <>
      <ModalWrapGov
        type={MODAL_TYPE.notif}
        title={t('modalNotif.odebirat_upozorneni', 'Odebírat upozornění na nové zakázky')}
      >
        <ModalNotif />
      </ModalWrapGov>

      <ModalWrapGov
        type={MODAL_TYPE.newsletter}
        title={t('modalNotif.odebirat_informace', 'Odebírat informace o nových aktualitách')}
      >
        <ModalNewsletter />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.changeWorkflow} title='Změnit stav'>
        <ModalChangeWorkflow />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.confirmSubmit} title='Odeslat formulář'>
        <ModalConfirmSubmit />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.confirmSave} title='Uložit formulář'>
        <ModalConfirmSave />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.confirmCancelForm} title='Potvrdit akci'>
        <ModalConfirmCancelForm />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.confirmReopenForm} title='Potvrdit akci'>
        <ModalConfirmReopenForm />
      </ModalWrapGov>

      <ModalWrapGov
        type={MODAL_TYPE.newForm}
        title={
          (dataNewRepairForm ? 'Založení opravného formuláře ' : 'Založení nového formuláře ') +
          (typeof dataNewForm === 'string' ? formTypeToCodeLabel(dataNewForm) : dataNewForm)
        }
      >
        <ModalNewForm />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.copyForm} title='Kopírovat formulář'>
        <ModalCopyForm />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.help} title='Nápověda'>
        <ModalHelp />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.helpSimple} title='Nápověda'>
        <ModalHelpSimple />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.helpAdvance} title='Nápověda'>
        <ModalHelpAdvance />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.errorDetail} title='Diagnostické informace'>
        <ModalErrorDetail />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.export} title='Exportovat seznam'>
        <ModalExport />
      </ModalWrapGov>

      <ModalWrapGov type={MODAL_TYPE.deleteForm} title='Smazat formulář'>
        <ModalDeleteForm />
      </ModalWrapGov>
    </>
  )
}

export default ModalManager
