export const TEXTS: { [path: string]: any } = {
  yes: 'Ano',
  no: 'Ne',
  YES: 'Ano',
  NO: 'Ne',
  true: 'Ano',
  false: 'Ne',
  LEGAL_BASIS: {
    el_attrs: {
      VALUE: {
        '32009L0081': 'směrnice 2009/81/ES',
        '32014L0024': 'směrnice 2014/24/EU',
        '32014L0025': 'směrnice 2014/25/EU',
        '32014L0023': 'směrnice 2014/23/EU',
      },
    },
  },
  CONTRACTING_BODY: {
    ADDRESS_FURTHER_INFO_IDEM: {
      yes: 'na výše uvedené adrese',
      no: 'na jiné adrese: (uveďte jinou adresu)',
    },
    ADDRESS_PARTICIPATION_IDEM: {
      yes: 'na výše uvedené adrese',
      no: 'na následující adrese: (uveďte jinou adresu)',
    },
    CE_ACTIVITY: {
      el_attrs: {
        VALUE: {
          AIRPORT_RELATED_ACTIVITIES: 'ČINNOSTI SOUVISEJÍCÍ S LETIŠTI',
          EXPLORATION_EXTRACTION_COAL_OTHER_SOLID_FUEL: 'PRŮZKUM A TĚŽBA UHLÍ A JINÝCH PEVNÝCH PALIV',
          PORT_RELATED_ACTIVITIES: 'ČINNOSTI SOUVISEJÍCÍ S PŘÍSTAVY',
          PRODUCTION_TRANSPORT_DISTRIBUTION_GAS_HEAT: 'VÝROBA, DOPRAVA A DISTRIBUCE PLYNU A TEPLA',
          URBAN_RAILWAY_TRAMWAY_TROLLEYBUS_BUS_SERVICES:
            'MĚSTSKÁ ŽELEZNIČNÍ, TRAMVAJOVÁ, TROLEJBUSOVÁ NEBO AUTOBUSOVÁ DOPRAVA',
          ELECTRICITY: 'ELEKTŘINA',
          EXPLORATION_EXTRACTION_GAS_OIL: 'TĚŽBA ZEMNÍHO PLYNU A ROPY',
          POSTAL_SERVICES: 'POŠTOVNÍ SLUŽBY',
          RAILWAY_SERVICES: 'ŽELEZNIČNÍ SLUŽBY',
          WATER: 'VODA',
        },
      },
    },
  },
  CA_TYPE: {
    el_attrs: {
      VALUE: {
        BODY_PUBLIC: 'Veřejnoprávní instituce',
        EU_INSTITUTION: 'Evropská instituce/agentura nebo mezinárodní organizace',
        MINISTRY: 'Ministerstvo nebo jiný celostátní či federální orgán včetně jejich organizačních složek',
        NATIONAL_AGENCY: 'Celostátní či federální úřad/agentura',
        REGIONAL_AGENCY: 'Regionální či místní úřad/agentura',
        REGIONAL_AUTHORITY: 'Regionální či místní orgán',
      },
    },
  },
  CA_ACTIVITY: {
    el_attrs: {
      VALUE: {
        DEFENCE: 'Obrana',
        ECONOMIC_AND_FINANCIAL_AFFAIRS: 'Hospodářské a finanční záležitosti',
        EDUCATION: 'Vzdělávání',
        ENVIRONMENT: 'Životní prostředí',
        GENERAL_PUBLIC_SERVICES: 'Služby pro širokou veřejnost',
        HEALTH: 'Zdravotnictví',
        HOUSING_AND_COMMUNITY_AMENITIES: 'Bydlení a občanská vybavenost',
        PUBLIC_ORDER_AND_SAFETY: 'Veřejný pořádek a bezpečnost',
        RECREATION_CULTURE_AND_RELIGION: 'Rekreace, kultura a náboženství',
        SOCIAL_PROTECTION: 'Sociální služby',
      },
    },
  },
  OBJECT_CONTRACT: {
    TYPE_CONTRACT: {
      el_attrs: {
        CTYPE: {
          SERVICES: 'Služby',
          SUPPLIES: 'Dodávky',
          WORKS: 'Práce',
        },
      },
    },
  },
  OBJECT_DESCR: {
    DURATION: {
      el_attrs: {
        TYPE: {
          DAY: 'Dny',
          MONTH: 'Měsíce',
        },
      },
    },
  },
  NOTICE: {
    el_attrs: {
      TYPE: {
        PRI_ONLY: 'Předběžné oznámení',
        PRI_CALL_COMPETITION:
          'Předběžné oznámení - Toto oznámení představuje výzvu k účasti v soutěži [Zainteresované subjekty musí o svém zájmu o danou zakázku (zakázky) informovat veřejného zadavatele. Zakázka či zakázky budou zadány bez dalšího uveřejnění oznámení o zahájení zadávacího řízení.]',
        CONTRACT: 'Oznámení o zahájení zadávacího řízení',
        AWARD_CONTRACT: 'Oznámení o výsledku zadávacího řízení',
        PER_ONLY: 'Pravidelné předběžné oznámení',
        PER_CALL_COMPETITION:
          'Pravidelné předběžné oznámení - Toto oznámení představuje výzvu k účasti v soutěži\n[Zainteresované subjekty musí o svém zájmu o danou zakázku (zakázky) informovat zadavatele.\nZakázka či zakázky budou přiděleny bez následného zveřejnění výzvy k účasti v soutěži.]',
        QSU_ONLY: 'Systém kvalifikace',
        QSU_CALL_COMPETITION:
          'Systém kvalifikace - Toto oznámení představuje výzvu k účasti v soutěži\n[Subjekty, které mají o zakázku zájem, musí požádat zadavatele o kvalifikaci podle systému kvalifikace.\nZakázka či zakázky budou zadány bez zveřejnění další výzvy k účasti v soutěži.]',
        PRI: 'Předběžné oznámení',
        CONCESSION_AWARD_CONTRACT: 'Oznámení o výsledku koncesního řízení',
      },
    },
  },
  TYPE_CONTRACT: {
    el_attrs: {
      VALUE: {
        WORKS: 'Stavební práce',
        SERVICES: 'Služby',
        SUPPLIES: 'Dodávky',
      },
    },
  },
  F01: {
    NOTICE: {
      el_attrs: {
        TYPE: {
          PRI_ONLY: 'Toto oznámení je pouze pro předběžné informace',
          PRI_CALL_COMPETITION: 'Toto oznámení představuje výzvu k účasti v soutěži',
          PRI_REDUCING_TIME_LIMITS: 'Na základě tohoto oznámení budou zkráceny lhůty pro doručení nabídek',
        },
      },
    },
  },
  F04: {
    NOTICE: {
      el_attrs: {
        TYPE: {
          PER_ONLY: 'Toto oznámení je pouze pravidelným předběžným oznámením',
          PER_CALL_COMPETITION: 'Toto oznámení představuje výzvu k účasti v soutěžii',
          PER_REDUCING_TIME_LIMITS: 'Na základě tohoto oznámení budou zkráceny lhůty pro doručení nabídek',
        },
      },
    },
  },
  F08: {
    LEGAL_BASIS: {
      el_attrs: {
        VALUE: {
          '32009L0081': 'Předběžné oznámení (směrnice 2009/81/ES)',
          '32014L0024': 'Předběžné oznámení (směrnice 2014/24/EU) (nepředstavuje výzvu k účasti v soutěži)',
          '32014L0025': 'Pravidelné předběžné oznámení (směrnice 2014/25/EU) (nepředstavuje výzvu k účasti v soutěži)',
        },
      },
    },
  },
  F20: {
    MODIFICATIONS_CONTRACT: {
      DESCRIPTION_PROCUREMENT: {
        DURATION: {
          el_attrs: {
            TYPE: {
              DAY: 'Dny',
              MONTH: 'Měsíce',
            },
          },
        },
      },
    },
  },
  dtt___TIME_FRAME: {
    dtt___DURATION: {
      el_attrs: {
        TYPE: {
          MONTH: 'měsících',
          DAY: 'dnech',
        },
      },
    },
  },
  ted___TYPE_CONTRACT: {
    el_attrs: {
      CTYPE: {
        WORKS: 'Stavební práce',
        SUPPLIES: 'Dodávky',
        SERVICES: 'Služby',
      },
    },
  },

  FD_NOTICE: {
    TYPE_AND_ACTIVITIES: {
      TYPE_OF_CONTRACTING_AUTHORITY: {
        el_attrs: {
          VALUE: {
            CR: 'Česká republika (§ 2 odst. 2 písm. a)*',
            PRISPEV_ORG: 'Státní příspěvková organizace (§ 2 odst. 2 písm. b)*',
            UZEMNI_CELEK: 'Územní samosprávní celek nebo jeho příspěvková organizace (§ 2 odst. 2 písm. c)*',
            JINA_PO: 'Jiná právnická osoba (§ 2 odst. 2 písm. d)*',
            EU_INST: 'Evropská instituce nebo mezinárodní organizace',
          },
        },
      },
    },
    CONTRACT_TYPES: {
      TYPE: {
        TYPE_WORK_CONTRACT: {
          el_attrs: {
            VALUE: {
              EXECUTION: 'Provádění',
              DESIGN_EXECUTION: 'Projekt a provádění',
              REALISATION_REQUIREMENTS_SPECIFIED_CONTRACTING_AUTHORITIES:
                'Provádění stavebních prací v souladu s požadavky zadavatele',
            },
          },
        },
      },
    },
  },

  CONTRACTING_AUTHORITY: {
    CA_TYPE: {
      el_attrs: {
        VALUE: {
          CR: 'Česká republika [§ 4 odst. 1 písm. a)]*',
          PRISPEV_ORG: 'Státní příspěvková organizace [§ 4 odst. 1 písm. c)]*',
          UZEMNI_CELEK: 'Územní samosprávný celek nebo jeho příspěvková organizace [§ 4 odst. 1 písm. d)]*',
          JINA_PO: 'Jiná právnická osoba [§ 4 odst. 1 písm. e)]*',
          EU_INST: 'Evropská instituce nebo mezinárodní organizace',
          JINY: 'Jiný',
        },
      },
    },
  },
  CZ01: {
    NOTICE: {
      el_attrs: {
        TYPE: {
          PRI_ONLY: 'Toto oznámení je pouze pro předběžné informace',
          PRI_CALL_COMPETITION: 'Toto oznámení představuje výzvu k účasti v soutěži',
          PRI_REDUCING_TIME_LIMITS: 'Na základě tohoto oznámení budou zkráceny lhůty pro doručení nabídek',
        },
      },
    },
  },
  CZ05: {
    CONTRACTING_AUTHORITY: {
      CA_ACTIVITY: {
        el_attrs: {
          VALUE: {
            EDUCATION: 'Školství',
          },
        },
      },
    },
  },
  TYPE_SUPPLIES_CONTRACT: {
    el_attrs: {
      VALUE: {
        HIRE_PURCHASE: 'Koupě na splátky',
        RENTAL: 'Nájem',
        LEASE: 'Koupě najaté věci',
        PURCHASE: 'Koupě',
        COMBINATION_THESE: 'Kombinace uvedeného',
      },
    },
  },
  F18_PT_NEGOTIATED_WITHOUT_PUBLICATION_CONTRACT_NOTICE: {
    ANNEX_D: {
      NO_OPEN_RESTRICTED: {
        el_attrs: {
          VALUE: {
            RESTRICTED_PROCEDURE: 'omezené řízení',
            NEGOTIATED_PROCEDURE_COMPETITION: 'vyjednávací řízení s předchozím zveřejněním oznámení o zakázce',
            COMPETITIVE_DIALOGUE: 'soutěžní dialog',
          },
        },
      },
    },
  },
  F17_DIV_INTO_LOT_YES: {
    el_attrs: {
      VALUE: {
        ONE_LOT_ONLY: 'Pouze jednu část',
        ONE_OR_MORE_LOT: 'Několik částí',
        ALL_LOTS: 'Všechny části',
      },
    },
  },
  PRIOR_INFORMATION_NOTICE_F17: {
    el_attrs: {
      CHOICE: {
        NOTICE_BUYER_PROFILE: 'Oznámení na profilu kupujícího',
        PRIOR_INFORMATION_NOTICE: 'Oznámení předběžných informací',
      },
    },
  },
  TYPE_OF_CONTRACTING_AUTHORITY: {
    el_attrs: {
      VALUE: {
        MINISTRY: 'Ministerstvo nebo jiný celostátní či federální orgán včetně jejich organizačních složek',
        NATIONAL_AGENCY: 'Celostátní či federální úřad/agentura',
        REGIONAL_AUTHORITY: 'Regionální či místní orgán',
        REGIONAL_AGENCY: 'Regionální či místní úřad/agentura',
        BODY_PUBLIC: 'Veřejnoprávní instituce',
        EU_INSTITUTION: 'Evropská instituce/agentura nebo mezinárodní organizace',
      },
    },
  },
  TYPE_OF_ACTIVITY: {
    el_attrs: {
      VALUE: {
        GENERAL_PUBLIC_SERVICES: 'Služba pro širokou veřejnost',
        SOCIAL_PROTECTION: 'Sociální služby',
        EDUCATION: 'Školství',
        HEALTH: 'Zdravotnictví',
        ENVIRONMENT: 'Životní prostředí',
        PUBLIC_ORDER_AND_SAFETY: 'Veřejný pořádek a bezpečnost',
        HOUSING_AND_COMMUNITY_AMENITIES: 'Bydlení a občanská vybavenost',
        DEFENCE: 'Obrana',
        ECONOMIC_AND_FINANCIAL_AFFAIRS: 'Hospodářské a finanční záležitosti',
        RECREATION_CULTURE_AND_RELIGION: 'Rekreace, kultura a náboženství',
      },
    },
  },
  TYPE_OF_ACTIVITY_OTHER: {
    el_attrs: {
      VALUE: {
        OTHER: 'Jiný',
      },
    },
  },
  ACTIVITY_OF_CONTRACTING_ENTITY_OTHER: '@TYPE_OF_ACTIVITY_OTHER',
  TYPE_OF_CONTRACTING_AUTHORITY_OTHER: '@TYPE_OF_ACTIVITY_OTHER',
  ACTIVITY_OF_CONTRACTING_ENTITY: {
    el_attrs: {
      VALUE: {
        RAILWAY_SERVICES: 'Železniční služby',
        EXPLORATION_EXTRACTION_COAL_OTHER_SOLID_FUEL: 'Vyhledávání a těžba uhlí a dalších pevných paliv',
        PORT_RELATED_ACTIVITIES: 'Činnosti související s přístavy',
        WATER: 'Vodohospodářství',
        ELECTRICITY: 'Elektřina',
        AIRPORT_RELATED_ACTIVITIES: 'Činnosti související s letišti',
        URBAN_RAILWAY_TRAMWAY_TROLLEYBUS_BUS_SERVICES:
          'Městská železniční, tramvajová, trolejbusová nebo autobusová doprava',
        EXPLORATION_EXTRACTION_GAS_OIL: 'Vyhledávání a těžba zemního plynu a ropy',
        PRODUCTION_TRANSPORT_DISTRIBUTION_GAS_HEAT: 'Výroba, přeprava a distribuce plynu a tepla',
        POSTAL_SERVICES: 'Poštovní služby',
      },
    },
  },
  PREVIOUS_NOTICE_BUYER_PROFILE_F18: {
    el_attrs: {
      CHOICE: {
        NOTICE_BUYER_PROFILE: 'Oznámení na profilu kupujícího',
        PRIOR_INFORMATION_NOTICE: 'Oznámení předběžných informací',
      },
    },
  },
  F16: {
    FD_PRIOR_INFORMATION_DEFENCE: {
      OBJECT_WORKS_SUPPLIES_SERVICES_PRIOR_INFORMATION: {
        TYPE_CONTRACT_PLACE_DELIVERY_DEFENCE: {
          TYPE_CONTRACT_PI_DEFENCE: {
            SERVICE_CATEGORY_DEFENCE: 'Kategorie služeb č',
          },
        },
      },
    },
  },
  F18: {
    FD_CONTRACT_AWARD_DEFENCE: {
      AWARD_OF_CONTRACT_DEFENCE: {
        MORE_INFORMATION_TO_SUB_CONTRACTED: {
          CONTRACT_LIKELY_SUB_CONTRACTED_WITH_DEFENCE: {
            SUBCONTRACT_DEFENCE: {
              SUBCONTRACT_AWARD_PART: {
                el_attrs: {
                  VALUE: {
                    YES: 'Všechny nebo některé subdodávky budou zadány na základě soutěže (viz hlava III směrnice 2009/81/ES)',
                    NO: 'Část zakázky bude zadána subdodavatelům na základě soutěže (viz hlava III směrnice 2009/81/ES)',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  EXISTUJICI_ZAKAZKA: {
    yes: 'Formulář se vztahuje k existující zakázce/profilu',
    no: 'Formulář zakládá novou zakázku/profil',
  },
  REZIM_VEREJNE_ZAKAZKY: {
    NADLIMITNI: 'Nadlimitní',
    PODLIMITNI: 'Podlimitní',
    VZMR: 'Veřejná zakázka malého rozsahu',
  },
  PREVAZUJICI_ZPUSOB_FINANCOVANI: {
    Z_VEREJNYCH_ROZPOCTU_A_STATNICH_FONDU_CR: 'Zdroje z veřejných rozpočtů a státních fondů ČR',
    Z_FONDU_EU: 'Zdroje z fondů EU',
    SOUKROME_A_JINE: 'Soukromé a jiné zdroje',
  },
  ZASLANI_POTVRZENI: {
    el_attrs: {
      MOZNOST: {
        EMAIL: 'na e-mailovou adresu',
        DATOVA_SCHRANKA: 'do datové schránky',
      },
    },
  },
}
