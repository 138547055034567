import React from 'react'

interface iPartGovError {
  text?: string
}

const PartGovError = ({ text }: iPartGovError) => {
  if (!text) {
    return null
  }

  return <span className='gov-form-control__message'>{text}</span>
}

export default PartGovError
