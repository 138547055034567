import axios, { GenericAbortSignal } from 'axios'

import qs from 'qs'
import { ENV } from '../ENV'
import {
  iEformsDataJsonPatch,
  iEformsFileData,
  iEformsFilterOrderParam,
  iEformsFilterParam,
  iEformsSubmissionPostData,
  iEformTranslates,
} from '../interfaces/iEformsApi'
import { iEformsForm } from '../interfaces/iEformsForm'
import { iEformsFormSchema } from '../interfaces/iEformsFormSchema'
import { iEformsSubmission, iEformsSubmissionVersion } from '../interfaces/iEformsSubmission'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

export const SLUG_FORM = {
  HLAVNI: process.env.REACT_APP_EFORMS_SLUG_FORM_HLAVNI!,
  PROFIL: process.env.REACT_APP_EFORMS_SLUG_FORM_PROFIL!,
  ZADOST: process.env.REACT_APP_EFORMS_SLUG_FORM_ZADOST!,
  ZADOST_EDIT: process.env.REACT_APP_EFORMS_SLUG_FORM_ZADOST_EDIT!,
  ZADOST2: process.env.REACT_APP_EFORMS_SLUG_FORM_ZADOST2!,
}

const HEADER_JSON_MERGE = { 'Content-Type': 'application/merge-patch+json' }

class EformsApi {
  api = axios.create({
    baseURL: ENV.EFORMS_API_URL,
    // paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
    paramsSerializer: (params) => qs.stringify(params, {}),
  })

  setAdminAcl = () => {
    this.api.defaults.headers.common['X-Auth-Token'] = ENV.EFORMS_ADMIN_TOKEN
  }

  clearAdminAcl = () => {
    delete this.api.defaults.headers.common['X-Auth-Token']
  }

  getBaseUrl = () => {
    return this.api.defaults.baseURL
  }

  getFileBaseUrl = () => {
    return this.getBaseUrl() + '/download/submission_attachments/public/'
  }

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
    // this.setAdminAcl()
  }

  /*   informace o formulari    */
  getFormBySlug = (orgSlug: string, formSlug: string): Promise<iEformsForm> =>
    this.api.get(`/api/organizations/slug/${orgSlug}/forms/slug/${formSlug}`).then((res) => res.data)

  getSubmission = (id: string): Promise<iEformsSubmission<any>> =>
    this.api.get(`/api/submissions/${id}`).then((res) => res.data)

  getSubmissionPublic = (publicId: string) =>
    this.api.get(`/api/submissions/public/${publicId}`).then((res) => res.data)

  getSubmissionsSearch = (formSlug: string, page?: number, limit?: number, params?: iEformsFilterOrderParam) =>
    this.api
      .get(`/api/submissions/search`, {
        params: {
          page: page,
          limit: limit,
          form: formSlug,
          ...params,
        },
      })
      .then((res) => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count']),
        maxItems: parseInt(res.headers['x-max-items']),
      }))

  getSubmissionsChildrenSearch = (submission: string, form?: string | string[], params?: iEformsFilterOrderParam) =>
    this.api
      .get(`/api/submissions/children/search`, {
        params: {
          submission: submission,
          form: form,
          // page: page,
          // limit: limit,
          ...params,
        },
      })
      .then((res) => res.data)

  postSubmission = (submitData: iEformsSubmissionPostData, lang = 'cs'): Promise<iEformsSubmission<any>> =>
    this.api.post(`/api/submissions`, submitData, { headers: { 'Accept-Language': lang } }).then((res) => res.data)

  newForm = ({
    formSlug,
    orgSlug,
    data,
    children,
    parent,
    related,
    lang = 'cs',
    copyFrom,
  }: {
    formSlug: string
    orgSlug: string
    data?: any
    children?: string | string[]
    parent?: string
    related?: string
    lang?: string
    copyFrom?: string
  }) =>
    this.postSubmission(
      {
        form: formSlug,
        organization: orgSlug,
        children: children,
        parent: parent,
        data: data,
        related: related,
        // copyFrom: copyFrom, // TODO az bude nasazen BE
      },
      lang,
    )

  submitForm = (
    schemaIri: string,
    orgSlug: string,
    data: any,
    attachments: string[] = [],
    // state?: string,
    // signature?: Signature,
    organizations?: string[],
    parent?: string,
    lang: string = 'cs',
  ) =>
    this.postSubmission(
      {
        formSchema: schemaIri,
        organization: orgSlug,
        data: data,
        attachments: attachments,
        // signature,
        // state
        organizations: organizations,
        parent: parent,
      },
      lang,
    )

  updateForm = (
    id: string,
    data: any,
    workflowCode?: string,
    // attachments: string[] = [],
    // state?: string,
    // signature?: Signature
    // organizations?: string[],
  ): Promise<iEformsSubmission<any>> =>
    this.api
      .patch(
        `/api/submissions/${id}`,
        {
          data: data,
          workflowPlaceCode: workflowCode,
          // attachments: attachments,
          // organizations: organizations,
        },
        { headers: HEADER_JSON_MERGE },
      )
      .then((res) => res.data)

  updateFormWorkflow = (
    id: string,
    workflowCode?: string,
    dataJsonPatch?: iEformsDataJsonPatch[],
  ): Promise<iEformsSubmission<any>> =>
    this.api
      .patch(
        `/api/submissions/${id}`,
        {
          workflowPlaceCode: workflowCode,
          dataJsonPatch: dataJsonPatch,
        },
        { headers: HEADER_JSON_MERGE },
      )
      .then((res) => res.data)

  // updateFormParent = (publicId: string, parent: string) =>
  //   this.api
  //     .patch(
  //       `/api/submissions/public/${publicId}`,
  //       {
  //         parent: parent,
  //       },
  //       { headers: HEADER_JSON_MERGE },
  //     )
  //     .then((res) => res.data)

  deleteForm = (id: string) => {
    return this.api.delete(`/api/submissions/${id}`).then((res) => res.data)
  }
  /*   Organizace info  + setAdminAcl */
  // getOrganizations = () =>
  //   this.api.get(`/api/organizations`)
  //     .then(res => res.data)

  // getOrganizationBySlug = (orgSlug: string) =>
  //   this.api.get(`/api/organizations/slug/${orgSlug}`)
  //     .then(res => res.data)
  //
  // /*    formulare v organizaci */
  // getOrganizationForms = (orgId: string) =>
  //   this.api.get(`/api/organizations/${orgId}/forms`)
  //     .then(res => res.data)

  // getOrganizationFormsBySlug = (orgSlug: string, page?: number) =>
  //   this.api.get(`/api/organizations/slug/${orgSlug}/forms`, { params: { page: page } })
  //     .then(res => res.data)

  /*   schema formulare    */
  getFormSchemaByIri = (schemaIri: string): Promise<iEformsFormSchema> =>
    this.api.get(`${schemaIri}`).then((res) => res.data)

  getFormSchemaById = (formSchemaId: string): Promise<iEformsFormSchema> =>
    this.api.get(`/api/form_schemas/${formSchemaId}`).then((res) => res.data)

  // setFormSchemaById = (formSchemaId: string, schema: any) =>
  //   this.api.put(`/api/form_schemas/${formSchemaId}`, {
  //     schema: schema,
  //     note: 'edit by react-eforms-lib'
  //   })

  getFormSchemaTranslates = (
    formSchemaId: string,
    domains: string | string[],
    key?: string,
    locale = 'cs',
  ): Promise<iEformTranslates> =>
    this.api
      .get(`/api/form_schema_translations/messages`, {
        params: {
          formSchema: formSchemaId,
          locale: locale,
          domain: domains,
          key: key,
        },
      })
      .then((res) => res.data)

  getSubmissionAcl = (id: string) => this.api.get(`/api/submissions/${id}/acl`).then((res) => res.data)

  getEnumerationsSearch = (formSchema: string, enumId: string, limit = 50, query?: string) =>
    this.api
      .get(`/api/enumerations/search`, {
        params: {
          formSchema: formSchema,
          enumerationGroup: enumId,
          limit: limit,
          label: query,
          enabled: true,
        },
      })
      .then((res) => res.data)

  getEnumerationByCode = (formSchema: string, enumId: string, code: string) =>
    this.api
      .get(`/api/enumerations/enumeration_group/${enumId}/code/${code}`, {
        params: {
          formSchema: formSchema,
        },
      })
      .then((res) => res.data)

  getAttachments = (submissionVersionId: string, type?: string, submission?: string): Promise<iEformsFileData[]> =>
    this.api
      .get(`/api/submission_attachments`, {
        params: {
          limit: 100,
          submissionVersion: submissionVersionId,
          type: type,
          submission: submission,
        },
      })
      .then((res) => res.data)

  getAttachment = (id: string): Promise<iEformsFileData> =>
    this.api.get(`/api/submission_attachments/${id}`).then((res) => res.data)

  getAttachmentPublic = (publicId: string): Promise<iEformsFileData> =>
    this.api.get(`/api/submission_attachments/public/${publicId}`).then((res) => res.data)

  getAttachmentContentPublic = (publicId: string) =>
    this.api
      .get(`/download/submission_attachments/public/${publicId}`, { responseType: 'blob' })
      .then((res) => res.data)

  getAttachmentContent = (attachUrl: string) =>
    this.api.get(attachUrl, { responseType: 'blob' }).then((res) => res.data)

  uploadFile = (
    file: File,
    type: string,
    subtype?: string,
    onUploadProgress?: (progressEvent: any) => void,
    signal?: GenericAbortSignal,
  ): Promise<iEformsFileData> => {
    var formData = new FormData()
    formData.append('file', file, file.name)
    if (type) {
      formData.append('type', type)
    }
    if (subtype) {
      formData.append('subtype', subtype)
    }
    return this.api
      .post(`/api/submission_attachments`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress,
        signal,
      })
      .then((res) => res.data)
  }

  downloadFiles = (
    submissionVersion: string,
    filesId: string[],
    archiveFilename = 'archiv_priloh.zip',
    submission?: string,
  ) =>
    this.api
      .post(
        '/download/submission_attachments',
        {
          archiveFilename: archiveFilename,
          items: filesId,
        },
        {
          // headers:{
          //   Accept: 'application/zip'
          // },
          params: {
            submissionVersion: submissionVersion,
            submission: submission,
          },
          responseType: 'blob',
        },
      )
      .then((res) => res.data)

  //
  // importRequest = (schemaUrl: string, orgId: string) => {
  //   return this.api.post('/api/submission_import_requests', {
  //     formSchema: schemaUrl,
  //     organization: `/api/organizations/${orgId}`
  //   }).then(res => res.data)
  // }
  //
  //
  exportRequest = (form: string, filter: iEformsFilterOrderParam) => {
    return this.api
      .post('/api/submission_export_requests', {
        filter: {
          form: form,
          ...filter,
        },
      })
      .then((res) => res.data)
  }

  getVersions = (id: string, page = 1, limit = 10) =>
    this.api
      .get(`/api/submission_versions`, {
        params: {
          limit: limit,
          page: page,
          submission: id,
          order: { createdAt: 'asc' },
        },
      })
      .then((res) => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count']),
      }))

  getVersionLast = (id: string, workflowPlace?: string[]) =>
    this.api
      .get(`/api/submission_versions`, {
        params: {
          limit: 1,
          page: 1,
          submission: id,
          order: { createdAt: 'desc' },
          workflowPlace: workflowPlace,
        },
      })
      .then((res) => res.data)

  getVersionDetail = (versionId: string): Promise<iEformsSubmissionVersion<any>> =>
    this.api.get(`/api/submission_versions/${versionId}`).then((res) => res.data)

  //
  // sendOrgNotifyMail = (orgFormId: string, message: string, scopes?: string[], recivers?: string[], subject?: string) =>
  //   this.api.post('/api/send_organization_notify_mail', {
  //     organizationForm: orgFormId,
  //     scopes: scopes,
  //     receivers: recivers,
  //     subject: subject ? subject : null,
  //     message: message
  //
  //   }).then(res => res.data)
  //
  //
  addSubmissionComment = (id: string, message: string) =>
    this.api
      .post('/api/submission_comments', {
        submission: id,
        message: message,
      })
      .then((res) => res.data)

  getSubmissionComments = (id: string) =>
    this.api
      .get(`/api/submission_comments`, {
        params: {
          submission: id,
          limit: 50,
          order: { createdAt: 'asc' },
        },
      })
      .then((res) => res.data)

  /*
  Bulk
   */

  bulkEdit = (
    formId: string,
    filter: iEformsFilterParam,
    dataJsonPatch?: iEformsDataJsonPatch[],
    workflowPlace?: string,
  ) =>
    this.api
      .post('/api/submissions/bulk_patch', {
        workflowPlaceCode: workflowPlace,
        // workflowTransitionCode: "string",
        dataJsonPatch: dataJsonPatch,
        filter: {
          form: formId, // "IRI/UUID/slug",
          ...filter,
        },
      })
      .then((res) => res.data)
  //
  // bulkDelete = (orgFromId: string, filter: iEformsFilterParam) =>
  //   this.api.post("/api/submissions/bulk_delete", {
  //     organizationForm: `/api/organization_forms/${orgFromId}`,
  //     filter: filter
  //   }).then(res => res.data);
  //
  //
  // bulkGetAttr = (orgFromId: string, filter: iEformsFilterParam, attr: string) =>
  //   this.api.post("/api/submissions/bulk_get_data", {
  //     organizationForm: `/api/organization_forms/${orgFromId}`,
  //     filter: filter,
  //     dataReference: attr
  //   }).then(res => res.data);
  //
  // getSubmissionShare = (publicId: string) =>
  //   this.api.get(`/api/submissions/public/${publicId}/shared_scopes`)
  //     .then(res => res.data);
  //
  // addSubmissionShare = (id: string, scope: string) =>
  //   this.api.post("/api/submission_shared_scopes", {
  //     submission: `/api/submissions/${id}`,
  //     scope: scope
  //     // scope: "/SDILENY/13245678/"
  //   }).then(res => res.data);
  //
  // deleteSubmissionShare = (id: string) =>
  //   this.api.delete(`/api/submission_shared_scopes/${id}`)
  //     .then(res => res.data);
  //
  // calculateSimilar = () =>
  //   this.api.post(`/api/scheduler_commands/68ffe256-bc10-489c-a5f0-8bd937e6df14/execute`)
  //     .then(res => res.data);
}

const EA = new EformsApi()
export default EA
