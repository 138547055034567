import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { changeLikeType } from '../../utils/changeLikeType'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import { getUiSchemeLikeType } from '../../interfaces/iUiSchema'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const GovInputCheckbox = (props: ControlProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
  } = props

  const { likeNumber, likeString } = getUiSchemeLikeType(uischema)

  const change = (value: boolean) => {
    changeLikeType(value, uischema, path, handleChange)
  }

  const isChecked = !!data || (likeString && data === '') || (likeNumber && data === 0)

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov uischema={uischema} path={path} error={errors} description={description} custom>
      <DebugElement data={props} />
      <input
        id={elId}
        className='gov-form-control__checkbox'
        type='checkbox'
        disabled={!enabled}
        checked={isChecked}
        onChange={(e: any) => change(e.target.checked)}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={!enabled ? 'true' : 'false'}
        aria-labelledby={elId}
      />
      <InputGovLabel inputId={elId} label={label} required={required} withIndicator />
    </WrapInputGov>
  )
}

export default withJsonFormsControlPropsCustom(GovInputCheckbox)

export const govInputCheckTester = rankWith(10, uiTypeIs(UiElTypesInput.Checkbox))
