import axios from 'axios'


class NewsletterApi {
  api = axios.create({
    baseURL: process.env.REACT_APP_NEWSLETTER_API_URL,
  })

  subscribe = (email: string, key: string) =>
    this.api
      .post(`/prihlasit-odber`, {
        Email: email,
        Klic: key,
      })
      .then((res) => res.data)
}

export default new NewsletterApi()
