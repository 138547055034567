import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import slugify from 'slugify'
import WPApi from '../../api/WPApi'
import AlertErrorPage from '../../components/Alert/AlertErrorPage'
import { LINKS } from '../../components/App/LINKS'
import ButtonGovLink from '../../components/Btns/ButtonGovLink'
import Loading from '../../components/Loading/Loading'
import PageWrap from '../../components/PageWrap/PageWrap'
import { useFetch } from '../../hooks/useFetch'

const FaqLine = ({ otazka, odpoved }: { otazka: string; odpoved: string }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <button
        className={'gov-accordion__header ' + (open ? 'is-expanded' : '')}
        role='listitem'
        onClick={() => setOpen(!open)}
      >
        <h3>{otazka}</h3>
      </button>

      <div
        className='gov-accordion__content'
        aria-hidden={open ? 'false' : 'true'}
        role='tabpanel'
        style={open ? { height: 'auto', visibility: 'visible', overflow: 'initial' } : undefined}
      >
        <div className='gov-accordion__content-inner'>
          <p dangerouslySetInnerHTML={{ __html: odpoved }} />
        </div>
      </div>
    </>
  )
}
const NapovedaDetailPage = () => {
  const { t } = useTranslation()
  const { slug } = useParams()

  const { data: post, loading, error, fetchData } = useFetch(() => WPApi.getDocBySlug(slug!))

  useEffect(() => {
    fetchData()
  }, [slug])

  if (loading) {
    return <Loading />
  }
  if (error || !post) {
    return <AlertErrorPage msg='Je nám líto, ale tato nápověda neexistuje.' />
  }

  const faq = post.acf?.faq

  return (
    <PageWrap title={post.title.rendered}>
      <div className='u-mb--50' dangerouslySetInnerHTML={{ __html: post.content.rendered }} />

      {faq?.length && (
        <div>
          <div className='u-mb--50'>
            {faq.map((faqSekce: any) => (
              <div>
                <a href={'#' + slugify(faqSekce.nazev, { lower: true })}>{faqSekce.nazev}</a>
              </div>
            ))}
          </div>

          {faq.map((faqSekce: any) => (
            <div className='u-mb--50' id={slugify(faqSekce.nazev, { lower: true })}>
              <h2>{faqSekce.nazev}</h2>
              <div className='gov-accordion gov-js-accordion gov-accordion--small' role='list'>
                {faqSekce.sekce?.map((f: any) => (
                  <FaqLine otazka={f.otazka} odpoved={f.odpoved} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      <p className='u-align--center u-pt--20'>
        <ButtonGovLink to={LINKS.napoveda} variant='primary' size='large'>
          {t('others.zpet_na_napovedu', 'Zpět na nápovědu')}
        </ButtonGovLink>
      </p>
    </PageWrap>
  )
}

export default NapovedaDetailPage
