import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SLUG_FORM } from '../../api/EformsApi'
import AlertError from '../../components/Alert/AlertError'
import AlertInfo from '../../components/Alert/AlertInfo'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import TextTrim from '../../components/TextTrim/TextTrim'
import { isFormDraft } from '../../enums/enumWorkflowPlaces'
import { useFetchForms } from '../../hooks/useFetchForms'
import { iSubmissionDataProfil } from '../../interfaces/iEformsSubmission'
import { dateFormatShort } from '../../utils/dateFormat'
import SortTableHeader from '../components/sort/SortTableHeader'

const TableProfiles = () => {
  const { t } = useTranslation()
  const { data, loading, error, totalCount, maxItems } = useFetchForms<iSubmissionDataProfil[]>(SLUG_FORM.PROFIL)

  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
        <table className='gov-table gov-sortable-table gov-table--mobile-block'>
          <thead>
            <tr>
              <th>
                <SortTableHeader name={'variableId'}>{t('tableProfiles.ev_form', 'Ev. č. formuláře')}</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.evCisloProfiluVvz'}>
                  {t('tableProfiles.ev_profil', 'Ev. č. profilu')}
                </SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.nazevZadavatele'}>
                  {t('tableProfiles.nazev_zadavatele', 'Název zadavatele')}
                </SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.icoZadavatele'}>
                  {t('tableProfiles.ico', 'IČO zadavatele')}
                </SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.urlProfilu'}>
                  {t('tableProfiles.adresa_zadavattele', 'Adresa profilu zadavatele')}
                </SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.datumUverejneniVvz'}>
                  {t('tableProfiles.datum_uverejneni', 'Dat. uveřejnění')}
                </SortTableHeader>
              </th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((form, index) => (
              <tr key={index}>
                <td>
                  <Link to={LINKS.vyhledatFormular + '/' + form.id}>
                    {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}
                  </Link>
                </td>
                <td>
                  <Link to={LINKS.formulareProfilu + '/' + form.data.evCisloProfiluVvz}>
                    {form.data.evCisloProfiluVvz}
                  </Link>
                </td>
                <td className='cellContractName'>
                  <TextTrim text={form.data.zadavatel.nazev} length={200} />
                </td>
                <td>{form.data.zadavatel.ico}</td>
                <td>
                  {form.data.urlProfilu && (
                    <a
                      href={form.data.urlProfilu.includes('//') ? form.data.urlProfilu : '//' + form.data.urlProfilu}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <TextTrim text={form.data.urlProfilu} length={45} />
                    </a>
                  )}
                </td>
                <td className='gov-table__cell--narrow'>{dateFormatShort(form.data.datumUverejneniVvz)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {!data?.length && <AlertInfo />}
      </div>
      <PaginatorPerPage totalCount={totalCount} maxItems={maxItems} />
    </>
  )
}

export default TableProfiles
