import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { useState } from 'react'
import { toast } from 'react-toastify'
import AresGovApi from '../../../api/AresGovApi'
import ButtonGov from '../../../components/Btns/ButtonGov'
import { scopePartToPath } from '../../../utils/scopeModifs'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import { iUIschema } from '../../interfaces/iUiSchema'
import { UiElTypesInput } from '../uiElTypes'

const KOD_KRAJ_NUTS: any = {
  '19': 'CZ010',
  '35': 'CZ031',
  '116': 'CZ064',
  '51': 'CZ041',
  '108': 'CZ063',
  '86': 'CZ052',
  '78': 'CZ051',
  '132': 'CZ080',
  '124': 'CZ071',
  '94': 'CZ053',
  '43': 'CZ032',
  '27': 'CZ020',
  '60': 'CZ042',
  '141': 'CZ072',
}

const NO_INPUT_MAP = '-'

const ARES_DEFAULTS = [
  'OFFICIALNAME',
  'ADDRESS',
  'TOWN',
  'POSTAL_CODE',
  'n2021___NUTS/el_attrs/CODE',
  'COUNTRY/el_attrs/VALUE',
]

const GovAresBtn = (props: ControlProps) => {
  const { data, handleChange, path, uischema, visible, enabled } = props
  const [loading, setLoading] = useState(false)

  const aresInputMap = (uischema as iUIschema).options?.aresInputMap
  const aresInputRelativeDeep = (uischema as iUIschema).options?.aresInputRelativeDeep || 1
  const mapName = scopePartToPath(aresInputMap?.[0] || ARES_DEFAULTS[0])
  const mapAddress = scopePartToPath(aresInputMap?.[1] || ARES_DEFAULTS[1])
  const mapTown = scopePartToPath(aresInputMap?.[2] || ARES_DEFAULTS[2])
  const mapPostalCode = scopePartToPath(aresInputMap?.[3] || ARES_DEFAULTS[3])
  const mapNutsCode = scopePartToPath(aresInputMap?.[4] || ARES_DEFAULTS[4])
  const mapStateCode = scopePartToPath(aresInputMap?.[5] || ARES_DEFAULTS[5])

  if (!visible || !enabled) {
    return null
  }

  const ico = data

  const clickAres = () => {
    if (!ico || !/^\d{8}$/.test(ico)) {
      toast.error('IČO musí mít 8 číslic')
      return
    }
    setLoading(true)

    //ico - 26262525 , 02846683 , 47117559 , 28125487
    AresGovApi.aresByIco(ico)
      .then((data: any) => {
        const values = {
          nazev: data['obchodniJmeno'],
          obec: data['sidlo']?.['nazevObce'],
          castObce: data['sidlo']?.['nazevCastiObce'],
          ulice: data['sidlo']?.['nazevUlice'],
          cisloDomovni: data['sidlo']?.['cisloDomovni'],
          cisloOrientacni: data['sidlo']?.['cisloOrientacni'], //data['sidlo']?.['cisloOrientacniPismeno']
          psc: data['sidlo']?.['psc']?.toString(),
          kodKraje: data['sidlo']?.['kodKraje'],
          kodStatu: data['sidlo']?.['kodStatu'],
        }

        const parentPath = path.split('.').slice(0, -aresInputRelativeDeep).join('.') + '.'

        const change = (mapEl: string, value: string) => {
          if (mapEl !== NO_INPUT_MAP) {
            handleChange(parentPath + mapEl, value)
          }
        }

        change(mapName, values.nazev)
        const cislo = [values.cisloDomovni, values.cisloOrientacni].filter((a) => a).join('/')
        const adresa = [values.ulice || values.obec, cislo].filter((a) => a).join(' ')
        let obec = values.obec
        if (values.castObce && values.obec !== values.castObce) {
          obec += ' - ' + values.castObce
        }
        change(mapAddress, adresa)
        change(mapTown, obec)
        change(mapPostalCode, values.psc)
        change(mapNutsCode, KOD_KRAJ_NUTS[values.kodKraje])
        change(mapStateCode, values.kodStatu)
        toast.success('Název a adresa načtena z ARES')
      })
      .catch((err) => {
        const code = err.response.status
        if (code === 400) {
          toast.error('Chybné IČO.')
        } else if (code === 404) {
          toast.error('Nenalezen subjekt se zadaným IČO.')
        } else {
          toast.error('Nepodařilo se načíst data z ARES')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='gov-form-control'>
      <DebugElement data={props} />
      <ButtonGov variant='primary' disabled={!enabled || loading} loading={loading} onClick={clickAres}>
        Předvyplnit údaje podle IČO
      </ButtonGov>
    </div>
  )
}

export default withJsonFormsControlPropsCustom(GovAresBtn)

export const govAresBtnTester = rankWith(3, uiTypeIs(UiElTypesInput.AresBtn))
