export const jsonPathToShort = (longPath: string) =>
  longPath.replace(/\$\./g, '').replace(/\[(\d+)]/g, (old, num) => '.' + num) // '[1]' => '.0'

export const jsonPathToLong = (shortPath: string) => {
  if (!shortPath.startsWith('$.')) {
    shortPath = '$.' + shortPath
  }

  return shortPath.replace(/\.(\d+)/g, (old, num) => '[' + num + ']') // '.1.' => '[0]'
}

export const pathWithoutRepeat = (path: string) => path.replace(/\.\d+\./g, '.')
export const pathWithoutRepeatIncludeEnds = (path: string) => path.replace(/\.\d+\./g, '.').replace(/\.\d+$/g, '')
