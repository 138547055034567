import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SLUG_FORM } from '../../api/EformsApi'
import AlertError from '../../components/Alert/AlertError'
import AlertInfo from '../../components/Alert/AlertInfo'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import TextTrim from '../../components/TextTrim/TextTrim'
import { useFilterContext } from '../../context/FilterContext'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { isFormDraft, WORKFLOW_PLACES_LABEL } from '../../enums/enumWorkflowPlaces'
import { useFetchForms } from '../../hooks/useFetchForms'
import { iSubmissionDataHlavni, iSubmissionDataProfil } from '../../interfaces/iEformsSubmission'
import { selectUserIsSubjectUser, selectUserSubjekt } from '../../redux/user/selectors'
import { dateFormatFull } from '../../utils/dateFormat'
import SortTableHeader from '../components/sort/SortTableHeader'
import ActionBtns from './ActionBtns'
import { FILTER_MY_FORM } from './FilterMyForms'

const TableMyForms = () => {
  const { activeFilters } = useFilterContext()
  const isUserRoleSubjectUser = useSelector(selectUserIsSubjectUser)

  const { [FILTER_MY_FORM]: formMySlug } = activeFilters
  const isProfil = formMySlug === SLUG_FORM.PROFIL

  const orgSlug = useSelector(selectUserSubjekt)

  const { data, loading, error, totalCount, maxItems } = useFetchForms<
    iSubmissionDataHlavni[] | iSubmissionDataProfil[]
  >(formMySlug || SLUG_FORM.HLAVNI, {
    organization: orgSlug,
    owner: isUserRoleSubjectUser ? 'me()' : undefined,
  })

  if (!orgSlug) {
    return <AlertError msg={'Je nám líto, ale uživatel nemá identifikátor organizace'} />
  }

  if (error) {
    return <AlertError msg={'Chyba načtení seznamu formulářů: ' + error} />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
        <table className='gov-table gov-sortable-table gov-table--mobile-block'>
          <thead>
            <tr>
              <th>
                <SortTableHeader name={'variableId'}>Ev. č. formuláře</SortTableHeader>
              </th>
              {/*<th>*/}
              {/*  <SortTableHeader name={'createdAt'}>Dat. vytvoření</SortTableHeader>*/}
              {/*</th>*/}
              <th>
                <SortTableHeader name={'data.uzivatelskyNazevFormulare'}>Vlastní pojmenování</SortTableHeader>
              </th>
              <th>
                {isProfil ? (
                  <SortTableHeader name={'data.nazevProfilu'}>Název profilu</SortTableHeader>
                ) : (
                  <SortTableHeader name={'data.nazevZakazky'}>Název veřejné zakázky</SortTableHeader>
                )}
              </th>
              <th>
                <SortTableHeader name={'data.druhFormulare'}>Druh</SortTableHeader>
              </th>
              <th>
                {/*  <SortTableHeader name={'data.datumUverejneniVvz'}>Dat. uveřejnění</SortTableHeader>*/}
                <SortTableHeader name={'updatedAt'}>Poslední změna</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'workflowPlace'}>Stav formuláře</SortTableHeader>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((form, index) => (
              <tr key={index}>
                <td>
                  <Link to={LINKS.vyhledatFormular + '/' + form.id}>
                    {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}
                  </Link>
                </td>
                {/*<td className='gov-table__cell--narrow'>{dateFormatShort(form.createdAt)}</td>*/}
                <td className='cellContractName'>
                  <TextTrim text={form.data.uzivatelskyNazevFormulare} length={200} />
                </td>
                <td className='cellContractName'>
                  <TextTrim
                    text={isProfil ? (form.data as any).nazevProfilu : (form.data as any).nazevZakazky}
                    length={200}
                  />
                </td>
                <td className='gov-table__cell--narrow'>{formTypeToCodeLabel(form.data.druhFormulare)}</td>
                {/*<td className='gov-table__cell--narrow'>{dateFormatShort(form.data.datumUverejneniVvz)}</td>*/}
                <td className='gov-table__cell--narrow'>{dateFormatFull(form.updatedAt)}</td>
                <td>
                  {WORKFLOW_PLACES_LABEL[form.workflowPlaceCode] || form.workflowPlaceCode}
                  {(form.data?.formularZneplatnen || form.data?.formularOpravuje) && (
                    <small style={{ display: 'block' }}>
                      ({form.data?.formularZneplatnen ? 'zneplatněný' : form.data?.formularOpravuje ? 'opravný' : ''})
                    </small>
                  )}
                </td>
                <td className='gov-table__cell--narrow u-align--right'>
                  <ActionBtns
                    formId={form.id}
                    workflowPlaceCode={form.workflowPlaceCode}
                    isV1={!!form.data?.verzeXsd}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {!data?.length && <AlertInfo />}
      </div>
      <PaginatorPerPage totalCount={totalCount} maxItems={maxItems} />
    </>
  )
}

export default TableMyForms
