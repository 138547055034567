import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface iBcrumb {
  items: { link?: string; label: string }[]
  nohomelink?: boolean
}

const BreadcrumbApp = ({ items, nohomelink }: iBcrumb) => {
  const { t } = useTranslation()
  if (items.length === 0 && nohomelink) {
    return null
  }
  const HOME_LABEL = t('title.uvod', 'Úvod')

  const itemsr = !nohomelink ? [{ label: HOME_LABEL, link: items.length ? '/' : undefined }, ...items] : items

  return (
    <div className='gov-breadcrumbs u-mb--30'>
      {itemsr.map((item, index) => (
        <span key={index} className='gov-breadcrumbs__item'>
          {item.link ? (
            <Link className='gov-link gov-link--standalone' to={item.link}>
              {item.label}
            </Link>
          ) : (
            <strong className='gov-title gov-title--delta'>{item.label}</strong>
          )}
        </span>
      ))}
    </div>
  )
}

export default BreadcrumbApp
