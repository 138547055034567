import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import moment from 'moment/moment'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { DATE_FORMAT_DATEPICKER_SHORT, DATE_FORMAT_SHORT } from '../../../utils/dateFormat'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const DATEPARTS_DEFAULTS = ['YEAR', 'MONTH', 'DAY']
const GovInputDateParts = (props: ControlProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
  } = props

  const [openLabel, setOpenLabel] = useState(false)

  const change = (value?: string) => {
    if (value) {
      const parts = value.split('-')
      handleChange(path + '.' + DATEPARTS_DEFAULTS[0], parseInt(parts[0]))
      handleChange(path + '.' + DATEPARTS_DEFAULTS[1], parseInt(parts[1]))
      handleChange(path + '.' + DATEPARTS_DEFAULTS[2], parseInt(parts[2]))
    } else {
      handleChange(path, undefined)
    }
  }

  if (!visible) {
    return null
  }

  const dataYear = data?.[DATEPARTS_DEFAULTS[0]]
  const dataMonth = data?.[DATEPARTS_DEFAULTS[1]]
  const dataDay = data?.[DATEPARTS_DEFAULTS[2]]

  const dateValue = data
    ? moment(dataYear + '-' + (dataMonth < 10 ? '0' : '') + dataMonth + '-' + (dataDay < 10 ? '0' : '') + dataDay)
    : undefined

  const value = dateValue?.isValid() ? dateValue?.toDate() : undefined

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov
      uischema={uischema}
      value={data}
      description={description}
      error={errors}
      path={path}
      openLabel={openLabel}
    >
      <DebugElement data={props} />
      <DatePicker
        isClearable={enabled}
        dateFormat={DATE_FORMAT_DATEPICKER_SHORT}
        disabled={!enabled}
        onChange={(date) => {
          // const dateString = date ? moment(date).toISOString(true).split('T') : ''
          const dateString = date ? moment(date).format(DATE_FORMAT_SHORT) : undefined
          change(dateString)
        }}
        selected={value}
        id={elId}
        className='gov-form-control__input'
        onFocus={() => {
          setOpenLabel(true)
        }}
        onBlur={() => {
          setOpenLabel(false)
        }}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={!enabled ? 'true' : 'false'}
        aria-labelledby={elId}
        autoComplete='off'
      />
      <InputGovLabel label={label} inputId={elId} required={required} />
    </WrapInputGov>
  )
}

export default withJsonFormsControlPropsCustom(GovInputDateParts)

export const govInputDatePartsTester = rankWith(3, uiTypeIs(UiElTypesInput.DateParts))
