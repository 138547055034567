import React from 'react'
import { iValidationField } from '../../api/Validation2Api'
import { iScopeToLabel } from '../utils/findSchemaScopeToLabel'
import ErrorLine from './ErrorLine'

interface iErrorPanelBlock {
  title: string
  errors?: iValidationField[]
  scopeToLabels: iScopeToLabel
}

export const ErrorPanelBlock = ({ title, errors, scopeToLabels }: iErrorPanelBlock) => {
  if (!errors?.length) {
    return null
  }

  return (
    <>
      <h5>{title}</h5>
      <ul>
        {errors?.map((err, index) => (
          <li key={index}>
            <ErrorLine err={err} scopeToLabels={scopeToLabels} />
          </li>
        ))}
      </ul>
    </>
  )
}
