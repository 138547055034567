import React from 'react'

interface iError {
  msg: string | React.ReactNode
}

const AlertErrorPage = ({ msg }: iError) => (
  <div className='gov-container gov-container--wide'>
    <div className='gov-container__content container_bg_white overflowVisible'>
      <div className='gov-alert'>
        <div className='gov-alert__content'>
          <p>{msg}</p>
        </div>
      </div>
    </div>
  </div>
)

export default AlertErrorPage
