import debounce from 'lodash/debounce'
import { iOption } from '../interfaces/iOption'
import { setDynamEnumAll } from '../redux/dynamEnum/dynamEnumSlice'
import store from '../redux/store'

class DynamEnumDebounce {
  private DYN_ENUM: { [path: string]: iOption[] } = {}

  private debounceStoreDispatch = debounce(() => {
    store.dispatch(setDynamEnumAll({ ...this.DYN_ENUM }))
  }, 20)

  setDynEnam(path: string, dynemEnam: iOption[]) {
    this.DYN_ENUM[path] = dynemEnam
    this.debounceStoreDispatch()
  }
  clear(){
    this.DYN_ENUM = {}
  }
}

const DynEnumDebounce = new DynamEnumDebounce()

export default DynEnumDebounce
