import IamApi from '../api/IamApi'

export interface iIamModel {
  name: string
  ico: string
  address: string
  town: string
  postalCode: string
  countryCode: string
  userName: string
  userNameFirst: string
  userNameLast: string
  email: string
  phone: string
  contact: string
  databox: string
}

const COUNTRY_CODE_CZ = 'CZE'

class ModelSimpleIam {
  iamDataByName(username: string, subjekt: string): Promise<iIamModel> {
    const promiseUser = IamApi.getUzivatelDetail(username)
    const promiseOrg = IamApi.getSubjektDetail(subjekt)

    return Promise.all([promiseUser, promiseOrg])
      .then(([userData, orgData]) => ({
        name: orgData.nazev,
        ico: orgData.ico,
        address: `${orgData.adresa?.ulice} ${orgData.adresa?.cisloDomovni}`,
        town: orgData.adresa?.obec,
        postalCode: orgData.adresa?.psc,
        userName: `${userData.jmeno} ${userData.prijmeni}`,
        userNameFirst: userData.jmeno,
        userNameLast: userData.prijmeni,
        email: userData.email,
        phone: userData.telefon,
        countryCode: COUNTRY_CODE_CZ,
        contact: `${userData.jmeno} ${userData.prijmeni}`,
        databox: orgData.idDatoveSchranky,
      }))
      .catch((e) => {
        throw new Error('Nepodařilo se načíst data z profilu uživatele nebo organizace')
      })
  }
}

export default new ModelSimpleIam()
