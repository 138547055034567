import React from 'react'

interface iPartGovLabelSimple {
  id?: string
  label?: string
  withIndicator?: boolean
  noLeftPadding?: boolean
}

const PartGovLabelSimple = ({ withIndicator, noLeftPadding, label, id }: iPartGovLabelSimple) => {
  if (!label && !withIndicator) {
    return null
  }

  return (
    <>
      <label className={'gov-form-control__label ' + (noLeftPadding ? 'noLeftPadding' : '')} htmlFor={id}>
        {label}
      </label>
      {withIndicator && <span className='gov-form-control__indicator'></span>}
    </>
  )
}

export default React.memo(PartGovLabelSimple)
