import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import EformsApi, { SLUG_FORM } from '../../api/EformsApi'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { WORKFLOW_PLACES_LABEL } from '../../enums/enumWorkflowPlaces'
import { FORM_TYPES } from '../../enums_v1/enumFormType'
import { ENV } from '../../ENV'
import { useNavigateToFormVariableId } from '../../hooks/useNavigateToFormVariableId'
import { selectSchemaIsV1, selectSubmForm, selectSubmMainInfo, selectSubmReq } from '../../redux/submissions/selectors'
import { convertEvCisloTedOld } from '../../utils/convertVariableToEvCisloTed'
import { dateFormatFull, dateFormatShort } from '../../utils/dateFormat'
import { LINKS } from '../App/LINKS'
import LoadingInline from '../Loading/LoadingInline'
import FormHeaderPosibleReplaced from './FormHeaderPosibleReplaced'
import FormHeaderState from './FormHeaderState'

const FormHeaderInfo = () => {
  const { submMain, isProfil, submMainData, isDraft, formSlug, formType } = useSelector(selectSubmMainInfo)
  const submReq = useSelector(selectSubmReq)
  const submForm = useSelector(selectSubmForm)
  const isV1 = useSelector(selectSchemaIsV1)

  const [isForceProfilCZ04, setIsForceProfilCZ04] = useState<boolean>()
  const { navigateToFormVariableId } = useNavigateToFormVariableId(isForceProfilCZ04 ? SLUG_FORM.PROFIL : formSlug)

  useEffect(() => {
    if (formType) {
      if (formType === FORM_TYPES.CZ04 && submMainData?.evCisloVvzSouvisejicihoFormulare) {
        EformsApi.getSubmissionsSearch(SLUG_FORM.PROFIL, 1, 1, {
          variableId: submMainData.evCisloVvzSouvisejicihoFormulare,
        }).then((res) => {
          setIsForceProfilCZ04(!!res.data.length)
        })
      } else {
        setIsForceProfilCZ04(false)
      }
    }
  }, [formType, submMainData])

  const isOwner = !!submReq.data

  const kod = isV1
    ? submReq.data?.kodProSouvisejiciFormulare
    : submReq.data?.['ND-Root']?.['BT-002-KodProSouvisejiciFormulare']

  const idTedF = [submForm.data?.['ND-Root']?.['BT-701-notice'], submForm.data?.['ND-Root']?.['BT-757-notice']]
    .filter((a) => a)
    .join('-')

  const idTEdZ = submForm.data?.['ND-Root']?.['BT-04-notice']

  const tedLink = isV1 ? convertEvCisloTedOld(submMainData?.evCisloTed ?? '') : submMainData?.evCisloTed

  return (
    <div className='custom-detail__main'>
      <p>
        {/*BT-02-notice = Notice type = Typ oznámení, např.: Oznámení o výsledku soutěže o návrh, Oznámení o změně smlouvy,...*/}
        {/*BT-03-notice = Form type = Druh formuláře, např. Zadávání, Oprava, Výsledek,...*/}
        {/*OPP-070-notice = Notice subtype = Podtyp oznámení, např.: 1-40, T01, T02, ...*/}
        <span>
          Stav: <strong>{WORKFLOW_PLACES_LABEL[submMain.workflowPlaceCode] || submMain.workflowPlaceCode}</strong>{' '}
          {isDraft && (
            <span
              style={{ fontWeight: 'bold', display: 'inline-block' }}
              title={'Poslední změna editačního formuláře ' + dateFormatFull(submForm.updatedAt)}
            >
              [{submMain.variableId}]
            </span>
          )}
          {isV1 ? <FormHeaderPosibleReplaced key={submMain.id} /> : <FormHeaderState />}
        </span>
        <span>
          Typ formuláře: <strong>{formTypeToCodeLabel(submMain.data.druhFormulare)}</strong>
        </span>
        {!isDraft && (
          <span>
            Evidenční číslo formuláře: <strong>{submMain.variableId}</strong>
          </span>
        )}
        {isForceProfilCZ04 === undefined ? (
          <LoadingInline />
        ) : isForceProfilCZ04 ? (
          <span>
            Evidenční číslo profilu:{' '}
            <strong>
              <Link to={LINKS.formulareProfilu + '/' + (submMainData as any)?.evCisloZakazkyVvz}>
                {(submMainData as any)?.evCisloZakazkyVvz}
              </Link>
            </strong>
          </span>
        ) : isProfil ? (
          (submMainData as any)?.evCisloProfiluVvz ? (
            <>
              <span>
                Evidenční číslo profilu:{' '}
                <strong>
                  <Link to={LINKS.formulareProfilu + '/' + (submMainData as any)?.evCisloProfiluVvz}>
                    {(submMainData as any)?.evCisloProfiluVvz}
                  </Link>
                </strong>
              </span>
            </>
          ) : null
        ) : submMainData?.evCisloZakazkyVvz ? (
          <>
            <span>
              Evidenční číslo zakázky:{' '}
              <strong>
                <Link to={LINKS.formulareZakazky + '/' + submMainData?.evCisloZakazkyVvz}>
                  {submMainData?.evCisloZakazkyVvz}
                </Link>
              </strong>
            </span>
          </>
        ) : null}

        {submMainData?.evCisloTed && (
          <span>
            Číslo oznámení TED:{' '}
            <strong>
              <a href={ENV.URL_TED_FORM + tedLink} target='_blank' rel='noreferrer noopener'>
                {submMainData?.evCisloTed}
              </a>
            </strong>
          </span>
        )}
        {submMainData?.evCisloVvzSouvisejicihoFormulare && (
          <span>
            Evidenční číslo souvisejícího formuláře:{' '}
            <strong>
              <Link to={''} onClick={() => navigateToFormVariableId(submMainData.evCisloVvzSouvisejicihoFormulare!)}>
                {submMainData.evCisloVvzSouvisejicihoFormulare}
              </Link>
            </strong>
          </span>
        )}

        {!isDraft && isOwner && (
          <>
            <span>
              Kód pro související formuláře: <strong>{kod}</strong>
            </span>
            {!isV1 && submMainData.uverejnitTed && (
              <>
                {idTedF && (
                  <span>
                    Identifikátor formuláře v TED: <strong>{idTedF}</strong>
                  </span>
                )}
                {idTEdZ && (
                  <span>
                    Identifikátor zakázky v TED: <strong>{idTEdZ}</strong>
                  </span>
                )}
              </>
            )}
          </>
        )}
      </p>

      {!isDraft && (
        <p>
          {submMainData?.datumOdeslaniTed && (
            <span>
              Datum odeslání do TED: <strong>{dateFormatShort(submMainData?.datumOdeslaniTed)}</strong>
            </span>
          )}
          <span>
            Datum odeslání do VVZ: <strong>{dateFormatShort(submMainData?.datumPrijetiVvz)}</strong>
          </span>
          <span>
            Datum uveřejnění ve VVZ: <strong>{dateFormatShort(submMainData?.datumUverejneniVvz)}</strong>
          </span>
        </p>
      )}
    </div>
  )
}

export default FormHeaderInfo
