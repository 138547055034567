import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import { iValidationField, iValidationFieldSimple } from '../../api/Validation2Api'

interface iFormErrors {
  openPanel: boolean
  formErrors: iValidationField[]
  reqErrors: iValidationField[]
  formLiveErrors: iValidationField[]
  reqLiveErrors: iValidationField[]

  mandatoryFields?: iValidationFieldSimple[]
  forbiddenFields?: iValidationFieldSimple[]
  mandatoryFieldsReq?: iValidationFieldSimple[]
  forbiddenFieldsReq?: iValidationFieldSimple[]

  formHidden?: iValidationFieldSimple[]
  reqHidden?: iValidationFieldSimple[]
  globalHidden?: iValidationFieldSimple[]
}

const slice = createSlice({
  name: 'formErrors',
  initialState: {
    openPanel: false,
    formErrors: [],
    reqErrors: [],
    formLiveErrors: [],
    reqLiveErrors: [],
    mandatoryFields: [],
    forbiddenFields: [],
    formHidden: [],
    reqHidden: [],
    globalHidden: [],
  } as iFormErrors,
  reducers: {
    clearFormAndReqErrorsState(state) {
      state.formErrors = []
      state.reqErrors = []
      state.formLiveErrors = []
      state.reqLiveErrors = []
    },
    clearFormMandatoryForbiden(state) {
      state.mandatoryFields = []
      state.forbiddenFields = []
      state.formHidden = []
    },
    clearReqMandatoryForbiden(state) {
      state.mandatoryFieldsReq = []
      state.forbiddenFieldsReq = []
      state.reqHidden = []
    },
    clearGlobalHidden(state){
      state.globalHidden = []
    },
    clearFormErrorsPath(state, action: PayloadAction<string>) {
      //state.formErrors = state.formErrors?.filter((err) => err.atribut !== action.payload)
      state.formErrors = filter(state.formErrors, (err) => err.atribut !== action.payload)
    },
    clearReqErrorsPath(state, action: PayloadAction<string>) {
      //state.reqErrors = state.reqErrors?.filter((err) => err.atribut !== action.payload)
      state.reqErrors = filter(state.reqErrors, (err) => err.atribut !== action.payload)
    },
    setFormErrors(state, action: PayloadAction<iValidationField[]>) {
      state.formErrors = action.payload
    },
    setReqErrors(state, action: PayloadAction<iValidationField[]>) {
      state.reqErrors = action.payload
    },
    clearFormLiveErrorsSource(state, action: PayloadAction<string>) {
      state.formLiveErrors = filter(state.formLiveErrors, (err) => err.source !== action.payload)
    },
    clearReqLiveErrorsSource(state, action: PayloadAction<string>) {
      state.reqLiveErrors = filter(state.reqLiveErrors, (err) => err.source !== action.payload)
    },
    clearFormLiveErrorsPath(state, action: PayloadAction<string>) {
      state.formLiveErrors = filter(state.formLiveErrors, (err) => err.atribut !== action.payload)
    },
    clearReqLiveErrorsPath(state, action: PayloadAction<string>) {
      state.reqLiveErrors = filter(state.reqLiveErrors, (err) => err.atribut !== action.payload)
    },
    setFormLiveErrors(state, action: PayloadAction<iValidationField[]>) {
      const errors = action.payload
      if (errors) {
        state.formLiveErrors.push(...errors)
      }
    },
    setReqLiveErrors(state, action: PayloadAction<iValidationField[]>) {
      const errors = action.payload
      if (errors) {
        state.reqLiveErrors.push(...errors)
      }
    },
    setMandatoryFields(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.mandatoryFields = action.payload
    },
    setForbiddenFields(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.forbiddenFields = action.payload
    },
    deleteForbiddenFields(state, action: PayloadAction<string>) {
      const path = action.payload
      state.forbiddenFields = filter(state.forbiddenFields, (f) => !f.atribut.startsWith(path))
    },
    setMandatoryFieldsReq(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.mandatoryFieldsReq = action.payload
    },
    setForbiddenFieldsReq(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.forbiddenFieldsReq = action.payload
    },
    deleteForbiddenFieldsReq(state, action: PayloadAction<string>) {
      const path = action.payload
      state.forbiddenFieldsReq = filter(state.forbiddenFieldsReq, (f) => !f.atribut.startsWith(path))
    },
    setFormHidden(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.formHidden = action.payload
    },
    setReqHidden(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.reqHidden = action.payload
    },
    setGlobalHidden(state, action: PayloadAction<iValidationFieldSimple[]>) {
      state.globalHidden = action.payload
    },
    setOpenErrorPanel(state, action: PayloadAction<boolean>) {
      state.openPanel = action.payload
    },
  },
})

export const {
  clearFormAndReqErrorsState,
  clearFormMandatoryForbiden,
  clearReqMandatoryForbiden,
  clearFormErrorsPath,
  clearFormLiveErrorsSource,
  clearFormLiveErrorsPath,
  clearReqErrorsPath,
  clearReqLiveErrorsSource,
  clearReqLiveErrorsPath,
  setFormErrors,
  setFormLiveErrors,
  setReqErrors,
  setReqLiveErrors,
  setMandatoryFields,
  setForbiddenFields,
  setMandatoryFieldsReq,
  setForbiddenFieldsReq,
  setFormHidden,
  setReqHidden,
  setGlobalHidden,
  clearGlobalHidden,
  setOpenErrorPanel,
  deleteForbiddenFields,
  deleteForbiddenFieldsReq,
} = slice.actions
export default slice.reducer
