import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { ELEMENT_TYPE } from '../builder/elements/ELEMENTS'
import { iUIschema } from '../builder/interfaces/iUiSchema'
import { ENV } from '../ENV'
import { setFormIsFullInit, setFormTreeIsBigDataArrayLength } from '../redux/formTree/formTreeSlice'
import store from '../redux/store'
import { pathWithoutRepeatIncludeEnds } from '../utils/jsonPaths'
import { scopeFullToPath } from '../utils/scopeModifs'

class FormFullInit {
  private inputSelectScopes: { [scope: string]: boolean } = {}
  private arrayLength: { [scope: string]: number } = {}
  private allArrayLength = 0
  private FORM_INIT_LOADING_INPUT_TYPE = [
    ELEMENT_TYPE.Autocomplete,
    ELEMENT_TYPE.SelectAutocomplete,
    ELEMENT_TYPE.Select,
  ]
  private isDraft = false
  private hiddenScopes: string[] = []

  private checkIsFullInit() {
    const notInitScopes = Object.keys(this.inputSelectScopes).filter(
      (scope) => !this.hiddenScopes.some((hidenScope) => scope.startsWith(hidenScope)),
    )

    if (isEmpty(notInitScopes)) {
      this.setFullDebounce()
    }
  }

  setFullDebounce = debounce(() => {
    store.dispatch(setFormIsFullInit(true))
  }, 200)

  clear() {
    this.inputSelectScopes = {}
    this.arrayLength = {}
    this.allArrayLength = 0
  }

  setIsDraft(isDraft: boolean) {
    this.isDraft = isDraft
  }

  setHiddenPaths(paths: string[]) {
    this.hiddenScopes = paths
  }

  setNoInit() {
    store.dispatch(setFormIsFullInit(true))
  }

  setFormData(data: any, path = '') {
    for (const key in data) {
      const val = data[key]
      const thisPath = (path ? path + '.' : '') + key
      if (Array.isArray(val)) {
        this.arrayLength[thisPath] = val.length
        this.allArrayLength += val.length
        this.testIsBig()
        val.forEach((v, index) => this.setFormData(v, thisPath + '.' + index))
      } else if (typeof val === 'object') {
        this.setFormData(val, thisPath)
      }
    }
  }

  testIsBig() {
    if (this.allArrayLength > ENV.FORM_TREE_SIZE_REPEATS_BIG) {
      store.dispatch(setFormTreeIsBigDataArrayLength(this.allArrayLength))
    }
  }

  setFormDataSchema(uiSchemas: iUIschema[], arrayPath = '') {
    uiSchemas.forEach((uiSchema) => {
      let path = uiSchema.scope ? scopeFullToPath(uiSchema.scope) : ''
      if (arrayPath && path) {
        path = path.replace(pathWithoutRepeatIncludeEnds(arrayPath), arrayPath)
      }

      if (uiSchema.type === ELEMENT_TYPE.Array && uiSchema.scope && uiSchema.elements) {
        // zjednoduseni, kdyz existuje arrayIdentifierScope tak muze mit disable podminku a pak je min 0
        const minLength = uiSchema.options?.arrayIdentifierScope ? 0 : this.isDraft ? 1 : 0
        const arrayLength = this.arrayLength[path] || minLength
        for (let i = 0; i < arrayLength; i++) {
          this.setFormDataSchema(uiSchema.elements, path + '.' + i)
        }
      } else if (uiSchema.type === ELEMENT_TYPE.Group && uiSchema.elements) {
        this.setFormDataSchema(uiSchema.elements, arrayPath)
      } else if (this.FORM_INIT_LOADING_INPUT_TYPE.includes(uiSchema.type)) {
        this.inputSelectScopes[path] = true
      }
    })
  }

  removeInputSelectScopes(scope: string) {
    scope = scope.replace(/\.(\d+)$/, '') // odstraneni posledniho opakovani - pro pripad ze je to simpleArray
    delete this.inputSelectScopes[scope]
    this.checkIsFullInit()
  }
}

export default new FormFullInit()
