import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import PartGovLabel from '../parts/PartGovLabel'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const FORMAT_PHONE = 'phone'
const PHONE_PATTERN = /^(\+(\d{1,3}(\s(\d{1,25})?)?)?)$/
const PHONE_PREFIX = '+420 '
const formatTelNumber = (value: string) => {
  if (value.length === 1 && value !== '+') {
    value = PHONE_PREFIX + value
  }
  if (value.length > 4 && !value.includes(' ')) {
    value = value.substring(0, 4) + ' ' + value.substring(4)
  }
  return value
}

const createRegMask = (textMask: string) => {
  try {
    return new RegExp(textMask, 'u')
  } catch (e) {
    return undefined
  }
}

const InputText = () => {
  const { field, errors } = useControlRegister()
  const { uiSchema, id, required, disabled } = useElementContext()

  const textMask = uiSchema.options?.textMask
  const isFormatPhone = uiSchema.options?.format === FORMAT_PHONE

  const onChange = (val: string) => {
    if (val) {
      if (textMask) {
        const mask = createRegMask(textMask)
        if (mask && !mask.test(val)) {
          return
        }
      }
      if (isFormatPhone) {
        val = formatTelNumber(val)
        if (!val.match(PHONE_PATTERN)) {
          return
        }
      }
    }
    field.onChange(val)
  }

  return (
    <WrapInputGov value={field.value} errors={errors}>
      <input
        {...field}
        onChange={(e) => onChange(e.target.value)}
        value={field.value ?? ''}
        id={id}
        className='gov-form-control__input'
        type='text'
        disabled={disabled}
        aria-required={required ? 'true' : 'false'}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-labelledby={id}
      />
      <PartGovLabel />
    </WrapInputGov>
  )
}

export default InputText
