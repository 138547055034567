
export const DRUH_VZ = {
  DODAVKY: 'DODAVKY',
  SLUZBY: 'SLUZBY',
  PRACE: 'PRACE',
}

export const optionsDruhVz = [
  { value: DRUH_VZ.DODAVKY, label: 'Dodávky' },
  { value: DRUH_VZ.SLUZBY, label: 'Služby' },
  { value: DRUH_VZ.PRACE, label: 'Stavební práce' },
]



