import React, { useCallback, useEffect, useState } from 'react'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import EformsApi from '../../../api/EformsApi'
import FileLink from '../../../components/FileLink/FileLink'
import { ENV } from '../../../ENV'
import { iEformsFileData } from '../../../interfaces/iEformsApi'
import { useElementContext } from '../../context/ElementContext'
import { useFormFilesRenderContext } from '../../context/RenderFormFilesContext'
import { uploadAcceptFileMimeTypes } from '../../utils/uploadAcceptFileMimeTypes'
import NavigationAnchor from '../parts/NavigationAnchor'
import PartGovDescription from '../parts/PartGovDescription'
import PartGovHelp from '../parts/PartGovHelp'
import { useControlRegister } from '../useControlRegister'

const UPLOAD_FILE_TYPE = 'form'

interface iUploadingFile {
  index: number
  name: string
  data: File
  cancel?: boolean
  done?: boolean
  response?: iEformsFileData
  signal: AbortController
}

let counterUploadingFiles = 0

const errFilePlaceholder = (fileId: string) => ({ id: fileId, file: { name: '- chyba načtení souboru -' } })

const InputUpload = () => {
  const { field, errors } = useControlRegister()
  const { uiSchema, required, disabled, path, label } = useElementContext()

  const { setValue, getValues } = useFormContext()
  const { addUploadingPath, removeUploadingPath, formFiles, addFormFile, removeFormFile } = useFormFilesRenderContext()

  const filesIds: string[] = field.value ? field.value.split('|') : []

  const uploadedFiles: iEformsFileData[] = filesIds.map(
    (fileId: string) => formFiles[fileId] || errFilePlaceholder(fileId),
  )

  const addUploadedFile = (resFile: iEformsFileData) => {
    addFormFile(resFile)

    const val =
      getValues(path)
        ?.split('|')
        .filter((a: string) => a) || []
    const newVal = [...val, resFile.id].join('|')
    setValue(path, newVal, { shouldValidate: true })
  }
  const removeUploadedFile = (fileId: string) => {
    removeFormFile(fileId)

    const val =
      getValues(path)
        ?.split('|')
        .filter((a: string) => a) || []
    const newVal = val.filter((v: string) => v !== fileId).join('|')
    setValue(path, newVal, { shouldValidate: true })
  }

  const [progress, setProgress] = useState<{ [index: string]: number }>({})
  const [uploadingFiles, setUploadingFiles] = useState<iUploadingFile[]>([])

  const setUploadingFileAttrs = (fileIndex: number, data: Partial<iUploadingFile>) => {
    setUploadingFiles((files) => files.map((file) => (file.index === fileIndex ? { ...file, ...data } : file)))
  }
  const addUploadingFile = (newFile: iUploadingFile) => {
    setUploadingFiles((uploadingFiles) => [...uploadingFiles, newFile])
  }
  const clickUploadingCancel = (fileIndex: number) => {
    const file = uploadingFiles.find((f) => f.index === fileIndex)
    file?.signal.abort()
    setUploadingFileAttrs(fileIndex, { cancel: true })
  }

  const uploadingFilesActive = uploadingFiles.filter((file) => !file.cancel && !file.done)
  useEffect(() => {
    if (uploadingFilesActive.length) {
      addUploadingPath(path)
    } else {
      removeUploadingPath(path)
    }
  }, [uploadingFiles])

  useEffect(() => {
    uploadingFiles.forEach((file) => {
      if (file.response && !file.done && !file.cancel) {
        addUploadedFile(file.response)
        setUploadingFileAttrs(file.index, { done: true })
      }
    })
  }, [uploadingFiles])

  const upload = (file: iUploadingFile) => {
    addUploadingFile(file)
    EformsApi.uploadFile(
      file.data,
      UPLOAD_FILE_TYPE,
      undefined,
      (event) => {
        const percent = Math.round((100 * event.loaded) / event.total)
        setProgress((progress) => ({ ...progress, [file.index]: percent }))
      },
      file.signal.signal,
    )
      .then((data) => {
        setUploadingFileAttrs(file.index, { response: data })
      })
      .catch((err) => {
        toast.error(`Soubor ${file.name} se nepodařilo nahrát`)
        setUploadingFileAttrs(file.index, { done: true }) // TODO mozna spis error
      })
  }

  const onDropAccept = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file, index: number) => {
        const fileIndex = counterUploadingFiles++
        const newFile = {
          name: file.name,
          index: fileIndex,
          data: file,
          signal: new AbortController(),
        }
        upload(newFile)
      })
    },
    [uploadingFiles],
  )

  const onDropReject = useCallback((rejectedFiles: FileRejection[]) => {
    rejectedFiles.forEach((file) => {
      const err = file.errors[0].code
      switch (err) {
        case ErrorCode.FileInvalidType:
          toast.error('Nepovolený typ souboru: ' + file.file.name)
          break
        case ErrorCode.FileTooLarge:
          toast.error('Velikost souboru je větší než povolený limit: ' + file.file.name)
          break
        case ErrorCode.FileTooSmall:
          toast.error('Velikost souboru je menší než povolený limit: ' + file.file.name)
          break
        case ErrorCode.TooManyFiles:
          toast.error('Nepovolený počet souborů: ' + file.file.name)
          break
        default:
          toast.error('Chyba souboru: ' + file.file.name)
      }
    })
  }, [])

  //TODO z builderu - onlyOne, maxFilesCount, maxSize, acceptMimeTypes
  const onlyOne = false
  const fullFiles = onlyOne ? uploadedFiles.length + uploadingFilesActive.length : false
  const acceptFileExts: string[] = [] //['*']
  const filesFormatInfo = acceptFileExts.join(', ').toUpperCase()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onDropAccept,
    onDropRejected: onDropReject,
    disabled: disabled,
    maxSize: ENV.UPLOAD_MAX_FILES_SIZE,
    // validator: nameLengthValidator(file)
    multiple: !onlyOne,
    maxFiles: onlyOne ? 1 : 0,
    accept: uploadAcceptFileMimeTypes,
    noClick: true,
  })

  const uploadedFilesRender = uploadedFiles.map((file, index) => (
    <div key={index}>
      {file && <FileLink fileData={file} />}
      {!disabled && (
        <button type='button' className='gov-button' onClick={() => removeUploadedFile(file.id)}>
          <span aria-hidden='true' className='gov-icon gov-icon--cross gov-icon--16x16'></span>
        </button>
      )}
    </div>
  ))

  const uploadingFilesRender = uploadingFilesActive.map((file, index) => (
    <div key={file.index}>
      {/* todo hezci loading*/}
      <span>Nahrávám .... {progress[file.index]} </span>-{file.index}-{file.name}
      <button type='button' className='gov-button' onClick={() => clickUploadingCancel(file.index)}>
        <span aria-hidden='true' className='gov-icon gov-icon--cross gov-icon--16x16'></span>
      </button>
    </div>
  ))

  const renderFiles = () => {
    if (disabled && !uploadedFiles.length && !uploadingFilesActive.length) {
      return <div> Žádné soubory </div>
    }
    return (
      <div>
        {/*<pre>{JSON.stringify(field.value, null, 2)}</pre>*/}
        {/*<pre>{JSON.stringify(uploadedFiles, null, 2)}</pre>*/}
        {/*<pre>{JSON.stringify(uploadingFiles, null, 2)}</pre>*/}
        {/*<pre>{JSON.stringify(formFiles, null, 2)}</pre>*/}
        {uploadedFilesRender}
        {uploadingFilesRender}
      </div>
    )
  }

  const { descriptionBefore, description, helpText } = uiSchema.options || {}

  const [isFolder, setIsFolder] = useState(false)
  const inputDirAttr = isFolder ? { webkitdirectory: '', directory: '' } : {}

  return (
    <div className='custom-form  u-mb--30'>
      <NavigationAnchor>
        <h2 className={'custom-form__title gov-title--delta'}>
          {label}
          {required ? '*' : ''}
        </h2>
        <PartGovDescription text={descriptionBefore} />
        <div className={'gov-form-control gov-fileinput ' + (errors ? 'gov-form-control--error' : '')}>
          {/*<p className='gov-fileinput__label'>*/}
          {/*  {label}*/}
          {/*  {required && '*'}*/}
          {/*</p>*/}
          {renderFiles()}

          {!disabled && !fullFiles && (
            <div className='gov-fileinput__upload' {...getRootProps()}>
              <input
                id={'file-' + path}
                {...getInputProps()}
                className='gov-fileinput__upload-input d-block'
                aria-required={required ? 'true' : 'false'}
                aria-disabled={disabled ? 'true' : 'false'}
                {...inputDirAttr}
                ref={field.ref}
                style={{ display: 'block', width: 0, height: 0 }}
              />
              <div className='gov-fileinput__upload-content'>
                <p className='gov-fileinput__upload-copy'>
                  {isDragActive ? 'Přetáhněte soubory sem ...' : 'Přetáhněte soubor nebo'}
                </p>
                <label
                  htmlFor={'file-' + path}
                  className='gov-button gov-button--primary-outlined gov-fileinput__upload-btn'
                >
                  Nahrajte ze zařízení
                </label>
                {filesFormatInfo && <p className='gov-fileinput__upload-note'>podporované formáty {filesFormatInfo}</p>}

                {!onlyOne && (
                  <p
                    className='gov-fileinput__upload-note'
                    style={{ position: 'absolute', zIndex: 99, width: 165 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      checked={isFolder}
                      onChange={(e) => setIsFolder(e.target.checked)}
                      type='checkbox'
                      id={'isFolder-' + path}
                    />{' '}
                    <label className='ms-2' htmlFor={'isFolder- ' + path}>
                      nahrát celou složku
                    </label>
                  </p>
                )}
              </div>
            </div>
          )}
          <PartGovDescription text={description} />
          {errors?.map((error) => (
            <span className='gov-form-control__message'>{error}</span>
          ))}
        </div>
        {helpText && <PartGovHelp text={helpText} />}
      </NavigationAnchor>
    </div>
  )
}

export default InputUpload
