import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { DATA_TYPE, iDataSchema, iDataSchemas } from '../builder/interfaces/iDataSchema'
import { scopeFullToPath } from '../utils/scopeModifs'

export const DATA_SCHEMA_DEFAULTS_CONST = {
  NOW: '{NOW}',
}
export const DATA_SCHEMA_DEFAULT_MODE = {
  ON_INIT: 'on_init_load_when_null', // defaultni
  ON_LOAD: 'on_load_when_null',
  BEFORE_SAVE: 'before_save',
}

export class SchemaDefaults {
  dataSchemas: iDataSchemas
  dateSchemaArrayScopes: string[]
  defaults: any = {}
  defaultsOnLoad: any = {}
  defaultsArray: { [paths: string]: any } = {}
  defaultsCalculate: { [paths: string]: iDataSchema } = {}

  constructor(schema: iDataSchemas, dateSchemaArrayScopes: string[]) {
    this.dataSchemas = schema
    this.dateSchemaArrayScopes = dateSchemaArrayScopes
  }

  calculateDefaults() {
    this.processDataSchemas(this.dataSchemas)
    this.proccessArrayDefaults()

    return {
      dateSchemaDefs: this.defaults,
      dateSchemaDefsOnLoad: this.defaultsOnLoad,
      dateSchemaArrayDefaults: this.defaultsArray,
      dateSchemaDefaultsCalculate: this.defaultsCalculate,
    }
  }

  processDataSchemas(dataSchemas: iDataSchemas, parentPath = '') {
    Object.entries(dataSchemas).forEach(([name, dataSchema]) => {
      const path = (parentPath && parentPath + '.') + name
      if (dataSchema.default !== undefined && dataSchema.default !== null && dataSchema.default !== '') {
        if (dataSchema.defaultMode === DATA_SCHEMA_DEFAULT_MODE.BEFORE_SAVE) {
          this.defaultsCalculate[path] = dataSchema
        } else {
          let defVal = dataSchema.default
          if (typeof defVal === 'string') {
            defVal = defVal.replace(/\\n/g, '\n')
          }
          set(this.defaults, path, defVal)
          if (dataSchema.defaultMode === DATA_SCHEMA_DEFAULT_MODE.ON_LOAD) {
            set(this.defaultsOnLoad, path, defVal)
          }
        }
      }
      if (dataSchema.type === DATA_TYPE.ARRAY && dataSchema.items?.type === DATA_TYPE.OBJECT) {
        if (dataSchema.items.properties) {
          this.processDataSchemas(dataSchema.items.properties, path)
        }
      } else if (dataSchema.properties) {
        this.processDataSchemas(dataSchema.properties, path)
      }
    })
  }

  proccessArrayDefaults() {
    this.dateSchemaArrayScopes.reverse().forEach((arrayScope) => {
      const arrayPath = scopeFullToPath(arrayScope)
      const val = get(this.defaults, arrayPath)
      if (val && !Array.isArray(val)) {
        unset(this.defaults, arrayPath)
        unset(this.defaultsOnLoad, arrayPath)
        this.clearParentPath(arrayPath)
        this.defaultsArray[arrayScope] = val
      }
    })
  }

  clearParentPath(path: string) {
    const parentPath = path.split('.').slice(0, -1).join('.')
    if (isEmpty(get(this.defaults, parentPath))) {
      unset(this.defaults, parentPath)
      unset(this.defaultsOnLoad, parentPath)
      this.clearParentPath(parentPath)
    }
  }
}
