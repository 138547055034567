import AresGovApi from '../api/AresGovApi'

const KOD_KRAJ_NUTS: any = {
  '19': 'CZ010',
  '35': 'CZ031',
  '116': 'CZ064',
  '51': 'CZ041',
  '108': 'CZ063',
  '86': 'CZ052',
  '78': 'CZ051',
  '132': 'CZ080',
  '124': 'CZ071',
  '94': 'CZ053',
  '43': 'CZ032',
  '27': 'CZ020',
  '60': 'CZ042',
  '141': 'CZ072',
}

const COUNTRY_CODE_CZ = 'CZE'

interface iAresModel {
  name: string
  address: string
  town: string
  postalCode: string
  nutsCode: string
  countryCode: string
  ico: string
}

class ModelSimpleAres {
  aresDataByIco(ico: string): Promise<iAresModel> {
    return AresGovApi.aresByIco(ico).then((data: any) => {
      const values = {
        nazev: data['obchodniJmeno'],
        obec: data['sidlo']?.['nazevObce'],
        castObce: data['sidlo']?.['nazevCastiObce'],
        ulice: data['sidlo']?.['nazevUlice'],
        cisloDomovni: data['sidlo']?.['cisloDomovni'],
        cisloOrientacni: [data['sidlo']?.['cisloOrientacni'], data['sidlo']?.['cisloOrientacniPismeno']]
          .filter((a) => a)
          .join(''),
        psc: data['sidlo']?.['psc']?.toString(),
        kodKraje: data['sidlo']?.['kodKraje'],
        kodStatu: data['sidlo']?.['kodStatu'],
      }

      const cislo = [values.cisloDomovni, values.cisloOrientacni].filter((a) => a).join('/')
      const adresa = [values.ulice || values.obec, cislo].filter((a) => a).join(' ')
      let obec = values.obec
      if (values.castObce && values.obec !== values.castObce) {
        obec += ' - ' + values.castObce
      }

      return {
        name: values.nazev,
        address: adresa,
        town: obec,
        postalCode: values.psc,
        nutsCode: KOD_KRAJ_NUTS[values.kodKraje],
        ico: ico,
        countryCode: COUNTRY_CODE_CZ,
      }
    })
  }
}

export default new ModelSimpleAres()
