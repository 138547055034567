import { TITLE_HEIGHT } from '../navigation/SectionHeaderNav'

const BLOCK_WRAP_CLASS = 'blockWrap'
export const scrollToPath = (path: string) => {
  const element = document.getElementById(path)
  if (element) {
    const level = countNesting(element, BLOCK_WRAP_CLASS)
    scrollToWithOfsset(element, level * TITLE_HEIGHT)
    // element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else {
    console.log('Nenalezen element id', path)
  }
}

function scrollToWithOfsset(element: HTMLElement, offset: number) {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

function countNesting(element: HTMLElement, className: string) {
  let count = 0
  let parent = element.parentElement
  while (parent) {
    if (parent.classList.contains(className)) {
      count++
    }
    parent = parent.parentElement
  }
  return count
}
