import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import RenderElements from '../../renders/RenderElements'

const RowTxt = () => {
  const { uiSchema } = useElementContext()

  const classes = ['custom-form__wrap']

  if (uiSchema.options?.rowCssAlign === 'left') {
    classes.push('rowAlignLeft')
  }
  if (uiSchema.options?.rowCssAlign === 'right') {
    classes.push('rowAlignRight')
  }

  return (
    <div className={classes.join(' ')}>{uiSchema.elements && <RenderElements uiSchemas={uiSchema.elements} />}</div>
  )
}
export default RowTxt
