import React from 'react'
import { useSelector } from 'react-redux'
import { WORKFLOW_PLACES } from '../../enums/enumWorkflowPlaces'
import { selectSubmMainInfo } from '../../redux/submissions/selectors'

const FormHeaderState = () => {
  const { submMain, submMainData } = useSelector(selectSubmMainInfo)

  const stateText = submMainData?.formularZneplatnen
    ? 'ZNEPLATNĚNÝ'
    : submMainData?.formularOpravuje
    ? 'PLATNÝ OPRAVNÝ'
    : 'PLATNÝ'

  const stateColor = submMainData?.formularZneplatnen ? 'var(--gov-color-error)' : 'var(--gov-color-success)'

  if (submMain.workflowPlaceCode !== WORKFLOW_PLACES.UVEREJNENO_VVZ) {
    return null
  }
  return (
    <>
      {' '}
      (<strong style={{ color: stateColor }}>{stateText}</strong>)
    </>
  )
}

export default FormHeaderState
