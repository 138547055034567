import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { selectFormTree, selectFormTreeReq } from '../../redux/formTree/formTreeSelectors'
import { treeIndexesToPath } from '../../utils/formTreeSibling'
import { useIndexesContext } from '../context/IndexesContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { iFormTree } from '../utils/createFormTree'

export const useFormTreeEl = () => {
  const { isReq } = useFormRenderContext()
  const formTree = useSelector(isReq ? selectFormTreeReq : selectFormTree)
  const indexes = useIndexesContext().indexes
  const treePath = treeIndexesToPath(indexes)
  const formTreeEl: iFormTree | undefined = get(formTree, treePath)
  // TODO optimalizovat aby se neprekreslovavlo vzdy ???
  // const formTreeElStr = JSON.stringify(formTreeEl)
  // return useMemo(() => formTreeEl, [formTreeElStr])

  // const [res, setRes] = useState<iFormTree>()
  // useEffect(() => {
  //   if (JSON.stringify(res) !== JSON.stringify(formTreeEl)) {
  //     setRes(formTreeEl)
  //   }
  // }, [formTreeEl])

  return formTreeEl
}
