import React from 'react'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import TableContracts from '../../tables/TableContracts/TableContracts'

const StornovanePage = () => {
  return (
    <PageWrap title='Seznam stornovaných formulářů'>
      <FilterContextProvider name={TABLE_TYPES.stornoContracts}>
        <TableContracts noLinks />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default StornovanePage
