import React from 'react'
import { useElementEnum } from '../../hooks/useElementEnum'
import PartGovLabelTxt from '../parts/PartGovLabelTxt'
import { useControlRegister } from '../useControlRegister'

const InputRadioTxt = () => {
  const { field } = useControlRegister()
  const { enumOptions } = useElementEnum()

  const value = enumOptions?.find((op) => op.value === field.value?.toString())?.label

  return (
    <div>
      <PartGovLabelTxt /> {value}
    </div>
  )
}

export default InputRadioTxt
