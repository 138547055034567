import React from 'react'

interface iNoData {
  msg?: string
}

const AlertInfo = ({ msg = 'Žádná data' }: iNoData) => {
  return <p style={{ textAlign: 'center' }}>{msg}</p>
}

export default AlertInfo
