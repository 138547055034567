import React from 'react'
import { useTranslation } from 'react-i18next'
import { optionsDruhVz } from '../../enums/enumContractType'
import { optionsFormTypeV2 } from '../../enums/enumFormTypeV2'
import { optionsFormTypeV1Group } from '../../enums_v1/enumFormType'
import FilterFormWrap from '../components/FilterFormWrap'
import DateFilterInput from '../components/filterInputs/DateFilterInput'
import SelectFilterInput from '../components/filterInputs/SelectFilterInput'
import TextFilterInput from '../components/filterInputs/TextFilterInput'

const optionsDruhRizeni = [
  { value: 'OTEVRENE', label: 'Otevřené řízení' },
  { value: 'UZSI', label: 'Užší řízení' },
  { value: 'JRSU', label: 'Jednací řízení s uveřejněním' },
  { value: 'JRBU', label: 'Jednací řízení bez uveřejnění' },
  { value: 'ZPR', label: 'Zjednodušené podlimitní řízení' },
  { value: 'DIALOG', label: 'Soutěžní dialog' },
  { value: 'INOVACNI', label: 'Inovační partnerství' },
  { value: 'KONCESE', label: 'Koncese, koncesní řízení' },
  { value: 'NAVRH', label: 'Soutěž o návrh' },
  { value: 'KVALIFIKACE', label: 'Systém kvalifikace' },
  { value: 'DOPRAVA', label: 'Výběr dopravce' },
  { value: 'SUBDOD', label: 'Výběr poddodavatele' },
  { value: 'MINITENDR', label: 'Minitendr z rámcové dohody' },
  { value: 'VZDNS', label: 'Zadávání v DNS' },
  { value: 'ZJR', label: 'Zjednodušený režim' },
]

const optionsRezimVz = [
  { value: 'nadlimitni', label: 'Nadlimitní' },
  { value: 'podlimitni', label: 'Podlimitní' },
  { value: 'zjednoduseny', label: 'Zjednodušený' },
]

export const optionsZneplatnen = [
  { value: 'false', label: 'Platný' },
  { value: 'true', label: 'Zneplatněný' },
]

const FilterContracts = () => {
  const { t } = useTranslation()
  return (
    <FilterFormWrap className='custom-form u-mb--50'>
      <div className='custom-form u-mb--20'>
        <h2 className='custom-form__title gov-title--delta'>{t('filterContracts.formular', 'Formulář')}</h2>
        <div className='custom-form__wrap'>
          <SelectFilterInput
            name='data.druhFormulare'
            label={t('filterContracts.druh_form', 'Druh uveřejňovacího formuláře')}
            options={optionsFormTypeV2}
            optgroup={optionsFormTypeV1Group}
          />
        </div>

        <div className='custom-form__wrap'>
          <TextFilterInput name='variableId' label={t('filterContracts.ev_form', 'Evidenční číslo formuláře')} />
          <DateFilterInput
            name='data.datumUverejneniVvz[gte]'
            label={t('filterContracts.datum_uverejneni_od', 'Datum uveřejnění od')}
          />
          <DateFilterInput
            name='data.datumUverejneniVvz[lte]'
            label={t('filterContracts.datum_uverejneni_do', 'Datum uveřejnění do')}
          />
        </div>
      </div>

      <div className='custom-form u-mb--20'>
        <h2 className='custom-form__title gov-title--delta'>{t('filterContracts.zakazka', 'Zakázka')}</h2>

        <div className='custom-form__wrap'>
          <TextFilterInput name='data.evCisloZakazkyVvz' label={t('filterContracts.ev_vz', 'Evidenční číslo VZ')} />
          <TextFilterInput name='data.nazevVzACasti' label={t('filterContracts.nazev_vz', 'Název VZ/části')} />
        </div>

        <div className='custom-form__wrap'>
          <TextFilterInput name='data.popisVzACasti' label={t('filterContracts.popis_vz', 'Stručný popis VZ/části')} />
          <TextFilterInput name='data.cpvVzACasti' label={t('filterContracts.cpv_vz', 'CPV kód VZ/části')} />
        </div>

        <div className='custom-form__wrap'>
          <DateFilterInput
            name='data.lhutaNabidkyZadosti[gte]'
            label={t('filterContracts.lhuta_nabidky_od', 'Lhůta pro nabídky/žádosti od')}
          />
          <DateFilterInput
            name='data.lhutaNabidkyZadosti[lte]'
            label={t('filterContracts.lhuta_nabidky_do', 'Lhůta pro nabídky/žádosti do')}
          />
        </div>

        <div className='custom-form__wrap'>
          <SelectFilterInput
            name='data.druhRizeni'
            label={t('filterContracts.druh_rizeni', 'Druh zadávacího řízení')}
            options={optionsDruhRizeni}
          />
          <SelectFilterInput
            name='data.druhVz'
            label={t('filterContracts.druh_vz', 'Druh VZ')}
            options={optionsDruhVz}
          />
        </div>

        <div className='custom-form__wrap'>
          <TextFilterInput
            name='data.mistoPlneniVzACastiPopis'
            label={t('filterContracts.misto_vz_popis', 'Místo plnění VZ/části – popis')}
          />
          <TextFilterInput
            name='data.mistoPlneniVzACastiNuts'
            label={t('filterContracts.misto_vz-nuts', 'Místo plnění VZ/části – NUTS kód')}
          />
        </div>

        <div className='custom-form__wrap'>
          <SelectFilterInput
            name='data.rezimVz'
            label={t('filterContracts.rezim_vz', 'Režim zakázky')}
            options={optionsRezimVz}
          />
          <SelectFilterInput
            name='data.formularZneplatnen'
            label={t('filterContracts.formular_zneplatnen', 'Stav platnosti')}
            options={optionsZneplatnen}
          />
        </div>
      </div>

      <div className='custom-form u-mb--20'>
        <h2 className='custom-form__title gov-title--delta'>{t('filterContracts.zadavatel', 'Zadavatel')}</h2>

        <div className='custom-form__wrap custom-form__wrap--special'>
          <TextFilterInput name='data.nazevZadavatele' label={t('filterContracts.jmeno', 'Obchodní název/jméno')} />
          <TextFilterInput name='data.icoZadavatele' label={t('filterContracts.ico', 'IČO')} />
        </div>
      </div>

      <div className='custom-form u-mb--20'>
        <h2 className='custom-form__title gov-title--delta'>
          {t('filterContracts.vybrany_dodavatel', 'Vybraný dodavatel')}
        </h2>
        <div className='custom-form__wrap custom-form__wrap--special'>
          <TextFilterInput name='data.nazevDodavatele' label={t('filterContracts.jmeno', 'Obchodní název/jméno')} />
          <TextFilterInput name='data.icoDodavatele' label={t('filterContracts.ico', 'IČO')} />
        </div>
      </div>
    </FilterFormWrap>
  )
}

export default FilterContracts
