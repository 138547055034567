import React from 'react'
import { FileEarmarkPlus, InfoCircle } from 'react-bootstrap-icons'
import { enumFormsCategoryRows, LINE_TYPE } from '../../enums/enumFormsCategory'
import { useModal } from '../../hooks/useModal'
import { formTypeToMetodic } from '../../utils/formTypeToMetodic'

const TableFormTypes = () => {
  const { openModalNewForm } = useModal()

  return (
    <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
      <table className='gov-table gov-sortable-table gov-table--mobile-block'>
        <thead>
          <tr>
            <th className='u-align--center'>{/*Druh formuláře*/}</th>
            <th>{/*Název formuláře*/}</th>
            <th className='u-align--center'>Online formulář</th>
            <th className='u-align--center'>Metodické pokyny</th>
          </tr>
        </thead>
        <tbody className='gov-table__body'>
          {enumFormsCategoryRows.map(({ name, type, formType, formTypeLabel, description }, index) => (
            <tr key={index}>
              {type === LINE_TYPE.TITLE ? (
                <td colSpan={4}>
                  <h3 className='u-mb--unset u-mt--20' style={{ whiteSpace: 'normal' }}>
                    {name}
                  </h3>
                </td>
              ) : null}
              {type === LINE_TYPE.SUBTITLE ? (
                <td colSpan={4}>
                  <h4 className='u-mb--unset u-mt--20' style={{ whiteSpace: 'normal' }}>
                    {name} {description && <span style={{ textTransform: 'none', marginLeft: 5 }}>{description}</span>}
                  </h4>
                </td>
              ) : null}

              {type === LINE_TYPE.FORM && formType ? (
                <>
                  <td className='u-align--center' style={{ minWidth: 80 }}>
                    {formTypeLabel}
                  </td>
                  <td>
                    <div style={{ maxWidth: 950, whiteSpace: 'normal' }}>{name}</div>
                  </td>
                  <td className='u-align--center'>
                    <span
                      onClick={() => openModalNewForm(formType)}
                      style={{ cursor: 'pointer' }}
                      title={'Online formulář ' + formTypeLabel}
                    >
                      <FileEarmarkPlus />
                    </span>
                  </td>
                  <td className='u-align--center'>
                    <a
                      href={formTypeToMetodic(formType)}
                      target='_blank'
                      rel='noopener noreferrer'
                      title={'Metodické pokyny ' + formTypeLabel}
                    >
                      <InfoCircle />
                    </a>
                  </td>
                </>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableFormTypes
