import { iSchemaFull } from '../interfaces/iSchemaFull'
import { convertUiSchemaInheritRules } from '../convertors/convertUiSchemaInheritRules'
import { findAllRulesCompact } from './findAllRules'
import { isVisibleResolveAll } from './isVisibleRules'

export interface iRet {
  enabled: string[]
  visible: string[]
  enabledSome: string[]
  visibleSome: string[]
  enableSomeNoLikeType: string[]
  visibleSomeNoLikeType: string[]
}

export interface iResolveRules {
  visibleEnabledScopes: string[]
  visibleEnabledScopesSome: string[]
  visibleEnabledScopesSomeNoLikeType: string[]
}

export const resolveRules = (dataFull: any, schemaFull?: iSchemaFull) => {
  const schemaLayout = schemaFull?.layout
  const schemaData = schemaFull?.properties
  const allRules = schemaLayout ? findAllRulesCompact(convertUiSchemaInheritRules(schemaLayout)) : {}

  const isVisibleResolve = (thisScope: string) => isVisibleResolveAll(dataFull, thisScope, allRules)
  const resolveSubTree = (data: any, schema: any, scope: string) => {
    const ret: iRet = {
      visible: [],
      enabled: [],
      visibleSome: [],
      enabledSome: [],
      enableSomeNoLikeType: [],
      visibleSomeNoLikeType: [],
    }
    const addRet = ({
      enabled,
      visible,
      enabledSome,
      visibleSome,
      enableSomeNoLikeType,
      visibleSomeNoLikeType,
    }: iRet) => {
      ret.enabled.push(...enabled)
      ret.visible.push(...visible)
      ret.enabledSome.push(...enabledSome)
      ret.visibleSome.push(...visibleSome)
      ret.enableSomeNoLikeType.push(...enableSomeNoLikeType)
      ret.visibleSomeNoLikeType.push(...visibleSomeNoLikeType)
    }
    for (const key in schema) {
      const schemaItem = schema[key]
      const thisScope = scope + '/' + key
      const thisData = data?.[key]

      const { enabled, visible, enabledSome, visibleSome, enableSomeNoLikeType, visibleSomeNoLikeType } =
        isVisibleResolve(thisScope)
      addRet({
        enabled: enabled ? [thisScope] : [],
        visible: visible ? [thisScope] : [],
        enabledSome: enabledSome ? [thisScope] : [],
        visibleSome: visibleSome ? [thisScope] : [],
        enableSomeNoLikeType: enableSomeNoLikeType ? [thisScope] : [],
        visibleSomeNoLikeType: visibleSomeNoLikeType ? [thisScope] : [],
      })

      if (schemaItem.items) {
        if (Array.isArray(thisData) && thisData?.length) {
          thisData.forEach((item: any, index) => {
            const subRet = resolveSubTree(item, schemaItem.items.properties, thisScope + '/' + index)
            addRet(subRet)
          })
        }
      } else if (schemaItem.properties) {
        const subRet = resolveSubTree(thisData, schemaItem.properties, thisScope)
        addRet(subRet)
      }
    }
    return ret
  }

  const rulesResult = resolveSubTree(dataFull, schemaData, '#')
  return {
    visibleEnabledScopes: rulesResult.visible.filter((value) => rulesResult.enabled.includes(value)),
    visibleEnabledScopesSome: rulesResult.visibleSome.filter((value) => rulesResult.enabledSome.includes(value)),
    visibleEnabledScopesSomeNoLikeType: rulesResult.visibleSomeNoLikeType.filter((value) =>
      rulesResult.enableSomeNoLikeType.includes(value),
    ),
  }
}

