import axios from 'axios'
import { Buffer } from 'buffer'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

export interface iValidationField {
  atribut: string
  chyba: string
  urovenChyby: string // TS - WARN | ERROR
  source?: string
}

export interface iValidationResponse {
  seznamChybFormular?: iValidationField[]
  seznamChybZadost?: iValidationField[]
  zakazaneFieldy?: iValidationFieldSimple[] // TS - smazat
  povinneFieldy?: iValidationFieldSimple[] // TS - smazat
}

export interface iValidationLiveResponse {
  seznamChybFormular?: iValidationField[] // TS - sjednotit
  seznamChybZadost?: iValidationField[] // TS - sjednotit
  zakazaneFieldy?: iValidationFieldSimple[]
  povinneFieldy?: iValidationFieldSimple[]
  skryteFieldy?: iValidationFieldSimple[]
}

export interface iValidationFieldSimple {
  atribut: string
  chyba?: string
}

class ValidationApi2 {
  api = axios.create({
    baseURL: process.env.REACT_APP_VALIDATION_V2_API_URL,
  })

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
  }

  validateLive = (fieldPath: string, formData: any) => {
    const formDataBase64 = Buffer.from(JSON.stringify(formData) || '').toString('base64') || undefined

    return this.api.post<iValidationLiveResponse>(`/podani/validovat-live`, {
      validovanyField: fieldPath,
      data: formDataBase64,
    })
  }

  validateAll = (formData: any, reqData: any, formularOpravuje = false) => {
    const formDataBase64 = Buffer.from(JSON.stringify(formData) || '').toString('base64')
    const reqDataBase64 = Buffer.from(JSON.stringify(reqData) || '').toString('base64')
    return this.api.post<iValidationResponse>(`/podani/validovat`, {
      formular: formDataBase64,
      zadostOUverejneni: reqDataBase64,
      formularOpravuje: formularOpravuje,
    })
  }
}

const VA = new ValidationApi2()
export default VA
