import { iDataSchemas } from '../interfaces/iDataSchema'

export const convertDataOrderLikeSchemaSimple = (data: any, schema: iDataSchemas): any => {
  if (data === undefined) {
    return undefined
  }

  const ret: any = {}
  for (const key in schema) {
    if (typeof data === 'object' && key in data) {
      const schemaItem = schema[key]
      if (schemaItem.items && Array.isArray(data[key])) {
        const schemaItemsProperties = schemaItem.items.properties
        ret[key] = data[key].map((item: any) =>
          schemaItemsProperties ? convertDataOrderLikeSchemaSimple(item, schemaItemsProperties) : item,
        )
      } else if (schemaItem.properties) {
        ret[key] = convertDataOrderLikeSchemaSimple(data[key], schemaItem.properties)
      } else {
        ret[key] = data[key]
      }
    }
  }

  return ret
}
