import { DATA_TYPE } from '../../enums_v1/enumDataType'
import { iSchemaDatas } from '../interfaces/iSchemaData'
import { KEY_P_FORMATTEXT, KEY_P_FORMATTEXT_2 } from '../specialChars'

export const convertDataTypeLikeSchema = (data: any, schema: iSchemaDatas): any => {
  if (data === undefined) {
    return undefined
  }
  const ret: any = {}
  for (const key in data) {
    if (key in schema) {
      const schemaItem = schema[key]
      if (schemaItem.items) {
        ret[key] = Array.isArray(data[key]) ? data[key] : [data[key]]
        const schemaItemsProperties = schemaItem.items.properties
        if (schemaItemsProperties) {
          //simple array
          if (
            'el_text_nest' in schemaItemsProperties &&
            Object.keys(schemaItemsProperties).length === 1 &&
            !(typeof ret[key]?.[0] === 'object' && 'el_text_nest' in ret[key]?.[0])
          ) {
            ret[key] = ret[key].map((item: any) => ({ el_text_nest: item }))
          } else {
            ret[key] = ret[key].map((item: any) => convertDataTypeLikeSchema(item, schemaItemsProperties))
          }
        } else {
          // pripadne simple array
          ret[key] = ret[key].map((item: any) => convertOneItemType(item, schemaItem.type))
        }
      } else if (schemaItem.properties) {
        //zanorena hodnota bez atributu
        if (
          'el_text_nest' in schemaItem.properties &&
          typeof data[key] === 'string' &&
          !(typeof data[key] === 'object' && 'el_text_nest' in data[key])
        ) {
          ret[key] = { el_text_nest: convertOneItemType(data[key], schemaItem.type) }
        } else if (
          KEY_P_FORMATTEXT in schemaItem.properties &&
          !(typeof data[key] === 'object' && KEY_P_FORMATTEXT in data[key])
        ) {
          ret[key] = { [KEY_P_FORMATTEXT]: typeof data[key] === 'string' ? data[key] : undefined }
        } else if (
          KEY_P_FORMATTEXT_2 in schemaItem.properties &&
          !(typeof data[key] === 'object' && KEY_P_FORMATTEXT_2 in data[key])
        ) {
          ret[key] = { [KEY_P_FORMATTEXT_2]: typeof data[key] === 'string' ? data[key] : undefined }
        } else {
          ret[key] = convertDataTypeLikeSchema(data[key], schemaItem.properties)
        }
      } else {
        const dataType = schemaItem.type
        ret[key] = convertOneItemType(data[key], dataType)
      }
    }
  }
  return ret
}

const convertOneItemType = (value: any, dataType: string) => {
  if (dataType === DATA_TYPE.boolean) {
    return !!value
  } else if (dataType === DATA_TYPE.number) {
    return parseFloat(value)
  } else if (dataType === DATA_TYPE.integer) {
    return parseInt(value)
  } else {
    return value
  }
}
