import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useMatch } from 'react-router-dom'
import { useMobileMenu } from '../../hooks/useMobileMenu'
import { useModal } from '../../hooks/useModal'
import { useUser } from '../../hooks/useUser'
import { LINKS } from '../App/LINKS'
import Loading from '../Loading/Loading'

const MenuLinkClick = ({ label, onClick }: { label: string; onClick: () => void }) => {
  return (
    <div className={'gov-header__link '}>
      <div
        onClick={() => {
          onClick()
        }}
        className='gov-link  gov-link--standalone  gov-link--large  gov-link--inversed'
      >
        {label}
      </div>
    </div>
  )
}
const MenuLinkExternal = ({ link, label }: { label: string; link: string }) => {
  return (
    <div className={'gov-header__link '}>
      <a className='gov-link  gov-link--standalone  gov-link--large  gov-link--inversed' href={link}>
        {label}
      </a>
    </div>
  )
}

const MenuLink = ({ link, label }: { link: string; label: string }) => {
  const match = useMatch(link)

  const ref = useRef<any>(null)
  return (
    <div className={'gov-header__link ' + (match ? ' is-active' : '')}>
      <Link
        ref={ref}
        to={link}
        onClick={ref.current?.blur()}
        className='gov-link  gov-link--standalone  gov-link--large  gov-link--inversed'
      >
        {label}
      </Link>
    </div>
  )
}

type MenuProps = {
  mobile?: boolean
}

const Menu = ({ mobile = false }: MenuProps) => {
  const { t } = useTranslation()
  const { userIsLoged, userLoading, usernameFull, login, logout, hasFullAccess } = useUser()
  const { mobileMenuOpen, setMenuOpen } = useMobileMenu()
  const { openModalNotif } = useModal()

  return (
    <nav className={'gov-header__nav ' + (mobileMenuOpen ? 'active' : '')}>
      <div className='gov-header__nav-logo'></div>
      {userLoading ? (
        <Loading inversed />
      ) : (
        <div className='gov-header__nav-holder' onClick={() => setMenuOpen(false)}>
          <MenuLink link={LINKS.home} label={t('menu.uvod', 'Úvod')} />
          {hasFullAccess && userIsLoged && (
            <MenuLink link={LINKS.mojeVyhlaseni} label={t('menu.moje', 'Moje vyhlášení')} />
          )}
          {hasFullAccess && userIsLoged && (
            <MenuLink link={LINKS.podatFormular} label={t('menu.podat', 'Podat formulář')} />
          )}
          {hasFullAccess && <MenuLink link={LINKS.vyhledatFormular} label={t('menu.vyhledat', 'Vyhledat formulář')} />}
          {hasFullAccess && <MenuLink link={LINKS.vyhledatProfil} label={t('menu.profil', 'Vyhledat profil')} />}
          {hasFullAccess && !userIsLoged && (
            <MenuLinkClick
              onClick={openModalNotif}
              label={t('modalNotif.odebirat_upozorneni', 'Odebírat upozornění na nové zakázky')}
            />
          )}
          <MenuLink link={LINKS.napoveda} label={t('menu.napoveda', 'Nápověda a dokumentace')} />
          {mobile && (
            <>
              {userIsLoged ? (
                <>
                  <MenuLinkExternal link={LINKS.cdzMujUcet} label={usernameFull} />
                  <MenuLinkClick
                    onClick={() => {
                      logout()
                    }}
                    label={t('menu.odhlasit', 'Odhlásit se')}
                  />
                </>
              ) : (
                <>
                  <MenuLinkClick
                    onClick={() => {
                      login()
                    }}
                    label={t('menu.prihlasit', 'Přihlásit se')}
                  />
                  <MenuLinkExternal link={LINKS.cdzRegistrace} label={t('menu.registrovat', 'Registrovat')} />
                </>
              )}
            </>
          )}
        </div>
      )}
    </nav>
  )
}

export default Menu
