import React, { useEffect } from 'react'
import { FormProvider, useForm, ValidationMode } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading/Loading'
import { ENV } from '../../ENV'
import { setLoadFormData, setLoadReqData } from '../../redux/formState/formStateSlice'
import { selectLoadFormData, selectLoadReqData } from '../../redux/formState/selectors'
import { selectFormIsFullInit } from '../../redux/formTree/formTreeSelectors'
import { useAppDispatch } from '../../redux/store'
import { useFormRenderContext } from '../context/RenderFormContext'
import DebugFormData from '../form_elements/DebugFormData'
import FormTreeWatch from '../form_elements/FormTreeWatch'
import GlobalFormsStore from '../GlobalFormsStore'
import { useFormLiveValidation } from '../hooks/useFormLiveValidation'
import RenderFormElements from './RenderFormElements'

const RenderForm = () => {
  const dispatch = useAppDispatch()
  const {
    // dateSchemaDefaults,
    shouldUnregisterInput,
    dataSchemas,
    submissionValues,
    dateSchemaDefaultsCalculate,
    isReq,
    validationMode,
    formDisabled,
  } = useFormRenderContext()

  const { validateFormLive } = useFormLiveValidation()

  const methods = useForm({
    shouldUnregister: shouldUnregisterInput,
    // defaultValues: dateSchemaDefaults,
    mode: (validationMode as keyof ValidationMode) || 'onBlur',
  })

  const loadData = useSelector(isReq ? selectLoadReqData : selectLoadFormData)
  const isFullInit = useSelector(selectFormIsFullInit)

  useEffect(() => {
    for (const key in submissionValues) {
      if (submissionValues.hasOwnProperty(key)) {
        methods.setValue(key, submissionValues[key])
      }
    }
    GlobalFormsStore.setForm(isReq, methods, dataSchemas, dateSchemaDefaultsCalculate)
    dispatch(isReq ? setLoadReqData(true) : setLoadFormData(true))
    return () => {
      dispatch(isReq ? setLoadReqData(false) : setLoadFormData(false))
    }
  }, [])

  useEffect(() => {
    let timeoutId: any = null
    // inicializacni provolani validace - nastavi mandatory a forbiden
    if (loadData) {
      timeoutId = setTimeout(() => {
        const force = !validationMode && !formDisabled // zruseno pro wizarda
        validateFormLive(force)
      }, 500)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [loadData])

  if (!loadData) {
    return <Loading />
  }

  return (
    <FormProvider {...methods}>
      {isFullInit && <FormTreeWatch />}

      <form className='u-mt--30 u-mb--95'>
        <RenderFormElements />
      </form>

      {/*<DevTool control={methods.control} />*/}
      {ENV.DEBUG_MODE && <DebugFormData isReq={isReq} />}
    </FormProvider>
  )
}

export default React.memo(RenderForm)
