import React from 'react'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import FilterContractsAdmin from '../../tables/TableContractsAdmin/FilterContractsAdmin'
import TableContractsAdmin from '../../tables/TableContractsAdmin/TableContractsAdmin'

const AdminSezamanFormularuPage = () => {
  return (
    <PageWrap title='Seznam formulářů'>
      <FilterContextProvider name={TABLE_TYPES.adminContracts}>
        <FilterContractsAdmin />
        <TableContractsAdmin />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default AdminSezamanFormularuPage
