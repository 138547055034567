import React from 'react'
import { Outlet } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Loading from '../Loading/Loading'
import ProtectOrgUser from './ProtectOrgUser'

interface iLayout {
  isAdmin?: boolean
}

const Layout = ({ isAdmin }: iLayout) => {
  const { userLoading } = useUser()

  return (
    <>
      <Header isAdmin={isAdmin} />
      {/*<InfoLine />*/}
      <main role='main' id='content'>
        {userLoading ? (
          <Loading />
        ) : (
          <ProtectOrgUser>
            <Outlet />
          </ProtectOrgUser>
        )}
      </main>
      <Footer />
    </>
  )
}

export default Layout
