import React from 'react'
import { useParams } from 'react-router-dom'
import FormTxt from '../../components/FormTxt/FormTxt'

const FormularTxtPage = () => {
  const { id } = useParams()

  return (
    <div className='gov-container gov-container--wide gov-container--special overflowVisible'>
      <div className='gov-container__content container_bg_white overflowVisible'>
        {id ? <FormTxt id={id} /> : 'Chybné ID formuláře'}
      </div>
    </div>
  )
}

export default FormularTxtPage
