import React from 'react'
import { useTranslation } from 'react-i18next'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import NewsList from './NewsList'

const AktualityPage = () => {
  const { t } = useTranslation()

  return (
    <PageWrap title={t('news.aktuality_informace', 'Aktuality a informace')}>
      <FilterContextProvider name={TABLE_TYPES.newsList}>
        <NewsList />
      </FilterContextProvider>
    </PageWrap>
  )
}

export default AktualityPage
