import React, { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIndexesContext } from '../context/IndexesContext'
import { iElement } from '../interfaces/iElement'

interface iDebugElement {
  elementData: iElement
}

const DebugElement = ({ elementData }: iDebugElement) => {
  const [open, setOpen] = useState(false)
  const indexes = useIndexesContext().indexes

  return (
    <span style={{ position: 'relative', width: 'auto' }}>
      <small
        style={{
          position: 'absolute',
          zIndex: 99,
          marginTop: -10,
          marginLeft: -5,
          background: 'rgba(220,220,220,0.8)',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
        onClick={() => setOpen(!open)}
        title={elementData.path}
      >
        {indexes} {elementData.uiSchema.type}
        <small> | {elementData.uiSchema.scope?.split('/').slice(-1) || elementData.uiSchema.labelId} </small>
      </small>
      {open && (
        <div
          onClick={() => setOpen(false)}
          style={{
            fontSize: 14,
            zIndex: 9999999,
            position: 'absolute',
            background: 'rgba(260,260,260,0.8)',
            border: '1px solid black',
          }}
        >
          <DebugElementFormData elementData={elementData} />
        </div>
      )}
    </span>
  )
}

// kvuli regexp v pattern v hook form rules
const stringifyRegexpReplacer = (key: string, value: any) => (value instanceof RegExp ? value.toString() : value)

const DebugElementFormData = ({ elementData }: { elementData: iElement }) => {
  const { getFieldState } = useFormContext()
  const val = useWatch({ name: elementData.path })
  const state = getFieldState(elementData.path)
  return (
    <>
      <pre>{JSON.stringify(elementData, stringifyRegexpReplacer, 2)}</pre>
      <pre>{JSON.stringify({ fieldData: val, fieldState: state }, undefined, 2)}</pre>
    </>
  )
}

export default DebugElement
